import { get, isEmpty } from "lodash";
import { Reducer } from "redux";
import { REHYDRATE } from "redux-persist";
import { initializeGTM } from "../../utils/GoogleTagManager";

import {
  authenticate,
  forgotPassword,
  login,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  register,
  requestOTP,
  resetPassword,
  updateUser,
  uploadProfileImage,
  validateToken,
  verifyOTP,
} from "./routines";
import { AuthState } from "./types";

// Type-safe initialState!
const initialState: AuthState = {
  authenticated: false,
  errors: undefined,
  imageUploadLoading: false,
  loading: false,
  user: undefined,
};

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    // Triggers
    case forgotPassword.TRIGGER:
    case login.TRIGGER:
    case loginWithFacebook.TRIGGER:
    case loginWithGoogle.TRIGGER:
    case logout.TRIGGER:
    case register.TRIGGER:
    case resetPassword.TRIGGER:
    case updateUser.TRIGGER:
    case validateToken.TRIGGER:
    case verifyOTP.TRIGGER:
    case requestOTP.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    case authenticate.TRIGGER: {
      return state;
    }

    case uploadProfileImage.TRIGGER: {
      return { ...state, imageUploadLoading: true, errors: undefined };
    }

    // Success
    case authenticate.SUCCESS:
    case login.SUCCESS:
    case loginWithFacebook.SUCCESS:
    case loginWithGoogle.SUCCESS:
    case register.SUCCESS: {
      return {
        ...state,
        authenticated: true,
        user: action.payload,
      };
    }

    case logout.SUCCESS: {
      return {
        ...state,
        authenticated: false,
        user: undefined,
      };
    }

    case updateUser.SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case verifyOTP.SUCCESS: {
      return {
        ...state,
        user: { ...state.user, status: "Verified" },
        errors: undefined,
      };
    }

    case requestOTP.SUCCESS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case uploadProfileImage.SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case resetPassword.SUCCESS:
    case forgotPassword.SUCCESS:
    case validateToken.SUCCESS: {
      return {
        ...state,
      };
    }

    case authenticate.FAILURE: {
      return {
        ...state,
      };
    }

    // Failure
    case forgotPassword.FAILURE:
    case login.FAILURE:
    case loginWithFacebook.FAILURE:
    case loginWithGoogle.FAILURE:
    case logout.FAILURE:
    case register.FAILURE:
    case resetPassword.FAILURE:
    case uploadProfileImage.FAILURE:
    case validateToken.FAILURE: {
      return {
        ...state,
        authenticated: false,
        errors: action.payload,
        user: undefined,
      };
    }

    case verifyOTP.FAILURE:
    case requestOTP.FAILURE:
    case updateUser.FAILURE: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    // Fullfill
    case authenticate.FULFILL:
    case forgotPassword.FULFILL:
    case login.FULFILL:
    case loginWithFacebook.FULFILL:
    case loginWithGoogle.FULFILL:
    case logout.FULFILL:
    case register.FULFILL:
    case resetPassword.FULFILL:
    case updateUser.FULFILL:
    case uploadProfileImage.FULFILL:
    case validateToken.FULFILL:
    case verifyOTP.FULFILL:
    case requestOTP.FULFILL: {
      return {
        ...state,
        imageUploadLoading: false,
        loading: false,
      };
    }

    case REHYDRATE: {
      const auth: AuthState = get(action, "payload.auth");
      if (get(auth, "authenticated", false) && !isEmpty(get(auth, "user"))) {
        initializeGTM({
          user: auth.user,
        });
      } else {
        initializeGTM();
      }

      return {
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as authReducer };
