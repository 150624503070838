import _, { get, has, isEmpty } from "lodash";
import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import {
  buildQuery,
  formatName,
  getParams,
  isGuest,
  mapInviteCard,
} from "../../utils/listingUrlHelper";
import { enqueueSnackbar } from "../notifications";
import {
  fetchActiveListings,
  fetchActiveListingsById,
  fetchActiveListingsFilters,
  fetchEnquiry,
  fetchPartners,
  fetchRecommendedProperties,
  fetchRecommendedPropertiesByQuery,
  listingsSearch,
} from "./routines";

function* handleFetchActiveListings(action: AnyAction) {
  try {
    const guestEndpoint = isGuest() ? "/guest" : "";
    const { limit, start } = action.payload;
    const $limit = limit || 10;
    const $start = start || 0;
    const payload = action.payload;
    const params = getParams(window.location.pathname);
    if (
      params &&
      params.listingType &&
      params.listingSector &&
      params.organisationName
    ) {
      const orgName = get(
        payload,
        "organisationName",
        get(params, "organisationName", undefined)
      );
      Object.assign(payload, {
        ...params,
        organisationName: formatName(orgName, true),
      });
    }
    const query = buildQuery(payload);

    if (!_.isEmpty(query) && false) {
      // todo disable till clarified
      addGTMDataLayer({
        data: { searchTerms: query },
        event: "tenant_searched",
        page: "listings",
      });
    }
    const res = yield call(
      callApi,
      "get",
      `/v3${guestEndpoint}/search${
        _.isEmpty(query) ? "?" : `${query}&`
      }_start=${$start}&_limit=${$limit}`
    );

    const data = res.data;
    const count = data.length; // This count is for the infinite scroll to work
    const dataLimit = count > $limit ? $limit : count;

    const listingsWithImagesSorted = data
      .slice(0, dataLimit)
      .map((listing: any) => {
        const images = listing.images.sort((imageA: any, imageB: any) => {
          if (
            imageA.type === "cover" &&
            ["interior", "exterior", "kitchen", "bedroom", "bathroom"].includes(
              imageB.type
            )
          ) {
            return -1;
          }

          if (
            imageA.type === "exterior" &&
            ["interior", "kitchen", "bedroom", "bathroom"].includes(imageB.type)
          ) {
            return -1;
          }

          if (
            imageA.type === "interior" &&
            ["kitchen", "bedroom", "bathroom"].includes(imageB.type)
          ) {
            return -1;
          }

          return 0;
        });

        return {
          ...listing,
          images,
        };
      });

    yield put(
      fetchActiveListings.success({
        count,
        listings: listingsWithImagesSorted,
        loadMore: has(action, "payload.loadMore") ? payload.loadMore : false,
      })
    );
  } catch (err) {
    if (err.response) {
      yield put(
        fetchActiveListings.failure(
          errorHandler(get(err, "response.data", err.response))
        )
      );
    } else {
      yield put(fetchActiveListings.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveListings.fulfill());
  }
}

function* handleFetchActiveListingsFilters(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", "/enums", {
      pullFromListings: false,
    });
    yield put(fetchActiveListingsFilters.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchActiveListingsFilters.failure(errorHandler(err.response)));
    } else {
      yield put(fetchActiveListingsFilters.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveListingsFilters.fulfill());
  }
}

function* handleFetchActiveListingsById(action: AnyAction) {
  let url = `/v3/guest/properties/${action.payload.id}`;
  const previewToken = get(action, "payload.previewToken", undefined);

  if (!isEmpty(previewToken)) {
    url = `${url}?previewToken=${previewToken}`;
  }

  try {
    const res = yield call(callApi, "get", url, {
      pullFromListings: false,
    });

    yield put(fetchActiveListingsById.success(res.data));
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      yield put(fetchActiveListingsById.failure(errorHandler(err.response)));
    } else {
      yield put(fetchActiveListingsById.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveListingsById.fulfill());
  }
}

function* handleFetchEnquiry(action: AnyAction) {
  const url = `/anonymous-user/enquiry/${action.payload.token}`;

  try {
    const res = yield call(callApi, "get", url, {
      pullFromListings: false,
    });

    yield put(fetchEnquiry.success(mapInviteCard(res)));
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      yield put(fetchEnquiry.failure(errorHandler(err.response)));
    } else {
      yield put(fetchEnquiry.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchEnquiry.fulfill());
  }
}

function* handleFetchPartners(action: AnyAction) {
  const url = `/partners?status=published`;
  try {
    const res = yield call(callApi, "get", url, {
      pullFromListings: true,
    });

    yield put(fetchPartners.success(res.data));
    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response) {
      yield put(fetchPartners.failure(errorHandler(err.response)));
    } else {
      yield put(fetchPartners.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchPartners.fulfill());
  }
}

function* handleFetchRecommendedProperties(action: AnyAction) {
  const guestEndpoint = isGuest() ? "/guest" : "";
  const url = `/v3${guestEndpoint}/search/${action.payload}/recommendation`;
  try {
    const res = yield call(callApi, "get", url, {
      pullFromListings: false,
    });

    yield put(fetchRecommendedProperties.success(res.data));
    return res.data;
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      yield put(fetchRecommendedProperties.failure(errorHandler(err.response)));
    } else {
      yield put(fetchRecommendedProperties.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchRecommendedProperties.fulfill());
  }
}

function* handleFetchRecommendedPropertiesByQuery(action: AnyAction) {
  const query = buildQuery(action.payload);
  const guestEndpoint = isGuest() ? "/guest" : "";
  const url = `/v3${guestEndpoint}/agency/recommendation${query}`;
  try {
    const res = yield call(callApi, "get", url, {
      pullFromListings: false,
    });

    yield put(fetchRecommendedPropertiesByQuery.success(res.data));
    return res.data;
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      yield put(
        fetchRecommendedPropertiesByQuery.failure(errorHandler(err.response))
      );
    } else {
      yield put(
        fetchRecommendedPropertiesByQuery.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(fetchRecommendedPropertiesByQuery.fulfill());
  }
}

function* fetchEnquiryWatcher() {
  yield takeEvery(fetchEnquiry.TRIGGER, handleFetchEnquiry);
}

function* fetchActiveListingsWatcher() {
  yield takeEvery(fetchActiveListings.TRIGGER, handleFetchActiveListings);
}

function* fetchActiveListingsFiltersWatcher() {
  yield takeEvery(
    fetchActiveListingsFilters.TRIGGER,
    handleFetchActiveListingsFilters
  );
}

function* fetchActiveListingsByIdWatcher() {
  yield takeEvery(
    fetchActiveListingsById.TRIGGER,
    handleFetchActiveListingsById
  );
}

function* fetchPartnersWatcher() {
  yield takeEvery(fetchPartners.TRIGGER, handleFetchPartners);
}

function* fetchRecommendedPropertiesWatcher() {
  yield takeEvery(
    fetchRecommendedProperties.TRIGGER,
    handleFetchRecommendedProperties
  );
}

function* fetchRecommendedPropertiesByQueryWatcher() {
  yield takeEvery(
    fetchRecommendedPropertiesByQuery.TRIGGER,
    handleFetchRecommendedPropertiesByQuery
  );
}

// Error handlers
function* handleListingError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: get(action, "payload", "An unknown error occured"),
      options: {
        variant: "error",
      },
    })
  );
}

function* listingErrorWatcher() {
  yield takeEvery(
    [
      fetchActiveListingsById.FAILURE,
      fetchRecommendedProperties.FAILURE,
      fetchActiveListings.FAILURE,
      fetchEnquiry.FAILURE,
      fetchPartners.FAILURE,
      listingsSearch.FAILURE,
    ],
    handleListingError
  );
}

function* handleListingsSearch(action: AnyAction) {
  yield put(listingsSearch.success(action.payload));
}

function* handleListingsSearchWatcher() {
  yield takeEvery([listingsSearch.TRIGGER], handleListingsSearch);
}

export function* listingsSaga() {
  yield all([
    fork(fetchActiveListingsWatcher),
    fork(fetchActiveListingsFiltersWatcher),
    fork(fetchActiveListingsByIdWatcher),
    fork(listingErrorWatcher),
    fork(handleListingsSearchWatcher),
    fork(fetchEnquiryWatcher),
    fork(fetchPartnersWatcher),
    fork(fetchRecommendedPropertiesWatcher),
    fork(fetchRecommendedPropertiesByQueryWatcher),
  ]);
}
