import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import { ApplicationState } from '../store';
import { verifyOTPPromise as verifyOTPPromiseAction } from '../store/auth';
import styled from '../utils/styledComponents';

const Seperator = styled.span`
  width: 10px;
`;

interface PropsFromState {
  errors: string;
  loading: boolean;
}

interface PropsFromDispatch {
  verifyOTPPromise: typeof verifyOTPPromiseAction;
}

type AllProps = PropsFromState & PropsFromDispatch;

const VerifyOTPContainer: React.FunctionComponent<AllProps> = ({
  loading,
  errors,
  verifyOTPPromise,
}: AllProps) => {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (errors) {
      setCode("");
    }
  }, [errors]);

  const OTPChanged = async (val: string) => {
    await setCode(val);

    if (val.length === 6) {
      await verifyOTPPromise({ code: val });
    }
  };

  return (
    <OtpInput
      onChange={OTPChanged}
      numInputs={6}
      value={code}
      separator={<Seperator />}
      isDisabled={loading}
      shouldAutoFocus={true}
      isInputNum={true}
      inputStyle={{ width: "1.5em", height: "1.65em" }}
    />
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
  loading: auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    { verifyOTPPromise: verifyOTPPromiseAction },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOTPContainer) as any;
