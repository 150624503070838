import React from "react";
import Table, { TableProps } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import { get, isBoolean, isEmpty, isEqual, isNumber, startCase } from "lodash";
import {
  formatAmount,
  formatNumber,
  getSquaredMeasurement,
} from "../../utils/numberFormatter";
import {
  MEASUREMENT_TYPES,
  PropertyItem,
} from "../../utils/propertyOverviewItems";
import moment from "moment";
import { LocaleSettingsProps } from "../../store/organisation";
import { Listing } from "../../store/listings";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontFamily: "MuseoSans-300",
    fontWeight: 300,
    border: "none !important",
    padding: "6px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F7F7F7",
    },
  },
}))(TableRow);

interface OwnProps {
  listing: Listing;
  localeSettings: LocaleSettingsProps;
}

type AllProps = OwnProps & TableProps;

const PropertyOverviewTable: React.FunctionComponent<AllProps> = ({
  localeSettings,
  listing,
}) => {
  let data = get(listing, "propertyOverView", undefined);
  const formatValue = (value: any) => {
    const formattedAmount = formatAmount(value, localeSettings);
    return formattedAmount;
  };

  const isVehicle = isEqual(listing.listingSector, "vehicle");

  if (isVehicle) {
    const vehicleSpecs = get(listing, "syndicator.doc.specs") || {};
    const specsData = Object.keys(vehicleSpecs)
      .filter(key => ["make", "model", "engine", "style", "seating", "color"].includes(key))
      .map(key => ({
        datatype: 'string',
        name: key,
        value: vehicleSpecs[key]
      }));

    data = data
      .filter((item: any) => item.name === "Property Type")
      .map((item: any) => ({ ...item, name: "Listing Type" }))
      .concat(specsData);
  }

  const isValid = (value: any): boolean =>
    !isEmpty(value) ||
    (isNumber(value) && value > 0) ||
    (isBoolean(value) && value);

  const getIntegerMeasurement = (measurementType: string, value: any) => {
    if (measurementType === "Metres Squared") {
      const listingMeasurement =
        get(listing, "syndicator.doc.measurement_type") || "";
      const measurement =
        get(MEASUREMENT_TYPES, `${listingMeasurement}`) ||
        getSquaredMeasurement(localeSettings);
      return `${formatNumber(value, localeSettings)} ${
        !isEmpty(MEASUREMENT_TYPES[measurementType]) ? measurement : ""
      }`;
    }
    const displayedValue = `${formatNumber(value, localeSettings)} ${
      !isEmpty(MEASUREMENT_TYPES[measurementType])
        ? MEASUREMENT_TYPES[measurementType]
        : ""
    }`;
    return displayedValue;
  };

  const getTableRow = (record: PropertyItem) => {
    if (record.name && isValid(record["value"])) {
      const value = record["value"];
      let displayedValue = value;
      switch (record.datatype) {
        case "money":
          displayedValue = formatValue(value);
          break;
        case "bool":
          displayedValue = value ? "Yes" : "No";
          break;
        case "date":
          displayedValue = moment(value).format("DD/MM/YYYY").toLocaleString();
          break;
        case "int":
          displayedValue = !isEmpty(record.measurementType)
            ? getIntegerMeasurement(record.measurementType!, value)
            : formatNumber(value, localeSettings);
          break;
        default:
          displayedValue = value;
      }

      return (
        <StyledTableRow key={record.name}>
          <StyledTableCell>{startCase(record.name)}</StyledTableCell>
          <StyledTableCell align="right">{displayedValue}</StyledTableCell>
        </StyledTableRow>
      );
    }
    return false;
  };

  const tableRows: any = data
    .filter((rec: PropertyItem) => rec.name !== "Property Title")
    .map((rec: PropertyItem) => getTableRow(rec));

  return (
    <Table>
      <TableBody>{data && tableRows}</TableBody>
    </Table>
  );
};
export default PropertyOverviewTable;
