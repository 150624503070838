import { withFormik } from "formik";
import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import * as Yup from "yup";

import LoginForm, { LoginFormValues } from "../components/forms/LoginForm";
import { ApplicationState } from "../store";
import { loginPromise } from "../store/auth";
import { DialogsState, toggleMessageDialog } from "../store/dialogs";
import styled from "../utils/styledComponents";

const Wrapper = styled.div`
  flex: 1;
  justify-self: center;
  align-self: center;
`;

interface PropsFromState {
  authenticated: boolean;
  location: Location;
  dialogs: DialogsState;
}

interface PropsFromDispatch {
  loginPromise: typeof loginPromise;
  toggleMessageDialog: typeof toggleMessageDialog;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

type AllProps = PropsFromState & PropsFromDispatch;

class LoginFormContainer extends React.Component<AllProps> {
  public render() {
    const { authenticated } = this.props;

    if (!authenticated) {
      return <Wrapper>{this.renderForm()}</Wrapper>;
    }

    return null;
  }

  private renderForm() {
    const formikEnhancer = withFormik<{}, LoginFormValues>({
      displayName: "LoginForm",
      handleSubmit: async (values, { setSubmitting }) => {
        try {
          const { dialogs } = this.props;
          await this.props.loginPromise(values).then((res) => {
            const { openMessageDialog } = dialogs;
            if (
              !openMessageDialog.messageSent &&
              // tslint:disable-next-line: no-string-literal
              openMessageDialog["toggleLogin"] &&
              openMessageDialog.openDialog &&
              openMessageDialog.unitId
            ) {
              this.props.toggleMessageDialog({
                openDialog: openMessageDialog.openDialog,
                toggleLogin: false,
                unitId: openMessageDialog.unitId,
              });
            }
          });
        } catch (error) {
          console.log(error);
        } finally {
          setSubmitting(false);
        }
      },
      mapPropsToValues: () => ({ email: "", password: "" }),
      validationSchema: schema,
    });
    const EnhancedLoginForm = formikEnhancer(LoginForm);

    return <EnhancedLoginForm />;
  }
}

const mapStateToProps = ({ auth, router, dialogs }: ApplicationState) => ({
  authenticated: auth.authenticated,
  dialogs,
  location: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ toggleMessageDialog }, dispatch),
  ...bindPromiseCreators({ loginPromise }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormContainer) as any;
