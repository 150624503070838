import parser from "html-react-parser";
import { get, has, isEmpty } from "lodash";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { Grid, Paper } from "@material-ui/core";

import placeHolder from "../../assets/images/placeholders/grey-placeholder.png";
import EnquiryFormContainer from "../../containers/EnquiryFormContainer";
import MessageFormContainer from "../../containers/MessageFormContainer";
import { ApplicationState } from "../../store";
import { AuthenticatedUser } from "../../store/auth";
import {
  DialogsState,
  toggleLoginDialog as toggleLoginDialogAction,
  toggleMessageDialog as toggleMessageDialogAction,
  toggleProfileDialog as toggleProfileDialogAction,
} from "../../store/dialogs";
import { Listing, Unit } from "../../store/listings";
import { DEFAULT_LOCALE_SETTINGS } from "../../utils/defaultLocaleSettings";
import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import { validateImg } from "../../utils/imageUrls";
import {
  formatPropertyAmountByLocale,
  formatAmount,
  formatNumber,
  getSquaredMeasurement,
} from "../../utils/numberFormatter";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import Button from "../buttons/Button";
import DialogWithImage from "../dialogs/DialogWithImage";
import FlowFontAwesomeIcon from "../icons/FlowFontAwesomeIcon";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";
import { getUnitPrice } from "../../utils/units.helper";
import { isNumeric } from "../../utils/general";

const StyledHeader = styled.span`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey4};
`;

const StyledIcon = styled(FlowFontAwesomeIcon).attrs(({ theme }) => {
  return {
    color: theme.colors.grey3,
  };
})`
  && {
    font-size: 40px;
  }
` as any;

const StyledGrid = styled(Grid)`
  && {
    border-radius: 10px;
    ${mediaDown.sm`width: 95vw; overflow: hidden`};
  }
`;

const StyledButton = styled(Button)`
  && {
    height: 40px;
    width: 108px;
    border-radius: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
  }
`;

const RoomFeatures = styled(Grid)`
  && {
    ${mediaDown.sm`padding-bottom: 30px`};
    ${mediaDown.sm`display: inline-flex`};
    ${mediaDown.sm`flex-direction: row`};
    ${mediaUp.sm`flex-direction: column`};

    ${mediaUp.sm`justify-content: flex-end`};
    ${mediaUp.sm`display: flex`};
    text-align: left;
  }
`;

const StyledFeature = styled.div`
  display: inline-flex;
  ${mediaUp.sm`display: grid`};
  ${mediaUp.sm`grid: 30px / 20px 100px`};
  ${mediaUp.sm`grid-gap: 17px`};

  ${mediaDown.sm`margin-right: 12px`};
  text-align: left;
`;

const StyledInfo = styled.p`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey3};
  font-size: 14px;
  line-height: 21px;
`;

const StyledContent = styled(Grid)`
  && {
    ${mediaDown.sm`margin-top: 20px`};
  }
`;

const StyledButtonBase = styled((props: UnitImgProps) => <div {...props} />)`
  && {
    display: flex;
    cursor: pointer;
    padding-right: 12px;
    border-radius: 10px;
    height: 130px;
    ${mediaUp.sm`width: 130px`};
    ${mediaDown.sm`width: 89vw`};
    ${mediaDown.sm`padding-right: 0`};
    background-size: cover;
    background-position: center;
    background-image: url(${({ src }) => src});
  }
`;

const StyledLabel = styled.a`
  display: none;
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: 12px;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.grey3};
  margin-top: 10px;
  padding-bottom: 3px;
`;

const FeatureName = styled.span`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  ${mediaDown.sm`margin-left: 15px`};
  text-align: left;
`;

const StyledPrice = styled.div`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey4};
  text-align: right;
  margin-bottom: 16px;
  ${mediaDown.sm`margin-top: 16px`};
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledMainContainer = styled.div`
  flex-flow: 1;
`;

const StyledPriceContainer = styled(Grid)`
  && {
    ${mediaDown.sm`display: inline-flex`};
    ${mediaDown.sm`flex-direction: row-reverse`};
    ${mediaDown.sm`justify-content: space-between`};
    ${mediaDown.sm`align-items: flex-start`};

    ${mediaUp.sm`align-items: flex-end`};
    ${mediaUp.sm`flex-direction: column`};
    ${mediaUp.sm`justify-content: flex-end`};
  }
`;

const StyledPaper = styled(Paper)`
  && {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 9px;
    padding-top: 9px;
    margin: auto;
    ${mediaDown.sm`margin-top: 20px`};
    border: 0;
    box-shadow: none;
  }
`;

const ViewAll = styled.div`
  margin-left: 6px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  align-items: flex-start;
  align-self: flex-end;
  height: 30px;
  width: 150px;
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  span {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.MS700};
    margin-right: 5px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 5px 10px;
    border: solid thin #6d6d6d;
  }
`;

export function isEmptyProfile(profile: any) {
  if (isEmpty(profile)) {
    return true;
  } else {
    return !Object.values(profile).every((value: any) => !isEmpty(value));
  }
}

export function unitImage(unit: any) {
  if (isEmpty(unit.images)) {
    return undefined;
  }
  const cover = unit.images.find((img: any) => img && img.type === "cover");
  return cover || unit.images[0];
}

interface UnitImgProps extends React.HTMLAttributes<any> {
  src: string;
}
export interface ActionProps {
  onClick?: () => void;
}

interface PropsFromState {
  authenticated: boolean;
  user?: AuthenticatedUser;
  dialogs: DialogsState;
  selectedProperty?: Listing;
}

interface PropsFromDispatch {
  toggleLoginDialog: typeof toggleLoginDialogAction;
  toggleProfileDialog: typeof toggleProfileDialogAction;
  toggleMessageDialog: typeof toggleMessageDialogAction;
}
interface OwnProps {
  unit: Unit;
  property: any;
  organisation: any;
  agentId: string;
  landlord: { name: string };
}

type AllProps = PropsFromState & PropsFromDispatch & ActionProps & OwnProps;

const AvailableUnit: React.FunctionComponent<AllProps> = ({
  unit,
  property,
  agentId,
  authenticated,
  user,
  organisation,
  dialogs,
  toggleMessageDialog,
  selectedProperty,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const openSendMessageDialog: boolean =
    dialogs.openMessageDialog.unitId === unit._id &&
    dialogs.openMessageDialog.openDialog;

  const openModal = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    addGTMDataLayer({
      data: { unitId: unit._id, listingId: unit.property },
      event: "tenant_viewed_gallery",
      page: "available-unit",
    });
    setModalIsOpen(true);
  };

  const closeModal = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setModalIsOpen(false);
  };

  const handleOpenMessageDialog = (): void => {
    addGTMDataLayer({
      data: {
        listingId: unit.property,
        title: get(unit, "title", property.title),
        user: has(user, "id") ? user?.id : undefined,
        price: Number(getUnitPrice(unit, true) || 0),
        listingType: get(selectedProperty, "listingType", ""),
        province: get(selectedProperty, "address.province", ""),
        suburb: get(selectedProperty, "address.suburb", ""),
        city: get(selectedProperty, "address.city", ""),
        organisation: get(selectedProperty, "organisation", ""),
      },
      event: "tenant_initiated_message",
      page: "chat-messages",
    });

    toggleMessageDialog({
      openDialog: true,
      unitId: unit._id,
    });
  };

  const handleCloseMessageDialog = () => {
    toggleMessageDialog({
      openDialog: false,
      unitId: unit._id,
    });
  };
  const subjectTitle = isEmpty(get(unit, "title", ""))
    ? property.title
    : `${property.title} - ${unit.title}`;

  const emailSubject = `mailto:info@flow.rent?Subject=Report Listing | ${subjectTitle}`;
  const localeSettings = get(
    organisation,
    "settings.locale",
    DEFAULT_LOCALE_SETTINGS
  );

  const hasUnits = get(selectedProperty, "hasUnits") || false;
  const price = getUnitPrice(unit) || 0;
  let amountText = price;
  if (isNumeric(price)) {
    amountText = hasUnits
      ? formatAmount(Number(price), localeSettings)
      : formatPropertyAmountByLocale(property, localeSettings);
  }

  const squaredMeasurement = getSquaredMeasurement(localeSettings);
  const unitSize = formatNumber(get(unit, "size", 0), localeSettings);
  const colors = getWhiteLabelOrganisationColors(organisation);

  return (
    <>
      <StyledMainContainer>
        <StyledPaper elevation={0}>
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              md={12}
              sm={12}
              container={true}
              justify="space-between"
            >
              <DialogWithImage
                open={openSendMessageDialog}
                backgroundImage={
                  validateImg(unitImage(unit), "medium") || placeHolder
                }
                onClose={handleCloseMessageDialog}
                closeDialog={handleCloseMessageDialog}
                enquiry={!authenticated}
              >
                {!authenticated ? (
                  <EnquiryFormContainer
                    unit={unit}
                    property={property}
                    handleCloseMessageDialog={handleCloseMessageDialog}
                    agentId={agentId}
                    landlordName={
                      has(property, "landlord.name")
                        ? property.landlord.name
                        : ""
                    }
                  />
                ) : (
                  <MessageFormContainer
                    unit={unit}
                    property={property}
                    handleCloseMessageDialog={handleCloseMessageDialog}
                    agentId={agentId}
                    landlordName={
                      has(property, "landlord.name")
                        ? property.landlord.name
                        : ""
                    }
                  />
                )}
              </DialogWithImage>
              <StyledGrid md={2} sm={3} item={true}>
                <StyledButtonBase
                  id="img-overlay"
                  src={validateImg(unitImage(unit), "small") || placeHolder}
                  onClick={
                    validateImg(unitImage(unit), "small")
                      ? openModal
                      : undefined
                  }
                >
                  {!isEmpty(unit.images) && (
                    <ViewAll id="view-all-icon">
                      <span>View Gallery</span>
                    </ViewAll>
                  )}
                </StyledButtonBase>
              </StyledGrid>
              <Grid
                item={true}
                md={5}
                sm={4}
                container={true}
                direction="column"
              >
                <StyledContent item={true}>
                  <StyledHeader>
                    {get(unit, "title", property.title)}
                  </StyledHeader>
                  <StyledInfo>
                    {parser(get(unit, "description", property.description))}
                  </StyledInfo>
                </StyledContent>
              </Grid>
              <RoomFeatures md={2} sm={2} item={true} container={true}>
                <StyledFeature>
                  <StyledIcon name="bed" />
                  <FeatureName>{`${unit.bedrooms} Bed`}</FeatureName>
                </StyledFeature>
                <StyledFeature>
                  <StyledIcon name="bath" />
                  <FeatureName>{`${unit.bathrooms} Bath`}</FeatureName>
                </StyledFeature>
                <StyledFeature>
                  <StyledIcon name="car-side" />
                  <FeatureName>{`${unit.parkings} Parking`} </FeatureName>
                </StyledFeature>
                {hasUnits && (
                  <StyledFeature>
                    <StyledIcon name="clone" />
                    <FeatureName>
                      {`${unitSize} ${squaredMeasurement}`}{" "}
                    </FeatureName>
                  </StyledFeature>
                )}
              </RoomFeatures>
              <StyledPriceContainer md={2} sm={2} item={true} container={true}>
                <StyledPrice>{amountText}</StyledPrice>
                <StyledMessage>
                  {!isEmpty(agentId) && (
                    <StyledButton
                      primary={true}
                      onClick={handleOpenMessageDialog}
                      orgColors={colors}
                    >
                      Message
                    </StyledButton>
                  )}
                  <StyledLabel href={emailSubject} target="_blank">
                    Report listing
                  </StyledLabel>
                </StyledMessage>
              </StyledPriceContainer>
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledMainContainer>

      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={closeModal}>
            <Carousel
              views={unit.images.map((i) => ({
                caption: i.title || "Image",
                source: validateImg(i, "medium") || placeHolder,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </>
  );
};

const mapStateToProps = ({ auth, dialogs, listing }: ApplicationState) => ({
  authenticated: auth.authenticated,
  dialogs,
  user: auth.user,
  selectedProperty: listing.property,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      toggleLoginDialog: toggleLoginDialogAction,
      toggleMessageDialog: toggleMessageDialogAction,
      toggleProfileDialog: toggleProfileDialogAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableUnit);
