import { withFormik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import * as Yup from "yup";

import ForgotPasswordForm, {
  ForgotPasswordFormValues,
} from "../components/forms/ForgotPasswordForm";
import { ApplicationState } from "../store";
import { forgotPasswordPromise } from "../store/auth";
import styled from "../utils/styledComponents";

interface PropsFromState {
  errors: string;
}

interface PropsFromDispatch {
  forgotPasswordPromise: typeof forgotPasswordPromise;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface State {
  showSuccessMessage: boolean;
}

const Wrapper = styled.div``;

const SuccessMessage = styled.div`
  color: ${({ theme }) => theme.colors.grey4};
  text-align: center;
`;

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
});

class ForgotPasswordFormContainer extends React.Component<AllProps, State> {
  public readonly state: State = {
    showSuccessMessage: false,
  };

  public render() {
    return (
      <Wrapper>
        {this.state.showSuccessMessage ? (
          <SuccessMessage>
            Please check your email for instructions on resetting your password.
          </SuccessMessage>
        ) : (
          <>{this.renderForm()}</>
        )}
      </Wrapper>
    );
  }

  private renderForm() {
    const formikEnhancer = withFormik<{}, ForgotPasswordFormValues>({
      displayName: "ForgotPasswordForm",
      handleSubmit: async (values, { setSubmitting }) => {
        try {
          await this.props.forgotPasswordPromise(values);
          this.setState({ showSuccessMessage: true });
        } catch (error) {
          console.log(error);
        } finally {
          setSubmitting(false);
        }
      },
      mapPropsToValues: () => ({ email: "" }),
      validationSchema: schema,
    });
    const EnhancedForgotPasswordForm = formikEnhancer(ForgotPasswordForm);

    return <EnhancedForgotPasswordForm />;
  }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ forgotPasswordPromise }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordFormContainer) as any;
