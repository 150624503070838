import { omit } from 'lodash';
import React from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styled from '../../utils/styledComponents';

interface ActionDialogOwnProps extends DialogProps {
  contentComponent?: React.ComponentElement<any, any> | null;
  rounded?: boolean;
  onClose?: () => void;
}

const StyledDialog = styled((props: ActionDialogOwnProps) => (
  <Dialog {...omit(props, "rounded")} />
))`
  .MuiPaper-root {
    margin: 15px;
    width: 100%;
    ${({ rounded }) =>
      rounded
        ? `border-radius: 20px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    font-family: MuseoSans-300;`
        : null}
  }
`;

const CloseButton = styled((props: IconButtonProps) => (
  <IconButton {...props} />
))`
  && {
    position: absolute;
    top: 0.1em;
    right: 0.2em;
  }
` as any;

const ActionDialog: React.FunctionComponent<ActionDialogOwnProps> = ({
  contentComponent,
  onClose,
  ...props
}) => {
  return (
    <StyledDialog {...props}>
      {onClose && (
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
      {contentComponent ? contentComponent : <div />}
    </StyledDialog>
  );
};

export default ActionDialog;
