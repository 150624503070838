import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import styled from "../../utils/styledComponents";
import ErrorMessage from "../ui/ErrorMessage";

interface OwnProps {
  selectedDate?: string;
  disabled?: boolean;
  label: string;
  errors: any;
  // onErros: any;
  name: string;
  handleDateChange(date: Date | any): void;
}

const date = new Date();
const futureDate = date.setDate(date.getDate());

const StyledFlowDatePicker = styled(KeyboardDatePicker)`
  && {
    .Mui-disabled:before {
      border-bottom-style: solid !important;
    }
    .MuiInput-formControl {
      margin-top: 16px;
    }
    .MuiFormHelperText-root {
      margin-top: 5px;
      min-height: 0px;
    }
    .MuiInputLabel-root {
      font-family: ${({ theme }) => theme.fonts.MS500};
      color: ${({ theme }) => theme.colors.grey2};
    }
    .MuiInputBase-input {
      font-family: ${({ theme }) => theme.fonts.MS300};
      font-weight: ${({ theme }) => theme.fontWeights.MS300};
      color: #4c4c4e;
    }
  }
`;

const StyledError = styled.div`
  span {
    color: #e65757;
    font-family: "Museo-Sans-300", Helvetica, sans-serif;
    font-weight: 300;
  }
`;
const DatePicker: React.FunctionComponent<OwnProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openPicker = () => {
    setIsOpen(true);
  };
  const closePicker = () => {
    setIsOpen(false);
  };
  const { selectedDate, handleDateChange, errors, name, label } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledFlowDatePicker
        open={isOpen}
        onOpen={openPicker}
        onClose={closePicker}
        disableToolbar={true}
        format="dd MMMM yyyy"
        fullWidth={true}
        onClick={openPicker}
        id="date-picker-dialog"
        label={label}
        error={false}
        minDate={futureDate}
        FormHelperTextProps={{ hidden: false }}
        value={selectedDate}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: !!selectedDate,
        }}
        helperText={
          <StyledError>
            {errors && errors[name] && <ErrorMessage name={name} />}
          </StyledError>
        }
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
