export interface SubscriptionPackage {
  id: string | number;
  name: string;
  title: string;
  description: string;
  price: number | string;
  frequency: Frequencies;
  currency: Currency;
  maxListings: number;
  checkoutUrl?: string;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface Picture {
  _id: string;
  original: string;
  thumbnail: string;
  height: number;
  width: number;
  size: number;
  caption: string
}

export interface Address {
  full: string;
  lng: number;
  lat: number;
  street: string;
  city: string;
  country: string;
  zipcode: string;
  state: string;
}

export interface Listing {
  _id: string;
  picture: Pick<Picture, 'thumbnail' | 'caption'>;
  address: Address;
  pictures: Picture[];
  nickname: string;
  title: string;
  isListed: boolean;
  active: boolean;
  type: string;
  [key: string]: any;
}

export interface AuthDetails {
  integrationToken: string;
  homePageUrl: string;
}

export interface GeoPoint {
  lat: number;
  lng: number;
}

export interface Location {
  locationName: string;
  geoPoint: GeoPoint;
}

export interface GuestyState {
  loadingListings: boolean;
  subscribing: boolean;
  subscriptionPackages: SubscriptionPackage[];
  listings: Listing[];
  selectedPackage?: SubscriptionPackage | null;
  authDetails: AuthDetails | null;
  selectedListings: Listing[];
  selectedLocations: Location[];
  errors: any;
  accessToken?: string;
}

export interface SubscribePayload {
  listings: Listing[];
  locations: GeoPoint[];
}

export interface FaqModel {
  title: string;
  content: string;
  isActive: boolean;
  id: number;
};

export enum Frequencies {
  Monthly = 'monthly',
  Annually = 'annually',
}

export enum Frequencies2 {
  Monthly = 'month',
  Annually = 'annum',
}
