import has from 'lodash/has';
import React from 'react';

import { EnquiryProps } from '../../store/listings';
import { ReferralInfo } from '../../store/referral';
import styled from '../../utils/styledComponents';
import SmallTick from '../icons/SmallTick';

const Card = styled.div`
  border-radius: 20px;
  background-color: #eef4f6;
  margin: 10px 0 20px;
  padding: 20px;
`;

const Header = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 18px;
  }
`;

const LandlordContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PropertyContainer = styled.div`
  height: 70px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin-bottom: 20px;
  text-align: left;
`;

const PropertyDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const PropertyImage = styled.div<{ propertyThumbnail: string }>`
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ propertyThumbnail }) => propertyThumbnail});
`;

const LanlordLogo = styled.img`
  border-radius: 5px;
  height: 50px;
  border: solid 2px white;
`;
const LandlordAvator = styled.div<{ avator: string }>`
  height: 50px;
  width: 50px;
  border-radius: 30px;
  border: solid 2px white;
  position: relative;
  right: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ avator }) => avator});
  svg {
    position: relative;
    z-index: 20;
    top: -9px;
    left: -11px;
  }
`;
const LanlordName = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
  }
`;

const Heading = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 12px;
  }
`;

const ReferralHeading = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 18px;
  }
`;

const Heading2 = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS900};
    font-weight: ${({ theme }) => theme.fontWeights.MS900};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
  }
`;

const PropertyName = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 16px;
  }
`;

const Footer = styled.div`
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 14px;
`;

export interface EnquiryCardProps extends EnquiryProps {
  referralInfo?: ReferralInfo;
}

const EnquiryCard: React.FunctionComponent<EnquiryCardProps> = (props) => {
  const isRegistered = has(props, "tenantInfo.registered")
    ? props.tenantInfo.registered
    : false;

  const isReferral = props.referralInfo;
  const initials = isReferral
    ? props.referralInfo!.firstName.charAt(0) +
    props.referralInfo!.lastName.charAt(0)
    : "";

  const landlordLogo = props.landlordLogo ? props.landlordLogo!.replace(/'/g, ' ') : "";

  return (
    <Card>
      {!isRegistered && has(props, "tenantInfo") && (
        <>
          <Header>You enquired about a property</Header>
          <LandlordContainer>
            <LanlordLogo src={`${landlordLogo}`} alt="Landlord Logo" />
            <LandlordAvator avator={props.landlordAvator!}>
              <SmallTick />
            </LandlordAvator>
            <LanlordName>{props.landlordName}</LanlordName>
          </LandlordContainer>
          <PropertyContainer>
            <PropertyImage propertyThumbnail={props.propertyThumbnail!} />
            <PropertyDetails>
              <Heading>{props.address}</Heading>
              <PropertyName>{props.propertyName}</PropertyName>
            </PropertyDetails>
          </PropertyContainer>
        </>
      )}

      {isReferral && (
        <>
          <Header>You've been invited to Flow</Header>
          <LandlordContainer>
            <LandlordAvator
              avator={`https://via.placeholder.com/150/D3D3D3/FFFFFF/?text=${initials}`}
            />{" "}
            <ReferralHeading>
              by {props.referralInfo?.displayName}
            </ReferralHeading>
          </LandlordContainer>
          <ReferralHeading>
            Complete your registration to accept {props.referralInfo?.firstName}
            's invite and earn up to 2400 points.
          </ReferralHeading>
        </>
      )}

      {isReferral ? null : (
        <Footer>
          {!isRegistered ? (
            <>
              <Heading2>{props.landlordName}</Heading2> from{" "}
              <Heading2>{props.propertyName}</Heading2> would like to chat and
              help you get the best deal. To continue please register or login.
            </>
          ) : (
              <>
                Welcome back <Heading2>{props.tenantInfo.firstName}</Heading2>,
              this account has already been registered, please login.
              </>
            )}
        </Footer>
      )}
    </Card>
  );
};

export default EnquiryCard;
