import React from 'react';

import Grid from '@material-ui/core/Grid';

import landlordImage from '../../assets/images/illustrations/landlord.png';
import tenantImage from '../../assets/images/illustrations/tenant.png';
import Button from '../../components/buttons/Button';
import Title from '../../components/typography/Title';
import { IsMobile } from '../../utils/responsive';
import styled, { mediaDown, mediaUp } from '../../utils/styledComponents';

const TitleWrapper = styled.div`
  text-align: center;
`;

const StyledTitle = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
    line-height: 21px;
  }
`;

const Description = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey2};
    line-height: 21px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const DialogColumn = styled.div`
  ${mediaUp.sm`
    width: 50%;
    padding: 10px;`};
  ${mediaDown.sm`
    width: 100%;
    padding: 0;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDialogColumn = styled(DialogColumn)`
  background-color: #f6f6f6;
`;

const GraphicWrapper = styled.div`
  text-align: center;
  border-width: 1px solid;
  border-color: black;
  ${mediaDown.sm`&&{
    margin-top: 50px;
    margin-bottom: 15px;
    }`}
`;

const Graphic = styled.img`
  width: 100%;
  max-width: 170px;
  ${mediaDown.sm`
    max-width: 70px !important;
  `}
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  ${mediaDown.sm`
    margin-top: 10px !important;
    margin-bottom: 40px !important;
  `}
`;

const StyledButton = styled(Button)`
  && {
    &:hover {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

interface OwnProps {
  isLogin: boolean;
  isTenant: boolean;
  buttonAction: () => void;
}

type AllProps = OwnProps;

const DialogColumnView: React.FunctionComponent<AllProps> = ({
  isLogin,
  isTenant,
  buttonAction,
}) => {
  const isTrue = IsMobile();
  return (
    <StyledDialogColumn
      style={{ backgroundColor: !!isTenant ? '#f6f6f6' : '#ffffff' }}
    >
      <Grid container={true} justify="center">
        <Grid item={true} xs={6} sm={6} md={10} lg={8} xl={8}>
          <GraphicWrapper>
            <Graphic src={!!isTenant ? tenantImage : landlordImage} />
          </GraphicWrapper>
        </Grid>

        <Grid container={true} justify="center">
          <Grid item={true} xs={7} sm={8}>
            <TitleWrapper>
              <StyledTitle>{!!isTenant ? 'Buyers and Tenants' : 'Agents'}</StyledTitle>
              <Description>
                {isTenant
                  ? 'Find homes to buy or rent'
                  : 'Automatically create Facebook and Instagram ads from your listings'}
              </Description>
            </TitleWrapper>
          </Grid>
        </Grid>
        <Grid container={true} justify="center">
          <Grid item={true} xs={6}>
            <ButtonWrapper>
              <StyledButton
                primary={!!isTenant}
                secondary={!isTenant}
                onClick={buttonAction}
                fullWidth={true}
                text={true}
                style={!isTrue && !!isTenant ? { marginTop: '22px' } : {}}
              >
                &nbsp; &nbsp; {!!isTenant ? 'Buyer/Tenant ' : 'Agent '}
                {!!isLogin ? 'Login' : 'Signup'} &nbsp; &nbsp;
              </StyledButton>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </Grid>
    </StyledDialogColumn>
  );
};

export default DialogColumnView as any;
