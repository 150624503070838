import { withFormik } from "formik";
import { Location } from "history";
import _ from "lodash";
import queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import validator from "validator";
import * as Yup from "yup";

import RegisterForm, {
  RegisterFormValues,
} from "../components/forms/RegisterForm";
import { ApplicationState } from "../store";
import { registerPromise } from "../store/auth";
import styled from "../utils/styledComponents";

const INTERCOM_SOURCE = process.env.REACT_APP_INTERCOM_SOURCE;

interface OwnProps {
  referral?: { action: string; type: string; referralCode: string };
  inviteToken?: string;
  userType?: "landlord" | "tenant";
  populatedValues?: {
    lastName: string;
    firstName: string;
    email: string;
    contactNumber: string;
  };
}

interface PropsFromState {
  errors: string;
  location: Location;
}

interface PropsFromDispatch {
  registerPromise: typeof registerPromise;
}

/* tslint:disable:object-literal-sort-keys */
const schema = Yup.object().shape({
  userType: Yup.string()
    .oneOf(["tenant", "landlord"])
    .required("Please select whether you are a tenant or landlord"),
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email")
    .test({
      name: "validateEmail",
      message: "Please provide a valid email",
      test: (value) => value == null || validator.isEmail(value),
    }),
  contactNumber: Yup.string().required("Please enter your contact number"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords does not match")
    .required("Password confirm is required"),
});

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class RegisterFormContainer extends React.Component<AllProps> {
  public render() {
    return <Wrapper>{this.renderForm()}</Wrapper>;
  }

  private renderForm() {
    const formikEnhancer = withFormik<any, RegisterFormValues>({
      displayName: "RegisterForm",
      handleSubmit: async (values, { setSubmitting }) => {
        try {
          const registerValues = _.omit(values, [
            "confirmPassword",
            "acceptTC",
          ]);
          const { action, referralCode, type } = queryString.parse(
            this.props.location.search
          );

          registerValues.tags = [values.userType];

          if (
            typeof action === "string" &&
            typeof type === "string" &&
            typeof referralCode === "string"
          ) {
            registerValues.referral = {
              action,
              referralCode,
              type,
            };
          } else if (this.props.inviteToken) {
            registerValues.invite = {
              token: this.props.inviteToken,
            };
          }

          registerValues.Source = INTERCOM_SOURCE;
          registerValues.UserType = values.userType;

          await this.props.registerPromise(registerValues);
        } catch (error) {
          console.log(error);
        } finally {
          setSubmitting(false);
        }
      },
      mapPropsToValues: ({ populatedValues }) => {
        const mapedValues = {
          acceptTC: false,
          confirmPassword: "",
          contactNumber: "",
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          userType: this.props.userType || "",
        };

        if (populatedValues) {
          Object.assign(mapedValues, populatedValues);
        }

        return mapedValues;
      },
      validationSchema: schema,
    });
    const EnhancedRegisterForm: any = formikEnhancer(RegisterForm);

    return (
      <EnhancedRegisterForm
        typeSelectDisabled={this.props.userType}
        populatedValues={this.props.populatedValues}
      />
    );
  }
}

const mapStateToProps = ({ auth, router }: ApplicationState) => ({
  errors: auth.errors,
  location: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ registerPromise }, dispatch),
});

const Wrapper = styled.div``;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFormContainer) as any;
