import * as React from 'react';

import { CircularProgress, Grid } from '@material-ui/core';

import { Body, Page } from '../layout';

const LoadingPage: React.FunctionComponent<{}> = props => {
  return (
    <Page>
      <Body justify="center" alignItems="center">
        <Grid item={true}>
          <CircularProgress color="primary" />
        </Grid>
      </Body>
    </Page>
  );
};

export default LoadingPage;
