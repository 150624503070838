import get from "lodash/get";
import React from "react";

import { PartnerProps } from "../../store/listings/types";
import { Organisation, OrganisationColors } from "../../store/organisation";
import { IsMobile } from "../../utils/responsive";
import styled from "../../utils/styledComponents";
import {
  getOrganisationColor,
  getWhiteLabelOrganisationColors,
} from "../../utils/whiteLabel";
import Button from "../buttons/Button";
import FlowImage from "../ui/FlowImage";

const BackgroudImage = styled.div<{ headerImage: string; isOnMobile: boolean }>`
  && {
    border-radius: 10px;
    background-image: url(${({ headerImage }) => headerImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 10px 0px 0px 10px;
    width: calc(100% - 10px);
    /* height: ${({ isOnMobile }) => (isOnMobile ? "150" : "140")}; */
    height: 100%;
    /* height: calc(100% + 100px); */
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
  }
`;
const Ribbon = styled.div`
  && {
    font-size: 10px;
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    top: -7px;
    right: -17px;
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
`;
const RibbonSpan = styled.span<{ orgColors?: OrganisationColors }>`
  && {
    display: block;
    width: 237px;
    padding: 15px 0px 5px 0px;
    background-color: ${({ orgColors, theme }) =>
      getOrganisationColor(orgColors, "primaryColor", theme.colors.orange)};
    color: #fff;
    text-align: center;
    transform: rotate(45deg);
  }
`;
const Container = styled.div<{ isOnMobile: boolean }>`
  && {
    width: ${({ isOnMobile }) => (isOnMobile ? "100%" : "270px")};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    border: solid thin #f0f0f0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);
    padding: 10px;
    cursor: pointer;
    min-height: 413px;
    > div {
      width: 100% !important;
      text-overflow: ellipsis;
      /* over-flow: hidden; */
      height: 261px;
    }
  }
`;

const Header = styled.div`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 10px 0px;
  }
`;

const Description = styled.span`
  && {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    color: #4c4c4e;
  }
`;

const CallToAction = styled(Button)`
  && {
    height: 40px;
    border-radius: 10px;
    margin-top: 10px;
  }
`;

const ImageWrapper = styled.div<{ isOnMobile: boolean }>`
  && {
    background-color: white;
    height: ${({ isOnMobile }) => (isOnMobile ? "70px" : "50px")};
    width: ${({ isOnMobile }) => (isOnMobile ? "70px" : "50px")};
    padding: 7px;
    border-radius: 10px;
  }
`;

const DescriptionContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export interface PartnersCardProps extends PartnerProps {
  onClick: (slug: string) => (Event: React.MouseEvent) => void;
}

interface OwnProps {
  organisation?: Organisation | object;
}

type AllProps = PartnersCardProps & OwnProps;

const PartnersCard: React.FunctionComponent<AllProps> = ({
  headerImage,
  partnerLogo,
  title,
  description,
  slug,
  organisation,
  onClick,
}) => {
  const isMobile = IsMobile();
  const colors = getWhiteLabelOrganisationColors(organisation);

  return (
    <Container isOnMobile={isMobile} onClick={onClick(slug)}>
      <div partner-card={slug}>
        <BackgroudImage
          headerImage={get(headerImage, "url", "sizes.large")}
          isOnMobile={isMobile}
        >
          <ImageWrapper isOnMobile={isMobile}>
            <FlowImage
              image={get(partnerLogo, "url", "")}
              radius={20}
              size={{ width: `100%`, height: `100%` }}
            />
          </ImageWrapper>
          <Ribbon className="ribbon ribbon-top-right">
            <RibbonSpan orgColors={colors}>
              Flow <br /> Partner
            </RibbonSpan>
          </Ribbon>
        </BackgroudImage>
        <Header>{title}</Header>
        <DescriptionContainer>
          <Description>{description}</Description>
        </DescriptionContainer>
        <CallToAction
          primary={true}
          orgColors={colors}
          outline={true}
          onClick={onClick(slug)}
        >
          Learn More
        </CallToAction>
      </div>
    </Container>
  );
};

export default PartnersCard;
