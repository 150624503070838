import { createRoutine } from 'redux-saga-routines';

export const toggleForgotPasswordDialog = createRoutine(
  "@@dialogs/TOGGLE_FORGOT_PASSWORD_DIALOG"
);
export const toggleLoginDialog = createRoutine("@@dialogs/TOGGLE_LOGIN_DIALOG");
export const toggleProfileDialog = createRoutine(
  "@@dialogs/TOGGLE_PROFILE_DIALOG"
);
export const toggleRegisterDialog = createRoutine(
  "@@dialogs/TOGGLE_REGISTER_DIALOG"
);
export const toggleMessageDialog = createRoutine(
  "@@dialogs/TOGGLE_MESSAGE_DIALOG"
);
export const toggleLoginOptionsDialog = createRoutine(
  "@@dialogs/TOGGLE_LOGIN_OPTIONS_DIALOG"
);
export const toggleRegisterOptionsDialog = createRoutine(
  "@@dialogs/TOGGLE_REGISTER_OPTIONS_DIALOG"
);

export const toggleOTPVerificationDialog = createRoutine(
  "@@dialogs/TOGGLE_VERIFICATION_DIALOG"
);
