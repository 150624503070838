import { History } from 'history';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ButtonProps } from '@material-ui/core/Button';
import Button from './Button';
import styled from '../../utils/styledComponents';

interface OwnProps {
  title?: string;
}

interface ConnectedProps {
  history: History;
}

const StyledButton = styled(Button)`
  && {
    background-color: #F7F7F7 !important;
    color: #4C4C4E !important;
    &:hover {
      background-color: #F7F7F7 !important;
      color: #4C4C4E !important;
    }
  }
`;

type AllProps = ConnectedProps & OwnProps & ButtonProps & RouteComponentProps;

const DoneButton: React.FunctionComponent<AllProps> = ({
  history,
  title = "Done",
  disabled,
  ...props
}) => {
  const onClick = () => {
    history.goBack();
  };

  return (
    <StyledButton primary={true} onClick={onClick} disabled={disabled} {...props}>
      {title}
    </StyledButton>
  );
};

export default withRouter(DoneButton) as any;
