import has from "lodash/has";
import { Reducer } from "redux";

import {
  toggleForgotPasswordDialog,
  toggleLoginDialog,
  toggleLoginOptionsDialog,
  toggleMessageDialog,
  toggleOTPVerificationDialog,
  toggleProfileDialog,
  toggleRegisterDialog,
  toggleRegisterOptionsDialog,
} from "./routines";
import { DialogsState } from "./types";

const initialState: DialogsState = {
  openForgotPasswordDialog: false,
  openLoginDialog: false,
  openLoginOptionsDialog: false,
  openMessageDialog: {
    messageSent: false,
    openDialog: false,
    unitId: null,
  },
  openOTPVerificationDialog: false,
  openProfileDialog: false,
  openRegisterDialog: false,
  openRegisterOptionsDialog: false,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case toggleForgotPasswordDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: action.payload,
        openLoginDialog: false,
        openLoginOptionsDialog: false,

        openMessageDialog: {
          ...state.openMessageDialog,
          openDialog: false,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: false,
        openRegisterOptionsDialog: false,
      };
    }

    case toggleLoginDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: action.payload,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          openDialog: true,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: false,
        openRegisterOptionsDialog: false,
      };
    }

    case toggleProfileDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: false,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          ...state.openMessageDialog,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: action.payload,
        openRegisterDialog: false,
        openRegisterOptionsDialog: false,
      };
    }

    case toggleRegisterDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: false,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          ...state.openMessageDialog,
          openDialog: false,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: action.payload,
        openRegisterOptionsDialog: false,
      };
    }

    case toggleMessageDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: has(action, "payload.toggleLogin")
          ? action.payload.toggleLogin
          : false,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          ...state.openMessageDialog,
          ...action.payload,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: false,

        openRegisterOptionsDialog: false,
      };
    }

    case toggleLoginOptionsDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: false,
        openLoginOptionsDialog: action.payload,
        openMessageDialog: {
          ...state.openMessageDialog,
          openDialog: false,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: false,
        openRegisterOptionsDialog: false,
      };
    }

    case toggleRegisterOptionsDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: false,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          ...state.openMessageDialog,
          openDialog: false,
        },
        openOTPVerificationDialog: false,
        openProfileDialog: false,
        openRegisterDialog: false,
        openRegisterOptionsDialog: action.payload,
      };
    }

    case toggleOTPVerificationDialog.SUCCESS: {
      return {
        ...state,
        openForgotPasswordDialog: false,
        openLoginDialog: false,
        openLoginOptionsDialog: false,
        openMessageDialog: {
          ...state.openMessageDialog,
          openDialog: false,
        },
        openOTPVerificationDialog: action.payload,
        openProfileDialog: false,
        openRegisterDialog: false,
        openRegisterOptionsDialog: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as dialogsReducer };
