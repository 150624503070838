
import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import { getUnitsByPropertyId } from './routines';

function* handleGetUnitsByPropertyId(action: AnyAction) {
  try {
    const { propertyId } = action.payload;
    yield put(getUnitsByPropertyId.request());

    const res = yield call(callApi, "get", `/v3/guest/units/${propertyId}/propertyId`);

    yield put(getUnitsByPropertyId.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(getUnitsByPropertyId.failure(errorHandler(err.response)));
    } else {
      yield put(getUnitsByPropertyId.failure("An unknown error occured."));
    }
  } finally {
    yield put(getUnitsByPropertyId.fulfill());
  }
}

function* getUnitsByPropertyIdWatcher() {
  yield takeEvery(getUnitsByPropertyId.TRIGGER, handleGetUnitsByPropertyId);
}


// Error handlers
function* handleUnitError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* unitErrorWatcher() {
  yield takeEvery([getUnitsByPropertyId.FAILURE], handleUnitError);
}

export function* unitsSaga() {
  yield all([
    fork(unitErrorWatcher),
    fork(getUnitsByPropertyIdWatcher)
  ]);
}
