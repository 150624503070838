import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const createChat = createRoutine("@@chat/LISTING_ENQUIRY");
export const createChatPromise = promisifyRoutine(createChat);

export const setChatUnreadCount = createRoutine("@@chat/LISTING_CHAT_COUNT");

export const getProfile = createRoutine("@@auth/GET_PROFILE_BY_ID");
export const getProfilePromise = promisifyRoutine(getProfile);

//send enquiry
export const sendEnquiry = createRoutine("@@chat/SEND_ENQUIRY");
export const sendEnquiryPromise = promisifyRoutine(sendEnquiry);
