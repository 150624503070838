import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../../utils/styledComponents';

const FormSectionFields = styled((props: React.ComponentType<GridProps>) => (
  <Grid container={true} direction="row" {...props} />
))`` as any;

export default FormSectionFields;
