import { get, isEmpty } from "lodash";
import React from "react";

import Table, { TableProps } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { DEFAULT_LOCALE_SETTINGS } from "../../utils/defaultLocaleSettings";
import { formatDate } from "../../utils/general";
import { formatAmount } from "../../utils/numberFormatter";
import styled, { mediaDown } from "../../utils/styledComponents";
import Title from "../typography/Title";

const StyledTable = styled(Table)`
  && {
    background: ${({ theme }: any) => theme.colors.grey1};
    border-radius: 5px;
    margin: 5px 0;
    ${mediaDown.sm`margin-top:-25px;`}
    tbody > tr:last-child >td {
      padding-bottom: 10px;
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    line-height: 26px;
    border-bottom: 0px;
    padding: 0px 10px;
  }
`;

const StyledTableCellValue = styled(TableCell)`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey3};
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    line-height: 26px;
    border-bottom: 0px;
    padding: 0px 10px;
  }
`;

const StyledTableRow = styled(TableRow)`
  && {
  }
`;

const StyledTitle = styled(Title)`
  && {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    padding: 10px;
    margin-left: -10px;
    margin-top: 2.5px;
  }
`;

interface OwnProps {
  data: any;
  organisation: object;
  listingType: string;
}

type AllProps = OwnProps & TableProps;

const AuctionDetailsTable: React.FunctionComponent<AllProps> = ({
  data,
  organisation,
  listingType,
}) => {
  const formatValue = (value: any) => {
    const localeSettings = get(
      organisation,
      "settings.locale",
      DEFAULT_LOCALE_SETTINGS
    );
    const formattedAmount = formatAmount(value, localeSettings);
    return formattedAmount;
  };
  const startDate = get(data, "auctionStartDate", undefined);
  const startTime = get(data, "auctionStartTime", "00:00");

  const formateddate = formatDate(startDate, "DD MMMM YYYY");
  const auctionTime = formateddate && `${formateddate} ${startTime}`;
  const isAuction = listingType === "auction";

  if (!isAuction || isEmpty(data)) {
    return <div />;
  }

  const auctionGuidePriceHigh = get(data, "auctionGuidePriceHigh", 0);
  const auctionVenueName = get(data, "auctionVenueName");
  const auctionVenueAddress = get(data, "auctionVenueAddress");
  const auctionGuidePriceLow = get(data, "auctionGuidePriceLow", 0);
  const auction = data
    ? Object.values(data).filter((elem: any) => !isEmpty(elem))
    : [];

  return (
    <StyledTable style={{ display: isEmpty(auction) ? "none" : "block" }}>
      <TableHead>
        <StyledTableRow>
          <StyledTableCell colSpan={2}>
            <StyledTitle align="left">Auction Details</StyledTitle>
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell>Auction time:</StyledTableCell>
          <StyledTableCellValue align="right">
            {auctionTime}
          </StyledTableCellValue>
        </StyledTableRow>
        {!isEmpty(auctionVenueName) && (
          <StyledTableRow>
            <StyledTableCell>Auction Venue:</StyledTableCell>
            <StyledTableCellValue align="right">
              {auctionVenueName}
            </StyledTableCellValue>
          </StyledTableRow>
        )}
        {!isEmpty(auctionVenueAddress) && (
          <StyledTableRow>
            <StyledTableCell>Auction Address:</StyledTableCell>
            <StyledTableCellValue align="right">
              {get(data, "auctionVenueAddress")}
            </StyledTableCellValue>
          </StyledTableRow>
        )}
        {auctionGuidePriceHigh > 0 && (
          <StyledTableRow>
            <StyledTableCell>Guide Price:</StyledTableCell>
            <StyledTableCellValue align="right">
              {`${formatValue(auctionGuidePriceLow)} -
              ${formatValue(auctionGuidePriceHigh)}`}
            </StyledTableCellValue>
          </StyledTableRow>
        )}
      </TableBody>
    </StyledTable>
  );
};
export default AuctionDetailsTable;
