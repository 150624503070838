import { omit } from 'lodash';
import React from 'react';

import MuiPaper, { PaperProps } from '@material-ui/core/Paper';

import styled from '../../utils/styledComponents';

export interface OwnPaperProps extends PaperProps {
  rounded?: boolean;
}
const Paper = styled((props: OwnPaperProps) => (
  <MuiPaper elevation={0} {...omit(props, "rounded")} />
))`
  && {
    ${props =>
      props.rounded
        ? `
    border-radius: 20px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    padding: 30px 30px;
    font-family: MuseoSans-300;
    `
        : ``}
  }
` as any;

export default Paper;
