import has from 'lodash/has';
import { Reducer } from 'redux';

import {
    fetchActiveListings, fetchActiveListingsById, fetchActiveListingsFilters, fetchEnquiry,
    fetchPartners, fetchRecommendedProperties, listingsSearch
} from './routines';
import { Listing, ListingsState } from './types';

const initialState: ListingsState = {
  count: 0,
  enquiryInfo: undefined,
  errors: undefined,
  filters: undefined,
  listLoading: false,
  filtersLoading: false,
  listings: [],
  property: undefined,
  partners: [],
  recommended: []
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchActiveListingsById.TRIGGER:
    case fetchRecommendedProperties.TRIGGER:
    case fetchEnquiry.TRIGGER:
    case fetchPartners.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case fetchActiveListings.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    case fetchActiveListingsFilters.TRIGGER: {
      return { ...state, filtersLoading: true, errors: undefined };
    }

    case fetchRecommendedProperties.SUCCESS: {
      Object.keys(action.payload).forEach((key) => {
        if (!has(action, `payload[${key}].address`)) {
          delete action.payload[key];
        }
      });

      return { ...state, recommended: action.payload }
    }

    case fetchActiveListings.SUCCESS: {
      Object.keys(action.payload.listings).forEach((key) => {
        if (!has(action, `payload.listings[${key}].address`)) {
          delete action.payload.listings[key];
        }
      });

      return {
        ...state,
        count: action.payload.count,
        listings: action.payload.loadMore
          ? LoadMoreListings(state.listings, action.payload.listings)
          : action.payload.listings,
      };
    }

    case fetchPartners.SUCCESS: {
      return { ...state, partners: action.payload, error: undefined }
    }

    // Success
    case fetchEnquiry.SUCCESS: {
      return {
        ...state,
        enquiryInfo: action.payload,
      };
    }

    case fetchActiveListingsFilters.SUCCESS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case fetchActiveListingsById.SUCCESS: {
      return {
        ...state,
        property: action.payload,
      };
    }

    case listingsSearch.SUCCESS: {
      localStorage.setItem("search", JSON.stringify(action.payload));

      return {
        ...state,
        search: action.payload,
      };
    }

    // Failure
    case fetchEnquiry.FAILURE:
    case fetchActiveListings.FAILURE: {
      return {
        ...state,
        enquiryInfo: undefined,
        errors: action.payload,
        listings: [],
        listLoading: false,
      };
    }

    case listingsSearch.FAILURE:
    case fetchPartners.FAILURE: {
      return {
        ...state,
        partners: [],
        error: action.payload
      }
    }

    // Failure
    case fetchActiveListingsFilters.FAILURE: {
      return { ...state, filters: initialState.filters, errors: action.payload, filtersLoading: false };
    }

    case fetchRecommendedProperties.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        recommended: []
      };
    }

    case fetchActiveListingsById.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        property: undefined,
        recommended: []
      };
    }

    // Fullfill

    case fetchActiveListings.FULFILL: {
      return {
        ...state,
        listLoading: false,
        filtersLoading: false // incase the loading was set to true
      };
    }

    case fetchActiveListingsFilters.FULFILL: {
      return {
        ...state,
        filtersLoading: false,
      };
    }

    case fetchEnquiry.FULFILL:
    case fetchPartners.FULFILL: {
      return { ...state }
    }

    default: {
      return state;
    }
  }
};

const LoadMoreListings = (stateListings: Listing[], listings: Listing[]) => {
  return stateListings.concat(listings);
};

export { reducer as listingsReducer };
