import { Field, Formik } from "formik";
import { get, has, isEmpty, isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import tick from "../assets/images/icons/tick.png";
import Button from "../components/buttons/Button";
import DoneButton from "../components/buttons/DoneButton";
import ContactNumberWithCountryCode from "../components/inputs/contactNumberWithCode";
import TextInput from "../components/inputs/TextInput";
import UnitItem from "../components/listItems/UnitItem";
import PrivacyPolicy from "../components/typography/PrivacyPolicy";
import { FieldWrapper, FormFieldsContainer } from "../components/ui/forms";
import { ApplicationState } from "../store";
import { sendEnquiryPromise } from "../store/chat";
import { fetchActiveLocalesPromise } from "../store/defaults";
import { toggleMessageDialog } from "../store/dialogs/routines";
import { DialogsState } from "../store/dialogs/types";
import { Listing } from "../store/listings/types";
import { enqueueSnackbar } from "../store/notifications";
import { addGTMDataLayer } from "../utils/GoogleTagManager";
import styled, { mediaDown, mediaUp } from "../utils/styledComponents";
import { getCoverImage } from "../utils/imageUrls";
import { getWhiteLabelOrganisationColors } from "../utils/whiteLabel";
import Link from "../components/links/Link";
import { getUnitPrice } from "../utils/units.helper";
import { Unit } from "../store/units";
import { addUrlPrefix } from "../utils/url.helper";

/* tslint:disable:object-literal-sort-keys */

const Wrapper = styled.div``;
const StyledFieldWrapper = styled(FieldWrapper)`
  && {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const FormContainer = styled.div`
  ${mediaDown.sm`padding: 0; background-color: unset;  width: 100%;`}
  display: block;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormFieldsContainer = styled(FormFieldsContainer)`
  && {
    ${mediaDown.sm`padding: 0;  width: 100%;`}
    display: block;
  }
`;

const ButtonContainer = styled.div<{ isDone?: boolean }>`
  display: flex;
  top: 14px;
  position: ${({ isDone }) => (isDone ? `absolute` : `relative`)};
  bottom: 20px;
  ${({ isDone }) =>
    isDone ? mediaUp.sm`right: 20px` : mediaUp.sm`right: 0px`};
  ${({ isDone }) =>
    isDone ? mediaDown.sm`left: 20px` : mediaDown.sm`left: 0px`};
  ${mediaDown.sm`bottom: 20px;`}
`;

const ButtonWrapper = styled.div`
  ${mediaUp.sm`margin-left: auto;`}
  position: "relative";
`;

const StyledTick = styled.img`
  width: 100%;
  max-width: 134px;
  height: auto;
  margin: 20px;
`;

const SuccessMessage = styled.div`
  color: ${({ theme }) => theme.colors.grey4};
  text-align: center;
`;

const InfoContainer = styled.div`
  text-align: center;
  max-width: 190px;
  margin: 35px auto 0;
`;
const MessageInfoContainer = styled(InfoContainer as any)`
  max-width: 100%;
  margin-bottom: 20px;
`;
const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
  color: ${({ theme }) => theme.colors.grey4};
  margin: 0 0 10px;
`;
const MessageHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
`;

const UnitItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MessageContainer = styled.div``;
const StyledLink = styled(Link)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    color: ${({ theme }) => theme.colors.orange};
    font-size: ${({ theme }) => theme.fontSizes.navbarItem};
    margin: 0 0 10px;
    line-height: 1.7;
  }
`;
const LeadUrlMessage = styled.p`
  color: #888;
`;
const Separator = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
`;

interface OwnProps {
  unit: Unit;
  property: Listing;
  populatedValues?: {
    lastName: string;
    firstName: string;
    email: string;
    contactNumber: string;
  };
}

export interface EnquiryFormProps {
  property: Listing;
  unit: Unit;
  dialogs: DialogsState;
  inviteLink: string;
  handleCloseMessageDialog: () => void;
}

interface PropsFromState extends EnquiryFormProps {
  errors: string;
}

interface State {
  isContactNumberValid: boolean;
}
interface PropsFromDispatch {
  sendEnquiryPromise: typeof sendEnquiryPromise;
  toggleMessageDialog: typeof toggleMessageDialog;
  handleCloseMessageDialog: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class EnquiryFormContainer extends React.Component<AllProps> {
  public readonly state: State = {
    isContactNumberValid: true,
  };
  public render() {
    const { unit, property } = this.props;
    const isResidential = isEqual(property.listingSector, "residential");
    const headingText = isResidential
      ? "Contact the agent"
      : "Contact the broker";
    const initialCountry = get(
      property,
      "organisationObject.settings.locale.country.code",
      "za"
    );

    const coverImageUrl = !isEmpty(property.coverImageUrl)
      ? property.coverImageUrl
      : getCoverImage(property);

    const CustomHeader = () => (
      <>
        <InfoContainer>
          <Heading>{headingText}</Heading>
        </InfoContainer>
        <UnitItemContainer>
          <UnitItem
            image={coverImageUrl}
            address={property.address.suburb}
            buildingName={property.title}
            apartmentName={get(unit, "title", property.title)}
          />
        </UnitItemContainer>
      </>
    );
    const initialValues = {
      contactNumber: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      message: `I'm interested in this ${property.propertyType}, please contact me`,
    };

    if (this.props.populatedValues) {
      Object.assign(initialValues, this.props.populatedValues);
    }

    const colors = getWhiteLabelOrganisationColors(
      get(property, "organisationObject")
    );
    const zoning = get(property, "listingSector", "residential");
    const listingType = get(property, "listingType", "sale");
    const slug = get(property, "organisationObject.slug");
    const isDuplicateChat = get(
      this.props,
      "dialogs.openMessageDialog.duplicateChat",
      false
    );
    let responseMessage = "Message sent.";
    if (isDuplicateChat) {
      responseMessage =
        get(this.props, "dialogs.openMessageDialog.responseMessage") ||
        responseMessage;
    }
    const privacyPolicyUrl = get(
      property,
      "organisationObject.privacyPolicyUrl"
    );

    const leadUrl = get(property, "leadUrl.url", "");
    const validLeadUrl = addUrlPrefix(leadUrl);
    const hasLeadUrl = get(property, "hasLeadUrl", false);
    const leadUrlTitle = get(property, "leadUrl.title", "");
    const leadUrlMessage = get(property, "leadUrl.message", "");
    return (
      <Wrapper>
        <>
          {this.props.dialogs.openMessageDialog.messageSent ? (
            <SuccessMessage>
              <StyledTick src={tick} />
              <MessageInfoContainer>
                <MessageHeading>{responseMessage}</MessageHeading>
                <Separator />
                {hasLeadUrl && (
                  <LeadUrlMessage>{leadUrlMessage}</LeadUrlMessage>
                )}

                <StyledLink
                  primary
                  orgColors={colors}
                  href={
                    hasLeadUrl
                      ? validLeadUrl
                      : `/listings/${slug}/${zoning}/for-${listingType}`
                  }
                  target="_blank"
                >
                  {hasLeadUrl ? leadUrlTitle : "Explore more listings"}
                </StyledLink>
              </MessageInfoContainer>
              <ButtonContainer isDone={true}>
                <ButtonWrapper>
                  <DoneButton
                    type="button"
                    onClick={this.props.handleCloseMessageDialog}
                  >
                    Done
                  </DoneButton>
                </ButtonWrapper>
              </ButtonContainer>
            </SuccessMessage>
          ) : (
            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const errors: any = {};
                if (!values.firstName) {
                  errors.firstName = "Please enter your first name";
                }
                if (!values.lastName) {
                  errors.lastName = "Please enter your last name";
                }
                if (!values.email) {
                  errors.email = "Please enter your email";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Please enter a valid email";
                }
                if (!values.contactNumber) {
                  errors.contactNumber = "Please enter your contact number";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let skip = false;
                if (
                  !has(values, "contactNumber") ||
                  isEmpty(values.contactNumber)
                ) {
                  this.setState({ isContactNumberValid: false });
                  setSubmitting(false);
                  skip = true;
                }
                if (!skip) {
                  try {
                    const additionalInfo = {
                      message: values.message,
                      price: getUnitPrice(unit),
                      suburb: property.address.suburb,
                    };

                    const listingEnquiry = {
                      additionalInfo,
                      ...additionalInfo,
                      isAnonymousUser: true,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      contactNumber: values.contactNumber,
                      email: values.email,
                      inviteSource: "Tenant Portal",
                      listingId: property._id,
                      organisation: property.organisation,
                      listingType: property.listingType,
                      city: property.address.city,
                      province: property.address.province,
                    };

                    if (property.hasUnits) {
                      Object.assign(listingEnquiry, { unitId: unit._id });
                    } else {
                      Object.assign(listingEnquiry, {
                        propertyId: property._id,
                      });
                    }

                    await this.props
                      .sendEnquiryPromise(listingEnquiry)
                      .then((response) => {
                        this.props.toggleMessageDialog({
                          responseMessage: get(response, "message"),
                          duplicateChat: get(
                            response,
                            "data.duplicateChat",
                            false
                          ),
                        });
                      });

                    this.props.toggleMessageDialog({
                      unitId: unit._id,
                      messageSent: true,
                    });
                    setSubmitting(false);

                    addGTMDataLayer({
                      data: { organisation: property.organisation },
                      event: "tenant_enquiry_submit",
                      page: "property_listing",
                    });
                  } catch (error) {
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({ handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <MainContainer>
                    <FormContainer>
                      <CustomHeader />
                      <StyledFieldWrapper item={true} sm={12} xs={12}>
                        <Field
                          type="text"
                          label="First Name"
                          name="firstName"
                          component={TextInput}
                        />
                      </StyledFieldWrapper>
                      <StyledFieldWrapper item={true} sm={12} xs={12}>
                        <Field
                          type="text"
                          label="Last Name"
                          name="lastName"
                          component={TextInput}
                        />
                      </StyledFieldWrapper>

                      <StyledFieldWrapper item={true} xs={12}>
                        <ContactNumberWithCountryCode
                          name="contactNumber"
                          disabled={false}
                          required={true}
                          initialCountry={initialCountry}
                          locales={[]}
                          isValid={this.state.isContactNumberValid}
                          fetchLocales={fetchActiveLocalesPromise}
                          handleOnChange={(e) =>
                            setFieldValue("contactNumber", e)
                          }
                          handleOnBlur={(e) =>
                            setFieldValue("contactNumber", e)
                          }
                        />
                      </StyledFieldWrapper>
                      <StyledFieldWrapper item={true} xs={12}>
                        <Field
                          type="email"
                          label="Email"
                          name="email"
                          component={TextInput}
                        />
                      </StyledFieldWrapper>
                    </FormContainer>
                    <StyledFormFieldsContainer>
                      <MessageContainer>
                        <Field
                          type="textarea"
                          multiline={true}
                          label="Message"
                          name="message"
                          placeholder="Type or select options above"
                          component={TextInput}
                        />
                        <PrivacyPolicy
                          orgColors={colors}
                          policy={privacyPolicyUrl}
                        />
                        <ButtonContainer>
                          <ButtonWrapper>
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              primary={true}
                              fullWidth={true}
                              loading={isSubmitting}
                              orgColors={colors}
                            >
                              Send a message
                            </Button>
                          </ButtonWrapper>
                        </ButtonContainer>
                      </MessageContainer>
                    </StyledFormFieldsContainer>
                  </MainContainer>
                </form>
              )}
            </Formik>
          )}
        </>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({
  auth,
  dialogs,
  chats,
  listing,
}: ApplicationState) => ({
  errors: auth.errors,
  property: listing.property,
  dialogs,
  inviteLink: get(chats.enquiryResponse!, "link", "/register"),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ enqueueSnackbar, toggleMessageDialog }, dispatch),
  ...bindPromiseCreators({ sendEnquiryPromise }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnquiryFormContainer) as any;
