import React from 'react';

import styled from '../../utils/styledComponents';
import Button from '../buttons/Button';
import ActionDialog from './ActionDialog';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.header`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  color: ${({ theme }) => theme.colors.grey4};
  padding-top: 27px;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 14px;
  text-align: center;
`;

const StyledContent = styled.div`
  padding-top: 20px;
  padding-left: 45px;
  padding-right: 45px;
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey2};
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 20px;
`;

const StyledButton = styled(Button).attrs(({ theme }) => ({
  label: {
    color: theme.colors.grey4
  }
}))`
  && {
    &:hover {
      color: ${({ theme }) => theme.colors.grey4} !important;
    }
    width: 60px;
    height: 60px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: 12px;
  }
`;

interface InfoModalProps {
  oClickEvent?: () => void;
  isOpened: boolean;
  header: string;
  content: React.ComponentElement<any, any> | string;
}

const InfoDialog: React.FunctionComponent<InfoModalProps> = ({
  header,
  content,
  oClickEvent,
  isOpened
}) => {
  return (
    <ActionDialog
      open={isOpened}
      rounded={true}
      contentComponent={
        <MainContainer>
          <StyledHeader>{header}</StyledHeader>
          <StyledContent>{content}</StyledContent>
          <StyledButtonContainer>
            <StyledButton onClick={oClickEvent} default={true} size={"small"}>
              Done
            </StyledButton>
          </StyledButtonContainer>
        </MainContainer>
      }
    />
  );
};

export default InfoDialog;
