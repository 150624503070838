import * as React from 'react';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import { FacebookButton } from '../components/buttons';
import { ApplicationState } from '../store';
import { loginWithFacebookPromise } from '../store/auth';

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || "";

interface PropsFromDispatch {
  loginWithFacebookPromise: typeof loginWithFacebookPromise;
}

interface State {
  loading: boolean
}
interface OwnProps {
  register?: boolean;
  onError: (error: string) => void;
  onLoginError: (
    data: {
      email?: string;
      firstName?: string;
      lastName?: string;
    },
    accessToken: string,
    error?: string
  ) => void;
}

type AllProps = OwnProps & PropsFromDispatch;

class FacebookLoginButtonContainer extends React.Component<AllProps, State> {
  public state: State = {
    loading: false
  };

  public static defaultProps = {
    register: false
  };

  public renderFacebookButton = (renderProps: { onClick: () => void }) => (
    <FacebookButton
      onClick={renderProps.onClick}
      fullWidth={true}
      disabled={this.state.loading}
      loading={this.state.loading}>
      Facebook
    </FacebookButton>
  );

  public onClickEvent = (event: any) => {
    this.setState({ loading: true });
  }

  public render() {
    return (
      <FacebookLogin

        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="email,first_name,last_name"
        callback={this.responseFacebook}
        render={this.renderFacebookButton}
        onClick={this.onClickEvent}
        isMobile={false}
      />
    );
  }

  private responseFacebook = async (
    response: ReactFacebookLoginInfo & {
      first_name: string;
      last_name: string;
    } & ReactFacebookFailureResponse
  ) => {
    if (response.hasOwnProperty("status")) {
      let error = `An error occured while trying to ${this.props.register ? "register" : "log in"
        } with Facebook`;
      if (!response.status) {
        error = `User cancelled ${this.props.register ? "register" : "log in"
          } attempt`;
      }

      this.setState({ loading: false });
      return this.props.onError(error);
    } else {
      const { accessToken } = response;
      const data = {
        email: response.email,
        firstName: response.first_name,
        lastName: response.last_name
      };

      try {
        await this.props.loginWithFacebookPromise({
          accessToken,
          data,
          register: false
        });
        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
        return this.props.onLoginError(data, accessToken, error);
      }
    }
  };
}

const mapStateToProps = (store: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ loginWithFacebookPromise }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookLoginButtonContainer) as any;
