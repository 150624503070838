import { omit } from "lodash";
import { transparentize } from "polished";
import React from "react";

import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import MuiCircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";

import styled, { mediaDown } from "../../utils/styledComponents";
import { OrganisationColors } from "../../store/organisation";
import { getOrganisationColor } from "../../utils/whiteLabel";

interface OwnProps {
  loading?: boolean;
  isActive?: boolean;
}

export interface StyledButton {
  primary?: boolean;
  secondary?: boolean;
  default?: boolean;
  text?: boolean;
  outline?: boolean;
  orgColors?: OrganisationColors;
}

export type AllProps = StyledButton & ButtonProps;

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const StyledButton = styled((props: AllProps) => (
  <MuiButton
    {...omit(props, [
      "primary",
      "secondary",
      "text",
      "outline",
      "default",
      "orgColors",
    ])}
  />
))`
  && {
    height: 60px;
    border-radius: 25px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 12px;
    width: ${({ fullWidth }) => (fullWidth ? `100%` : `auto`)};
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;

    max-width: ${({ fullWidth }) => (fullWidth ? `100%` : `315px`)};
    color: ${({ theme, primary, secondary, outline, disabled, orgColors }) => {
      let color = theme.colors.grey4;

      if (primary || secondary) {
        color = getOrganisationColor(
          orgColors,
          "buttonTextColor",
          theme.colors.white
        );
      }

      if (outline && primary) {
        color = getOrganisationColor(
          orgColors,
          "primaryColor",
          theme.colors.orange
        );
      }

      if (outline && secondary) {
        color = getOrganisationColor(
          orgColors,
          "secondaryColor",
          theme.colors.lightblue
        );
      }

      if (disabled) {
        color = transparentize("0.25", color);
      }

      return color;
    }}!important;

    background: ${(props) => {
      const { theme, primary, secondary, outline, orgColors } = props;
      let color = "transparent";

      if (primary && !outline) {
        color = getOrganisationColor(
          orgColors,
          "primaryColor",
          theme.colors.orange
        );
      }

      if (secondary && !outline) {
        color = getOrganisationColor(
          orgColors,
          "secondaryColor",
          theme.colors.lightblue
        );
      }

      if (props.default && !outline) {
        color = theme.colors.grey1;
      }

      return color;
    }};

    border: ${(props) => {
      const { theme, primary, secondary, text, outline, disabled, orgColors } =
        props;

      let color = theme.colors.grey4;

      if (outline && primary) {
        color = getOrganisationColor(
          orgColors,
          "primaryColor",
          theme.colors.orange
        );
      }

      if (outline && secondary) {
        color = getOrganisationColor(
          orgColors,
          "secondaryColor",
          theme.colors.lightblue
        );
      }

      if (outline && disabled) {
        color = transparentize("0.25", color);
      }

      if (outline && props.default) {
        color = theme.colors.grey1;
      }

      if ((primary || secondary || text || props.default) && !outline) {
        color = "none";
      }

      return `1px solid ${color} !important`;
    }};

    &:hover {
      background: ${(props) => {
        const { theme, primary, secondary, orgColors } = props;
        let color = "rgba(255,255,255,0)";

        if (primary) {
          color = getOrganisationColor(
            orgColors,
            "primaryColor",
            theme.colors.orange
          );
        } else if (secondary) {
          color = getOrganisationColor(
            orgColors,
            "secondaryColor",
            theme.colors.lightblue
          );
        } else if (props.default) {
          color = transparentize("0.25", theme.colors.grey2);
        }

        return transparentize("0.25", color);
      }}!important;

      color: ${({ theme, text }) =>
        !text ? theme.colors.white : "initial"}!important;
    }

    > span {
      white-space: nowrap;
    }

    ${mediaDown.sm`width: 100%`}
  }
`;

const StyledProgress = styled(
  MuiCircularProgress as React.FunctionComponent<CircularProgressProps>
)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const Button: React.FunctionComponent<OwnProps & AllProps> = ({
  loading,
  isActive,
  ...props
}) => (
  <Wrapper>
    <StyledButton {...props} />
    {loading && <StyledProgress size={24} color="inherit" />}
  </Wrapper>
);

export default Button;
