import { size } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import placeHolder from '../../assets/images/placeholders/grey-placeholder.png';
import { ImageObject } from '../../store/listings/types';
import { addGTMDataLayer } from '../../utils/GoogleTagManager';
import { validateImg } from '../../utils/imageUrls';
import { IsMobile } from '../../utils/responsive';
import styled, { mediaDown } from '../../utils/styledComponents';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
`;

const ImgContainer = styled.div<ImgContainerProps>`
  && {
    background-color: ${({ theme }) => theme.colors.grey1};
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    max-width: ${({ width }) => width && `calc(1vw + ${width}px)`};
    max-height: ${({ height }) => height && `${height}px`};
    display: flex;
    align-items: center;
    ${mediaDown.sm`height: 100%; margin: 10px -3px;
    border-radius: 0px;`};
    ${({ setHover }) =>
    setHover
      ? `
    &:hover {
      
      > img#img-overlay {
        filter: brightness(50%);
      }
    }
    cursor: pointer;
      > div#view-all-icon {
        opacity: 1;
      }
    `
      : ``};
  }
`;

const ImgGroup = styled.div`
  display: flex;
  width: 26em;
  flex-direction: column;
  ${mediaDown.sm`display: none`};
`;

const Graphic = styled.img`
  ${mediaDown.sm`height: 100%`};
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
`;

const ClickWrapper = styled.div`
  position: relative;
`;

const ViewAll = styled.div`
  text-align: end;
  position: absolute;
  bottom: 10px;
  right: 18px;
  height: 30px;
  width: 150px;
  opacity: 0;
  transition: 0.2s ease-in-out;

  span {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.MS700};
    margin-right: 5px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 5px 10px;
  }
`;

interface ImgContainerProps extends React.HTMLAttributes<any> {
  setHover?: boolean;
  width?: number;
  height: number;
}
export interface ImageProps {
  img: string;
  title: string;
}

interface GalleryProps {
  images: ImageObject[];
  isOpen?: boolean;
  listingId: string;
  isSyndicatedProperty?: boolean;
}

const UnitListGallery: FunctionComponent<GalleryProps> = ({
  images,
  listingId,
  isSyndicatedProperty,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const openModal = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    addGTMDataLayer({
      data: { listingId },
      event: "tenant_viewed_gallery",
      page: "unit-list-gallery",
    });
    setModalIsOpen(true);
  };

  const closeModal = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setModalIsOpen(false);
  };

  const imgCount = size(images) < 3;
  return (
    <>
      <StyledContainer>
        {IsMobile() || imgCount ? (
          <ClickWrapper
            onClick={
              validateImg(images[0], "medium")
                ? openModal
                : undefined
            }
          >
            <ImgContainer
              setHover={images && images[0] ? true : false}
              height={381}
            >
              <Graphic
                id="img-overlay"
                src={validateImg(images[0], "medium") || placeHolder}
                style={{ width: `100em` }}
              />
              <ViewAll id="view-all-icon">
                <span>View Gallery</span>
              </ViewAll>
            </ImgContainer>
          </ClickWrapper>
        ) : (
          <>
            <ImgContainer height={400} width={740}>
              <Graphic
                src={validateImg(images[0], "medium")}
                style={{
                  height: validateImg(
                    images[0],
                    "medium"
                  )
                    ? "auto"
                    : "100%",
                  width: validateImg(
                    images[0],
                    "medium"
                  )
                    ? "100"
                    : "100em",
                }}
              />
            </ImgContainer>
            <ImgGroup>
              <ImgContainer height={190} width={390}>
                <Graphic
                  src={
                    validateImg(
                      images[1],
                      "small"
                    )
                  }
                />
              </ImgContainer>

              <ClickWrapper
                onClick={
                  validateImg(
                    images[2],
                    "small"
                  )
                    ? openModal
                    : undefined
                }
              >
                <ImgContainer
                  setHover={
                    validateImg(images[2], "small") ? true : false
                  }
                  height={190}
                  width={390}
                >
                  <Graphic
                    id="img-overlay"
                    src={
                      validateImg(
                        images[2],
                        "small"
                      )
                    }
                  />
                  <ViewAll id="view-all-icon">
                    <span>View Gallery</span>
                  </ViewAll>
                </ImgContainer>
              </ClickWrapper>
            </ImgGroup>
          </>
        )}
      </StyledContainer>
      {images && (
        <ModalGateway>
          {modalIsOpen && size(images) > 0 && (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map((i: ImageObject) => ({
                  caption: i.title,
                  source: validateImg(i, "large") || '',
                }))}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
    </>
  );
};

export default UnitListGallery;
