import React from "react";
import Link from "../links/Link";
import styled from "../../utils/styledComponents";
import { OrganisationColors } from "../../store/organisation";
import { addUrlPrefix } from "../../utils/url.helper";

const PolicyText = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey2};
  }
`;

const PolicyLink = styled(Link)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: 12px;
    text-decoration: underline;
  }
`;

interface OwnProps {
  text?: string;
  orgColors?: OrganisationColors;
  policy?: string;
}
const PrivacyPolicy: React.FunctionComponent<OwnProps> = ({
  orgColors,
  text,
  policy,
}) => {
  const privacyPolicyLink = addUrlPrefix(policy);

  return (
    <PolicyText>
      {text ||
        ` By submitting I agree to allow Flow to collect, store and process my data, as per the `}
      <PolicyLink
        primary
        orgColors={orgColors}
        href={privacyPolicyLink}
        target="_blank"
      >
        Privacy Policy
      </PolicyLink>
    </PolicyText>
  );
};
export default PrivacyPolicy;
