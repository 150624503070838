// import { isEmpty } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

export interface Option {
  title: string;
  active: boolean;
}
export interface CategorisedOption {
  category?: string;
  options?: Option[];
}

interface OwnProps {
  id: string;
  name: string;
  label: string;
  value: any;
  minPrice: string;
  maxPrice: string;
  priceRange: any;
  disabled?: boolean;
  options: Array<{ min: string; max: string; label: string }>;
  minWidth?: number;
  onGetMaxPrice: (event: any) => void;
  onGetMinPrice: (event: any) => void;
  onClose?: (event: any) => void;
}

const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS300};
  }
`;

const StyledSelect = styled(MuiSelect)`
  .MuiSelect-root {
    padding-bottom: 2px;
    max-width: 150px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 14px;
    &:focus {
      background: none;
    }
    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
  .MuiSelect-icon {
    color: #c1c1c1;
  }
  & ul {
    padding-top: 0px !important;
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      paddingLeft: 10,
      paddingRight: 5,
      marginTop: 40,
    },
  },
};

const NestedSelect: React.FunctionComponent<OwnProps> = (props) => {
  const { id, label, minWidth = 60, options } = props;
  const classes = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      maxWidth: 300,
      minWidth,
      width: "auto",
    },
  }))();

  const onRenderValue = (selected: any) => {
    const formatted = selected.split("-");
    if (formatted[0].trim().length === 0 && formatted[1].trim().length > 0) {
      return `Up to ${formatted[1].trim()}`;
    } else if (
      formatted[0].trim().length > 0 &&
      formatted[1].trim().length === 0
    ) {
      return `${formatted[0].trim()} +`;
    } else {
      return selected;
    }
  };

  const renderValue = (seleced: any) => seleced;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <StyledSelect
        labelId={`${id}-checkbox-label`}
        id={`${id}-label`}
        name={props.name}
        value={props.value}
        input={<Input disableUnderline={true} />}
        MenuProps={MenuProps}
        renderValue={onRenderValue}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="listing-price-min-label">Min Price</InputLabel>
          <StyledSelect
            labelId="listing-price-min-label"
            id="listing-price-min"
            name="minPrice"
            value={props.minPrice}
            onChange={props.onGetMinPrice}
            input={<Input disableUnderline={true} />}
            MenuProps={MenuProps}
            renderValue={renderValue}
          >
            <StyledMenuItem value={""}>any</StyledMenuItem>
            {options.map((pr: any) => (
              <StyledMenuItem key={pr.min} value={priceFormat(pr.min)}>
                {priceFormat(pr.min)}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="listing-price-max-label">Max Price</InputLabel>
          <StyledSelect
            labelId="listing-price-max-label"
            id="listing-price-max"
            name="maxPrice"
            value={props.maxPrice}
            onChange={props.onGetMaxPrice}
            input={<Input disableUnderline={true} />}
            MenuProps={MenuProps}
            renderValue={renderValue}
          >
            <StyledMenuItem value={""}>any</StyledMenuItem>
            {options.map((pr: any) => (
              <StyledMenuItem key={pr.max} value={priceFormat(pr.max)}>
                {priceFormat(pr.max)}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </StyledSelect>
    </FormControl>
  );
};

export const priceFormat = (price: string | number) => {
  return `R${numeral(price).format("0,0")}`;
};

export default NestedSelect;
