export function errorHandler(error: any): string {
  try {
    let errorMessage = "";

    if (error.message) {
      errorMessage = error.message;
    } else if (error.data) {
      errorMessage = error.data.message;
    } else if (error.response && error.response.data) {
      errorMessage = error.response.data.message;
    } else if (error.response) {
      errorMessage = error.response.data;
    } else if (error.request) {
      errorMessage = JSON.parse(error.request.responseText).message;
    } else {
      errorMessage = error;
    }
    return errorMessage;
  } catch (error) {
    console.log("error", error);
    return "Error";
  }
}
