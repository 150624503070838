import { css } from '../utils/styledComponents';

export default css`
  html,
  body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "Museo-Sans-300", Helvetica, sans-serif;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    position: relative;
    vertical-align: middle;
  }
  figure {
    margin: 2rem 0;
  }
  figcaption {
    font-size: 80%;
  }
  table {
    width: 100%;
    font-size: 85%;
    border-collapse: collapse;
  }

  div {
    &:focus {
      outline: 0;
    }
  }
  
  .intercom-launcher-frame {
    z-index: 1000 !important;
  }

  @font-face {
    font-family: "MuseoSans-300";
    src: url("/fonts/MuseoSans-300.woff2") format("woff2"),
      url("/fonts/MuseoSans-300.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-500";
    src: url("/fonts/MuseoSans-500.woff2") format("woff2"),
      url("/fonts/MuseoSans-500.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-700";
    src: url("/fonts/MuseoSans-700.woff2") format("woff2"),
      url("/fonts/MuseoSans-700.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-900";
    src: url("/fonts/MuseoSans-900.woff2") format("woff2"),
      url("/fonts/MuseoSans-900.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  #root {
    height: 100%;
  }

  .pac-container {
    z-index: 9999;
  }

  iframe {
    border: none;
  }
  @media only screen and (min-width: 768px) {
    .arrow-button {
      top: -50px !important;
    }
  }
  .locale-tel-search {
    padding: 10px !important;
  }
  .locale-tel-search-box {
    width: 100% !important;
    margin-left: 0px !important;
    font-family: "Museo-Sans-300", Helvetica, sans-serif !important;
  }
  .locale-tel-container {
    font-family: "Museo-Sans-300", Helvetica, sans-serif !important;
    margin-bottom: 10px !important;
    margin-top: 8px !important;
  }
  .locale-tel-dropdown{
    width: 305px !important;
  }
  .locale-tel-input{
    width: 100% !important;
    border-radius: 0px !important;
    color: #4C4C4E !important;
    font-family: 'MuseoSans-300',Helvetica,sans-serif !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    border: 0 !important;
    margin: 0 !important;
    animation-name: MuiInputBase-keyframes-auto-fill-cancel !important;
    -webkit-tap-highlight-color: transparent !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
    padding: 19.5px 0px 6.5px 40px !important;
  }
  .locale-tel-input:focus{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    -webkit-box-shadow: 0px 1px rgba(0, 0, 0, 0.42) !important;
    box-shadow: 0px 1px rgba(0, 0, 0, 0.42) !important;
  }
  .locale-tel-container >.special-label{
    color: #B0B1B3 !important;
    font-size: 0.8rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    padding: 0px !important;
    left: 0 !important;
    letter-spacing: 0.00938em !important;
    margin-bottom: 10px;
  }

  .locale-tel-container >.flag-dropdown >.selected-flag{
   padding: 0px !important;
   top: 3px !important;
  }

  .locale-tel-outlined >.flag-dropdown >.selected-flag{
   padding-left: 11px !important;
  
  }

  .locale-tel-outlined > .locale-tel-input{
    padding: 10.5px 0px 10.5px 51px !important;
    border-radius: 10px !important;
    border: 1px solid #CACACA !important;
  }

  .locale-tel-outlined-error > .locale-tel-input{
    border: 1px solid #e65757 !important;
  }

  .locale-tel-error > .locale-tel-input{
    border-bottom: 1px solid #e65757 !important;
  }

  .locale-tel-outlined >.special-label {
    left: 9px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }




`;
