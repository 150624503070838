import { isEmpty, omit } from 'lodash';
import React, { useState } from 'react';

import { FlowExtra } from '../../store/listings';
import { addGTMDataLayer } from '../../utils/GoogleTagManager';
import styled, { mediaDown } from '../../utils/styledComponents';
import ExtraInfoDialog from '../dialogs/ExtraInfoDialog';
import ExtraItem from '../listItems/ExtraItem';
import FlowExtraList from '../lists/FlowExtraList';

const Container = styled.div`
  max-height: 625px;
  width: 100%;
`;

interface OwnProps {
  light?: boolean;
}

const StyledList = styled((props) => (
  <FlowExtraList {...omit(props, ["scroll"])} />
))`
  && {
    display: flex;
    ${mediaDown.sm`max-height: 380px;`}
    overflow: ${({ scroll }) => (scroll ? "scroll" : "hidden")};
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -20px;
      width: 48px;
      height: 100%;      
    }
  }
`;

interface OwnProps {
  data: FlowExtra[];
  allowItemClick?: boolean;
}

const ExtraWidget: React.FunctionComponent<OwnProps> = ({
  allowItemClick = true,
  data,
}) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<FlowExtra>(data[0]);

  const onItemClick = (selectedId: string): void => {
    setDialogOpen(true);
    const record = data.find(({ _id }) => _id === selectedId);
    if (record) {
      setSelectedData(record);
      addGTMDataLayer({
        data: { badgeName: record.title },
        event: "tenant_viewed_badge",
        page: "flow-extra",
      });
    }
  };

  const onDialogOpen = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setDialogOpen(!isDialogOpen);
  };

  const handleOnItemClick = allowItemClick
    ? onItemClick
    : () => {
        /* */
      };

  return (
    <Container>
      {!isEmpty(data) && (
        <ExtraInfoDialog
          open={isDialogOpen}
          setDialogOpen={onDialogOpen}
          extraData={selectedData}
        />
      )}
      <StyledList scroll={allowItemClick ? true : false}>
        {!isEmpty(data)
          ? Object.keys(data).map((key) => {
              const item: FlowExtra = data[key];
              return (
                <ExtraItem
                  data={item}
                  key={key}
                  onItemClick={handleOnItemClick}
                />
              );
            })
          : null}
      </StyledList>
    </Container>
  );
};

export default ExtraWidget;
