import React from "react";
import { Listing } from "../../store/listings";
import styled, { mediaDown } from "../../utils/styledComponents";
import PriceWidget, { PriceWidgetProps } from "./PriceWidget";
import PropertyAgentWidget from "./PropertyAgentWidget";

const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: solid thin #e6e6e6;
    padding: 12px 14px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    ${mediaDown.md`
    display: none;
  `}
  }
`;

interface OwnProps extends PriceWidgetProps {
  agencyLogo: string;
  agentAvator: string;
  agentName: string;
  organisationName: string;
  agentProfile: any;
  property: Listing;
}

const ContactAgentWidget: React.FunctionComponent<OwnProps> = (props) => {
  return (
    <Container>
      <PriceWidget {...props} />
      {props.agentProfile && <PropertyAgentWidget {...props} />}
    </Container>
  );
};

export default ContactAgentWidget;
