import styled, { mediaDown } from "../../utils/styledComponents";

const AgencyLogo = styled.div<{
  src: string;
  height: number;
  width?: number;
  radius?: number;
  borderColor?: string;
  backgroundColor?: string;
}>`
  && {
    flex-shrink: 0;
    max-height: ${({ height }) => `${height}px`};
    min-height: ${({ height }) => `${height - 5}px`};
    width: ${({ width }) => (width ? `${width}px` : `calc(180px + 6% )`)};
    border-radius: ${({ radius }) => (radius ? `${radius}px` : `10px`)};
    border: solid 2px
      ${({ theme, borderColor }) =>
        borderColor ? borderColor : theme.colors.grey[250]};
    background-color: ${({ theme, backgroundColor, src }) => {
      return backgroundColor
        ? `${backgroundColor}`
        : src
        ? theme.colors.white
        : theme.colors.grey[250];
    }};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${({ src }) => src});
    ${mediaDown.sm`
  align-self:center;
  `}
  }
`;

export default AgencyLogo;
