import { Field, Form, InjectedFormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";

import Grid from "@material-ui/core/Grid";

import ContactNumberWithCountryCode from "../../components/inputs/contactNumberWithCode";
import { fetchActiveLocalesPromise } from "../../store/defaults";
import { DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES } from "../../utils/defaultLocaleSettings";
import styled, { mediaDown } from "../../utils/styledComponents";
import { UserTypeButton } from "../buttons";
import Button from "../buttons/Button";
import FlowTextInput from "../inputs/TextInput";
import ErrorMessage from "../ui/ErrorMessage";
import { FieldWrapper, FormFieldsContainer } from "../ui/forms";
import StepperIcons from "./StepperIcons";

export interface RegisterFormValues {
  firstName: string;
  lastName: string;
  contactNumber: string;
  password: string;
  confirmPassword: string;
  email: string;
  userType: string;
  referral?: { action: string; type: string; referralCode: string };
  invite?: { token: string };
  tags?: string[];
  Source?: string;
  UserType?: string;
}

export interface RegisterFormProps {
  typeSelectDisabled?: boolean;
}

type AllProps = InjectedFormikProps<{}, RegisterFormValues> & RegisterFormProps;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.grey3};
  margin: 10px 0 10px 0;
`;

const ButtonGrid = styled(Grid)`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  padding: 35px 20px 0;
  ${mediaDown.sm`
    padding: 35px 0 0;
  `}
  display: flex;
`;
const StyledButton = styled(Button)`
  && {
    margin-left: auto !important;
  }
`;

const StyledFieldWrapper = styled(FieldWrapper)`
  && {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const StyledFormFieldsContainer = styled(FormFieldsContainer)`
  padding: 0 15px;
  ${mediaDown.sm`padding: 0;`}
`;

const IntroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
  color: ${({ theme }) => theme.colors.grey4};
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

const TextInput = styled(FlowTextInput)`
  && {
    > div::after {
      transform: scaleX(0);
    }
  }
`;

export const RegisterForm: React.FunctionComponent<AllProps> = (props) => {
  const { isSubmitting, values, setFieldValue, typeSelectDisabled } = props;
  const [previousValue, setPreviousValue] = React.useState(
    DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES.country.phone.dialCode
  );
  const contactNo = get(values, "contactNumber", previousValue);
  const errorMessage =
    props.touched &&
    props.touched["contactNumber"] &&
    props.errors &&
    props.errors["contactNumber"];

  const handleUserTypeChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const input = event.target as HTMLElement;
    const value = input.innerText.toLowerCase();
    setFieldValue("userType", value);
  };

  const renderUserTypeButton = (userType: string, selectedType: string) => {
    return (
      <UserTypeButton
        onClick={handleUserTypeChange}
        isActive={userType.toLowerCase() === selectedType}
        fullWidth={true}
      >
        {userType}
      </UserTypeButton>
    );
  };

  return (
    <Form>
      <StyledFormFieldsContainer>
        <IntroTitle>Create a Buyer/Tenant account</IntroTitle>
        {!typeSelectDisabled && (
          <StyledFieldWrapper item={true} xs={12}>
            <div>
              <Header>Select</Header>
              <ButtonGrid spacing={1} container={true}>
                <ButtonGrid item={true}>
                  {renderUserTypeButton("Tenant", values.userType)}
                </ButtonGrid>
                <ButtonGrid item={true}>
                  {renderUserTypeButton("Landlord", values.userType)}
                </ButtonGrid>
              </ButtonGrid>
            </div>
            <div>
              <ErrorMessage name="userType" />
            </div>
          </StyledFieldWrapper>
        )}
        <StyledFieldWrapper item={true} sm={12} xs={12}>
          <Field
            type="text"
            label="First Name"
            name="firstName"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} sm={12} xs={12}>
          <Field
            type="text"
            label="Last Name"
            name="lastName"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <ContactNumberWithCountryCode
            name="contactNumber"
            disabled={false}
            value={contactNo}
            required={true}
            locales={[]}
            isValid={!errorMessage}
            fetchLocales={fetchActiveLocalesPromise}
            handleOnChange={(e, b) => {
              setPreviousValue(b);
              setFieldValue("contactNumber", e);
            }}
            handleOnBlur={(e) => setFieldValue("contactNumber", e)}
          />
        </StyledFieldWrapper>

        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="email"
            label="Email"
            name="email"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="password"
            label="Password"
            name="password"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            component={TextInput}
          />
        </StyledFieldWrapper>
      </StyledFormFieldsContainer>
      <ButtonWrapper>
        <StepperIcons steps={2} currentStep={1} />
        <ButtonContainer>
          <StyledButton
            type="submit"
            disabled={isSubmitting}
            primary={true}
            loading={isSubmitting}
          >
            Next
          </StyledButton>
        </ButtonContainer>
      </ButtonWrapper>
    </Form>
  );
};

export default RegisterForm;
