import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import styled, { mediaDown } from '../../utils/styledComponents';
import FlowFontAwesomeIcon from '../icons/FlowFontAwesomeIcon';
import Slide from '@material-ui/core/Slide';

interface OwnProps {
  closeDialog: (event?: any) => void;
  TransitionComponent?: any;
}

type AllProps = OwnProps & DialogProps;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      width: 100%;
      border-radius: 20px;
      min-height: 455px;
      max-width: 750px;
      margin: 20px;
      ${mediaDown.sm`
        background: white;
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        margin: 0;
      `};
    }
  }
`;
const DialogContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  ${mediaDown.sm`
    flex-direction: column;`}
`;

const CloseButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey2};
  background: none;
  border: none;
  position: absolute;
  top: 30px;
  right: 30px;
  ${mediaDown.sm`
    top: 20px;
    right: 10px !important;`}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWithImage: React.FunctionComponent<AllProps> = ({
  children,
  closeDialog,
  ...props
}) => {
  return (
    <StyledDialog TransitionComponent={Transition} {...props}>
      <DialogContentContainer>
        <CloseButton title="Close" onClick={closeDialog}>
          <FlowFontAwesomeIcon name="times" />
        </CloseButton>
        {children}
      </DialogContentContainer>
    </StyledDialog>
  );
};

export default DialogWithImage;
