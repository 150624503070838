import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

import styled from '../../../utils/styledComponents';

type AllProps = TypographyProps;

const FormSectionDescription = styled(MuiTypography as React.FunctionComponent<
  AllProps
>).attrs({
  variant: "subtitle2"
})`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
    color: ${({ theme }) => theme.colors.grey3};
    margin-bottom: 36px;
  }
`;

export default FormSectionDescription;
