import { ErrorMessage as FormikErrorMessage, ErrorMessageProps } from 'formik';
import React from 'react';

import styled from '../../utils/styledComponents';

const Message = styled.span`
  color: ${({ theme }) => theme.colors.red};
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: ${({ theme }) => theme.fonts.MS300};
`;

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = props => (
  <FormikErrorMessage {...props}>
    {msg => <Message>{msg}</Message>}
  </FormikErrorMessage>
);

export default ErrorMessage;
