import axios from 'axios';
import { Map } from 'google-maps-react';
import { isEmpty, get } from 'lodash';
import React from 'react';

import { Address } from '../../store/listings';
import { getAddressDetailsFromListing } from '../../utils/addressFormatter';

import styled from '../../utils/styledComponents';

const EmbeddedMap = styled.iframe`
  position: relative;
  width: 100%;
  height: 341px;
  border-radius: 10px;
  margin-top: 15px;
`;

const MainStyle = {
  position: 'relative',
  width: 'auto',
  height: 341,
  borderRadius: 20,

}
interface MapWidgetProps {
  title: string;
  address: Address;
  location: {
    coordinates: number[]
  },
  showFullAddress: boolean
}

interface GeolocationPoints {
  lat: number;
  lng: number;
}

const MapWidget: React.FunctionComponent<MapWidgetProps> = (props) => {
  const { address, location, title, showFullAddress } = props;
  const query = {
    query: getAddressDetailsFromListing(address),
    fields: ["name", "geometry"],
  };
  const mapZoom = showFullAddress ? 14 : 13;

  const _mapLoaded = (mapProps: any, map: any) => {
    const { google } = mapProps;

    const getGeoJSON = (point: GeolocationPoints) => {
      const openStreetAPIBaseURL = "https://nominatim.openstreetmap.org/reverse.php";
      const params = {
        lat: point.lat,
        lon: point.lng,
        zoom: 14, // 14 is suburb level
        polygon_geojson: 1,
        format: "json"
      };

      return axios.get(openStreetAPIBaseURL, { params });
    };

    const drawPolygon = (point: GeolocationPoints) => {
      getGeoJSON(point).then((response) => {
        const geo = get(response, "data.geojson", null);
        if (geo) {
          const geoJSON = createGoogleMapsGeoJSON(geo);
          map.data.addGeoJson(geoJSON);
          map.data.setStyle({
            fillColor: "rgba(255,116,24,1)",
            fillOpacity: 0.1,
            strokeWeight: 1
          });
        }
        // else nothing to draw without coordinates
      });
    };

    const createGoogleMapsGeoJSON = (geometry: any) => {
      return {
        "type": "FeatureCollection",
        "features": [
          { "type": "Feature",
            "geometry": geometry
          }
        ]
      };
    };

    const createMarker = (place: google.maps.places.PlaceResult) => {
      map.setCenter((place.geometry as google.maps.places.PlaceGeometry).location);
      // only show the marker if full address is enabled
      if (showFullAddress) {
        return new google.maps.Marker({
          map,
          position: (place.geometry as google.maps.places.PlaceGeometry).location,
        });
      }
    }

    if (!isEmpty(location.coordinates) && (location.coordinates[0] !== 0 || location.coordinates[1] !== 0)) {
      const point: GeolocationPoints = { lat: location.coordinates[0], lng: location.coordinates[1] };
      const coords = {
        geometry: {
          location: point
        },
        name: title
      } as google.maps.places.PlaceResult | any;

      createMarker(coords);

      if (!showFullAddress) {
        drawPolygon(point);
      }
    } else {
      const service = new google.maps.places.PlacesService(map);
      service.findPlaceFromQuery(
        query,
        (
          results: google.maps.places.PlaceResult[],
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            createMarker(results[0]);

            const coord = get(results, "0.geometry.location");
            if (coord && !showFullAddress) {
              drawPolygon(coord.toJSON());
            }
          }
        }
      );
    }
  }

  if (!showFullAddress) {
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(query.query)}&zoom=${mapZoom}&maptype=roadmap`;
    return (<EmbeddedMap src={mapUrl} />);
  }

  return (
    <Map
      {...props}
      google={window.google}
      containerStyle={MainStyle}
      style={{ borderRadius: 10 }}
      zoom={mapZoom}
      disableDefaultUI={false}
      zoomControl={true}
      gestureHandling='cooperative'
      initialCenter={
        {
          lat: -29.8771637,
          lng: 22.011454
        }
      }
      onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
    />
  )
}

export default MapWidget;
