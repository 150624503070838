import { withFormik } from "formik";
import { get, isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import * as Yup from "yup";

import tick from "../assets/images/icons/tick.png";
import DoneButton from "../components/buttons/DoneButton";
import MessageForm, {
  MessageFormValues,
} from "../components/forms/MessageForm";
import UnitItem from "../components/listItems/UnitItem";
import { ApplicationState } from "../store";
import { authenticatePromise as getUser } from "../store/auth";
import { createChatPromise, ListingEnquiry, Property } from "../store/chat";
import { DialogsState, toggleMessageDialog } from "../store/dialogs";
import { enqueueSnackbar } from "../store/notifications";
import { Unit } from "../store/units";
import { getCoverImage } from "../utils/imageUrls";
import styled from "../utils/styledComponents";

interface OwnProps {
  token?: string;
  property: Property;
  unit: Unit;
  agentId: string;
  landlordName: string;
}

interface PropsFromState {
  errors?: string;
  dialogs: DialogsState;
}

interface PropsFromDispatch {
  getUser: typeof getUser;
  createChatPromise: typeof createChatPromise;
  toggleMessageDialog: typeof toggleMessageDialog;
  handleCloseMessageDialog: () => void;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch;

interface State {
  isLoading: boolean;
}

const Wrapper = styled.div``;

const SuccessMessage = styled.div`
  color: ${({ theme }) => theme.colors.grey4};
  text-align: center;
`;
const InfoContainer = styled.div`
  text-align: center;
  max-width: 190px;
  margin: 35px auto 0;
`;
const MessageInfoContainer = styled(InfoContainer as any)`
  max-width: 100%;
  margin-bottom: 20px;
`;
const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
  color: ${({ theme }) => theme.colors.grey4};
  margin: 0 0 10px;
`;
const MessageHeading = styled(Heading as any)`
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
`;
const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey3};
  font-size: ${({ theme }) => theme.fontSizes.navbarItem};
  margin: 0 0 10px;
  line-height: 1.7;
`;
const ButtonContainer = styled.div`
  display: flex;
  padding-top: 38px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;
const StyledTick = styled.img`
  width: 100%;
  max-width: 134px;
  height: auto;
  margin: 20px;
`;
const ErrorMessage = styled(SuccessMessage)``;
const UnitItemContainer = styled.div`
  display: flex;
  align-items: center;
`;
/* tslint:disable:object-literal-sort-keys */
const schema = Yup.object().shape({
  message: Yup.string().required("Enter a message to send"),
});

class MessageFormContainer extends React.Component<AllProps> {
  public readonly state: State = {
    isLoading: false,
  };

  public render() {
    const { errors, unit, property } = this.props;

    const isResidential = isEqual(property.listingSector, "residential");
    const headingText = isResidential
      ? "Contact the agent"
      : "Contact the broker";
    const descriptionText = `Let’s get your chat with an ${
      isResidential ? "agent" : "broker"
    } started below`;

    const imageUrl = property.coverImageUrl
      ? property.coverImageUrl
      : getCoverImage(property);
    return (
      <Wrapper>
        {!errors ? (
          <>
            {this.props.dialogs.openMessageDialog.messageSent ? (
              <SuccessMessage>
                <StyledTick src={tick} />
                <MessageInfoContainer>
                  <MessageHeading>
                    Your message has been sent successfully.
                  </MessageHeading>
                  <Description>
                    Agents on Flow respond really quick and you can chat with
                    them under Messages tab.
                  </Description>
                </MessageInfoContainer>
                <UnitItemContainer>
                  <UnitItem
                    image={imageUrl}
                    address={property.address.location}
                    buildingName={property.title}
                    apartmentName={unit.title}
                  />
                </UnitItemContainer>
                <ButtonContainer>
                  <ButtonWrapper>
                    <DoneButton
                      type="button"
                      onClick={this.props.handleCloseMessageDialog}
                    >
                      Done
                    </DoneButton>
                  </ButtonWrapper>
                </ButtonContainer>
              </SuccessMessage>
            ) : (
              <>
                <InfoContainer>
                  <Heading>{headingText}</Heading>
                  <Description>{descriptionText}</Description>
                </InfoContainer>
                <UnitItemContainer>
                  <UnitItem
                    image={imageUrl}
                    address={property.address.location}
                    buildingName={property.title}
                    apartmentName={unit.title}
                  />
                </UnitItemContainer>
                {this.renderForm()}
              </>
            )}
          </>
        ) : (
          <ErrorMessage>There was an error sending your message.</ErrorMessage>
        )}
      </Wrapper>
    );
  }

  private renderForm() {
    const formikEnhancer = withFormik<{}, MessageFormValues>({
      displayName: "MessageForm",
      handleSubmit: async (values, { setSubmitting }) => {
        this.setState({ isLoading: true });
        const { property, unit, agentId, landlordName } = this.props;
        const coverImageUrl = property.coverImageUrl
          ? property.coverImageUrl
          : getCoverImage(property);

        try {
          property.address.suburb = property.address.location;
          const listingEnquiry: ListingEnquiry = {
            agent: agentId,
            landlordName,
            message: values.message,
            property: {
              _id: property._id,
              address: property.address,
              title: property.title,
              description: property.description,
              listingSector: property.listingSector,
              organisation: property.organisation,
            },
            unit: {
              _id: unit._id,
              image: coverImageUrl,
              title: get(unit, "title", property.title),
              rentalAmount: unit.rentalAmount,
              bedrooms: parseInt(String(unit.bedrooms)),
              bathrooms: parseInt(String(unit.bathrooms)),
              parkings: parseInt(String(unit.parkings)),
              displayPrice: unit.displayPrice,
            },
          };

          await this.props.createChatPromise(listingEnquiry).then(async () => {
            this.props.toggleMessageDialog({
              unitId: unit._id,
              messageSent: true,
            });

            await this.props.getUser({});
          });
        } catch (error) {
          this.setState({ isLoading: false });
          this.props.toggleMessageDialog({
            unitId: unit._id,
            messageSent: false,
          });
          console.log(error);
        } finally {
          this.setState({ isLoading: false });
          setSubmitting(false);
        }
      },
      mapPropsToValues: () => ({
        isLoading: this.state.isLoading,
        message: "",
      }),
      validationSchema: schema,
    });
    const EnhancedMessageForm = formikEnhancer(MessageForm);

    return <EnhancedMessageForm />;
  }
}

const mapStateToProps = ({ auth, dialogs }: ApplicationState) => ({
  errors: auth.errors,
  dialogs,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ enqueueSnackbar, toggleMessageDialog }, dispatch),
  ...bindPromiseCreators({ createChatPromise, getUser }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageFormContainer) as any;
