import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { enqueueSnackbar } from "../notifications";
import {
  createChat,
  getProfile,
  sendEnquiry,
  setChatUnreadCount,
} from "./routines";

function* handleSendEnquiry(action: AnyAction) {
  try {
    const res = yield call(
      callApi,
      "POST",
      "/anonymous-user/send-message?sourceOrigin=tenant-portal",
      {
        data: { data: action.payload },
      }
    );
    
    res.data.data["payload"] = action.payload;
    yield put(sendEnquiry.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(sendEnquiry.failure(errorHandler(err.response)));
    } else {
      yield put(sendEnquiry.failure("An unknown error occured"));
    }
  } finally {
    yield put(sendEnquiry.fulfill());
  }
}

function* handleCreateChat(action: AnyAction) {
  try {
    const res = yield call(
      callApi,
      "POST",
      "/chats?sourceOrigin=tenant-portal",
      {
        data: action.payload,
      }
    );
    yield put(createChat.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(createChat.failure(errorHandler(err.response)));
    } else {
      yield put(createChat.failure("An unknown error occured"));
    }
  } finally {
    yield put(createChat.fulfill());
  }
}

function* handleGetProfileById(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", `/chats/${action.payload.chatId}`);

    yield put(getProfile.success(res.data));
  } catch (err) {
    console.log(errorHandler(err));
    if (err.response.status === 400) {
      yield put(getProfile.failure(err));
    } else if (err.response) {
      yield put(getProfile.failure(errorHandler(err)));
    } else {
      yield put(getProfile.failure("An unknown error occured."));
    }
  } finally {
    yield put(getProfile.fulfill());
  }
}

function* handleSetUreadChatCount(action: AnyAction) {
  try {
    yield put(setChatUnreadCount.success(action.payload));
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: errorHandler(err.message),
        options: { variant: "error" },
      })
    );
  } finally {
    yield put(setChatUnreadCount.fulfill());
  }
}

function* createChatWatcher() {
  yield takeEvery(createChat.TRIGGER, handleCreateChat);
}

function* setChatUnreadCountWatcher() {
  yield takeEvery(setChatUnreadCount.TRIGGER, handleSetUreadChatCount);
}

function* getProfileById() {
  yield takeEvery(getProfile.TRIGGER, handleGetProfileById);
}

function* sendEnquiryWatcher() {
  yield takeEvery(sendEnquiry.TRIGGER, handleSendEnquiry);
}

export function* chatSaga() {
  yield all([
    fork(createChatWatcher),
    fork(setChatUnreadCountWatcher),
    fork(getProfileById),
    fork(sendEnquiryWatcher),
  ]);
}
