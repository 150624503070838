import React from "react";
import Iframe from "react-iframe";
import { IsMobile } from "../../utils/responsive";
import styled from "../../utils/styledComponents";
const Player = styled(Iframe)`
  && {
    margin-bottom: 25px;
    border-radius: 10px;
  }
`;
interface OwnProps {
  url: string;
}
const VideoPlayer: React.FunctionComponent<OwnProps> = ({ url }) => {
  const isYoutube = url.search("youtube.com");
  const cleanedUrl = isYoutube !== -1 ? url.replace("watch?v=", "embed/") : url;
  const isOnMobile = IsMobile();
  return (
    <Player
      url={cleanedUrl}
      width="100%"
      height={isOnMobile ? "240px" : "420px"}
      allowFullScreen={true}
      id={url}
      position="relative"
    />
  );
};

export default VideoPlayer;
