
import { values } from 'lodash';
import React from 'react';

import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import styled from '../../utils/styledComponents';

const StyledTexField = styled(TextField)`&&{

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 10px;
    height: 40px;
    }
     
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
  
    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 14px) scale(1);
      pointer-events: none;
    }
  
     & fieldset > legend {
        display: block;
      }

      margin-bottom: 8px;
    > div::after {
      border-bottom-color: ${({ theme }) => theme.colors.grey2};
    }

    > label {
      color: ${({ theme }) => theme.colors.grey2};
    }

    > p {
      color: ${({ theme }) => theme.colors.red};
      font-family: ${({ theme }) => theme.fonts.MS300};
      font-weight: ${({ theme }) => theme.fontWeights.MS300};
    }
  }`;

interface OwnProps {
  disableUnderline?: boolean;
  values?: any;
  touched?: any;
  setFieldValue?: any;
  dataset: any;
  bind?: string;
  name?: string;
  label?: string;
  errors?: any;
  selectedOption?: object;
  variant?: "outlined" | "standard" | "filled" | undefined;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: any) => void;
}

type AllProps = OwnProps;

const DynamicDropDown: React.FunctionComponent<AllProps> = (props) => {
  const {
    name,
    label,
    selectedOption,
    dataset,
    bind,
    variant,
    setFieldValue,
    disableUnderline,
    errors,
    touched,
    handleOnChange,
    ...rest
  } = props;

  const bindOrName: any = name || bind;
  const firstOption = selectedOption
    ? values(selectedOption)[0]
    : (rest.values && rest.values[bindOrName]) || '';

  const allDropDownElements = Object.keys(dataset).map((key, index) => {
    return (
      <MenuItem key={index} value={key}>
        {dataset[key]}
      </MenuItem>
    );
  });

  const onHandlechange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (setFieldValue) {
      setFieldValue(bindOrName, event.target.value);
    }

    if (handleOnChange) {
      handleOnChange(event);
    }
  }

  const isTouched = bindOrName && touched && touched[bindOrName];
  const errorMessage = bindOrName && errors && errors[bindOrName];

  return (
    <StyledTexField
      {...rest}
      fullWidth={true}
      InputProps={{
        disableUnderline
      }}
      id={bindOrName}
      name={bindOrName}
      select={true}
      label={label}
      variant={variant}
      onBlur={props.handleBlur}
      onChange={onHandlechange}
      value={firstOption}
      error={(isTouched && errorMessage) || false}
      helperText={(isTouched && errorMessage) || ""}
    >
      <MenuItem value="none" disabled={true}>
        Please select
      </MenuItem>
      {allDropDownElements}
    </StyledTexField>
  );
}

export default DynamicDropDown;
