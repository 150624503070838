import * as _ from "lodash";
import { Property } from "../store/chat";
import { Listing } from "../store/listings";

export enum imageSizes {
  AVATAR = "50x50",
  HERO = "1920x1080",
  LARGE = "1024x1024",
  MEDIUM = "300x300",
  THUMBNAIL = "150x150",
}

interface ImageProps {
  media: {
    sizes: "small" | "medium" | "large" | "avatar" | "thumbnail";
  };
}

export const imageUrl = (
  url: string | { publicUrl: string },
  size: imageSizes
) => {
  if (url) {
    const urlObj =
      typeof url === "string" ? new URL(url) : new URL(url.publicUrl);
    const urlPathArr = _.split(urlObj.pathname, "/");

    urlPathArr.splice(urlPathArr.length - 1, 0, size);

    const resizedImageUrl = `${urlObj.origin}${_.join(urlPathArr, "/")}${
      urlObj.search ? urlObj.search : ""
    }`;

    return resizedImageUrl;
  }

  return "";
};

export const getImages = (
  imageObjs: Array<{ publicUrl: string }>
): string[] => {
  return imageObjs.map((img: { publicUrl: string }) =>
    imageUrl(img.publicUrl, imageSizes.LARGE)
  );
};

export const getHeroImage = (
  imageObjs: Array<{ publicUrl: string }>
): string => {
  return imageObjs.length > 0
    ? imageUrl(imageObjs[0].publicUrl, imageSizes.LARGE)
    : "";
};

export const validateImg = (
  imgObj: ImageProps,
  size: "small" | "medium" | "large" | "avatar" | "thumbnail"
): string | undefined => {
  return _.get(
    imgObj,
    `media.sizes.${size}`,
    _.get(imgObj, "media.url", undefined)
  );
};

export const getCoverImage = (property: Listing | Property) => {
  const findCover = _.get(property, "images").find(
    (image: any) => image.type === "cover"
  );

  return _.get(
    findCover,
    "media.sizes.thumbnail",
    _.get(
      findCover,
      "media.url",
      _.get(
        property,
        "images[0].media.sizes.thumbnail",
        _.get(property, "images[0].media.url")
      )
    )
  );
};
