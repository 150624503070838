import { omit } from 'lodash';
import * as React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth, { isWidthDown, isWidthUp, WithWidth } from '@material-ui/core/withWidth';

import { FlowThemeInterface } from '../../styles/theme';
import styled, { withTheme } from '../../utils/styledComponents';
import { HeaderContextProvider } from '../context/HeaderContext';
import LoadingPage from '../ui/LoadingPage';
import { HeaderInterface } from './Header';

interface OwnProps {
  headerComponent?: React.ReactElement<HeaderInterface>;
  theme: FlowThemeInterface;
  loading?: boolean;
  headerHidden?:
    | "mdUp"
    | "lgDown"
    | "lgUp"
    | "mdDown"
    | "mdUp"
    | "smDown"
    | "smUp"
    | "xlDown"
    | "xlUp"
    | "xsDown"
    | "xsUp";
}

type AllProps = GridProps & WithWidth & OwnProps;

interface State {
  readonly isHeaderHidden: boolean;
}

const ContainerGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  position: relative;
`;

const ScrollContent = styled((props: GridProps & { hasHeader: boolean }) => (
  <Grid {...omit(props, ["hasHeader"])} />
))`
  min-height: calc(100% + 16px);
  padding: 20px 30px;
  background: ${({ theme }) => theme.colors.white};
  padding-top: ${({ hasHeader }) => (hasHeader ? "82px" : "32px")};
`;

const ScrollSection = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
`;

class SideBar extends React.Component<AllProps, State> {
  public readonly state: State = {
    isHeaderHidden: false
  };
  protected prev: any;

  public render() {
    const {
      children,
      headerComponent,
      loading,
      theme,
      width,
      headerHidden,
      ...props
    } = this.props;

    const hiddenProp = {};
    let headerPadding = true;
    if (headerHidden) {
      hiddenProp[headerHidden] = true;
      if (headerHidden.includes("Up")) {
        const breakpoint = headerHidden.replace("Up", "");
        headerPadding = !isWidthUp(breakpoint as Breakpoint, width);
      } else if (headerHidden.includes("Down")) {
        const breakpoint = headerHidden.replace("Down", "");
        headerPadding = !isWidthDown(breakpoint as Breakpoint, width);
      }
    }

    return (
      <HeaderContextProvider value={this.state}>
        <ContainerGrid item={true} xs={true} md={4}>
          <Hidden {...hiddenProp}>
            {headerComponent
              ? React.cloneElement(headerComponent, {
                  backgroundColor: theme.colors.white
                })
              : null}
          </Hidden>

          <ScrollSection ref={this.sideBarDidMount}>
            <ScrollContent
              container={true}
              direction="column"
              hasHeader={headerComponent && headerPadding ? true : false}
              {...props}
            >
              {loading ? <LoadingPage /> : children}
            </ScrollContent>
          </ScrollSection>
        </ContainerGrid>
      </HeaderContextProvider>
    );
  }

  private sideBarDidMount = (node: HTMLDivElement) => {
    if (node) {
      node.addEventListener("scroll", this.scrolled);
    }
  };

  private scrolled = (evt: any) => {
    const { isHeaderHidden } = this.state;

    if (evt.target.scrollTop > this.prev) {
      if (!isHeaderHidden) {
        this.setState({ isHeaderHidden: true });
      }
    } else {
      if (isHeaderHidden) {
        this.setState({ isHeaderHidden: false });
      }
    }
    this.prev = evt.target.scrollTop;
  };
}

export default withWidth()(withTheme(SideBar));
