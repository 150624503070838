import { SvgIconProps } from '@material-ui/core/SvgIcon';

export { default as PropertiesIcon } from './Properties';
export { default as AwardIcon } from './Award';
export { default as CommunicationIcon } from './Communication';
export { default as TenantsIcon } from './Tenants';
export { default as DashboardIcon } from './Dashboard';
export { default as MenuIcon } from './Menu';
export { default as ViewingsIcon } from './Viewings';
export { default as TicketsIcon } from './Tickets';
export { default as ApplicationsIcon } from './Applications';
export { default as EditPencilIcon } from './EditPencil';
export { default as MaintenanceIcon } from './Maintenance';
export { default as ProfileIcon } from './Profile';
export { default as GardenCottageIcon } from './GardenCottage';
export { default as HouseIcon } from './House';
export { default as ApartmentIcon } from './Apartment';
export { default as TownhouseIcon } from './Townhouse';
export { default as LogoutIcon } from './Logout';
export { default as InfoIcon } from './Info';
export { default as FacebookIcon } from './Facebook';
export { default as GoogleIcon } from './Google';
export { default as StatusIcon } from './Status';
export { default as CreditsIcon } from './Credits';
export { default as ManagedTenantsIcon } from './ManagedTenants';
export { default as SubscriptionIcon } from './Subscription';
export { default as VISAIcon } from './VISA';
export { default as MasterCardIcon } from './MasterCard';
export { default as AmericanExpressIcon } from './AmericanExpress';
export { default as FlowTenantsIcon } from './FlowTenants';
export { default as EmailIcon } from './Email';
export { default as TickIcon } from './Tick';
export { default as ElectricityIcon } from './Electricity';
export { default as FlowFontAwesomeIcon } from './FlowFontAwesomeIcon';
export { default as FindIcon } from './Find';
export { default as ListIcon } from './List';
export { default as SuccessTickIcon } from './SuccessTickIcon';
export { default as ErrorIcon } from './ErrorIcon';

export type IconProps = SvgIconProps & { nativeColor?: string };
