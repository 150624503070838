import React from "react";
import Carousel, { RenderArrowProps } from "react-elastic-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { PartnerProps } from "../../store/listings/types";
import { IsMobile } from "../../utils/responsive";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import Button, { AllProps } from "../buttons/Button";
import PartnersCard from "../cards/PartnersCard";
import { Organisation } from "../../store/organisation";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";
import get from "lodash/get";

const StyledContainer = styled.div`
  && {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
  }
`;

const StyledWhiteLabelContainer = styled.div`
  && {
    position: relative;
    display: flex;
    width: 100%;
    ${mediaDown.sm`margin-bottom: 70px`}
  }
`;

const Arrow = styled(Button)<OnwButton>`
  && {
    height: 50px;
    width: 50px;
    top: ${({ isOnMobile }) => (isOnMobile ? "auto" : "-12px")} !important;
    padding: 0;
    min-width: 0;
    display: ${({ isedge }) => (isedge ? `none` : `flex`)};
    ${mediaDown.sm`display: flex`}
    align-self: center;
  }
`;

const StyledCarousel = styled(Carousel)<{ isLess: boolean }>`
  && {
    .rec-slider {
      width: ${({ isLess }) => (isLess ? `100%` : `unset`)};
    }
    .rec-swipable {
      width: ${({ isLess }) => (isLess ? `100%` : `unset`)};
      justify-content: ${({ isLess }) => (isLess ? `space-between` : `unset`)};
    }
    .rec-item-wrapper {
      ${mediaUp.sm`width: auto !important`}
    }
    .rec-carousel > div:nth-child(1) {
      background-image: linear-gradient(to left, #f7f7f70a, #ffffff);
      position: absolute;
      left: 10px;
      top: 10px;
      height: 315px;
      border-radius: 0px;
      z-index: 300;
      display: flex;
      ${mediaDown.sm`
    height: 0;
    top: unset; 
    bottom: -40px; 
    z-index: 0;
    left: 90px;
    `}
    }
    .rec-carousel > div:nth-child(3) {
      background-image: linear-gradient(to right, #f7f7f70a, #ffffff);
      position: absolute;
      right: 10px;
      top: 10px;
      height: 315px;
      border-radius: 0px;
      display: flex;
      ${mediaDown.sm`
    height: 0;
    top: unset; 
    bottom: -40px; 
    z-index: 0;
    right: 90px;
    `}
    }
  }
`;

const StyledBackIosIcon = styled(ArrowForwardIosIcon)`
  && {
    transform: rotate(180deg);
    margin-right: 5px;
  }
`;

const SyledForwardIosIcon = styled(ArrowForwardIosIcon)`
  && {
    margin-left: 4px;
  }
`;
interface OnwButton extends AllProps {
  isedge: boolean;
  isOnMobile: boolean;
}
export interface PartnersCarouselProps {
  partners: PartnerProps[];
  organisation?: Organisation | object;
  onClick: (slug: string) => (Event: React.MouseEvent) => void;
}

const PartnersCarousal: React.FunctionComponent<PartnersCarouselProps> = ({
  partners,
  organisation,
  onClick,
}) => {
  const isMobile = IsMobile();
  const colors = getWhiteLabelOrganisationColors(organisation);

  const renderArrow = ({ type, onClick, isEdge }: RenderArrowProps) => {
    return (
      <Arrow
        onClick={onClick}
        className="arrow-button"
        isOnMobile={isMobile}
        isedge={isEdge}
        primary={true}
        type="button"
        orgColors={colors}
      >
        {type === "PREV" ? <StyledBackIosIcon /> : <SyledForwardIosIcon />}
      </Arrow>
    );
  };

  const toShow = isMobile ? 1 : 3;

  const carouselContent = () => (
    <StyledCarousel
      isLess={partners.length < 4}
      pagination={false}
      renderArrow={renderArrow}
      itemsToShow={toShow}
      itemsToScroll={1}
      itemPadding={[5, 5]}
    >
      {partners.map((partner: PartnerProps) => (
        <PartnersCard
          key={partner._id}
          onClick={onClick}
          {...partner}
          organisation={organisation}
        />
      ))}
    </StyledCarousel>
  );

  return !get(colors, "whiteLabel.active", false) ? (
    <StyledContainer>{carouselContent()}</StyledContainer>
  ) : (
    <StyledWhiteLabelContainer>{carouselContent()}</StyledWhiteLabelContainer>
  );
};

export default PartnersCarousal;
