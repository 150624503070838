import { omit } from "lodash";
import React from "react";
import Carousel, {
  ReactElasticCarouselProps,
  RenderArrowProps,
} from "react-elastic-carousel";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Listing } from "../../store/listings/types";
import { IsMobile } from "../../utils/responsive";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import Button, { AllProps } from "../buttons/Button";
import ListingItem from "../listItems/ListingItem";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";

const StyledContainer = styled.div`
  && {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    ${mediaDown.sm` margin-bottom: 90px`}
  }
`;

const Arrow = styled(Button)<OnwButton>`
  && {
    height: 50px;
    width: 50px;
    padding: 0;
    min-width: 0;
    display: ${({ isedge }) => (isedge ? `none` : `flex`)};
    ${mediaDown.sm`display: flex`}
    align-self: center;
  }
`;

const StyledCarousel = styled(Carousel)<{
  isLess: boolean;
  gradiantColor?: string;
}>`
  && {
    .rec-slider {
      width: ${({ isLess }) => (isLess ? `100%` : `unset`)};
    }
    .rec-swipable {
      width: ${({ isLess }) => (isLess ? `100%` : `unset`)};
      justify-content: ${({ isLess }) => (isLess ? `space-between` : `unset`)};
    }
    .rec-item-wrapper {
      ${mediaUp.sm`width: auto !important`}
    }

    .rec-carousel > div:nth-child(2) {
      margin: 0;
    }

    .rec-carousel > div:nth-child(1) {
      background-image: linear-gradient(
        to left,
        #f7f7f70a,
        ${({ gradiantColor }) => (gradiantColor ? gradiantColor : `#ffffff`)}
      );
      position: absolute;
      left: 0px;
      top: 10px;
      height: 266px;
      border-radius: 0px;
      z-index: 300;
      display: flex;
      ${mediaDown.sm`
    height: 0;
    top: unset; 
    bottom: -40px; 
    z-index: 0;
    left: 90px;
    `}
    }
    .rec-carousel > div:nth-child(3) {
      background-image: linear-gradient(
        to right,
        #f7f7f70a,
        ${({ gradiantColor }) => (gradiantColor ? gradiantColor : `#ffffff`)}
      );
      position: absolute;
      right: 0px;
      top: 10px;
      height: 266px;
      border-radius: 0px;
      display: flex;
      ${mediaDown.sm`
    height: 0;
    top: unset; 
    bottom: -40px; 
    z-index: 0;
    right: 90px;
    `}
    }
  }
`;

  const StyledListingItem = styled(ListingItem)<{ isOnMobile: boolean }>`
    && {
      width: ${({ isOnMobile }) => (isOnMobile ? "100%" : "500px")};
      & > div {
        margin-top: 0;
        border: solid thin ${({ theme }) => theme.colors.grey1};
      }
    }
  `;

const StyledBackIosIcon = styled(ArrowForwardIosIcon)`
  && {
    transform: rotate(180deg);
    margin-right: 5px;
  }
`;

const SyledForwardIosIcon = styled(ArrowForwardIosIcon)`
  && {
    margin-left: 4px;
  }
`;

interface OnwButton extends AllProps {
  isedge: boolean;
}

export interface RecommendedCarousalProps extends ReactElasticCarouselProps {
  recommended: Listing[];
  organisation: object;
  gradiantColor?: string;
}

const RecommendedCarousal: React.FunctionComponent<
  RecommendedCarousalProps
> = ({ recommended, organisation, gradiantColor, ...props }) => {
  const isMobile = IsMobile();
  const colors = getWhiteLabelOrganisationColors(organisation);

  const renderArrow = ({ type, onClick, isEdge }: RenderArrowProps) => {
    return (
      <Arrow
        onClick={onClick}
        isedge={isEdge}
        primary={true}
        type="button"
        orgColors={colors}
      >
        {type === "PREV" ? <StyledBackIosIcon /> : <SyledForwardIosIcon />}
      </Arrow>
    );
  };
  const toShow = isMobile ? 1 : 2;
  return (
    <StyledContainer>
      <StyledCarousel
        {...omit(props, ["organisation", "recommended"])}
        isLess={recommended.length < 2}
        gradiantColor={gradiantColor}
        pagination={false}
        renderArrow={renderArrow}
        itemsToShow={toShow}
        itemsToScroll={1}
      >
        {recommended.map((listing: Listing) => {
          delete listing.flowExtras; // remove extras
          delete listing.featureRating; // remove feature rating
          return (
            <StyledListingItem
              key={listing._id}
              isOnMobile={isMobile}
              listingData={listing}
              organisation={organisation}
            />
          );
        })}
      </StyledCarousel>
    </StyledContainer>
  );
};

export default RecommendedCarousal;
