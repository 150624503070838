import { History } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import DialogColumnView from '../../components/views/DialogColumnView';
import { ApplicationState } from '../../store';
import { AuthenticatedUser } from '../../store/auth';
import {
    toggleLoginDialog as toggleLoginDialogAction, toggleProfileDialog as toggleProfileDialogAction
} from '../../store/dialogs';

interface ConnectedProps {
  history: History;
}

interface PropsFromState {
  authenticated: boolean;
  user?: AuthenticatedUser;
}

interface PropsFromDispatch {
  toggleProfileDialog: typeof toggleProfileDialogAction;
  toggleLoginDialog: typeof toggleLoginDialogAction;
}

type AllProps = ConnectedProps & PropsFromState & PropsFromDispatch;

class LoginOptionsPage extends React.Component<AllProps> {
  public render() {
    return (
      <>
        {!this.props.authenticated && (
          <>
            <DialogColumnView
              isTenant={true}
              isLogin={true}
              buttonAction={this.onTenantLoginClick}
            />

            <DialogColumnView
              isTenant={false}
              isLogin={true}
              buttonAction={this.onLandlordLoginClick}
            />
          </>
        )}
      </>
    );
  }

  private onTenantLoginClick = () => {
    this.props.toggleLoginDialog(true);
  };

  private onLandlordLoginClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.location.replace(`${process.env.REACT_APP_CMS_URL}/login`);
  };
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
  authenticated: auth.authenticated,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      toggleLoginDialog: toggleLoginDialogAction,
      toggleProfileDialog: toggleProfileDialogAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginOptionsPage) as any;
