import useMediaQuery from '@material-ui/core/useMediaQuery';

import { sizes } from './styledComponents';

function IsMobile(): boolean {
  return useMediaQuery(`(max-width:${sizes.sm}px)`);
}

function IsTablet(): boolean {
  return useMediaQuery(`(max-width:${sizes.md}px)`);
}

export { IsMobile, IsTablet };
