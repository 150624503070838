import { get } from 'lodash';
import React from 'react';

import { InvitedByUser, InvitedUser } from '../../store/invites';
import styled from '../../utils/styledComponents';

const Card = styled.div`
  border-radius: 20px;
  background-color: #eef4f6;
  margin: 10px 0 20px;
  padding: 20px;
`;

const Header = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 18px;
  }
`;

const LandlordAvator = styled.div<{ avator: string }>`
  height: 50px;
  width: 50px;
  border-radius: 30px;
  border: solid 2px white;
  position: relative;
  right: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ avator }) => avator});
  svg {
    position: relative;
    z-index: 20;
  }
`;

const ReferralHeading = styled.span`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 18px;
`;

const ReferralText = styled(ReferralHeading)`
  && {
    font-size: 16px;
  }
`;

const LandlordContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface OwnProps {
  landlord: InvitedByUser;
  tenant?: InvitedUser;
}

const InvitesCard: React.FunctionComponent<OwnProps> = ({ landlord }) => {
  
  const publicUrl = get(landlord,'profileImage.publicUrl', null);
  const initials = landlord?.firstName.charAt(0) + landlord?.lastName.charAt(0);

  const placeHolder = `https://via.placeholder.com/150/D3D3D3/FFFFFF/?text=${initials}`;
  return (
    <Card>
      {landlord ? 
      (<>
      <Header>You've been invited to Flow</Header>
      <LandlordContainer>
        <LandlordAvator avator={publicUrl || placeHolder} />{" "}
        <ReferralHeading>by {landlord?.displayName}</ReferralHeading>
      </LandlordContainer>
      <ReferralText>
        Your landlord {landlord?.firstName} wants to connect with you on Flow, an
        app that rewards great tenants with rewards, every month. Complete your
        registration or log in to accept {landlord?.firstName}'s invite and start
        earning rewards
      </ReferralText>
      </>):(
        <ReferralText>
        The person who invited you deactivated their account
      </ReferralText>
      )}
    </Card>
  );
};

export default InvitesCard;
