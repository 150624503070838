import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from "../icons";

const TickIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 25 26" {...props}>
    <svg width={25} height={26} {...props}>
      <g
        transform="translate(1 1)"
        fillRule="nonzero"
        stroke={nativeColor}
        strokeWidth={1.5}
        fill="none"
      >
        <circle cx={11.5} cy={12} r={11.5} />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.692 12.301l3.252 3.253 6.364-6.364"
        />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default TickIcon;
