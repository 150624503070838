import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchActiveListings = createRoutine("@@listings/FETCH_LISTINGS");
export const fetchActiveListingsFilters = createRoutine(
  "@@listings/FETCH_LISTINGS_FIlTERS"
);
export const fetchActiveListingsById = createRoutine(
  "@@listings/FETCH_LISTINGS_BY_ID"
);

export const fetchEnquiry = createRoutine("@@listings/FETCH_ENQUIRY_INFO");
export const fetchEnquiryPromise = promisifyRoutine(fetchEnquiry);

export const fetchActiveListingsPromise = promisifyRoutine(fetchActiveListings);
export const fetchActiveListingsByIdPromise = promisifyRoutine(
  fetchActiveListingsById
);
export const fetchActiveListingsFiltersPromise = promisifyRoutine(
  fetchActiveListingsFilters
);

export const fetchPartners = createRoutine("@@listings/FETCH_PARTNERS");

export const fetchRecommendedProperties = createRoutine("@@listings/FETCH_RECOMMENDED");
export const fetchRecommendedPropertiesPromise = promisifyRoutine(fetchRecommendedProperties);

export const fetchRecommendedPropertiesByQuery = createRoutine("@@listings/FETCH_RECOMMENDED_BY_QUERY");
export const fetchRecommendedByQueryPromise = promisifyRoutine(fetchRecommendedPropertiesByQuery);

export const fetchPartnersPromise = promisifyRoutine(fetchPartners);
export const listingsSearch = createRoutine("@@listings/LISTINGS_SEARCH");
export const listingsSearchPromise = promisifyRoutine(listingsSearch);
