import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import { GoogleButton } from '../components/buttons';
import { ApplicationState } from '../store';
import { loginWithGooglePromise } from '../store/auth';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

interface PropsFromDispatch {
  loginWithGooglePromise: typeof loginWithGooglePromise;
}
interface State {
  loading: boolean
}
interface OwnProps {
  register?: boolean;
  onError: (error: string) => void;
  onLoginError: (
    data: {
      email?: string;
      firstName?: string;
      lastName?: string;
    },
    accessToken: string,
    error?: string
  ) => void;
}

type AllProps = OwnProps & PropsFromDispatch;

class GoogleLoginButtonContainer extends React.Component<AllProps, State> {
  public state: State = {
    loading: false
  };

  public static defaultProps = {
    register: false
  };

  public renderGoogleButton = (props: { onClick: () => void } | undefined) => {
    if (props) {
      return (
        <GoogleButton
          onClick={props.onClick}
          fullWidth={true}
          disabled={this.state.loading}
          loading={this.state.loading}>
          Google
        </GoogleButton>
      );
    }

    return <></>;
  };

  public onRequestEvent = () => {
    this.setState({ loading: true });
  }


  public render() {
    return (
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        autoLoad={false}
        onSuccess={this.successResponseGoogle}
        onFailure={this.failureResponseGoogle}
        onRequest={this.onRequestEvent}
        render={this.renderGoogleButton}
      />
    );
  }

  private successResponseGoogle = async (response: any) => {
    if (response.getId()) {
      const profile = response.getBasicProfile();
      const auth = response.getAuthResponse();
      const accessToken = auth.access_token;
      const data = {
        email: profile.getEmail(),
        firstName: profile.getGivenName(),
        lastName: profile.getFamilyName()
      };

      try {
        await this.props.loginWithGooglePromise({
          accessToken,
          data,
          register: false
        });
        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
        return this.props.onLoginError(data, accessToken, error);
      }
    }
  };

  private failureResponseGoogle = (response: any) => {
    let error = `An error occured while trying to ${this.props.register ? "register" : "log in"
      } with Google`;
    if (response.error === "popup_closed_by_user") {
      error = `User cancelled ${this.props.register ? "register" : "log in"
        } attempt`;
    }
    this.setState({ loading: false });
    return this.props.onError(error);
  };
}

const mapStateToProps = (store: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ loginWithGooglePromise }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleLoginButtonContainer) as any;
