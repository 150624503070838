import React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import { MenuIcon } from '../icons';
import styled from '../../utils/styledComponents';

import Button from './Button';

const MenuButton = styled(Button)`
  && {
    padding: 5px;
    height: 35px;
    min-width: 20px;
    margin: auto 10px;
    text-transform: none;
    font-family: ${({ theme }) => theme.fonts.MS500};
    color: ${({ theme }) => theme.colors.grey3};
    border-color: ${({ theme }) => theme.colors.white} !important;
    &&:hover {
      background: none;
    }
  }
`;

interface OwnProps {
  label?: string;
  placement?: PopperPlacementType;
}

type AllProps = OwnProps;

const DropDownMenuButton: React.FunctionComponent<AllProps> = ({
  label = '',
  children,
  placement,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = React.useCallback(
    (event: React.MouseEvent<Document, MouseEvent>) => {
      setAnchorEl(null);
    },
    []
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <MenuButton aria-describedby={id} onClick={handleClick} outline={false}>
          <MenuIcon
            nativeColor={'#4C4C4E'}
            style={{
              width: 16,
              height: 13,
            }}
          />
          {label}
        </MenuButton>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition={true}
          disablePortal={true}
          placement={placement}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'top',
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  {children}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default DropDownMenuButton;
