import MuiLink, { LinkProps } from "@material-ui/core/Link";
import React from "react";
import { OrganisationColors } from "../../store/organisation";
import styled, { mediaDown } from "../../utils/styledComponents";
import omit from "lodash/omit";
import { getOrganisationColor } from "../../utils/whiteLabel";

interface OwnProps {
  isActive?: boolean;
}

export interface StyledLink {
  primary?: boolean;
  secondary?: boolean;
  orgColors?: OrganisationColors;
}

export type AllProps = StyledLink & LinkProps;

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const StyledLink = styled((props: AllProps) => (
  <MuiLink {...omit(props, ["primary", "secondary", "orgColors"])} />
))`
  && {
    text-decoration: underline;
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: 14px;
    ${mediaDown.sm`font-size: 14px;`}
    color: ${({ theme, primary, secondary, orgColors }) => {
      let color = theme.colors.grey4;

      if (primary) {
        color = getOrganisationColor(
          orgColors,
          "primaryColor",
          theme.colors.orange
        );
      }

      if (secondary) {
        color = getOrganisationColor(
          orgColors,
          "secondaryColor",
          theme.colors.lightblue
        );
      }

      return color;
    }}!important;
  }
`;

const Link: React.FunctionComponent<OwnProps & AllProps> = ({
  isActive,
  ...props
}) => (
  <Wrapper>
    <StyledLink {...props} />
  </Wrapper>
);

export default Link;
