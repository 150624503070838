
import Button from '../components/buttons/Button';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { has, isEmpty } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import ContactNumberWithCountryCode from '../components/inputs/contactNumberWithCode';
import { ButtonsWrapper, FieldWrapper, FormFieldsContainer } from '../components/ui/forms';
import { ApplicationState } from '../store';
import { AuthenticatedUser, updateUserPromise as updateUserPromiseAction } from '../store/auth';
import { fetchActiveLocalesPromise } from '../store/defaults';

interface OwnProps {
  onNumberUpdated: () => void;
}
interface PropsFromState {
  errors: string;
  user: AuthenticatedUser;
}

interface PropsFromDispatch {
  updateUserPromise: typeof updateUserPromiseAction;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;


const UpdateContactNumberFormContainer: React.FunctionComponent<AllProps> = ({
  user,
  updateUserPromise,
  onNumberUpdated,
}) => {
  const [isContactNumberValid, setIsContactNumberValid] = useState(true);
  const initialValues = {
    contactNumber: "",
  }
  return < Formik
    initialValues={initialValues}
    validate={values => {
      const errors: any = {};
      if (!values.contactNumber) {
        errors.contactNumber = 'Please enter your contact number';
      }
      return errors;
    }}
    onSubmit={async (values, { setSubmitting }) => {
      let skip = false;
      if (!has(values, 'contactNumber') || isEmpty(values.contactNumber)) {
        setIsContactNumberValid(false);
        setSubmitting(false);
        skip = true
      }

      if (!skip) {
        try {
          const contactNumber = values.contactNumber;
          await updateUserPromise({ ...user, contactNumber });
          onNumberUpdated();
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }
    }}
  >
    {({
      handleSubmit,
      isSubmitting,
      setFieldValue,
    }) => (
      <form onSubmit={handleSubmit}>
        <FormFieldsContainer>
          <FieldWrapper xs={12}>
            <ContactNumberWithCountryCode
              name="contactNumber"
              disabled={false}
              required={true}
              locales={[]}
              isValid={isContactNumberValid}
              fetchLocales={fetchActiveLocalesPromise}
              handleOnChange={e => setFieldValue('contactNumber', e)}
              handleOnBlur={e => setFieldValue('contactNumber', e)}
            />
          </FieldWrapper>
        </FormFieldsContainer>

        <ButtonsWrapper>
          <Grid item={true} xs={12}>
            <Button
              type="submit"
              disabled={isSubmitting}
              primary={true}
              fullWidth={true}
              loading={isSubmitting}
            >
              Verify
          </Button>
          </Grid>
        </ButtonsWrapper>
      </form>
    )}
  </Formik>
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    {
      updateUserPromise: updateUserPromiseAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateContactNumberFormContainer) as any;
