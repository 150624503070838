import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../../utils/styledComponents';

const FieldWrapper = styled((props: React.ComponentType<GridProps>) => (
  <Grid item={true} {...props} />
))`
  && {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
` as any;

export default FieldWrapper;
