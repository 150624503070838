import * as React from 'react';

import MuiGrid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../utils/styledComponents';

const ContainerGrid = styled(MuiGrid as React.FunctionComponent<GridProps>)`
  position: relative;
`;

const Grid = styled(MuiGrid as React.FunctionComponent<GridProps>)``;

const Content: React.FunctionComponent<GridProps> = ({
  children,
  ...props
}) => (
  <ContainerGrid item={true} xs={true}>
    <Grid container={true} {...props}>
      {children}
    </Grid>
  </ContainerGrid>
);

export default Content;
