import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Unit } from '../../store/listings';

interface UnitSelectorDropdownProps {
  units: Array<Unit>;
  selectedUnit: any;
  onChange: (unit: any) => void;
}

const UnitSelectorDropdown: React.FC<UnitSelectorDropdownProps> = ({ units, selectedUnit, onChange }) => {
  return (
    <FormControl fullWidth required>
      <InputLabel>Select a Unit</InputLabel>
      <Select
        value={selectedUnit ? selectedUnit._id : ''}
        onChange={(event) => {
          const unit = units.find(u => u._id === event.target.value);
          onChange(unit);
        }}
      >
        {units.map((unit) => (
          <MenuItem key={unit._id} value={unit._id}>
            {unit.title || `Unit ${unit._id}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UnitSelectorDropdown;
