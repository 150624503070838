import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../../utils/styledComponents';

const FormSectionItem = styled((props: GridProps) => (
  <Grid item={true} xs={12} {...props} />
))`
  && {
    margin-bottom: 30px;
  }
`;

export default FormSectionItem;
