import { Field, Form, InjectedFormikProps } from 'formik';
import * as React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Button from '../buttons/Button';
import TextInput from '../inputs/TextInput';
import { FieldWrapper, FormFieldsContainer } from '../ui/forms';

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
export interface ForgotPasswordFormValues {
  email: string;
}

export const ForgotPassword: React.FunctionComponent<
  InjectedFormikProps<{}, ForgotPasswordFormValues>
> = props => {
  const { isSubmitting } = props;
  return (
    <Form>
      <FormFieldsContainer spacing={4}>
        <FieldWrapper xs={12}>
          <Field
            type="email"
            label="Email"
            name="email"
            component={TextInput}
          />
        </FieldWrapper>
      </FormFieldsContainer>
      <ButtonWrapper>
        <Grid item={true} xs={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            primary={true}
            fullWidth={true}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Grid>
      </ButtonWrapper>
    </Form>
  );
};

export default ForgotPassword;
