import { Reducer } from 'redux';

import {
  fetchActiveLocales
} from './routines';
import { DafaultState } from './types';

const initialState: DafaultState = {
  errors: undefined,
  listLoading: false,
  locales: [],
  selectedLocale: undefined,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {

    // Trigger
    case fetchActiveLocales.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    // Success
    case fetchActiveLocales.SUCCESS: {
      return {
        ...state,
        locales: action.payload.locales
      };
    }

    // Failure
    case fetchActiveLocales.FAILURE: {
      return {
        ...state,
        locales: [],
        listLoading: false,
        error: action.payload
      }
    }

    // Fullfill
    case fetchActiveLocales.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as defaultReducer };
