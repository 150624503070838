import { Location } from "history";
import { get, isEmpty } from "lodash";
import queryString from "query-string";
import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import Route from "./components/router/CustomRoute";
import LoadingPage from "./components/ui/LoadingPage";
import ListingDetails from "./pages/listings/ListingDetails";
import { ApplicationState } from "./store";
import { AuthenticatedUser, authenticatePromise } from "./store/auth";
import { fetchActiveLocales } from "./store/defaults";
import { toggleOTPVerificationDialog as toggleOTPVerificationDialogAction } from "./store/dialogs";
import { isVerificationRequired } from "./utils/verification";

const ListingsPage = lazy(() => import("./pages/listings"));
const TermsOfUsePage = lazy(() => import("./pages/termsofuse"));
const PrivacyPolicyPage = lazy(() => import("./pages/privacypolicy"));
const FlowFindingsPage = lazy(() => import("./pages/findings"));
const OrganisationPage = lazy(() => import("./pages/organisation"));
const AgentPage = lazy(() => import("./pages/agent"));
const GuestyPage = lazy(() => import("./pages/guesty"));
const NotFoundPage = lazy(() => import("./pages/notFound"));

interface PropsFromState {
  location: Location;
  authenticated: boolean;
  user?: AuthenticatedUser;
  verified: boolean;
}

interface PropsFromDispatch {
  toggleOTPVerificationDialog: typeof toggleOTPVerificationDialogAction;
  fetchLocales: typeof fetchActiveLocales;
  authenticatePromise: typeof authenticatePromise;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface State {
  requireVerification: boolean;
}
class Routes extends React.Component<AllProps, State> {
  public readonly state: State = {
    requireVerification: false,
  };

  public componentDidMount() {
    const { authenticated } = this.props;

    if (!authenticated) {
      this.props
        .authenticatePromise({ updateLastLogin: true })
        .then(() => {
          this.checkVerification();
        })
        .catch((error) => {
          localStorage.removeItem("auth_token");
        });
    }
    this.props.fetchLocales();
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { authenticated, verified } = this.props;
    if (prevProps.authenticated !== authenticated && !verified) {
      this.checkVerification();
    }
  }

  public render() {
    const {
      location,
      authenticated,
      user,
      verified,
      toggleOTPVerificationDialog,
    } = this.props;
    const { requireVerification } = this.state;
    if (authenticated && user?.profile && !verified && requireVerification) {
      toggleOTPVerificationDialog(true);

      // return (
      //   <Suspense fallback={<LoadingPage />}>
      //     <Switch location={location}>
      //       <Route exact={true} path="/verify" component={VerificationPage} />
      //       <Redirect from="/*" to="/verify" />
      //     </Switch>
      //   </Suspense>
      // );
    }
    const queryObject: any = queryString.parse(
      decodeURI(window.location.search)
    );
    const shouldRedirect = isEmpty(get(queryObject, "organisation", ""));
    return (
      <Suspense fallback={<LoadingPage />}>
        <Switch location={location}>
          {window.location.origin === process.env.REACT_APP_GUESTY && (
            <Route
              exact={true}
              path="/"
              title="Guesty"
              component={GuestyPage}
            />
          )}
          <Redirect exact={true} from="/" to="/notFound" />
          {shouldRedirect && (
            <Redirect exact={true} from="/listings" to="/notFound" />
          )}
          {!shouldRedirect && (
            <Route
              exact={true}
              title="listings"
              path="/listings"
              component={ListingsPage}
            />
          )}

          <Route
            exact={true}
            sensitive={true}
            strict={false}
            path="/listings/:organisationName/:listingSector?/:listingType?"
            component={ListingsPage}
            title="listings"
          />

          <Route
            exact={true}
            path="/listings/:listingSector/:listingType/:suburb?/:city?/:province?/:id"
            strict={false}
            sensitive={true}
            component={ListingDetails}
            title="listingDetailsView"
          />

          <Route
            exact={true}
            path="/listings/enquiry/:token"
            component={ListingsPage}
            title="enquiry"
          />

          <Route
            exact={true}
            path="/termsofuse"
            title="Terms of Use"
            component={TermsOfUsePage}
            redirectToPathAfterAuthenticated="/listings?listingType=sale"
          />
          <Route
            exact={true}
            path="/privacypolicy"
            title="Privacy Policy"
            component={PrivacyPolicyPage}
            redirectToPathAfterAuthenticated="/listings?listingType=sale"
          />
          <Route
            exact={true}
            path="/flowfindings"
            title="Flow Findings"
            component={FlowFindingsPage}
            redirectToPathAfterAuthenticated="/listings?listingType=sale"
          />
          <Route
            exact={true}
            path="/organisations/:organisationId"
            title="Organisations"
            component={OrganisationPage}
          />
          <Route
            exact={true}
            path="/organisations/:organisationId/agents/:agentId"
            title="Agents"
            component={AgentPage}
          />

          <Route
            exact={true}
            path="/agency/:organisationName"
            title="Agency"
            component={OrganisationPage}
          />
          <Route
            exact={true}
            path="/agent/:organisationName/:agentNames/:agentId"
            title="Agent"
            component={AgentPage}
          />
          <Route
            exact={true}
            path="/guesty"
            title="Guesty"
            component={GuestyPage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    );
  }

  private async checkVerification() {
    const requireVerification = isVerificationRequired();
    this.setState({ requireVerification });
  }
}

const mapStateToProps = ({ auth, router, defaults }: ApplicationState) => ({
  authenticated: auth.authenticated,
  location: router.location,
  user: auth.user,
  verified: !!auth.user && auth.user.status === "Verified",
  locales: defaults.locales,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ authenticatePromise }, dispatch),
  ...bindActionCreators(
    {
      toggleOTPVerificationDialog: toggleOTPVerificationDialogAction,
      fetchLocales: fetchActiveLocales,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes as any);
