import { get } from "lodash";
import { LocaleSettingsProps } from "../store/organisation";
import { getUnitPrice } from "../utils/units.helper";
import { Listing } from "../store/listings";
import { isNumeric } from "./general";

const phone = require("phone");
export const getSquaredMeasurement = (
  localeSettings: LocaleSettingsProps,
  fullWord = false
) => {
  const measurementSystem = get(localeSettings, "measurementSystem", "metric");
  return measurementSystem === "metric"
    ? fullWord
      ? "square meters (sqm)"
      : "m²"
    : fullWord
      ? "square feet (sqft)"
      : "sqft";
};

const formatIndiaLocValue = (price: number) => {
  let money = price;
  let displaySign = "";
  const minValue = 100000;
  const maxValue = 10000000;
  if (price >= minValue && price < maxValue) {
    money = price / minValue;
    displaySign = " L";
  } else if (price >= maxValue) {
    money = price / maxValue;
    displaySign = " Cr";
  }
  const returnValue = {
    value: money,
    displaySign,
  };
  return returnValue;
};
export const formatAmount = (
  amount: number,
  localeSettings: LocaleSettingsProps,
  isForSale = true,
  isResidential = true,
  isOnAuction = false
): string => {
  const currencyCode = localeSettings.currency.code;
  const countryCode = localeSettings.country.code;
  if (amount === 0 || (isOnAuction && currencyCode === "AUD")) {
    return "POA";
  }
  const isIndianRupeeLocale = countryCode === "in" && currencyCode === "INR";
  let displaySettings;
  if (isIndianRupeeLocale) {
    displaySettings = formatIndiaLocValue(amount);
    amount = get(displaySettings, "value", amount);
  }

  const formatter = new Intl.NumberFormat(countryCode, {
    currency: currencyCode,
    minimumFractionDigits: hasDecimalPlaces(amount) ? 2 : 0,
    style: "currency",
  });

  const formattedMoney = formatter
    .format(amount)
    .replace(currencyCode, localeSettings.currency.symbol);
  const measurement = `per ${getSquaredMeasurement(localeSettings)}`;
  const displayedMoney = isIndianRupeeLocale
    ? `${formattedMoney}${get(displaySettings, "displaySign", "")}`
    : formattedMoney;
  return isForSale
    ? displayedMoney
    : `${displayedMoney} ${isResidential ? "pm" : measurement}`;
};

export const formatNumber = (
  value: number,
  localeSettings: LocaleSettingsProps
): string => {
  if (value === 0) {
    return value.toString();
  }

  const countryCode = localeSettings.country.code;
  const formatter = new Intl.NumberFormat(countryCode, {
    currency: localeSettings.currency.code,
    minimumFractionDigits: 0,
  });

  const formattedValue = formatter.format(value).trim();

  return formattedValue;
};

/**
 * @param {string} contactNumber
 * @param {string} countryCode
 * @returns
 */
export const formatPhone = (
  contactNumber: string,
  countryCode = "za"
): string => {
  const formattedPhone = phone(contactNumber, countryCode.toUpperCase());
  return formattedPhone[0];
};

/**
 * Format amount by locale
 * @param localeSettings
 * @param property
 */
export const formatPropertyAmountByLocale = (
  property: Listing,
  localeSettings: LocaleSettingsProps
): string => {
  const units = property.units || [];
  const minUnit = units.find(
    (unit) => unit.rentalAmount === property.minRentalAmount
  );
  let amount = Number(getUnitPrice(minUnit) || property.minRentalAmount || 0);
  const isOnAuction = get(property, "listingType", "sale") === "auction";
  const currencyCode = localeSettings.currency.code;

  if (
    amount === 0 ||
    (isOnAuction && currencyCode === "AUD") ||
    !isNumeric(amount)
  ) {
    return "POA";
  }

  const countryCode = localeSettings.country.code;
  const listingSector = get(property, "listingSector", "residential");
  const listingType = get(property, "listingType", "sale");
  const isForSale = get(property, "listingType") !== "rent";
  const grossMonthlyRental = Number(
    get(property, "syndicator.doc.gross_monthly_rental", 0)
  );

  const isIndianRupeeLocale = countryCode === "in" && currencyCode === "INR";
  let displaySettings;
  if (isIndianRupeeLocale) {
    displaySettings = formatIndiaLocValue(amount);
    amount = get(displaySettings, "value", amount);
  }

  const formatter = new Intl.NumberFormat(countryCode, {
    currency: currencyCode,
    minimumFractionDigits: hasDecimalPlaces(amount) ? 2 : 0,
    style: "currency",
  });

  const formattedMoney = formatter
    .format(amount)
    .replace(currencyCode, localeSettings.currency.symbol);
  const displayedMoney = isIndianRupeeLocale
    ? `${formattedMoney}${get(displaySettings, "displaySign", "")}`
    : formattedMoney;

  return isForSale
    ? displayedMoney
    : `${displayedMoney} ${deriveCommercialRentalTag(
      amount,
      grossMonthlyRental,
      listingSector,
      listingType,
      localeSettings
    )}`;
};

/**
 * derive commercial rental tag
 * @param amount
 * @param grossMonthlyRental
 * @param listingSector
 * @param listingType
 * @param locale
 * @returns tag
 */
const deriveCommercialRentalTag = (
  amount: number,
  grossMonthlyRental: number,
  listingSector: string,
  listingType: string,
  locale: LocaleSettingsProps
): string => {
  const isCommercial = listingSector === "commercial";
  const isRental = listingType === "rent";

  let tag = isRental ? "pm" : "";
  if (isCommercial && isRental) {
    tag = `per ${getSquaredMeasurement(locale)}`;

    if (amount === grossMonthlyRental) {
      tag = "pm";
    }
  }
  return tag;
};

/**
 * Check if a number has decimal places
 * @param number
 * @returns boolean
 */
const hasDecimalPlaces = (number: number): boolean => {
  return number % 1 !== 0;
};