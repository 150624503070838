import { Reducer } from "redux";

import { fetchActiveCampaigns } from "./routines";
import { Campaign, CampaignsState } from "./types";

const initialState: CampaignsState = {
  campaigns: [],
  errors: undefined,
  listLoading: false
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchActiveCampaigns.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    // Success
    case fetchActiveCampaigns.SUCCESS: {
      return {
        ...state,
        campaigns: deriveCampaignFromResponse(action.payload)
      };
    }

    // Failure
    case fetchActiveCampaigns.FAILURE: {
      return { ...state, campaigns: undefined, errors: action.payload };
    }

    // Fullfill
    case fetchActiveCampaigns.FULFILL: {
      return {
        ...state,
        listLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

const deriveCampaignFromResponse = (campaigns: any): Campaign[] => {
  const data: Campaign[] = []; // lint requires declaration to be const
  Object.keys(campaigns).forEach(key => {
    Object.keys(campaigns[key]).forEach(arrayIndex => {
      const derivedCampaigns = campaigns[key][arrayIndex];
      const index = derivedCampaigns.length - 1;
      data.push(derivedCampaigns[index]); // only retrieve last record from array
    });
  });
  return data;
};

export { reducer as campasignsReducer };
