import React from 'react';

import styled from '../../utils/styledComponents';

const Svg = styled.svg<any>`
  border: solid 2px white;
  border-radius: 14px;
  background-color: white;
`;

function SmallTick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={14} height={14} viewBox="0 0 10 10" {...props}>
      <title>{"Shape"}</title>
      <path
        d="M10 5A5 5 0 110 5a5 5 0 0110 0zM4.422 7.647l3.71-3.71a.323.323 0 000-.455l-.457-.457a.323.323 0 00-.456 0L4.194 6.051 2.78 4.638a.323.323 0 00-.456 0l-.456.456a.323.323 0 000 .457l2.096 2.096c.126.126.33.126.457 0z"
        fill="#FF7418"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default SmallTick;
