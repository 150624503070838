import { AnyAction, Reducer } from 'redux';
import { produce } from 'immer';

import { GuestyState } from './types';
import { addToListingsList, authenticateAndFetchListings, startSubscription, subscribe } from './routines';
import { get, isArray, isEmpty } from 'lodash';
import { PackagesData } from './data';

const initialState: GuestyState = {
  loadingListings: false,
  subscribing: false,
  subscriptionPackages: PackagesData,
  listings: [],
  selectedPackage: PackagesData.find(_package => _package.id === 2),
  authDetails: null,
  selectedListings: [],
  selectedLocations: [],
  errors: null
};

const reducer: Reducer<GuestyState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // Trigger
    case startSubscription.TRIGGER: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.errors = null;
          draft.selectedPackage = action.payload;
        }
      );

      return newDerivedState;
    }
    case authenticateAndFetchListings.TRIGGER: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.errors = null;
          draft.loadingListings = true;
          draft.authDetails = action.payload;
        }
      );

      return newDerivedState;
    }
    case subscribe.TRIGGER: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.errors = null;
          draft.subscribing = true;
        }
      );

      return newDerivedState;
    }

    // Success
    case authenticateAndFetchListings.SUCCESS: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          const data = get(action.payload, 'data');
          if (!isEmpty(data) && isArray(data)) draft.listings = draft.listings.concat(data);
          draft.accessToken = get(action.payload, 'accessToken');
        }
      );

      return newDerivedState;
    }
    case addToListingsList.SUCCESS: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.listings = draft.listings.concat(action.payload);
        }
      );

      return newDerivedState;
    }

    // Failure
    case authenticateAndFetchListings.FAILURE:
    case subscribe.FAILURE: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.errors = action.payload;
          draft.listings = [];
        }
      );

      return newDerivedState;
    }

    // Fulfill
    case authenticateAndFetchListings.FULFILL: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.loadingListings = false;
        }
      );

      return newDerivedState;
    }
    case subscribe.FULFILL: {
      const newDerivedState = produce<GuestyState>(
        state,
        (draft: GuestyState) => {
          draft.subscribing = false;
        }
      );

      return newDerivedState;
    }
    default:
      return state;
  }
};

export { reducer as guestyReducer };
