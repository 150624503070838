import { AnyAction } from 'redux';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import {
    toggleForgotPasswordDialog, toggleLoginDialog, toggleLoginOptionsDialog, toggleMessageDialog,
    toggleOTPVerificationDialog, toggleProfileDialog, toggleRegisterDialog,
    toggleRegisterOptionsDialog
} from './routines';

function* handleToggleForgotPasswordDialog(action: AnyAction) {
  yield put(toggleForgotPasswordDialog.success(action.payload));
}

function* handleToggleLoginDialog(action: AnyAction) {
  yield put(toggleLoginDialog.success(action.payload));
}

function* handleToggleProfileDialog(action: AnyAction) {
  yield put(toggleProfileDialog.success(action.payload));
}

function* handleToggleLoginOptionsDialog(action: AnyAction) {
  yield put(toggleLoginOptionsDialog.success(action.payload));
}

function* handleToggleRegisterOptionsDialog(action: AnyAction) {
  yield put(toggleRegisterOptionsDialog.success(action.payload));
}

function* handleToggleRegisterDialog(action: AnyAction) {
  yield put(toggleRegisterDialog.success(action.payload));
}

function* handleToggleMessageDialog(action: AnyAction) {
  yield put(toggleMessageDialog.success(action.payload));
}

function* handleToggleOTPVerificationDialog(action: AnyAction) {
  yield put(toggleOTPVerificationDialog.success(action.payload));
}

function* toggleForgotPasswordDialogWatcher() {
  yield takeEvery(
    toggleForgotPasswordDialog.TRIGGER,
    handleToggleForgotPasswordDialog
  );
}

function* toggleLoginDialogWatcher() {
  yield takeEvery(toggleLoginDialog.TRIGGER, handleToggleLoginDialog);
}

function* toggleProfileDialogWatcher() {
  yield takeEvery(toggleProfileDialog.TRIGGER, handleToggleProfileDialog);
}

function* toggleLoginOptionsDialogWatcher() {
  yield takeEvery(
    toggleLoginOptionsDialog.TRIGGER,
    handleToggleLoginOptionsDialog
  );
}

function* toggleRegisterOptionsDialogWatcher() {
  yield takeEvery(
    toggleRegisterOptionsDialog.TRIGGER,
    handleToggleRegisterOptionsDialog
  );
}

function* toggleRegisterDialogWatcher() {
  yield takeEvery(toggleRegisterDialog.TRIGGER, handleToggleRegisterDialog);
}

function* toggleMessageDialogWatcher() {
  yield takeEvery(toggleMessageDialog.TRIGGER, handleToggleMessageDialog);
}

function* toggleOTPVerificationDialogWatcher() {
  yield takeEvery(
    toggleOTPVerificationDialog.TRIGGER,
    handleToggleOTPVerificationDialog
  );
}

export function* dialogsSaga() {
  yield all([
    fork(toggleForgotPasswordDialogWatcher),
    fork(toggleLoginDialogWatcher),
    fork(toggleProfileDialogWatcher),
    fork(toggleLoginOptionsDialogWatcher),
    fork(toggleRegisterOptionsDialogWatcher),
    fork(toggleRegisterDialogWatcher),
    fork(toggleMessageDialogWatcher),
    fork(toggleOTPVerificationDialogWatcher),
  ]);
}
