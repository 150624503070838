import React, { FunctionComponent } from "react";

import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
  IconName,
  IconPrefix,
  library,
} from "@fortawesome/fontawesome-svg-core";
import * as far from "@fortawesome/free-regular-svg-icons";
import * as fas from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Solid icons
library.add(
  fas.faWallet,
  fas.faGift,
  fas.faLightbulb,
  fas.faStar,
  fas.faCarSide,
  fas.faBed,
  fas.faBath,
  fas.faCheckCircle,
  fas.faShareAlt,
  fas.faImages,
  fas.faTimes,
  fas.faStore,
  fas.faHome,
  fas.faEnvelope,
  fas.faRulerCombined,
  fas.faClone,
  fas.faCogs,
  fas.faGasPump,
  fas.faCalendar,
  fas.faTachometerAlt,
);

// Regular icons
library.add(far.faLightbulb, far.faStar, far.faCheckCircle, far.faHeart);

export interface FlowFontAwesomeIconProps {
  name: any;
  color?: string;
  type?: IconPrefix;
  size?: any;
}

const assertedValue = (value: any): IconName => {
  return value as IconName;
};

export const FlowFontAwesomeIcon: FunctionComponent<
  FlowFontAwesomeIconProps
> = ({ name, color, size, type = "fas" }) => {
  const iconLookup: IconLookup = {
    iconName: assertedValue(name),
    prefix: type,
  };
  const iconDefinition: IconDefinition = findIconDefinition(iconLookup);

  return <FontAwesomeIcon icon={iconDefinition} size={size} color={color} />;
};

export default FlowFontAwesomeIcon;
