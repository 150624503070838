import { Reducer } from 'redux';

import { fetchOrganisation, sendBroadEnquiry } from './routines';
import { OrganisationState } from './types';

const initialState: OrganisationState = {
  errors: undefined,
  loading: false,
  organisation: undefined,
  broadEnquiry: undefined
};

const reducer: Reducer<OrganisationState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case sendBroadEnquiry.TRIGGER:
    case fetchOrganisation.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success
    case fetchOrganisation.SUCCESS: {
      return { ...state, organisation: action.payload };
    }

    case sendBroadEnquiry.SUCCESS: {
      return { ...state, broadEnquiry: action.payload }
    }

    // Failure
    case sendBroadEnquiry.FAILURE:
    case fetchOrganisation.FAILURE: {
      return { ...state, errors: action.payload };
    }

    // Fulfill
    case sendBroadEnquiry.FULFILL:
    case fetchOrganisation.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as organisationReducer };
