import { startCase, isEmpty, get } from "lodash";
import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import { IsMobile } from "../../utils/responsive";
import styled, { mediaUp } from "../../utils/styledComponents";
import Button from "../buttons/Button";
import InfoDialog from "../dialogs/InfoDialog";
import FlowFontAwesomeIcon, {
  FlowFontAwesomeIconProps,
} from "../icons/FlowFontAwesomeIcon";
import { theme } from "../../styles/theme";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";

const StyledIcon = styled((props: FlowFontAwesomeIconProps) => (
  <FlowFontAwesomeIcon
    {...props}
    color={!isEmpty(props.color) ? props.color : theme.colors.orange}
  />
))`
  && {
    font-size: 40px;
    position: absolute;
  }
`;

const StyledSpan = styled.div`
  margin-left: 30px;
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey4};
  position: absolute;
`;

const StyledGrid = styled(Grid)`
  && {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const MainGrid = styled(Grid)`
  margin-bottom: 21px;
`;

const StyledHeader = styled.h1`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  color: ${({ theme }) => theme.colors.grey4};
  margin-bottom: 20px;
`;

const StyledButton = styled(Button).attrs(({ theme }) => ({
  label: {
    color: theme.colors.grey4,
  },
}))`
  && {
    &:hover {
      color: ${({ theme }) => theme.colors.grey4} !important;
    }

    ${mediaUp.sm`display: none`};
    height: 60px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: 12px;
  }
`;

interface OwnProps {
  features: string[];
  organisation: object;
}

const ListingFeature: React.FunctionComponent<OwnProps> = ({
  features,
  organisation,
}) => {
  const isGreater = features && features.length > 5;
  const limitedList = IsMobile() && isGreater ? features.slice(0, 5) : features;
  const [featureList, setFeatureList] = useState<string[]>(limitedList);
  const [openDialog, setOPenDialog] = useState<boolean>(false);
  const colors = getWhiteLabelOrganisationColors(organisation);
  const onViewAll = () => {
    setFeatureList(features);
    setOPenDialog(true);
  };

  const onClose = () => {
    setFeatureList(limitedList);
    setOPenDialog(false);
  };

  const List: any = featureList.map((feature: string, key) => (
    <StyledGrid
      lg={4}
      md={3}
      sm={4}
      xs={12}
      container={true}
      item={true}
      direction="row"
      key={key}
    >
      <StyledIcon
        name="check-circle"
        size="sm"
        type="far"
        color={get(colors, "primaryColor")}
      />
      <StyledSpan>{startCase(feature)}</StyledSpan>
    </StyledGrid>
  ));

  return (
    <div>
      <InfoDialog
        isOpened={openDialog}
        header="Features"
        content={List}
        oClickEvent={onClose}
      />
      <StyledHeader>Features</StyledHeader>
      <MainGrid container={true} direction="row">
        {List}
      </MainGrid>
      {isGreater && (
        <StyledButton
          onClick={onViewAll}
          default={true}
          fullWidth={true}
          size={"small"}
        >
          View all features
        </StyledButton>
      )}
    </div>
  );
};

export default ListingFeature;
