import { withFormik } from 'formik';
import { Location } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import validator from 'validator';
import * as Yup from 'yup';

import SocialRegisterForm, {
    SocialRegisterFormValues
} from '../components/forms/SocialRegisterForm';
import { ApplicationState } from '../store';
import { loginWithFacebookPromise, loginWithGooglePromise } from '../store/auth';
import styled from '../utils/styledComponents';

const INTERCOM_SOURCE = process.env.REACT_APP_INTERCOM_SOURCE;

interface OwnProps {
  referral?: { action: string; type: string; referralCode: string };
  userType?: "landlord" | "tenant";
  data: {
    firstName: string;
    lastName: string;
    email?: string;
    profileImage?: string;
  };
  provider: "email" | "facebook" | "google";
  accessToken?: string;
  inviteToken?: string;
}

interface PropsFromState {
  errors: string;
  location: Location;
}

interface PropsFromDispatch {
  loginWithGooglePromise: typeof loginWithGooglePromise;
  loginWithFacebookPromise: typeof loginWithFacebookPromise;
}

/* tslint:disable:object-literal-sort-keys */
const schema = Yup.object().shape({
  userType: Yup.string()
    .oneOf(["tenant", "landlord"])
    .required("Please select whether you are a tenant or landlord"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email")
    .test({
      name: "validateEmail",
      message: "Please provide a valid email",
      test: (value) => value == null || validator.isEmail(value),
    }),
  contactNumber: Yup.string().required("Please enter your contact number"),
});

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class RegisterFormContainer extends React.Component<AllProps> {
  public render() {
    return <Wrapper>{this.renderForm()}</Wrapper>;
  }

  private renderForm() {
    const formikEnhancer = withFormik<{}, SocialRegisterFormValues>({
      displayName: "SocialRegisterForm",
      handleSubmit: async (values, { setSubmitting }) => {
        try {
          setSubmitting(true)
          const registerValues = _.omit(values, ["acceptTC"]);
          const { action, referralCode, type } = queryString.parse(
            this.props.location.search
          );

          registerValues.tags = [values.userType];

          if (
            typeof action === "string" &&
            typeof type === "string" &&
            typeof referralCode === "string"
          ) {
            registerValues.referral = {
              action,
              referralCode,
              type,
            };
          }

          registerValues.Source = INTERCOM_SOURCE;
          registerValues.UserType = values.userType;

          const payload = {
            data: registerValues,
            userType: values.userType,
            accessToken: this.props.accessToken,
            register: true,
          };

          if (this.props.inviteToken) {
            payload.data = {
              ...payload.data,
              invite: {
                token: this.props.inviteToken,
              },
            };
          }

          if (this.props.provider === "facebook") {
            await this.props.loginWithFacebookPromise(payload);
          }

          if (this.props.provider === "google") {
            await this.props.loginWithGooglePromise(payload);
          }
          setSubmitting(false);
        } catch (error) {
          console.log(error);
          setSubmitting(false);
        }
      },
      mapPropsToValues: () => ({
        acceptTC: false,
        contactNumber: "",
        email: "",
        userType: this.props.userType || "",
        ...this.props.data,
      }),
      validationSchema: schema,
    });
    const EnhancedSocialRegisterForm: any = formikEnhancer(SocialRegisterForm);

    return (
      <EnhancedSocialRegisterForm typeSelectDisabled={this.props.userType} />
    );
  }
}

const mapStateToProps = ({ auth, router }: ApplicationState) => ({
  errors: auth.errors,
  location: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    { loginWithFacebookPromise, loginWithGooglePromise },
    dispatch
  ),
});

const Wrapper = styled.div``;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFormContainer) as any;
