import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import {
  fetchActiveLocales
} from './routines';

function* handleFetchActiveLocales() {
  try {
    const res = yield call(
      callApi,
      "get",
      "/locales"
    );

    yield put(
      fetchActiveLocales.success({
        locales: res.data
      })
    );
  } catch (err) {
    console.log(err);
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(fetchActiveLocales.failure(errorHandler(err.response)));
    } else {
      yield put(fetchActiveLocales.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveLocales.fulfill());
  }
}
function* fetchActiveLocalesWatcher() {
  yield takeEvery(fetchActiveLocales.TRIGGER, handleFetchActiveLocales);
}
export function* defaultSaga() {
  yield all([
    fork(fetchActiveLocalesWatcher),
  ]);
}
