import { History, Location } from 'history';
import { includes } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import Grid, { GridProps } from '@material-ui/core/Grid';

import { EmailButton } from '../components/buttons';
import EnquiryCard from '../components/cards/EnquiryCard';
import InvitesCard from '../components/cards/InvitesCard';
import Title from '../components/typography/Title';
import OrDivider from '../components/ui/OrDivider';
import FacebookLoginContainer from '../containers/FacebookLoginContainer';
import GoogleLoginContainer from '../containers/GoogleLoginContainer';
import RegisterFormContainer from '../containers/RegisterFormContainer';
import SocialRegisterFormContainer from '../containers/SocialRegisterFormContainer';
import { ApplicationState } from '../store';
import {
    toggleLoginDialog as toggleLoginDialogAction, toggleProfileDialog as toggleProfileDialogAction
} from '../store/dialogs';
import { InvitedByUser, InvitedUser, validateInviteToken } from '../store/invites';
import { EnquiryProps } from '../store/listings/types';
import { enqueueSnackbar } from '../store/notifications';
import { fetchReferralInfo, ReferralState } from '../store/referral';
import styled from '../utils/styledComponents';

interface PropsFromDispatch {
  enqueueSnackbar: typeof enqueueSnackbar;
  toggleProfileDialog: typeof toggleProfileDialogAction;
  toggleLoginDialog: typeof toggleLoginDialogAction;
  fetchReferralInfo: typeof fetchReferralInfo;
  validateInviteToken: typeof validateInviteToken;
}
interface PropsFromState {
  errors?: string;
  loading: boolean;
  authenticated: boolean;
  enquiryInfo?: EnquiryProps;
  referral?: ReferralState;
  invitedByUser?: InvitedByUser;
  invitedUser?: InvitedUser;
}

interface ConnectedProps {
  history: History;
  location: Location;
  match: match;
}

interface State {
  accessToken?: string;
  data?: {
    email?: string;
    firstName: string;
    lastName: string;
  };
  provider?: "local" | "google" | "facebook";
  activeStep: number;
}

type AllProps = PropsFromState & PropsFromDispatch & ConnectedProps;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const OrDividerContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const LinksContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  margin-top: 0px;
  > div {
    margin-top: 0px;
  }
`;

const StyledTitle = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700} !important;
  font-size: ${({ theme }) => theme.fontSizes.title} !important;
  line-height: 1.5em !important;
`;

const SubSectionTitle = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.heading} !important;
  margin-bottom: 15px !important;
`;

const OrangeLink = styled.a<{ fontSize?: number }>`
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme, fontSize }) => fontSize ? `${fontSize}px` : theme.fontSizes.heading};
  color: ${({ theme }) => theme.colors.orange};
  text-decoration: none;
`;

const LinkText = styled.span`&&{
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
  color: ${({ theme }) => theme.colors.grey4};
}`;

const StyledSocialContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div:nth-child(1) {
    margin-right: 20px;;
  }
`;

const StyledHr = styled.div`&&{
  margin-top: 40px;
  margin-bottom:40px;
  border-bottom: solid thin ${({ theme }) => theme.colors.grey[250]};
}`;


class RegisterPageContainer extends React.Component<AllProps, State> {
  public static getDerivedStateFromProps(
    nextProps: AllProps,
    prevState: State
  ) {
    const {
      location: { state },
    } = nextProps;
    if (state && state.provider && state.provider !== prevState.provider) {
      return {
        ...prevState,
        ...state,
      };
    }
    return null;
  }
  public state: State = {
    accessToken: undefined,
    activeStep: 0,
    data: undefined,
    provider: undefined,
  };

  public componentDidMount() {
    const { token }: any = this.props.match.params;
    const { action, referralCode } = queryString.parse(
      this.props.location.search
    );
    if (token) {
      this.props.validateInviteToken({ token });
    }

    if (action && action === "refer") {
      this.props.fetchReferralInfo({ referralCode });
    }
  }

  public render() {
    const {
      invitedByUser,
      invitedUser,
      match,
      enquiryInfo,
      referral,
      authenticated,
    } = this.props;
    const { token }: any = match.params;
    const populatedVales = enquiryInfo?.tenantInfo || invitedUser;
    const registerCmsLink = `${process.env.REACT_APP_CMS_URL}/register`

    const DetailsCard = () => {
      if (enquiryInfo) {
        console.log(referral?.user);
        return <EnquiryCard {...enquiryInfo!} />;
      } else if (referral?.user) {
        return <EnquiryCard referralInfo={referral?.user} />;
      } else if (invitedUser && token) {
        return <InvitesCard landlord={invitedByUser!} />;
      }
      return (
        <SubSectionTitle>Are you an Agent? <OrangeLink href={registerCmsLink} title="register here">
          Register here
        </OrangeLink>
        </SubSectionTitle>
      );
    };
    return (
      <>
        {!authenticated && (
          <>
            {!this.state.provider && (
              <>
                <TitleWrapper>
                  <StyledTitle>Create a Buyer/Tenant account</StyledTitle>
                  <DetailsCard />
                </TitleWrapper>
                <StyledSocialContainer>
                  <FacebookLoginContainer
                    onError={this.onError}
                    onLoginError={this.onFacebookNotRegistered}
                  />
                  <GoogleLoginContainer
                    onError={this.onError}
                    onLoginError={this.onGoogleNotRegistered}
                  />
                </StyledSocialContainer>
                <OrDividerContainer>
                  <OrDivider />
                </OrDividerContainer>
                <Grid item={true} xs={12}>
                  <EmailButton
                    onClick={this.onRegisterWithEmailClick}
                    fullWidth={true}
                  >
                    Register with Email
                  </EmailButton>
                </Grid>
                <StyledHr />
                <LinksContainer container={true} justify="center">
                  <Grid item={true}>
                    <LinkText>Already have an account? </LinkText>
                    <OrangeLink href="/login" title="login here" fontSize={14}>
                      Login here
                    </OrangeLink>
                  </Grid>
                </LinksContainer>
              </>
            )}

            {this.state.provider === "local" && (
              <RegisterFormContainer
                userType="tenant"
                populatedValues={populatedVales}
              />
            )}
            {(this.state.provider === "google" ||
              this.state.provider === "facebook") && (
                <SocialRegisterFormContainer
                  userType="tenant"
                  data={this.state.data}
                  provider={this.state.provider}
                  accessToken={this.state.accessToken}
                />
              )}
          </>
        )}
      </>
    );
  }

  public loginButtonClicked = () => {
    const { location, history } = this.props;
    if (includes(location.pathname, "register")) {
      history.push("/login");
    } else {
      this.props.toggleLoginDialog(true);
    }
  };

  private onRegisterWithEmailClick = () => {
    this.setState({ provider: "local" });
  };

  private onGoogleNotRegistered = async (
    data: {
      email: string;
      firstName: string;
      lastName: string;
    },
    accessToken: string
  ) => {
    this.setState({
      accessToken,
      data,
      provider: "google",
    });
  };

  private onFacebookNotRegistered = async (
    data: {
      email: string;
      firstName: string;
      lastName: string;
    },
    accessToken: string
  ) => {
    this.setState({
      accessToken,
      data,
      provider: "facebook",
    });
  };

  private onError = (error: string) => {
    this.props.enqueueSnackbar({
      message: error,
      options: { variant: "error" },
    });
  };
}

const mapStateToProps = ({
  referral,
  auth,
  router,
  listing,
  invites,
}: ApplicationState) => ({
  authenticated: auth.authenticated,
  errors: auth.errors,
  loading: auth.loading,
  location: router.location,
  enquiryInfo: listing.enquiryInfo,
  referral,
  invitedByUser: invites.invitedBy,
  invitedUser: invites.userDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      enqueueSnackbar,
      toggleProfileDialog: toggleProfileDialogAction,
      toggleLoginDialog: toggleLoginDialogAction,
      fetchReferralInfo,
      validateInviteToken,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPageContainer) as any
);
