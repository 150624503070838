import "react-phone-input-2/lib/material.css";

import { get, isEmpty } from "lodash";
import React, { useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import { ApplicationState } from "../../store";
import {
  fetchActiveLocalesPromise,
  LocaleWithCountry,
} from "../../store/defaults";
import { DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES } from "../../utils/defaultLocaleSettings";
import { formatPhone } from "../../utils/numberFormatter";
import styled from "../../utils/styledComponents";

interface PropsFromState {
  locales: LocaleWithCountry[];
}
interface PropsFromDispatch {
  fetchLocales: typeof fetchActiveLocalesPromise;
}

interface InputProps extends PhoneInputProps {
  outlined?: boolean;
}
interface OwnProps {
  initialCountry?: string;
  name: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  outlined?: boolean;
  required?: boolean;
  isValid: boolean;
  handleOnChange(value: any, prev: any): void;
  handleOnBlur(value: any): void;
}
type AllProps = OwnProps & PropsFromState & PropsFromDispatch;
const StyledPhoneInput = styled(PhoneInput)<InputProps>`
  && {
    width: 100%;
  }
`;

// for more info -> https://www.npmjs.com/package/react-phone-input-2

const StyledError = styled.p`
  color: #e65757;
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  margin-bottom: 10px;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
`;
const ContactNumberWithCountryCode: React.FunctionComponent<AllProps> = (
  props
) => {
  const [isValid, setIsValid] = useState(props.isValid);
  const [hasValue, setHasValue] = useState(false);
  const locales: LocaleWithCountry[] = props.locales;

  if (locales && locales.length === 0) {
    locales.push(DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES);
  }
  const onPhoneChange = (
    value: string,
    data: object | {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ): void => {
    validatePhone(formattedValue, data); // validate the value
  };
  const onPhoneBlur = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: object | {}
  ): void => {
    const value = event.target.value;
    validatePhone(value, data, false); // validate the value
  };

  const validatePhone = (number: string, data: object, isOnChange = true) => {
    const currentLocale = locales.find(
      (locale) =>
        locale.country.code.toLowerCase() === data["countryCode"].toLowerCase()
    );
    const mask = currentLocale
      ? currentLocale.country.phone.mask
      : DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES.country.phone.mask;

    const dialCode = currentLocale
      ? currentLocale.country.phone.dialCode
      : DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES.country.phone.dialCode;

    const countryCode = currentLocale
      ? currentLocale.country.code
      : DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES.country.code;

    const valueWithoutCode = number
      .replace(`${dialCode} `, "")
      .replace(dialCode, "");

    //the validator doesnt support the swaziland locale
    const formattedPhone =
      countryCode.toLowerCase() === "sz"
        ? number.replace(" ", "")
        : formatPhone(number, countryCode);

    if (isOnChange) {
      props.handleOnChange(formattedPhone, number);
    } else {
      props.handleOnBlur(formattedPhone);
    }
    setIsValid(
      valueWithoutCode.length === mask.length && !isEmpty(formattedPhone)
    );
    setHasValue(valueWithoutCode.length > 0);
  };
  const label = get(props, "label", "Contact Number");
  const phoneProps = {
    name: props.name,
    required: get(props, "required", true),
    autoFocus: false,
  };
  // these country codes and masks needs to come from DB and stored in the state once we do localisation
  const onlyCountries: string[] = locales.map((locale) => {
    return locale.country.code.toLowerCase();
  });
  const defaultMasks: any = {};
  for (const locale of locales) {
    defaultMasks[locale.country.code.toLowerCase()] = locale.country.phone.mask;
  }
  const isDisabled = get(props, "disabled", false);
  const outlinedStyle = props.outlined
    ? `locale-tel-container locale-tel-outlined `
    : `locale-tel-container`;
  const containerStyle =
    !isValid || !props.isValid
      ? props.outlined
        ? `${outlinedStyle} locale-tel-outlined-error `
        : `${outlinedStyle}`
      : outlinedStyle;

  return (
    <>
      <StyledPhoneInput
        country={get(props, "initialCountry", "za")}
        value={get(props, "value", null)}
        inputProps={phoneProps}
        onlyCountries={onlyCountries}
        onChange={onPhoneChange}
        onBlur={onPhoneBlur}
        outlined={props.outlined}
        specialLabel={label}
        masks={defaultMasks}
        isValid={isValid}
        enableSearch={true}
        disabled={isDisabled}
        disableCountryCode={isDisabled}
        disableDropdown={isDisabled}
        countryCodeEditable={false}
        preferredCountries={["za"]}
        searchNotFound="country not found"
        containerClass={containerStyle}
        inputClass="locale-tel-input"
        searchClass="locale-tel-search"
        dropdownClass="locale-tel-dropdown"
      />
      {(!isValid || !props.isValid) && (
        <StyledError>
          {hasValue
            ? `Please enter a valid ${label.toLowerCase()}`
            : `Please enter your ${label.toLowerCase()}`}
        </StyledError>
      )}
    </>
  );
};
const mapStateToProps = ({ defaults }: ApplicationState) => ({
  locales: defaults.locales,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchLocales: fetchActiveLocalesPromise,
    },
    dispatch
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactNumberWithCountryCode);
