export const DEFAULT_LOCALE_SETTINGS = Object.freeze({
  language: {
    code: "en",
    description: "English",
  },
  country: {
    code: "za",
    description: "South Africa",
    phone: {
      mask: ".. ... ....",
      dialCode: "+27",
    },
  },
  currency: {
    code: "ZAR",
    symbol: "R",
    description: "South African Rand",
  },
  measurementSystem: "metric",
});

export const DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES = Object.freeze({
  languages: [
    {
      code: "en",
      description: "English",
    },
  ],
  country: {
    code: "za",
    description: "South Africa",
    phone: {
      mask: ".. ... ....",
      dialCode: "+27",
    },
  },
  currencies: [
    {
      code: "ZAR",
      symbol: "R",
      description: "South African Rand",
    },
  ],
  measurementSystem: "metric",
});
