import React from "react";
import { Property } from "../../store/chat";

import { Unit } from "../../store/listings/types";
import styled from "../../utils/styledComponents";
import AvailableUnit from "../listItems/AvailableUnit";

const StyledMain = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

interface OwnProps {
  unitData: Unit[];
  property: Property;
  organisation: any;
  agentId: string;
  landlord: { name: string };
}

type AllProps = OwnProps;

const AvailableUnitList: React.FunctionComponent<AllProps> = ({
  agentId,
  unitData,
  organisation,
  property,
  landlord,
}) => {
  return (
    <StyledMain>
      {unitData &&
        unitData.map((unit: Unit, key: any) => (
          <AvailableUnit
            key={key}
            organisation={organisation}
            unit={unit}
            property={property}
            agentId={agentId}
            landlord={landlord}
          />
        ))}
    </StyledMain>
  );
};

export default AvailableUnitList;
