import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

import styled from '../../utils/styledComponents';

type AllProps = TypographyProps;

const SubTitle = styled(MuiTypography as React.FunctionComponent<
  AllProps
>).attrs({
  variant: "subtitle1"
})`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ theme }) => theme.fontSizes.subtitle};
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

export default SubTitle;
