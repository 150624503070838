import { withFormik } from 'formik';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import ListingsFilterForm, {
    ListingsFilterFormProps, ListingsFilterFormValues
} from '../components/forms/ListingsFilterForm';
import { ApplicationState } from '../store';
import { AuthenticatedUser } from '../store/auth';
import {
    fetchActiveListingsFiltersPromise, fetchActiveListingsPromise, FiltersProps, Search
} from '../store/listings';
import { deriveListingsDataLayer } from '../utils/GoogleTagManager';
import { getParams, mapQueryToValues, mapValuesToQuery } from '../utils/listingUrlHelper';
import styled from '../utils/styledComponents';

interface OwnProps {
  filters?: FiltersProps;
  showListingsFilter: boolean;
  toggleListingsFilter: (event: React.SyntheticEvent) => void;
}

interface PropsFromState {
  errors: string;
  count: number;
  location: Location;
  search: Search;
  user?: AuthenticatedUser;
  loading: boolean;
  listingSector: "residendial" | "commercial";
  listingType: "sale" | "rent";
}

interface PropsFromDispatch {
  fetchActiveListingsFiltersPromise: typeof fetchActiveListingsFiltersPromise;
  fetchActiveListingsPromise: typeof fetchActiveListingsPromise;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch;

interface State {
  showSuccessMessage: boolean;
  query: string;
}

const SuccessMessage = styled.div`
  color: ${({ theme }) => theme.colors.grey4};
  text-align: center;
`;

class ListingsFilterFormContainer extends React.Component<AllProps, State> {
  public readonly state: State = {
    query: "",
    showSuccessMessage: false,
  };


  public render() {
    const { errors } = this.props;
    return (
      <Wrapper>
        {!errors ? (
          <>
            {this.state.showSuccessMessage ? (
              <SuccessMessage>
                Please check you email for instructions on resetting your
                password.
              </SuccessMessage>
            ) : (
              this.renderForm()
            )}
          </>
        ) : null}
      </Wrapper>
    );
  }

  private renderForm() {
    const formikEnhancer = withFormik<
      ListingsFilterFormProps,
      ListingsFilterFormValues
    >({
      displayName: "ListingsFilterForm",
      handleSubmit: async (values, { setSubmitting }) => {
        const payload = mapValuesToQuery(values);
        setSubmitting(true);
        this.props
          .fetchActiveListingsPromise(payload)
          .then((results) => {
            setSubmitting(false);
            deriveListingsDataLayer(
              results.listings,
              "tenant_searched",
              "listings"
            );
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error);
          });
      },
      mapPropsToValues: () => {
        const { listingSector } = this.props;
        const urlQuery = encodeURI(window.location.search);
        const params = getParams(window.location.pathname);
        const values = mapQueryToValues(urlQuery);

        return {
          ...values,
          listingSector: params && !isEmpty(params.listingSector) ? params.listingSector : listingSector,
          listingType: this.props.listingType
        };
      },
    });

    const EnhancedListingsFilterForm = formikEnhancer(ListingsFilterForm);
    return (
      <EnhancedListingsFilterForm
        filters={this.props.filters}
        showListingsFilter={this.props.showListingsFilter}
        toggleListingsFilter={this.props.toggleListingsFilter}
      />
    );
  }
}

const mapStateToProps = ({ auth, listing, router }: ApplicationState) => ({
  errors: auth.errors,
  filters: listing.filters,
  location: router.location,
  search: listing.search,
  user: auth.authenticated,
  listingSector: get(listing.listings[0], 'listingSector', 'residential'),
  listingType: get(listing.listings[0], 'listingType', 'sale')
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    { fetchActiveListingsPromise, fetchActiveListingsFiltersPromise },
    dispatch
  ),
});

const Wrapper = styled.div``;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingsFilterFormContainer) as any;
