import { get } from 'lodash';
import { Frequencies, SubscriptionPackage } from './types';

const CHECKOUT_URLS = {
  development: {
    1: "https://buy.stripe.com/test_eVa8zIed31OF15SfZ4",
    2: "https://buy.stripe.com/test_7sIeY61qh2SJdSEbIN",
  },
  feature: {
    1: "https://buy.stripe.com/test_aEUaHQglb50R29W7sw",
    2: "https://buy.stripe.com/test_3cs17g1qh64V4i45kn",
  },
  staging: {
    1: "https://buy.stripe.com/test_28o2bk3yp78Z8yk9AC",
    2: "https://buy.stripe.com/test_28og2ad8Z50R01O8wx",
  },
  production: {
    1: "https://buy.stripe.com/7sIaF32kKdmG2lO9AM",
    2: "https://buy.stripe.com/cN2fZn6B04Qa9OgfZ9",
  },
} as const;

const getCheckoutUrl = (id: number | string): string | undefined => {
  const env = process.env.REACT_APP_ENV;
  return get(CHECKOUT_URLS, `${env}.${id}`);
}

// TODO: delete once endpoints are ready
export const LISTINGS_RESPONSE = {
  "results": [
      {
          "_id": "648ee13abb7049009dc95a94",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085336/production/648edf7eaafd48004b661e11/g6pfinja0tghtvn6oslb.jpg",
              "caption": "Main Building"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 400
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-06-18T10:49:30.409Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-06-18T10:49:30.409Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-06-18T10:49:30.409Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-06-18T10:49:30.411Z",
              "rentalPeriods": []
          },
          "type": "SINGLE",
          "tags": [
              "smoking",
              "horse"
          ],
          "owners": [],
          "amenities": [
              "BBQ grill",
              "Extra pillows and blankets"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "28 Makepeace Ave, London N6 6EJ, UK",
              "lng": -0.1502321,
              "lat": 51.5643541,
              "street": "Makepeace Avenue 28",
              "city": "London",
              "country": "United Kingdom",
              "zipcode": "N6 6EJ",
              "state": "England"
          },
          "pictures": [
              {
                  "_id": "648ee13abb7049009dc95a95",
                  "original": "https://assets.guesty.com/image/upload/v1687085336/production/648edf7eaafd48004b661e11/g6pfinja0tghtvn6oslb.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085336/production/648edf7eaafd48004b661e11/g6pfinja0tghtvn6oslb.jpg",
                  "height": 1687,
                  "width": 3000,
                  "size": 549587,
                  "caption": "Main Building"
              }
          ],
          "nickname": "Villa Villekulla",
          "title": "Amazing Villa in the Middle of the Wetlands",
          "accountId": "648edf7eaafd48004b661e11",
          "isListed": true,
          "timezone": "Europe/London",
          "createdAt": "2023-06-18T10:49:30.410Z",
          "lastUpdatedAt": "2023-06-18T10:50:18.633Z",
          "integrations": [],
          "pendingTasks": [],
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "importedAt": "2023-06-18T10:49:30.411Z",
          "occupancyStats": [],
          "__v": 1,
          "accountTaxes": []
      },
      {
          "_id": "64ddfc32d6e8aa003818b78c",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692269587/production/648edf7eaafd48004b661e11/xaorubvj4kmq7sgvipam.jpg",
              "caption": "Bedroom"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 100
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-08-17T10:53:38.198Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-08-17T10:53:38.198Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-08-17T10:53:38.198Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-08-17T10:53:38.203Z",
              "rentalPeriods": []
          },
          "type": "SINGLE",
          "tags": [],
          "owners": [],
          "amenities": [
              "Heating",
              "Dishwasher",
              "Air conditioning",
              "Babysitter recommendations",
              "Lake access",
              "Kitchen"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Private room",
          "address": {
              "full": "1 Testa Rd, Uxbridge, ON L9P 1Y9, Canada",
              "lng": -79.10789240000001,
              "lat": 44.1033908,
              "street": "Testa Road 1",
              "city": "Uxbridge",
              "country": "Canada",
              "zipcode": "L9P 1Y9",
              "state": "Ontario"
          },
          "pictures": [
              {
                  "_id": "64ddfc32d6e8aa003818b78d",
                  "original": "https://assets.guesty.com/image/upload/v1692269587/production/648edf7eaafd48004b661e11/xaorubvj4kmq7sgvipam.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692269587/production/648edf7eaafd48004b661e11/xaorubvj4kmq7sgvipam.jpg",
                  "height": 1080,
                  "width": 1920,
                  "size": 165794,
                  "caption": "Bedroom"
              }
          ],
          "nickname": "Room 1",
          "title": "Complex - Room 1",
          "accountId": "648edf7eaafd48004b661e11",
          "isListed": true,
          "timezone": "America/Toronto",
          "createdAt": "2023-08-17T10:53:38.203Z",
          "lastUpdatedAt": "2023-08-17T10:55:45.831Z",
          "integrations": [],
          "pendingTasks": [],
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "importedAt": "2023-08-17T10:53:38.203Z",
          "occupancyStats": [],
          "__v": 2,
          "complexId": "64ddfc8c2c68b6003bc45926",
          "accountTaxes": []
      },
      {
          "_id": "64ddfc6b99d1f6003fe8b8da",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692269664/production/648edf7eaafd48004b661e11/aladdpb9qtllnxrhu4gw.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 50
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-08-17T10:54:35.358Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-08-17T10:54:35.358Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-08-17T10:54:35.358Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-08-17T10:54:35.360Z",
              "rentalPeriods": []
          },
          "type": "SINGLE",
          "tags": [],
          "owners": [],
          "amenities": [],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Private room",
          "address": {
              "full": "1 Testa Rd, Uxbridge, ON L9P 1Y9, Canada",
              "lng": -79.10789240000001,
              "lat": 44.1033908,
              "street": "Testa Road 1",
              "city": "Uxbridge",
              "country": "Canada",
              "zipcode": "L9P 1Y9",
              "state": "Ontario"
          },
          "pictures": [
              {
                  "_id": "64ddfc6b99d1f6003fe8b8db",
                  "original": "https://assets.guesty.com/image/upload/v1692269664/production/648edf7eaafd48004b661e11/aladdpb9qtllnxrhu4gw.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692269664/production/648edf7eaafd48004b661e11/aladdpb9qtllnxrhu4gw.jpg",
                  "height": 1080,
                  "width": 1920,
                  "size": 192590
              }
          ],
          "nickname": "Complex - Room 2",
          "title": "Complex - Room 2",
          "accountId": "648edf7eaafd48004b661e11",
          "isListed": true,
          "timezone": "America/Toronto",
          "createdAt": "2023-08-17T10:54:35.359Z",
          "lastUpdatedAt": "2023-08-17T10:55:30.217Z",
          "integrations": [],
          "pendingTasks": [],
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "importedAt": "2023-08-17T10:54:35.360Z",
          "occupancyStats": [],
          "__v": 2,
          "complexId": "64ddfc8c2c68b6003bc45926",
          "accountTaxes": []
      },
      {
          "_id": "64dcbdc8a8acaf004268ca4f",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692188091/production/648edf7eaafd48004b661e11/ylcc1ocst6yvre1pamsh.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 7
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "EUR",
              "basePrice": 120
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-08-16T12:15:04.609Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-08-16T12:15:04.609Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-08-16T12:15:04.609Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-08-16T12:15:04.610Z",
              "rentalPeriods": []
          },
          "type": "SINGLE",
          "tags": [],
          "owners": [],
          "amenities": [
              "Air conditioning",
              "Stove",
              "Refrigerator",
              "Fireplace guards",
              "Single level home",
              "Rural",
              "Doorman",
              "Washer in common space",
              "Toaster",
              "Internet",
              "Kettle"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 4,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "1 Test Centre Rd, Broadlands, Cape Town, 7140, South Africa",
              "lng": 18.8761629,
              "lat": -34.1194899,
              "unit": "2",
              "apartment": null,
              "floor": "4",
              "street": "Test Centre Road 1",
              "city": "Cape Town",
              "country": "South Africa",
              "zipcode": "7140",
              "state": "Western Cape"
          },
          "pictures": [
              {
                  "_id": "64dcbdc8a8acaf004268ca50",
                  "original": "https://assets.guesty.com/image/upload/v1692188091/production/648edf7eaafd48004b661e11/ylcc1ocst6yvre1pamsh.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1692188091/production/648edf7eaafd48004b661e11/ylcc1ocst6yvre1pamsh.jpg",
                  "height": 1080,
                  "width": 1920,
                  "size": 252426
              }
          ],
          "nickname": "Flowliving - Test",
          "title": "Great apartment in the City Centre",
          "accountId": "648edf7eaafd48004b661e11",
          "isListed": true,
          "timezone": "Africa/Johannesburg",
          "createdAt": "2023-08-16T12:15:04.610Z",
          "lastUpdatedAt": "2023-08-16T12:54:06.569Z",
          "integrations": [],
          "pendingTasks": [],
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "importedAt": "2023-08-16T12:15:04.611Z",
          "occupancyStats": [],
          "__v": 1,
          "accountTaxes": []
      },
      {
          "_id": "648ee1a5aafd48004b662e84",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 500
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-06-18T10:51:17.034Z",
              "rentalPeriods": []
          },
          "type": "MTL",
          "tags": [],
          "owners": [],
          "amenities": [
              "Refrigerator",
              "Stove",
              "Outdoor pool"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "28 Makepeace Ave, London N6 6EJ, UK",
              "lng": -0.1502321,
              "lat": 51.5643541,
              "street": "Makepeace Avenue 28",
              "city": "London",
              "country": "United Kingdom",
              "zipcode": "N6 6EJ",
              "state": "England"
          },
          "pictures": [
              {
                  "_id": "648ee1a5aafd48004b662e85",
                  "original": "https://assets.guesty.com/image/upload/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "height": 1687,
                  "width": 3000,
                  "size": 549587
              }
          ],
          "nickname": "Double Room",
          "title": "Spacious Double Bed Room with Free wifi!",
          "manageSubunitPictures": true,
          "mtl": {
              "c": [
                  "648ee1a5aafd48004b662f42",
                  "648ee1a5aafd48004b662f4a",
                  "648ee1a5aafd48004b662f52"
              ],
              "hdb4": 14,
              "lmcn": false,
              "_id": "648ee1a5aafd48004b662e86",
              "aas": "oc",
              "bc": null
          },
          "isListed": true,
          "accountId": "648edf7eaafd48004b661e11",
          "timezone": "Europe/London",
          "createdAt": "2023-06-18T10:51:17.033Z",
          "lastUpdatedAt": "2023-06-18T10:51:17.092Z",
          "integrations": [],
          "pendingTasks": [],
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "importedAt": "2023-06-18T10:51:17.034Z",
          "occupancyStats": [],
          "__v": 0,
          "accountTaxes": []
      },
      {
          "_id": "648ee1a5aafd48004b662f52",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 500
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-06-18T10:51:17.034Z",
              "rentalPeriods": []
          },
          "type": "MTL_CHILD",
          "tags": [],
          "owners": [],
          "amenities": [
              "Refrigerator",
              "Stove",
              "Outdoor pool"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "28 Makepeace Ave, London N6 6EJ, UK",
              "lng": -0.1502321,
              "lat": 51.5643541,
              "street": "Makepeace Avenue 28",
              "city": "London",
              "country": "United Kingdom",
              "zipcode": "N6 6EJ",
              "state": "England"
          },
          "pictures": [
              {
                  "_id": "648ee1a5aafd48004b662e85",
                  "original": "https://assets.guesty.com/image/upload/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "height": 1687,
                  "width": 3000,
                  "size": 549587
              }
          ],
          "nickname": "Double Room (3)",
          "title": "Spacious Double Bed Room with Free wifi!",
          "mtl": {
              "c": [],
              "hdb4": 14,
              "lmcn": false,
              "_id": "648ee1a5aafd48004b662f54",
              "p": "648ee1a5aafd48004b662e84"
          },
          "isListed": true,
          "accountId": "648edf7eaafd48004b661e11",
          "timezone": "Europe/London",
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "occupancyStats": [],
          "__v": 0,
          "picturesManagedFromParent": true,
          "createdAt": "2023-06-18T10:51:17.232Z",
          "lastUpdatedAt": "2023-06-18T10:51:17.232Z",
          "integrations": [],
          "pendingTasks": [],
          "importedAt": "2023-06-18T10:51:17.232Z",
          "accountTaxes": []
      },
      {
          "_id": "648ee1a5aafd48004b662f42",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 500
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-06-18T10:51:17.034Z",
              "rentalPeriods": []
          },
          "type": "MTL_CHILD",
          "tags": [],
          "owners": [],
          "amenities": [
              "Refrigerator",
              "Stove",
              "Outdoor pool"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "28 Makepeace Ave, London N6 6EJ, UK",
              "lng": -0.1502321,
              "lat": 51.5643541,
              "street": "Makepeace Avenue 28",
              "city": "London",
              "country": "United Kingdom",
              "zipcode": "N6 6EJ",
              "state": "England"
          },
          "pictures": [
              {
                  "_id": "648ee1a5aafd48004b662e85",
                  "original": "https://assets.guesty.com/image/upload/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "height": 1687,
                  "width": 3000,
                  "size": 549587
              }
          ],
          "nickname": "Double Room (1)",
          "title": "Spacious Double Bed Room with Free wifi!",
          "mtl": {
              "c": [],
              "hdb4": 14,
              "lmcn": false,
              "_id": "648ee1a5aafd48004b662f44",
              "p": "648ee1a5aafd48004b662e84"
          },
          "isListed": true,
          "accountId": "648edf7eaafd48004b661e11",
          "timezone": "Europe/London",
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "occupancyStats": [],
          "__v": 0,
          "picturesManagedFromParent": true,
          "createdAt": "2023-06-18T10:51:17.215Z",
          "lastUpdatedAt": "2023-06-18T10:51:17.215Z",
          "integrations": [],
          "pendingTasks": [],
          "importedAt": "2023-06-18T10:51:17.216Z",
          "accountTaxes": []
      },
      {
          "_id": "648ee1a5aafd48004b662f4a",
          "SaaS": {
              "autoRenew": true
          },
          "cleaningStatus": {
              "value": null
          },
          "picture": {
              "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg"
          },
          "terms": {
              "minNights": 1,
              "maxNights": 45
          },
          "prices": {
              "monthlyPriceFactor": 1,
              "weeklyPriceFactor": 1,
              "currency": "GBP",
              "basePrice": 500
          },
          "pms": {
              "automation": {
                  "answeringMachine": {
                      "confirmedBeforeCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckIn": {
                          "delayInMinutes": 45
                      },
                      "confirmedDayOfCheckOut": {
                          "delayInMinutes": 45
                      },
                      "confirmedDuringStay": {
                          "delayInMinutes": 45
                      },
                      "confirmedAfterCheckOut": {
                          "delayInMinutes": 45
                      },
                      "unconfirmedFirstMessage": {
                          "delayInMinutes": 55
                      },
                      "unconfirmedSubsequentMessage": {
                          "delayInMinutes": 45
                      }
                  },
                  "hooks": {
                      "ignoredHooks": []
                  },
                  "calendarSmartRules": {
                      "blockListings": []
                  },
                  "autoReviews": {
                      "templates": []
                  },
                  "autoPricing": {
                      "rules": []
                  }
              },
              "tasks": {
                  "defaultTasks": []
              },
              "autoPayments": {
                  "policy": []
              },
              "active": true,
              "cleaningStatus": {
                  "active": true
              }
          },
          "sales": {
              "salesService": {
                  "atPhones": []
              }
          },
          "receptionistsService": {
              "receptionDesk": {
                  "atPhones": [],
                  "ittt": []
              },
              "screening": {
                  "checklist": []
              }
          },
          "calendarRules": {
              "defaultAvailability": "AVAILABLE",
              "advanceNotice": {
                  "bookingCom": {
                      "isCutOffHoursEnabled": false
                  },
                  "expedia": {
                      "isCutOffHoursEnabled": false
                  },
                  "airbnb2": {
                      "isCutOffHoursEnabled": true
                  },
                  "directBookings": {
                      "isCutOffHoursEnabled": false
                  },
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "bookingWindow": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "preparationTime": {
                  "updatedAt": "2023-06-18T10:51:17.031Z"
              },
              "defaultAvailabilityUpdatedAt": "2023-06-18T10:51:17.034Z",
              "rentalPeriods": []
          },
          "type": "MTL_CHILD",
          "tags": [],
          "owners": [],
          "amenities": [
              "Refrigerator",
              "Stove",
              "Outdoor pool"
          ],
          "amenitiesNotIncluded": [],
          "useAccountRevenueShare": true,
          "netIncomeFormula": "host_payout",
          "commissionFormula": "net_income",
          "ownerRevenueFormula": "net_income - pm_commission",
          "useAccountAdditionalFees": true,
          "taxes": [],
          "useAccountTaxes": true,
          "markups": {},
          "useAccountMarkups": true,
          "active": true,
          "preBooking": [],
          "accommodates": 2,
          "bathrooms": 1,
          "defaultCheckInTime": "15:00",
          "defaultCheckOutTime": "10:00",
          "propertyType": "Apartment",
          "roomType": "Entire home/apt",
          "address": {
              "full": "28 Makepeace Ave, London N6 6EJ, UK",
              "lng": -0.1502321,
              "lat": 51.5643541,
              "street": "Makepeace Avenue 28",
              "city": "London",
              "country": "United Kingdom",
              "zipcode": "N6 6EJ",
              "state": "England"
          },
          "pictures": [
              {
                  "_id": "648ee1a5aafd48004b662e85",
                  "original": "https://assets.guesty.com/image/upload/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "thumbnail": "https://assets.guesty.com/image/upload/c_fit,h_200/v1687085461/production/648edf7eaafd48004b661e11/rgym5ovwbo8airokarxg.jpg",
                  "height": 1687,
                  "width": 3000,
                  "size": 549587
              }
          ],
          "nickname": "Double Room (2)",
          "title": "Spacious Double Bed Room with Free wifi!",
          "mtl": {
              "c": [],
              "hdb4": 14,
              "lmcn": false,
              "_id": "648ee1a5aafd48004b662f4c",
              "p": "648ee1a5aafd48004b662e84"
          },
          "isListed": true,
          "accountId": "648edf7eaafd48004b661e11",
          "timezone": "Europe/London",
          "listingRooms": [],
          "customFields": [],
          "offeredServices": [],
          "occupancyStats": [],
          "__v": 0,
          "picturesManagedFromParent": true,
          "createdAt": "2023-06-18T10:51:17.224Z",
          "lastUpdatedAt": "2023-06-18T10:51:17.224Z",
          "integrations": [],
          "pendingTasks": [],
          "importedAt": "2023-06-18T10:51:17.224Z",
          "accountTaxes": []
      }
  ],
  "title": "Listings Report",
  "fields": "",
  "limit": 25,
  "skip": 0,
  "count": 8
} as const;

export const PackagesData: SubscriptionPackage[] = [
  {
    id: 1,
    name: "Basic",
    price: 500,
    currency: {
      code: "usd",
      symbol: "$",
    },
    frequency: Frequencies.Monthly,
    title: "Promotes 15 listings",
    description: "Up to 70,000 ad views (impressions) \nUp to 2,000 targeted listing views on your website.",
    maxListings: 15,
    checkoutUrl: getCheckoutUrl(1),
  },
  {
    id: 2,
    name: "Great Value",
    price: 1000,
    currency: {
      code: "usd",
      symbol: "$",
    },
    frequency: Frequencies.Monthly,
    title: "Promotes 15 listings",
    description: "Up to 140,000 ad views (impressions) \nUp to 4,000 targeted listing views on your website.",
    maxListings: 15,
    checkoutUrl: getCheckoutUrl(2),
  },
];
