import { Reducer } from 'redux';

import { getUnitsByPropertyId } from './routines';
import { UnitsState } from './types';

const initialState: UnitsState = {
  loading: false,
  selectedUnit: undefined,
  units: [],
};

const reducer: Reducer<UnitsState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case getUnitsByPropertyId.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    // success
    case getUnitsByPropertyId.SUCCESS: {
      return { ...state, units: action.payload };
    }
    // Failure

    case getUnitsByPropertyId.FAILURE: {
      return { ...state, loading: false, errors: action.payload, units: [] };
    }

    // Fulfill

    case getUnitsByPropertyId.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as unitsReducer };
