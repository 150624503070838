import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../utils/styledComponents';

const Or = styled.div`
  display: flex;
  width: 30px;
  justify-content: center;
  height: 50px;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
`;

const LineDividerItem = styled(Grid as React.FunctionComponent<GridProps>)`
  padding-top: 25px;
  border-color: #dadada !important;
`;

const StyledHR = styled.div`
  display: flex;
  border-bottom: solid thin ${({ theme }) => theme.colors.grey[250]};
`;

const OrDivider: React.FunctionComponent<{}> = props => (
  <Grid container={true} justify="center" alignContent="center">
    <LineDividerItem item={true} xs={true}>
      <StyledHR />
    </LineDividerItem>
    <Or>or</Or>
    <LineDividerItem item={true} xs={true}>
      <StyledHR />
    </LineDividerItem>
  </Grid>
);

export default OrDivider;
