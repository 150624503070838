import { get } from 'lodash';
import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { addGTMDataLayer } from '../../utils/GoogleTagManager';
import { formatName } from '../../utils/listingUrlHelper';
import { enqueueSnackbar } from '../notifications/routines';
import { fetchOrganisation, sendBroadEnquiry } from './routines';
import { ApiResponse } from './types';

function* handleFetchOrganisation(action: AnyAction) {
  try {
    const { organisationId, organisationName } = action.payload;
    const url = organisationId ? `/v3/organisations/${organisationId}/agency` : `/v3/organisations/name/${formatName(organisationName, true)}/agency`;
    const res: ApiResponse = yield call(callApi, "get", url);
    yield put(fetchOrganisation.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchOrganisation.failure(errorHandler(err.response)));
    } else {
      yield put(fetchOrganisation.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchOrganisation.fulfill());
  }
}

function* handleSendBroadEnquiry(action: AnyAction) {
  try {
    const { payload } = action;
    const res: ApiResponse = yield call(callApi, "POST", `/anonymous/broad/enquiry`, {
      data: { data: payload }
    });

    yield put(sendBroadEnquiry.success(res.data.data));

    if (payload.agentId) {
      addGTMDataLayer({
        data: { organisation: get(payload, 'organisationId', undefined), agent: payload.agentId },
        event: "agent_broad_enquiry_submit",
        page: "agent_profile",
      });
    } else {
      addGTMDataLayer({
        data: { organisation: get(payload, 'organisationId', undefined) },
        event: "agency_broad_enquiry_submit",
        page: "agency_profile",
      });
    }

  } catch (err) {
    if (err.response) {
      yield put(sendBroadEnquiry.failure(errorHandler(err.response)));
    } else {
      yield put(sendBroadEnquiry.failure("An uknown error occured"));
    }
  } finally {
    yield put(sendBroadEnquiry.fulfill());
  }
}

function* handleShowngErrors(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* listingErrorWatcher() {
  yield takeEvery([
    fetchOrganisation.FAILURE,
    sendBroadEnquiry.FAILURE
  ],
    handleShowngErrors);
}

function* fetchOrganisationoWatcher() {
  yield takeEvery(fetchOrganisation.TRIGGER, handleFetchOrganisation);
}

function* sendBroadEnquiryWatcher() {
  yield takeEvery(sendBroadEnquiry.TRIGGER, handleSendBroadEnquiry);
}

export function* organisationSaga() {
  yield all([
    fork(fetchOrganisationoWatcher),
    fork(sendBroadEnquiryWatcher),
    fork(listingErrorWatcher),
  ]);
}
