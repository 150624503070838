import React from 'react';

import { Paper } from '@material-ui/core';

import styled from '../../utils/styledComponents';

interface AvatarProps {
  image: string;
}

const StyledPaper = styled(Paper)`
  && {
    background-color: #F7F7F7;
    padding: 10px;
    margin: auto;
    border: 0;
    border-radius: 10px;
    box-shadow: none;
    display: flex;
  }
`;

const Avatar = styled.div<AvatarProps>`
  ${({ image }) => `background: url("${image}")`};
  background-color: #ccc;
  background-position: center;
  background-size: cover;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const UnitInfo = styled.div`
  align-self: center;
  text-align: left;
`;

const BuildingName = styled.h2`
  color: #4C4C4E;
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  margin: 0;`;

const UnitInfoText = styled.p`
  margin: 0;
  color: #4C4C4E;
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme }) => theme.fontSizes.buttonText};
`;

export interface UnitProps {
  image: string;
  address: string;
  buildingName: string;
  apartmentName: string;
}

const AvailableUnit: React.FunctionComponent<UnitProps> = ({
  image,
  address,
  buildingName,
  apartmentName,

}) => {
  return (
    <StyledPaper elevation={0}>
      {!!image && <Avatar image={image} />}
      <UnitInfo>
        <UnitInfoText>{address}</UnitInfoText>
        <BuildingName>{buildingName}</BuildingName>
        <UnitInfoText>{apartmentName}</UnitInfoText>
      </UnitInfo>
    </StyledPaper>
  );
};

export default AvailableUnit;
