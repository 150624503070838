import React from 'react';
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import { IconProps } from '../icons';

const MenuIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon
    {...props}
    viewBox="0 0 20 20"
    style={{
      width: 16,
      height: 16,
    }}
  >
    <svg width={20} height={20} {...props}>
      <g fill={nativeColor} fillRule="nonzero">
        <path d="M0 0h20v2H0zM0 9h20v2H0zM0 18h20v2H0z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default MenuIcon;
