import { Field, Form, InjectedFormikProps } from "formik";
import { transparentize } from "polished";
import * as React from "react";

import styled from "../../utils/styledComponents";
import Button from "../buttons/Button";
import TextInput from "../inputs/TextInput";

export interface LoginFormValues {
  password: string;
  email: string;
}

const FormFieldsWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  && {
    color: #000 !important;
    border-color: #dadada;
    &:hover {
      background: ${transparentize("0.25", "#fff")}!important;
      border-color: ${({ theme }) => theme.colors.grey3} !important;
      color: ${({ theme }) => theme.colors.black} !important;
      border-size: 2px !important;
    }
  }
`;

export const LoginForm: React.FunctionComponent<
  InjectedFormikProps<{}, LoginFormValues>
> = props => {
  const { isSubmitting } = props;
  return (
    <Form>
      <FormFieldsWrapper>
        <Field type="email" label="Email" name="email" component={TextInput} />
        <Field
          type="password"
          label="Password"
          name="password"
          component={TextInput}
        />
      </FormFieldsWrapper>
      <StyledButton
        fullWidth={true}
        disabled={isSubmitting}
        loading={isSubmitting}
        type="submit"
      >
        Login
      </StyledButton>
    </Form>
  );
};

export default LoginForm;
