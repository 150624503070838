import parser from "html-react-parser";
import _, { get, has, isArray, isEmpty, omit } from "lodash";
import React, { FunctionComponent } from "react";

import { Grid, PaperProps } from "@material-ui/core";

import FlowImage, { ImageSize } from "../../components/ui/FlowImage";
import { Listing } from "../../store/listings/types";
import { DEFAULT_LOCALE_SETTINGS } from "../../utils/defaultLocaleSettings";
import { formatName } from "../../utils/listingUrlHelper";
import { formatPropertyAmountByLocale } from "../../utils/numberFormatter";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import Heading from "../typography/Heading";
import Title from "../typography/Title";
import Paper from "../ui/Paper";
import StarRating from "../ui/StarRating";
import ExtraWidget from "../widgets/ExtraWidget";
import { getCoverImage } from "../../utils/imageUrls";
import { OrganisationColors } from "../../store/organisation";
import {
  getOrganisationColor,
  getWhiteLabelOrganisationColors,
} from "../../utils/whiteLabel";
import { getUnitPrice } from "../../utils/units.helper";
import { isNumeric } from "../../utils/general";

const StyledContainer = styled.div`
  padding: 0px 5px;
  ${mediaDown.sm`margin-left: 0px; padding: 0px`}
`;

const StyledPaper = styled((props: PaperProps & { isExclusive?: boolean }) => (
  <Paper {...omit(props, ["isExclusive"])} />
))`
  && {
    ${({ isExclusive, theme }) =>
      isExclusive
        ? `border: 1px solid ${theme.colors.orange};`
        : `background-color: ${theme.colors.white}; border-radius: 20px; padding: 0px 0px 10px 0px; margin-top: 20px;`}
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;

const HeaderWrapper = styled.div`
  padding: 10px;
  height: 189px;
  position: relative;
`;

const ExclusiveDealWrapper = styled.div`
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 5px 5px 0 0;
  padding: 4px;
  width: 121px;
  margin-left: 20px;
`;

const ExclusiveDealText = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`;

const Location = styled(Heading)`
  && {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    color: ${({ theme }) => theme.colors.grey2};
    margin-top: -10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const PricingText = styled.p`
  && {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.MS700};
    text-align: right;
    margin: 0;
    display: block;
  }
`;

const StyledTitle = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
    margin-top: -7.5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const PricingValue = styled(StyledTitle)`
  && {
    font-size: 16px;
    margin: 0px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StartingWrapper = styled.div<{ orgColors?: OrganisationColors }>`
  background-color: ${({ theme, orgColors }) =>
    getOrganisationColor(orgColors, "primaryColor", theme.colors.orange)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  border-radius: 10px;
  padding: 5px;
  top: 20px;
  right: 20px;
`;

const GridItems = styled(Grid)`
  display: flex;
`;
const DescriptionContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Description = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey2};
    margin-left: 13px;
    margin-right: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    ${mediaUp.sm` white-space: nowrap;`}
    ${mediaDown.sm`margin-left: 10px;`}
    & > p {
      padding: 0;
      margin: 0;
    }
  }
`;

const ExtraWidgetWrapper = styled.div`
  margin: 9.5px 0px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey1};
`;

const WidgetPadding = styled.div`
  margin: 0px 13px;
  ${mediaDown.sm`margin: 0px; margin: 0px 7.5px`}
`;

const TextWrapper = styled.div`
  margin: 0px 13px;
  ${mediaDown.sm`margin:0px 10px`}
  width: 97%;
`;

const StyledImageBackground = styled.div<any>`
  height: 189px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grey1};
  background-image: url("${({ image }) => image}");
  background-size: cover;
  width: 100%;
  background-position: center;
`;

const LandlordThumbnail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 10px;
  margin-right: 5px;
  position: absolute;
`;

const ThumbnailWrapper = styled.div<{ hasImage: boolean }>`
  margin-top: -20px;
  position: absolute;

  ${({ hasImage }) => (hasImage ? "" : "display: none;")}
`;

interface OwnProps {
  listingData: Listing;
  organisation?: object;
}

type AllProps = OwnProps;

const ListingItem: FunctionComponent<AllProps> = (props) => {
  const { listingData } = props;
  const organisation = get(
    listingData,
    "organisationObject",
    get(props, "organisation")
  );

  const promoText = "";
  const isExclusiveDeal = !isEmpty(promoText);
  const colors = getWhiteLabelOrganisationColors(organisation);
  const handleListingItemClick = () => {
    const { listingType, listingSector, address, _id } = listingData;
    const { city, suburb, province } = address;

    const url =
      !listingSector ||
      !listingType ||
      !listingSector ||
      !listingType ||
      !suburb ||
      !city ||
      !province
        ? `/listings/${_id}`
        : `/listings/${listingSector}/for-${listingType}/${formatName(
            suburb.toLowerCase()
          )}/${formatName(city.toLowerCase())}/${formatName(
            province.toLowerCase()
          )}/${_id}`;

    window.location.href = url;
  };

  const imageSize: ImageSize = {
    height: "60px",
    width: "60px",
  };

  const _organisation = !isEmpty(organisation)
    ? organisation
    : listingData.organisationObject;

  const landlordLogo = get(
    _organisation,
    "logoUrl",
    get(
      listingData,
      "landlord.logo.url",
      get(listingData, "syndicator.agencyLogo", "")
    )
  );

  const landlordImage = landlordLogo ? landlordLogo.replace(/'/g, " ") : "";

  const minPrice = get(
    listingData,
    "MinPrice",
    get(listingData, "pricedFrom", 0)
  );

  const units = isArray(listingData.units) ? listingData.units : [];
  const minUnit = units.find((unit) => unit.rentalAmount === minPrice);
  const amount = getUnitPrice(minUnit) || minPrice;

  const localeSettings = _.get(
    _organisation,
    "settings.locale",
    DEFAULT_LOCALE_SETTINGS
  );

  let formattedAmount = amount;
  if (isNumeric(amount)) {
    formattedAmount = formatPropertyAmountByLocale(
      listingData,
      localeSettings,
    );
  }

  const address =
    has(listingData, "address.suburb") && has(listingData, "address.city")
      ? ` in ${listingData.address.suburb}, ${listingData.address.city}`
      : "";
  const hasUnits = get(listingData, "hasUnits") || false;
  const propertyTypeAndAddress = `${hasUnits ? "Multiple " : ""} ${_.capitalize(
    listingData.propertyType
  )} ${
    hasUnits ? "Units " : ""
  } for ${listingData.listingType.toLowerCase()}${address}`;

  const coverImageUrl = !isEmpty(listingData.coverImageUrl)
    ? listingData.coverImageUrl
    : getCoverImage(listingData);
  const shortDescription = get(listingData, "shortDescription", "");
  return (
    <StyledContainer {...props}>
      {isExclusiveDeal ? (
        <ExclusiveDealWrapper>
          <ExclusiveDealText>{promoText}</ExclusiveDealText>
        </ExclusiveDealWrapper>
      ) : null}
      <StyledPaper
        isExclusive={isExclusiveDeal}
        onClick={handleListingItemClick}
      >
        <HeaderWrapper>
          <StartingWrapper orgColors={colors}>
            {listingData.hasUnits ? <PricingText>Starting at</PricingText> : null}
            <PricingValue>{formattedAmount}</PricingValue>
          </StartingWrapper>
          <LandlordThumbnail>
            <ThumbnailWrapper hasImage={!isEmpty(landlordImage)}>
              {landlordImage && (
                <FlowImage
                  image={landlordImage}
                  size={imageSize}
                  hasBorder={true}
                />
              )}
            </ThumbnailWrapper>
          </LandlordThumbnail>
          <StyledImageBackground image={coverImageUrl} />
        </HeaderWrapper>
        <Grid container={true}>
          <GridItems item={true} xl={9} lg={9} md={9} sm={8} xs={8}>
            <TextWrapper>
              <Location>{propertyTypeAndAddress}</Location>
              <StyledTitle>{listingData.title}</StyledTitle>
            </TextWrapper>
          </GridItems>
          {!isEmpty(listingData.featureRating) && false && (
            <GridItems item={true} xl={3} lg={3} md={3} sm={4} xs={4}>
              <Grid container={true} justify="flex-end">
                <StarRating
                  ratingText="Features rating"
                  ratings={listingData.featureRating!}
                />
              </Grid>
            </GridItems>
          )}
        </Grid>

        {!isEmpty(shortDescription) && (
          <DescriptionContainer>
            <Description>{parser(shortDescription)}</Description>
          </DescriptionContainer>
        )}

        {!isEmpty(listingData.flowExtras) && false && (
          <ExtraWidgetWrapper>
            <WidgetPadding>
              <ExtraWidget
                allowItemClick={false}
                data={listingData.flowExtras!}
              />
            </WidgetPadding>
          </ExtraWidgetWrapper>
        )}
      </StyledPaper>
    </StyledContainer>
  );
};

export default ListingItem;
