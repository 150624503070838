import isEmpty from "lodash/isEmpty";
import React from "react";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";

import styled from "../../utils/styledComponents";
import AgencyLogo from "../ui/AgencyLogo";

const AgentAvator = styled.div<{ avator: string }>`
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 30px;
  border: solid 2px ${({ theme }) => theme.colors.grey1};
  margin-right: 15px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ avator }) => avator});
`;

const AgentSection = styled.div`
  && {
    background-color: ${({ theme }) => theme.colors.grey1};
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
  }
`;

const AgentAvatorPlaceHolder = styled(AccountCircleIcon)`
  && {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const LandlordLogoPlaceHolder = styled(BusinessIcon)`
  && {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const AgentName = styled.span`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    line-height: 17px;
  }
`;

const AgentInfo = styled.div`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    padding-right: 7px;
    width: 160px;

    @media screen and (max-width: 1230px) {
      width: 145px;
    }

    @media screen and (max-width: 1151px) {
      width: 130px;
    }

    @media screen and (max-width: 1108px) {
      width: 125px;
    }

    @media screen and (max-width: 1092px) {
      width: 101px;
    }

    @media screen and (max-width: 1023px) {
      width: 80px;
    }

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
`;

const Description = styled.span`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 11px;
    line-height: 13px;
  }
`;

const Section = styled.div`
  && {
    margin-right: 3px;
    display: flex;
    align-items: center;
  }
`;

interface OwnProps {
  agencyLogo: string;
  agentAvator: string;
  agentName: string;
  organisationName: string;
  slug?: string;
  agentProfile?: any;
}

const PropertyAgentWidget: React.FunctionComponent<OwnProps> = (props) => {
  const { agencyLogo, agentAvator, agentName, organisationName } = props;
  // const agentId = get(agentProfile, 'id', undefined);
  // const url = agentId ? `/agent/${slug || formatName(organisationName)}/${formatName(agentName)}/${agentId}` : `/agency/${slug || formatName(organisationName)}`;
  const strip = (logo: string) => (logo ? logo.replace(/'/g, " ") : "");

  return (
    <AgentSection>
      <Section style={{ overflow: "hidden" }}>
        <AgentAvator avator={strip(agentAvator)}>
          {isEmpty(agentAvator) ? <AgentAvatorPlaceHolder /> : null}
        </AgentAvator>
        <AgentInfo>
          <AgentName>{agentName}</AgentName>
          <Description>{organisationName}</Description>
        </AgentInfo>
      </Section>
      <Section>
        {!isEmpty(agencyLogo) ? (
          <AgencyLogo
            src={strip(agencyLogo)}
            height={50}
            width={80}
            radius={5}
            borderColor="white"
          />
        ) : (
          <LandlordLogoPlaceHolder />
        )}
      </Section>
    </AgentSection>
  );
};

export default PropertyAgentWidget;
