import { History, Location } from "history";
import Cookies from "js-cookie";
import { get, includes, isEmpty, omit } from "lodash";
import queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { Link, NavLink, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

// import { StreamChat } from 'stream-chat';
import {
  Button,
  Dialog,
  DialogContent,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { ListItemProps } from "@material-ui/core/ListItem";

import loginImage from "../assets/images/illustrations/image-25.png";
import profileImage from "../assets/images/illustrations/profile.png";
import modalBg from "../assets/images/modal-bg.png";
import DropDownMenuButton from "../components/buttons/DropDownMenuButton";
// import MessageCount from '../components/chats/MessageCount';
import DialogWithColumns from "../components/dialogs/DialogWithColumns";
import DialogWithImage from "../components/dialogs/DialogWithImage";
import { DashboardIcon, LogoutIcon, ProfileIcon } from "../components/icons";
import FlowFontAwesomeIcon from "../components/icons/FlowFontAwesomeIcon";
import NavBar from "../components/navigation/NavBar";
import CookieSnackBar from "../components/snackbar/CookieSnackBar";
import ListingsFilterFormContainer from "../containers/ListingsFilterFormContainer";
import ForgotPasswordIndexPage from "../pages/forgotPassword/index";
import LoginOptionsPage from "../pages/login/loginOptionsPage";
import ProfileIndexPage from "../pages/profile/index";
import RegisterOptionsPage from "../pages/register/registerOptionsPage";
import VerificationIndexPage from "../pages/verify/index";
import { ApplicationState } from "../store";
import { AuthenticatedUser, logout } from "../store/auth";
import {
  DialogsState,
  toggleForgotPasswordDialog,
  toggleLoginDialog,
  toggleLoginOptionsDialog,
  toggleOTPVerificationDialog,
  toggleProfileDialog,
  toggleRegisterDialog,
  toggleRegisterOptionsDialog,
} from "../store/dialogs";
import { EnquiryProps, Listing } from "../store/listings";
import { Organisation } from "../store/organisation";
import styled, { mediaDown, mediaUp } from "../utils/styledComponents";
import { isVerificationRequired } from "../utils/verification";
import LoginPageContainer from "./LoginPageContainer";
import RegisterPageContainer from "./RegisterPageContainer";

const Container = styled((props: GridProps) => (
  <Grid container={true} {...props} />
))`
  height: 100%;
`;

const NavBarGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  height: ${({ theme }) => theme.heights.navbar};
  z-index: 999;
`;

const PageGrid = styled((props: GridProps & PageGridProps) => (
  <Grid {...omit(props, ["isListingsPage"])} />
))`
  ${({ isListingsPage }) => isListingsPage && mediaUp.sm`border-top: none;`}
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContentAreaContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  position: relative;
  height: 100%;
`;

const MobileMenu = styled(Drawer as React.FunctionComponent<DrawerProps>)`
  ${mediaUp.md`display: none;`}
  ${mediaDown.sm`
      display: none;
    `}
  ul {
    padding-top: 25px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuButton = styled(Button)`
  && {
    text-transform: none;
    font-family: ${({ theme }) => theme.fonts.MS500};
    color: ${({ theme }) => theme.colors.grey4};
    border-radius: 10px;
    min-width: 78px !important;
    &&:hover {
      background: none;
    }
  }
`;

const MobileListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 20px;
    margin-bottom: 3px;
  }
`;

// const HomeMobileListItemIcon = styled(ListItemIcon)`
//   && {
//     min-width: 20px;
//     margin-top: 4px;
//   }
// `;
const MessagesMobileListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 20px;
    margin-top: 4px;
  }
`;

const PartnersMobileListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 15px;
    margin-bottom: 7px;
  }
`;
const MobilePartnersListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 15px;
    margin-bottom: 4px;
    font-size: 1.15em;
  }
`;

const MenuButtonHighlighted = styled(MenuButton)`
  && {
    text-transform: none;
    background: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
    :hover {
      background: ${({ theme }) => theme.colors.orange} !important;
    }
  }
`;

const StyledMenuLink = styled(Link)`
  padding: 6px 8px;
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.grey4};
  white-space: nowrap;
`;

// const ShopMenuListItem = styled(
//   ListItem as React.FunctionComponent<ListItemProps>
// )`
//   && {
//     font-size: 0.7rem;
//     margin-bottom: 3px;
//     padding-right: 1px;
//     //padding: 6px 8px;
//   }
// `;

const MenuListItem = styled(ListItem as React.FunctionComponent<ListItemProps>)`
  && {
    font-size: 0.7rem;
    padding-right: 1px;
    //margin-bottom:3px;
    //padding: 6px 8px;
  }
`;
const RightPartnerMenuListItem = styled(
  ListItem as React.FunctionComponent<ListItemProps>
)`
  && {
    font-size: 0.875rem;
    margin-bottom: 2px;
    padding-right: 8px;
  }
`;

const PartnersMenuListItem = styled(
  ListItem as React.FunctionComponent<ListItemProps>
)`
  && {
    font-size: 0.875rem;
    margin-top: 2px;
    //padding: 6px 8px;
  }
`;

const MobileMenuListItem = styled(MenuListItem)`
  && {
    padding-right: 30px;
    padding-left: 15px;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

const MobileListItemText = styled(ListItemText)`
  && {
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

const MobileLoginListItemText = styled(ListItemText)`
  && {
    font-size: 0.875rem !important;
    margin-left: 21px !important;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

const MobilePartnersListItemText = styled(ListItemText)`
  && {
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.grey4};
    padding-left: 5px;
  }
`;
const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: url(${modalBg}) center center repeat-y;
    border-radius: 20px;
    padding: 0;
    margin: 10px;
    max-width: 720px;
    width: 100%;
    ${mediaDown.md`background: white`};
    ${mediaDown.sm`
      background: white;
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      margin: 0;
    `};
  }
`;

const StyledDialogSimple = styled(StyledDialog)`
  .MuiDialog-paper {
    background: white;
  }
`;

const StyledMenuButton = styled(MenuButton)`
  && {
    padding: 0;
    background: none !important;
    justify-content: start;
    line-height: 1;
    .MuiButton-label {
      width: auto;
      text-transform: none;
    }
    &:focus {
      background: none !important;
    }
  }
`;

const StyledIcon = styled(FlowFontAwesomeIcon).attrs(({ theme }) => ({
  color: theme.colors.grey3,
}))`
  && {
    font-size: 5px !important;
    width: 16px !important;
    margin-bottom: 2px !important;
  }
`;

interface PageGridProps {
  isListingsPage: boolean;
}

interface PropsFromState {
  authenticated: boolean;
  location: Location;
  showTitle: boolean;
  title?: string;
  user?: AuthenticatedUser;
  dialogs: DialogsState;
  enquiryInfo?: EnquiryProps;
  organisation?: Organisation;
  listing?: Listing;
}

interface PropsFromDispatch {
  logout: typeof logout;
  toggleForgotPasswordDialog: typeof toggleForgotPasswordDialog;
  toggleLoginDialog: typeof toggleLoginDialog;
  toggleProfileDialog: typeof toggleProfileDialog;
  toggleRegisterDialog: typeof toggleRegisterDialog;
  toggleLoginOptionsDialog: typeof toggleLoginOptionsDialog;
  toggleRegisterOptionsDialog: typeof toggleRegisterOptionsDialog;
  toggleOTPVerificationDialog: typeof toggleOTPVerificationDialog;
}

interface ConnectedProps {
  history: History;
  match: match;
}

interface State {
  mobileMenuOpen: boolean;
  title: string;
  dialogOpen: boolean;
  showListingsFilter: boolean;
  ChatClientLoaded: boolean;
  count: number;
  hideNav: boolean;
  referer: string;
  pathname: string;
  openCookie: boolean;
  organisation?: Organisation;
}

// const GETSTREAM_APP_KEY = process.env.REACT_APP_GETSTREAM_APP_KEY || "";
// const chatClient = new StreamChat(GETSTREAM_APP_KEY);

type AllProps = PropsFromState & PropsFromDispatch & ConnectedProps;

class NavigationContainer extends React.Component<AllProps, State> {
  public static defaultProps = {
    showTitle: true,
  };

  public state: State = {
    ChatClientLoaded: false,
    count: 0,
    dialogOpen: false,
    hideNav: false,
    mobileMenuOpen: false,
    pathname: "",
    referer: "",
    showListingsFilter: false,
    openCookie: false,
    title: "",
    organisation: undefined,
  };

  public componentDidMount() {
    // this.initChat();
    const { location } = this.props;
    if (includes(location.pathname, "reset-password")) {
      this.setState({ hideNav: true });
    }

    // Validate cookies
    const registerOrLogin =
      includes(location.pathname, "login") ||
      includes(location.pathname, "register");
    const popi = Cookies.getJSON("popiAct");
    const accepted = get(popi, "accepted", false);

    if (!accepted && !registerOrLogin) {
      setTimeout(() => {
        this.setState({ openCookie: true });
      }, 2000); // delay for 2 seconds.
    }
  }

  public componentDidUpdate() {
    const { location } = this.props;
    const { referer, pathname } = this.state;

    if (!includes(location.pathname, "reset-password") && this.state.hideNav) {
      this.setState({ hideNav: false });
    }

    if (isEmpty(referer)) {
      this.setReferer("/");
    }

    if (location.pathname !== pathname) {
      this.setReferer(pathname);

      this.setState({
        pathname: location.pathname,
      });
    }

    // this.initChat();
  }

  public acceptCookie = (event: React.SyntheticEvent) => {
    event.preventDefault();
    Cookies.set(
      "popiAct",
      { accepted: true },
      { expires: 365, secure: true, sameSite: "strict" }
    );
    this.setState({ openCookie: false });
  };

  public setReferer(referer: string) {
    localStorage.setItem("referer", referer);

    this.setState({
      referer,
    });
  }

  // public async initChat() {
  //   const { user } = this.props;
  //   if (user && user.chatToken) {
  //     chatClient.disconnect();
  //     await chatClient.setUser(
  //       {
  //         id: user.id,
  //         image: user.profileImage && user.profileImage.publicUrl,
  //         name: user.displayName,
  //       },
  //       user.chatToken
  //     );

  //     if (chatClient.user) {
  //       const count = has(chatClient, "user.total_unread_count")
  //         ? chatClient.user.total_unread_count
  //         : 0;
  //       if (count !== this.state.count) {
  //         this.setState({ ChatClientLoaded: true, count });
  //       }
  //     }
  //   }
  // }

  public closeLoginDialog = () => {
    this.props.toggleLoginDialog(false);
  };

  public closeRegisterDialog = () => {
    this.props.toggleRegisterDialog(false);
  };

  public closeProfileDialog = () => {
    this.props.toggleProfileDialog(false);
  };

  public closeForgotPasswordDialog = () => {
    this.props.toggleForgotPasswordDialog(false);
  };

  public closeLoginOptionsDialog = () => {
    this.props.toggleLoginOptionsDialog(false);
  };

  public closeRegisterOptionsDialog = () => {
    this.props.toggleRegisterOptionsDialog(false);
  };

  public loginButtonClicked = () => {
    const { location, history } = this.props;
    if (includes(location.pathname, "register")) {
      history.push("/login");
    } else {
      // **this.props.toggleLoginOptionsDialog(true); login toggle not needed ref storyId:6664*/
      this.props.toggleLoginDialog(true);
    }
  };

  public registerButtonClicked = () => {
    const { location, history } = this.props;
    if (includes(location.pathname, "login")) {
      history.push("/register");
    } else {
      this.props.toggleRegisterOptionsDialog(true);
    }
  };

  public closeOTPVerificationDialog = () => {
    this.props.toggleOTPVerificationDialog(false);
    this.handleLogout();
  };

  public render() {
    const {
      children,
      authenticated,
      showTitle,
      title,
      user,
      dialogs,
      organisation,
      listing,
    } = this.props;
    const queryObject: any = queryString.parse(
      decodeURI(window.location.search)
    );
    const orgId = get(
      organisation,
      "_id",
      get(queryObject, "organisation", undefined)
    );
    const organisationName = get(organisation, "slug", undefined);
    const listingType = get(listing, "listingType", undefined);
    const listingSector = get(listing, "listingSector", undefined);

    const requireVerification = isVerificationRequired();
    const link =
      organisationName && listingType && listingSector
        ? `/listings/${organisationName}/${listingSector}/for-${listingType}`
        : organisation || !isEmpty(orgId)
        ? `/listings/${organisationName}`
        : `#`;

    // const shopUrl = `${process.env.REACT_APP_FLOW_SHOP_URL}`;
    const partnersUrl = `${process.env.REACT_APP_STATIC_PAGES_URL}/partners`;
    const showListingsFilterBlock =
      this.props.title!.toLowerCase() === "listings";

    const modalDescription =
      "Find properties to buy or rent, get great deals for your move from Flow Partners.";

    const RightMenu = () => (
      <MenuWrapper>
        {!authenticated && false && (
          <>
            {/* <StyledMenuLink style={{ display: "inline-flex" }} to="/listings?listingType=sale">
              <FindIcon
                nativeColor="#868788"
                style={{ width: "1rem", height: "1.4rem" }}
                viewBox="0 1 26 24"
              />
              &nbsp;Find a property
            </StyledMenuLink>
            {/* <StyledMenuLink
              style={{ display: "inline-flex", paddingRight: "1px" }}
              to="/landlords"
            >
              <ListIcon
                nativeColor="#868788"
                style={{ width: "1rem", height: "1.4rem" }}
                viewBox="0 1 26 24"
              />
              &nbsp;List a rental
            </StyledMenuLink>
            <ShopMenuListItem button={false}>
              <StyledIcon name="store" />
              <MobileListItemText disableTypography={true}>
                <a href={shopUrl} target="_blank" rel="noopener noreferrer">
                  &nbsp;Shop
                </a>
              </MobileListItemText>
            </ShopMenuListItem> */}
            <PartnersMenuListItem button={false}>
              <PartnersMobileListItemIcon>
                <StyledIcon name="gift" />
              </PartnersMobileListItemIcon>
              <MobileListItemText disableTypography={true}>
                <a href={partnersUrl} target="_blank" rel="noopener noreferrer">
                  &nbsp;Partners
                </a>
              </MobileListItemText>
            </PartnersMenuListItem>

            {/* <StyledMenuLink
              style={{ display: "inline-flex", marginBottom: "2px" }}
              to="/login"
            >
              <MessagesMobileListItemIcon>
                <StyledIcon name="envelope" />
              </MessagesMobileListItemIcon>
                Enquiries
           </StyledMenuLink> */}

            <MenuButton onClick={this.loginButtonClicked}>Login</MenuButton>
            <MenuButtonHighlighted onClick={this.registerButtonClicked}>
              Sign up
            </MenuButtonHighlighted>
          </>
        )}
        {authenticated &&
          false &&
          user &&
          (!requireVerification || get(user, "status") === "Verified") && (
            <>
              {/* <StyledMenuLink style={{ display: "inline-flex" }} to="/home">
                <HomeMobileListItemIcon>
                  <StyledIcon name="home" />
                </HomeMobileListItemIcon>
                Home
              </StyledMenuLink> */}
              {/* <StyledMenuLink
                style={{ display: "inline-flex", paddingRight: "1px" }}
                to="/listings?listingType=sale"
              >
                <FindIcon
                  nativeColor="#868788"
                  style={{ width: "1rem", height: "1.4rem" }}
                  viewBox="0 1 26 24"
                />
                &nbsp;Find a property
              </StyledMenuLink> */}
              {/* <MenuListItem button={false}>
                <MobileListItemIcon>
                  <StyledIcon name="store" />
                </MobileListItemIcon>
                <MobileListItemText disableTypography={true}>
                  <a href={shopUrl} target="_blank" rel="noopener noreferrer">
                    Shop
                  </a>
                </MobileListItemText>
              </MenuListItem> */}
              <RightPartnerMenuListItem button={false}>
                <MobileListItemIcon>
                  <StyledIcon name="gift" />
                </MobileListItemIcon>
                <MobileListItemText disableTypography={true}>
                  <a
                    href={partnersUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Partners
                  </a>
                </MobileListItemText>
              </RightPartnerMenuListItem>
              {/* <StyledMenuLink
                style={{ display: "inline-flex", marginBottom: "2px" }}
                to="/enquiries"
              >
                <MessagesMobileListItemIcon>
                  <StyledIcon name="envelope" />
                </MessagesMobileListItemIcon>
                <MessageCount
                  count={this.state.count}
                  ChatClientLoaded={this.state.ChatClientLoaded}
                />
              </StyledMenuLink> */}
              <StyledMenuLink
                style={{ display: "inline-flex", marginBottom: "2px" }}
                to="/profile"
              >
                <ProfileIcon
                  nativeColor="#868788"
                  style={{ width: "1rem", height: "1.4rem" }}
                  viewBox="0 1 46 43"
                />
                &nbsp;Profile
              </StyledMenuLink>
            </>
          )}

        {authenticated && (
          <DropDownMenuButton label="" placement="bottom-end">
            <>
              {/* <MenuListItem button={true}>
              <MobileListItemText disableTypography={true}>
                <a
                  href="https://medium.com/flow-rent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </MobileListItemText>
            </MenuListItem>
            <MenuListItem button={true}>
              <MobileListItemText disableTypography={true}>
                <a
                  href="https://findings.flow.rent/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FlowFindings
                </a>
              </MobileListItemText>
            </MenuListItem> */}
              {authenticated && (
                <MenuListItem button={false}>
                  <MobileListItemText disableTypography={true}>
                    <StyledMenuButton onClick={this.handleLogout}>
                      <LogoutIcon
                        nativeColor="#868788"
                        style={{ width: "1rem", height: "1.4rem" }}
                        viewBox="0 0 18 24"
                      />
                      &nbsp;Sign out
                    </StyledMenuButton>
                  </MobileListItemText>
                </MenuListItem>
              )}
            </>
          </DropDownMenuButton>
        )}
        <>
          <DialogWithColumns
            open={dialogs.openLoginOptionsDialog}
            onClose={this.closeLoginOptionsDialog}
            closeDialog={this.closeLoginOptionsDialog}
          >
            <LoginOptionsPage />
          </DialogWithColumns>

          <DialogWithColumns
            open={dialogs.openRegisterOptionsDialog}
            onClose={this.closeRegisterOptionsDialog}
            closeDialog={this.closeRegisterOptionsDialog}
          >
            <RegisterOptionsPage />
          </DialogWithColumns>

          <DialogWithImage
            image={loginImage}
            open={dialogs.openLoginDialog}
            onClose={this.closeLoginDialog}
            closeDialog={this.closeLoginDialog}
            description={modalDescription}
          >
            <LoginPageContainer />
          </DialogWithImage>

          <DialogWithImage
            image={loginImage}
            open={dialogs.openRegisterDialog}
            onClose={this.closeRegisterDialog}
            closeDialog={this.closeRegisterDialog}
            description={modalDescription}
          >
            <RegisterPageContainer />
          </DialogWithImage>

          <DialogWithImage
            image={profileImage}
            open={dialogs.openProfileDialog}
            onClose={this.closeProfileDialog}
            closeDialog={this.closeProfileDialog}
          >
            <ProfileIndexPage />
          </DialogWithImage>

          <DialogWithImage
            image={loginImage}
            open={dialogs.openOTPVerificationDialog}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <VerificationIndexPage />
          </DialogWithImage>

          <StyledDialogSimple
            open={dialogs.openForgotPasswordDialog}
            keepMounted={true}
            onClose={this.closeForgotPasswordDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <ForgotPasswordIndexPage />
            </DialogContent>
          </StyledDialogSimple>
        </>
      </MenuWrapper>
    );

    // let IntercomUser;

    // if (user) {
    //   const userTags = user.tags.filter(
    //     (tag: { id: string; name: string }) =>
    //       tag.name === "tenant" ||
    //       tag.name === "landlord" ||
    //       tag.name === "agent"
    //   );

    //   IntercomUser = {
    //     UserType: userTags[0] ? userTags[0].name : "not assigned",
    //     email: user.email,
    //     name: user.displayName,
    //     phone: user.contactNumber,
    //     user_id: user.id,
    //   };
    // }

    return (
      <>
        <Container>
          <Grid item={true} xs={true}>
            <ContentAreaContainer container={true} direction="column">
              <NavBarGrid item={true}>
                <NavBar
                  title={title ? title : ""}
                  showTitle={showTitle}
                  rightMenuComponent={RightMenu}
                  mobileToggle={this.handleDrawerToggle}
                  authenticated={authenticated}
                  user={user}
                  showListingsFilter={this.state.showListingsFilter}
                  showListingsFilterBlock={showListingsFilterBlock}
                  toggleListingsFilter={this.handleToggleListingsFilter}
                  hideNav={this.state.hideNav}
                  unreadMessageCount={this.state.count}
                  link={link}
                  organisation={organisation}
                />
              </NavBarGrid>
              <PageGrid isListingsPage={showListingsFilterBlock} item={true}>
                {showListingsFilterBlock && (
                  <ListingsFilterFormContainer
                    showListingsFilter={this.state.showListingsFilter}
                    toggleListingsFilter={this.handleToggleListingsFilter}
                  />
                )}
                {children}
              </PageGrid>
            </ContentAreaContainer>
          </Grid>
        </Container>
        <MobileMenu
          variant="temporary"
          anchor="right"
          open={this.state.mobileMenuOpen}
          onClose={this.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <List>
            {authenticated && (
              <></>
              // <NavLink to="/home">
              //   <MobileMenuListItem
              //     button={true}
              //     onClick={this.handleDrawerToggle}
              //   >
              //     {/* <MobileListItemIcon>
              //       <ProfileIcon style={{ fontSize: 13 }} nativeColor="#fff" />
              //     </MobileListItemIcon> */}
              //     {/* <MobileListItemText disableTypography={true}>
              //       <MobileListItemIcon>
              //         <StyledIcon name="home" />
              //       </MobileListItemIcon>
              //       Home
              //     </MobileListItemText> */}
              //   </MobileMenuListItem>
              // </NavLink>
            )}
            <NavLink to={link}>
              <MobileMenuListItem
                button={true}
                onClick={this.handleDrawerToggle}
              >
                {/* <MobileListItemIcon>
                  <FindIcon
                    nativeColor="#868788"
                    style={{ width: "1rem", height: "1.4rem" }}
                    viewBox="0 1 26 24"
                  />
                </MobileListItemIcon>
                <MobileListItemText disableTypography={true}>
                  Find a property
                </MobileListItemText> */}
              </MobileMenuListItem>
            </NavLink>
            {/* <MenuListItem button={true}>
              <MobileListItemIcon>
                <StyledIcon name="store" />
              </MobileListItemIcon>
              <MobileListItemText disableTypography={true}>
                <a href={shopUrl} target="_blank" rel="noopener noreferrer">
                  Shop
                </a>
              </MobileListItemText>
            </MenuListItem> */}
            {/* <RightPartnerMenuListItem button={false}>
              <MobileListItemIcon>
                <StyledIcon name="envelope" />
              </MobileListItemIcon>
              <MobileListItemText disableTypography={true}>
                <Link to="/enquiries" onClick={this.handleDrawerToggle}>
                  Messages
                </Link>
              </MobileListItemText> 
            </RightPartnerMenuListItem> */}
            <MenuListItem button={true}>
              <MobilePartnersListItemIcon>
                <StyledIcon name="gift" />
              </MobilePartnersListItemIcon>
              <MobilePartnersListItemText disableTypography={true}>
                <a href={partnersUrl} target="_blank" rel="noopener noreferrer">
                  Partners
                </a>
              </MobilePartnersListItemText>
            </MenuListItem>
            {/* {!authenticated && (
              <NavLink to="/landlords">
                <MobileMenuListItem
                  button={true}
                  onClick={this.handleDrawerToggle}
                >
                  <MobileListItemIcon>
                    <ListIcon
                      nativeColor="#868788"
                      style={{ width: "1rem", height: "1.4rem" }}
                      viewBox="0 1 26 24"
                    />
                  </MobileListItemIcon>
                  <MobileListItemText disableTypography={true}>
                    List a rental
                  </MobileListItemText>
                </MobileMenuListItem>
              </NavLink>
            )} */}

            {/* <MobileMenuListItem button={true}>
              <MobileListItemIcon>
                <InfoIcon style={{ fontSize: 15 }} nativeColor="#868788" />
              </MobileListItemIcon>
              <MobileListItemText disableTypography={true}>
                <a
                  href="https://medium.com/flow-rent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </MobileListItemText>
            </MobileMenuListItem> */}

            {/* <MobileMenuListItem button={true}>
              <MobileListItemIcon>
                <TickIcon style={{ fontSize: 15 }} nativeColor="#868788" />
              </MobileListItemIcon>
              <MobileListItemText disableTypography={true}>
                <a
                  href="https://findings.flow.rent/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FlowFindings
                </a>
              </MobileListItemText>
            </MobileMenuListItem> */}
            {!authenticated && (
              <>
                <NavLink to="/login">
                  <MobileMenuListItem
                    button={true}
                    onClick={this.handleDrawerToggle}
                  >
                    <MobileListItemIcon>
                      <MessagesMobileListItemIcon>
                        <StyledIcon name="envelope" />
                      </MessagesMobileListItemIcon>
                    </MobileListItemIcon>
                    <MobileListItemText disableTypography={true}>
                      Enquiries
                    </MobileListItemText>
                  </MobileMenuListItem>
                </NavLink>
                <MobileMenuListItem
                  button={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => {
                    this.handleDrawerToggle();
                    this.loginButtonClicked();
                  }}
                >
                  {false && (
                    <MobileListItemIcon>
                      <DashboardIcon nativeColor="#ffffff" />
                    </MobileListItemIcon>
                  )}
                  <MobileLoginListItemText disableTypography={true}>
                    Login
                  </MobileLoginListItemText>
                </MobileMenuListItem>
                <MobileMenuListItem
                  button={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => {
                    this.handleDrawerToggle();
                    this.registerButtonClicked();
                  }}
                >
                  <MenuButtonHighlighted style={{ marginLeft: 16 }}>
                    Sign up
                  </MenuButtonHighlighted>
                </MobileMenuListItem>
              </>
            )}
            {authenticated &&
              user &&
              (!requireVerification || user.status === "Verified") && (
                <>
                  {/* <NavLink to="/enquiries">
                    <MobileMenuListItem
                      button={true}
                      onClick={this.handleDrawerToggle}
                    >
                      <MobileListItemIcon>
                        <MessagesMobileListItemIcon>
                          <StyledIcon name="envelope" />
                        </MessagesMobileListItemIcon>
                      </MobileListItemIcon>
                      <MobileListItemText disableTypography={true}>
                        <MessageCount
                          count={this.state.count}
                          ChatClientLoaded={this.state.ChatClientLoaded}
                        />
                      </MobileListItemText>
                    </MobileMenuListItem>
                  </NavLink> */}

                  <NavLink to="/profile">
                    <MobileMenuListItem
                      button={true}
                      onClick={this.handleDrawerToggle}
                    >
                      <MobileListItemIcon>
                        <ProfileIcon
                          style={{ fontSize: 13 }}
                          nativeColor="#868788"
                        />
                      </MobileListItemIcon>
                      <MobileListItemText disableTypography={true}>
                        Profile
                      </MobileListItemText>
                    </MobileMenuListItem>
                  </NavLink>
                  <MobileMenuListItem button={true} onClick={this.handleLogout}>
                    <MenuButtonHighlighted style={{ marginLeft: 16 }}>
                      Sign out
                    </MenuButtonHighlighted>
                  </MobileMenuListItem>
                </>
              )}
          </List>
        </MobileMenu>
        <CookieSnackBar
          open={this.state.openCookie}
          onClose={this.acceptCookie}
          organisation={organisation}
        />
      </>
    );
  }

  private handleToggleListingsFilter = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.setState((prevState) => ({
      showListingsFilter: !prevState.showListingsFilter,
    }));
  };

  private handleLogout = () => {
    if (this.state.mobileMenuOpen) {
      this.handleDrawerToggle();
    }
    this.props.logout();
  };

  private handleDrawerToggle = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };
}

const mapStateToProps = ({
  router,
  auth,
  navigation,
  dialogs,
  listing,
  organisation,
}: ApplicationState) => ({
  authenticated: auth.authenticated,
  dialogs,
  enquiryInfo: listing.enquiryInfo,
  location: router.location,
  title: navigation.routeProps.title,
  user: auth.user,
  organisation: get(
    listing,
    "property.organisationObject",
    get(organisation, "organisation", undefined)
  ),
  listing: get(listing, "property", undefined),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      logout,
      toggleForgotPasswordDialog,
      toggleLoginDialog,
      toggleLoginOptionsDialog,
      toggleOTPVerificationDialog,
      toggleProfileDialog,
      toggleRegisterDialog,
      toggleRegisterOptionsDialog,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationContainer) as any
);
