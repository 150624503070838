import * as React from 'react';

import styled from '../../utils/styledComponents';

const Container = styled.div<any>`&&{
    height: 48px;
    width: 48px;
    background-color: #99b5cc38;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}`

function CookieIconSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Container {...props}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{"cookie-bite copy 2"}</title>
        <path
          d="M23.93 11.991a5.996 5.996 0 01-5.928-5.993A5.996 5.996 0 0112.01.07a6.186 6.186 0 00-3.737.6l-3.24 1.651a6.198 6.198 0 00-2.71 2.71L.678 8.261a6.218 6.218 0 00-.6 3.794l.566 3.575a6.212 6.212 0 001.742 3.42l2.567 2.567a6.19 6.19 0 003.408 1.737l3.596.57a6.184 6.184 0 003.775-.598l3.24-1.65a6.2 6.2 0 002.71-2.71l1.644-3.23a6.215 6.215 0 00.606-3.745zM8.25 17.25a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1.5-7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm7.5 6a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
          fill="#88B5D0"
          fillRule="nonzero"
        />
      </svg>
    </Container>
  )
}

export default CookieIconSVG
