import { Action } from 'redux-actions';
import { AuthDetails, Listing, SubscribePayload } from './types';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import callApi from '../../utils/callApi';
import { addToListingsList, authenticateAndFetchListings, subscribe } from './routines';
import { get, isArray, isEmpty } from 'lodash';
import { errorHandler } from '../../utils/errorHandler';
import { AnyAction } from 'redux';
import { enqueueSnackbar } from '../notifications';
import { ApplicationState } from '..';

const checkIsDoneProcessing = (responseData: any): boolean => {
  return isArray(responseData) && !isEmpty(responseData);
}

const getListings = async (payload: any): Promise<any | undefined> => {
  try {
    const response = await callApi('post', '/v3/guesty/listings', { data: payload });
    return get(response, 'data');
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export const pollListings = async (payload: any): Promise<any | undefined> => {
  if (!payload) return Promise.resolve(undefined);

  return new Promise(async (resolve, reject) => {
    let responseData = await getListings(payload);

    if (checkIsDoneProcessing(responseData)) resolve(responseData);
    else {
      const timeout = 600000; // 10m
      const delay = 30000; // 30s

      const pollInterval = setInterval(async () => {
        responseData = await getListings(payload);
        if (checkIsDoneProcessing(responseData)) {
          resolve(responseData);
          clearInterval(pollInterval);
        }
      }, delay);

      setTimeout(() => {
        clearInterval(pollInterval);
        if (checkIsDoneProcessing(responseData)) resolve(responseData);
        else reject('Timeout while fetching listings');
      }, timeout);
    }
  });
}

// Routine handlers
function* handleAuthenticateAndFetchListings(action: Action<AuthDetails>): any {
  const maxCalls = 25;

  try {
    const response = yield call(callApi, 'post', '/v3/guesty/listings', { data: action.payload });
    let data = get(response, 'data');

    const accessToken = get(data, 'accessToken');

    let nextPageUrl = get(data, 'nextPageUrl');
    if (!isEmpty(nextPageUrl)) {
      let callNo = 0;
      while (!isEmpty(nextPageUrl) && callNo < maxCalls) {
        const listings = get(data, 'data', data) || [];
        if (!isEmpty(listings) && isArray(listings)) yield put(addToListingsList(listings));

        data = yield call(getListings, { ...action.payload, accessToken, nextPageUrl });
        nextPageUrl = get(data, 'nextPageUrl');

        callNo++;
      }

      if (callNo >= maxCalls && !isEmpty(nextPageUrl)) throw new Error('Timeout while fetching listings');
    }

    const listings = get(data, 'data', data) || [];
    yield put(authenticateAndFetchListings.success({ data: listings, accessToken }));
  } catch (error) {
    if (error) {
      console.error(error);
      yield put(authenticateAndFetchListings.failure(errorHandler(error)));
    }
  } finally {
    yield put(authenticateAndFetchListings.fulfill());
  }
}

function* handleSubscribe(action: Action<SubscribePayload>): any {
  try {
    const selectedPackage = yield select((state: ApplicationState) => state.guesty.selectedPackage);
    const accessToken = yield select((state: ApplicationState) => state.guesty.accessToken);
    const { homePageUrl } = yield select((state: ApplicationState) => state.guesty.authDetails);
    const response = yield call(callApi, 'post', '/v3/guesty/subscribe', {
      data: {
        ...action.payload,
        selectedPackage,
        homePageUrl,
        accessToken,
      }
    });
    yield put(subscribe.success(get(response, 'data')));
  } catch (error) {
    if (error) {
      console.error(error);
      yield put(subscribe.failure(errorHandler(error)));
    }
  } finally {
    yield put(subscribe.fulfill());
  }
}

function* handleAddToListingsList(action: Action<Partial<Listing>[]>): any {
  try {
    yield put(addToListingsList.success(action.payload));
  } catch (error) {
    if (error) {
      console.error(error);
      yield put(addToListingsList.failure(errorHandler(error)));
    }
  } finally {
    yield put(addToListingsList.fulfill());
  }
}

// Error handlers
function* handleError(action: AnyAction): any {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: 'error',
      },
    })
  );
}

// Watchers
function* authenticateAndFetchListingsWatcher(): any {
  yield takeEvery(authenticateAndFetchListings.TRIGGER, handleAuthenticateAndFetchListings);
}

function* subscribeWatcher(): any {
  yield takeEvery(subscribe.TRIGGER, handleSubscribe);
}

function* addToListingsListWatcher(): any {
  yield takeEvery(addToListingsList.TRIGGER, handleAddToListingsList);
}

// Error watchers
function* errorWatcher(): any {
  yield takeEvery([authenticateAndFetchListings.FAILURE, subscribe.FAILURE], handleError);
}

export function* guestySaga() {
  yield all([
    fork(errorWatcher),
    fork(authenticateAndFetchListingsWatcher),
    fork(subscribeWatcher),
    fork(addToListingsListWatcher),
  ]);
}
