import { isEmpty, isNumber, isString, isNaN, isFunction } from 'lodash';
import moment from 'moment';
import { MutableRefObject, Ref } from 'react';

// should have a unit test for
export const formatDate = (date: Date | string, format: string = "DD/MM/YYY"): string | undefined => {
  if (!date) {
    return undefined;
  }

  const arrayOfDateFormat = [
    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",
    "DD/MM/YYYY",
    "YYYY/MM/DD",
    "DD MMMM YYYY",
    "YYYY MMMM DD"
  ];

  const formatedDate = moment(date, arrayOfDateFormat).format(format);
  if (!moment(formatedDate).isValid()) {
    return undefined;
  }

  return formatedDate;
}

export const isNumeric = (strOrNum: string | number): boolean => {
  let num = strOrNum;
  if (isString(strOrNum) && !isEmpty(strOrNum)) num = Number(strOrNum);

  return isNumber(num) && !isNaN(num);
}

export const assignRef = <T,>(ref: Ref<T>, instance: T): void => {
  if (ref) {
    if (isFunction(ref)) {
      ref(instance);
    } else {
      (ref as MutableRefObject<T>).current = instance;
    }
  }
};
