import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

import { AuthDetails, Listing, SubscribePayload, SubscriptionPackage } from './types';

export const startSubscription = createRoutine<SubscriptionPackage>('@@guesty/START_SUBSCRIPTION');
export const startSubscriptionPromise: (payload: SubscriptionPackage) => Promise<any> = promisifyRoutine(startSubscription);

export const authenticateAndFetchListings = createRoutine<AuthDetails>('@@guesty/AUTHENTICATE_AND_FETCH_LISTINGS');
export const authenticateAndFetchListingsPromise: (payload: AuthDetails) => Promise<Listing[]> = promisifyRoutine(authenticateAndFetchListings);

export const subscribe = createRoutine<SubscribePayload>('@@guesty/SUBSCRIBE');
export const subscribePromise: (payload: SubscribePayload) => Promise<any> = promisifyRoutine(subscribe);

export const addToListingsList = createRoutine<Partial<Listing>[]>('@@guesty/ADD_TO_LISTINGS_LIST');
