import { Form, InjectedFormikProps } from "formik";
import { get, has, isEmpty, isNull, startCase } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Drawer } from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";

import { ApplicationState } from "../../store";
import { FiltersProps } from "../../store/listings/types";
import {
  buildPath,
  buildQuery,
  formatListingType,
  getParams,
  mapValuesToQuery,
  removeExtraChar,
} from "../../utils/listingUrlHelper";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import NestedSelect, { priceFormat } from "../inputs/NestedSelect";
import Select from "../inputs/Select";
import SimpleAddressSearch from "../inputs/SimpleAddressSearch";
import { Organisation } from "../../store/organisation";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";

const ListingsFilter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  padding: 20px;
  background: white;
`;

const StyledFiltersContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  ${mediaUp.sm`
    display: flex;
  `};
`;

const StyledContainer = styled.div`
  display: inline-block;
  ${mediaUp.md`
    padding: 0 20px;
    border-left: 1px solid #D8D8D8;
    && {
      .MuiInput-formControl {
        margin-bottom: 10px;
      }
      label + .MuiInput-formControl {
        margin-top: 10px;
      }
      .MuiSelect-select {
        padding-top: 0;
      }
      .MuiInputLabel-formControl {
        transform: translate(0, 12px) scale(1);
        font-size: 14px;
        color: #B0B1B3;
      }
      .MuiInputLabel-shrink {
        font-size: 12px;
        transform: translate(0, 1.5px) scale(0.75);
      }
    }
  `};
  ${mediaDown.md`
    width: 33%;
    margin-bottom: 30px;
  `};
  ${mediaDown.sm`
    width: 50%;
  `};
`;

const StyledContainerFirst = styled(StyledContainer)`
  ${mediaUp.md`
    width: 40%;
    border-left: none;
    padding-left: 0;
  `};
  ${mediaDown.sm`
    width: 100%;
    margin-bottom: 0;

  `};
  padding-left: 0;
  align-self: center;
`;

const StyledContainerLast = styled(StyledContainer)`
  ${mediaUp.md`
    padding-right: 0
  `};
  padding-right: 0;
`;

const FiltersContainer = styled.div`
  ${mediaDown.sm`
    display: none;
  `};
`;

const DrawerFiltersContainer = styled.div`
  margin-top: 30px;
`;

const StyledListingsFilterDrawer = styled(
  Drawer as React.FunctionComponent<DrawerProps>
)`
  && {
    top: 60px;
    .MuiBackdrop-root,
    .MuiPaper-root {
      border-top: 1px solid ${({ theme }) => theme.colors.grey1};
      top: 60px;
    }
  }
`;

export const limitedFeatures = [
  "Furnished",
  "Parking",
  "Garden",
  "Balcony",
  "Pet friendly",
  "Pool",
];
export interface ListingsFilterFormValues {
  cities: string[];
  suburbs: string[];
  priceRange: string;
  beds: string;
  baths: string;
  landlord?: string;
  organisation?: string;
  organisationName?: string;
  features: string[];
  propertyTypes: string[];
  listingType: string;
  listingSector: string;
}
export interface ListingsFilterFormProps {
  filters?: FiltersProps;
  showListingsFilter: boolean;
  toggleListingsFilter: (event: React.SyntheticEvent) => void;
  organisation?: Organisation;
}

const ListingsFilterForm: React.FunctionComponent<
  InjectedFormikProps<ListingsFilterFormProps, ListingsFilterFormValues>
> = ({
  filters,
  values,
  setFieldValue,
  submitForm,
  isSubmitting,
  isValidating,
  showListingsFilter,
  toggleListingsFilter,
  organisation,
}) => {
  const { priceRange, beds, features, propertyTypes, baths, listingType } =
    values;

  const orgColors = getWhiteLabelOrganisationColors(organisation);

  const getMoney = (price: string) => {
    const money = price.split("-");
    return { min: money[0], max: !isEmpty(money[1]) ? money[1] : "" };
  };

  const [localPriceRange, setLocalPriceRange] = useState(priceRange);
  const listingTypeValue = !isEmpty(listingType)
    ? formatListingType(listingType.toLowerCase())
    : "sale";

  const [minPrice, setMinPrice] = useState(getMoney(priceRange).min);
  const [maxPrice, setMaxPrice] = useState(getMoney(priceRange).max);
  const [updateSearch, setUpdateSearch] = useState(false);
  const searchBtnText = updateSearch ? "Update" : "Search";

  const priceRangeFilter = get(
    filters,
    `listingFilters.priceRange.${listingTypeValue}`,
    []
  );
  const urlQuery = encodeURI(window.location.search);
  const { suburbs, cities } = values;
  const chips = cities.map((chip: string, i: number) => ({
    city: chip,
    suburb: suburbs[i],
  }));
  const searchValues = { chips: chips, query: urlQuery };

  const handleSubmit = (formValues: any) => {
    const payload = mapValuesToQuery(formValues);
    const query = buildQuery(payload);
    const path = buildPath(payload);
    const url = removeExtraChar(window.location.pathname + path + query, "/");

    setUpdateSearch(false);
    window.history.pushState("", "", url);
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFieldValue(name, value);
    // setListingType(value);
    setUpdateSearch(true);
    if (name === "listingType") {
      const params = getParams(window.location.pathname);
      const url = params.listingType
        ? "for-" + formatListingType(value.toLowerCase())
        : window.location.pathname + window.location.search;
      window.history.pushState("", "", url);
    }
  };

  const getMinPrice = (event: any) => {
    event.preventDefault();
    const { value } = event.target;

    const price =
      value.length > 0 || maxPrice.length > 0 ? `${value} - ${maxPrice}` : "";

    setMinPrice(value);

    const min = numeral(value).value();
    const max = numeral(maxPrice).value();

    if (min > max && !isNull(max)) {
      const range = priceRangeFilter.filter(
        (key: any) => key.min === numeral(value).value()
      )[0];

      const money = has(range, "max") ? priceFormat(range.max) : "";
      const formatedPrice = `${priceFormat(min)} - ${priceFormat(money)}`;

      setMaxPrice(money);
      setLocalPriceRange(formatedPrice);
      setFieldValue("priceRange", formatedPrice);
    } else {
      setLocalPriceRange(price);
      setFieldValue("priceRange", price);
    }

    setUpdateSearch(true);
  };

  const getMaxPrice = (event: any) => {
    event.preventDefault();
    const maxPriceValue = event.target.value;

    const price =
      minPrice.length > 0 || maxPriceValue.length > 0
        ? `${minPrice} - ${maxPriceValue}`
        : "";

    setMaxPrice(maxPriceValue);
    const max = numeral(maxPriceValue).value();
    const min = numeral(minPrice).value();

    if (min > max && !isNull(min) && !isNull(max)) {
      const range = priceRangeFilter.filter(
        (key: any) => key.max === numeral(maxPriceValue).value()
      )[0];

      const money = has(range, "min") ? priceFormat(range.min) : "";
      const formatedPrice = `${priceFormat(money)} - ${priceFormat(max)}`;

      setMinPrice(money);
      setLocalPriceRange(formatedPrice);
      setFieldValue("priceRange", formatedPrice);
    } else {
      setLocalPriceRange(price);
      setFieldValue("priceRange", price);
    }

    setUpdateSearch(true);
  };

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      handleSubmit(values);
    }
  });

  const withoutBuildingType = get(
    filters,
    `propertyTypes.${values.listingSector}`,
    []
  );

  const getForm = (drawer: boolean) => {
    const FormElements = (
      <>
        <StyledContainer>
          <Select
            minWidth={115}
            id="listing-property-type"
            name="listingType"
            label="Listing Type"
            value={formatListingType(listingType)}
            options={filters!.listingTypes.map((type: any) => type)}
            onChange={handleChange}
          />
        </StyledContainer>
        <StyledContainer>
          <NestedSelect
            minWidth={115}
            id="listing-price-range"
            name="priceRange"
            label="Price Range"
            priceRange={priceRange}
            onGetMaxPrice={getMaxPrice}
            onGetMinPrice={getMinPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            options={priceRangeFilter}
            value={localPriceRange}
          />
        </StyledContainer>
        <StyledContainer>
          <Select
            minWidth={115}
            id="listing-property-type"
            name="propertyTypes"
            label="Property Type"
            multiple={true}
            value={propertyTypes}
            options={withoutBuildingType.map((type: any) => startCase(type))}
            onChange={handleChange}
            orgColors={orgColors}
          />
        </StyledContainer>
        <StyledContainer>
          <Select
            id="listing-beds"
            name="beds"
            label="Beds"
            value={beds}
            options={get(filters, "listingFilters.bedrooms", []).map(
              (bed: any) => bed.label
            )}
            onChange={handleChange}
            optionsReset={true}
          />
        </StyledContainer>
        <StyledContainer>
          <Select
            id="listing-beds"
            name="baths"
            label="Baths"
            value={baths}
            options={get(filters, "listingFilters.bathrooms", []).map(
              (bath: any) => bath
            )}
            onChange={handleChange}
            optionsReset={true}
          />
        </StyledContainer>

        {
          // todo hide using calculated value/screensize
          false && (
            <StyledContainerLast>
              <Select
                minWidth={90}
                id="listing-features"
                name="features"
                label="Featuresddd"
                multiple={true}
                value={features}
                options={limitedFeatures}
                onChange={handleChange}
              />
            </StyledContainerLast>
          )
        }
      </>
    );
    return (
      !isEmpty(organisation) && (
        <ListingsFilter>
          <Form>
            <StyledFiltersContainer>
              <StyledContainerFirst>
                <SimpleAddressSearch
                  setFieldValue={setFieldValue}
                  submitForm={submitForm}
                  isSubmitting={isSubmitting}
                  updateSearch={updateSearch}
                  setUpdateSearch={setUpdateSearch}
                  searchBtnText={searchBtnText}
                  values={values}
                  searchValues={searchValues}
                  colors={orgColors}
                />
              </StyledContainerFirst>
              {drawer ? (
                <DrawerFiltersContainer>{FormElements}</DrawerFiltersContainer>
              ) : (
                <FiltersContainer>{FormElements}</FiltersContainer>
              )}
            </StyledFiltersContainer>
          </Form>
        </ListingsFilter>
      )
    );
  };

  if (filters) {
    return (
      <>
        {getForm(false)}
        <StyledListingsFilterDrawer
          style={{
            zIndex: 998,
          }}
          variant="temporary"
          anchor="top"
          open={showListingsFilter}
          onClose={toggleListingsFilter}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {getForm(true)}
        </StyledListingsFilterDrawer>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ listing, organisation }: ApplicationState) => ({
  filters: listing.filters,
  listings: listing.listings,
  organisation: organisation.organisation,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingsFilterForm) as any;
