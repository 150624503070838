import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Root from "./components/layout/Root";
import Notifier from "./components/ui/Notifier";
import NavigationContainer from "./containers/NavigationContainer";
import Routes from "./routes";
import { ApplicationState } from "./store";
import { theme } from "./styles/theme";
import { sizes, ThemeProvider } from "./utils/styledComponents";
import { useClearCache } from "react-clear-cache";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
interface OwnProps {
  store: Store<ApplicationState>;
  history: History;
}

// Create an intersection type of the component props and our Redux props.
type AllProps = OwnProps;

const muiTheme = createMuiTheme({
  breakpoints: {
    values: sizes,
  },
  palette: {
    primary: {
      main: theme.colors.orange,
    },
    secondary: {
      main: theme.colors.lightblue,
    },
  },
});
const Main: React.FunctionComponent<AllProps> = (props) => {
  const { store, history } = props;
  const persistor = persistStore(store);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  if (!isLatestVersion) {
    emptyCacheStorage(); // hard reload if the version is not the same as the current build
  }
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Root>
                  <NavigationContainer>
                    <Notifier />
                    <Routes />
                  </NavigationContainer>
                </Root>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default Main;
