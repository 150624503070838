import { Location } from 'history';
import { omit } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store';
import { routeChange } from '../../store/navigation';

//  Based on https://github.com/pixelfusion/react-route-guard/blob/master/src/enhanced-route.tsx
export type RouteGuardResultType = Promise<boolean>;

export interface OwnProps {
  routeRoleGuard: string[];
  redirectToPathWhenFail?: string;
  redirectToPathWhenAuthenticated?: string;
  componentWhenFail?: React.ComponentType<RouteComponentProps<any> | {}>;
  title?: string;
}

interface ConnectedProps {
  location: Location;
  authenticated: boolean;
  user: { [propName: string]: any };
}

interface PropsFromDispatch {
  routeChange: typeof routeChange;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = RouteProps & OwnProps & PropsFromDispatch & ConnectedProps;

class CustomRoute extends React.Component<AllProps> {
  public state = {}
  public static defaultProps = {
    routeRoleGuard: [],
  };


  public static getDerivedStateFromProps(nextProps: AllProps) {
    // This updates the title in the nav bar
    if (nextProps.title) {
      nextProps.routeChange({
        routeProps: { title: nextProps.title },
      });
    }
    return null;
  }

  public render() {
    const {
      location,
      redirectToPathWhenFail,
      componentWhenFail,
      authenticated,
      redirectToPathWhenAuthenticated,
      user,
      routeRoleGuard,
    } = this.props;

    const successRoute: JSX.Element = (
      <Route {...this.props} path={`${location.pathname}`} />
    );

    // If hasn't `routeGuard` props, then just render the real <Route>
    if (!routeRoleGuard.length) {
      if (authenticated && redirectToPathWhenAuthenticated) {
        return (
          <Redirect
            exact={true}
            from={location.pathname}
            to={{
              pathname: redirectToPathWhenAuthenticated,
              search: location.search,
            }}
          />
        );
      }
      return successRoute;
    }

    // Check if the user has the roles authorized to use this route
    const routeRoleGuardResult =
      user &&
      user.roles &&
      user.roles.some((r: string): boolean => routeRoleGuard.includes(r));

    if (authenticated && routeRoleGuardResult) {
      // if there is a redirect
      const query = queryString.parse(location.search);

      if (query && query["auth_redirect"]) {
        return (
          <Redirect
            exact={true}
            from={location.pathname}
            to={{
              pathname: query.auth_redirect as string,
              search: queryString.stringify(omit(query, "auth_redirect")),
            }}
          />
        );
      }
      return successRoute;
    }

    const redirectPath = redirectToPathWhenFail
      ? redirectToPathWhenFail
      : "/login";

    const failRedirect = (
      <Redirect
        exact={true}
        from={location.pathname}
        to={{
          pathname: redirectPath,
          search: queryString.stringify({
            auth_redirect: `${location.pathname}${location.search}`,
          }),
          state: {
            from: `${location.pathname}${location.search}`,
          },
        }}
      />
    );

    // Allows for the use of a custom component for fail routes
    const failComponentRoute = componentWhenFail ? (
      <Route path={location.pathname} component={componentWhenFail} />
    ) : null;

    return this.props.componentWhenFail ? failComponentRoute : failRedirect;
  }
}

const mapStateToProps = ({ auth, router }: ApplicationState) => ({
  authenticated: auth.authenticated,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      routeChange,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRoute as any) as any;
