import { withFormik } from "formik";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Yup from "yup";

import { bindPromiseCreators } from "redux-saga-routines";
import TenantDemographicsForm, {
  TenantDemographicsFormValues,
} from "../components/forms/TenantDemographicsForm";
import Paper from "../components/ui/Paper";
import { ApplicationState } from "../store";
import { AuthenticatedUser, updateUserPromise } from "../store/auth";
import {
  DialogsState,
  toggleMessageDialog as toggleMessageDialogAction,
  toggleProfileDialog as toggleProfileDialogAction,
} from "../store/dialogs";
import styled, { mediaDown } from "../utils/styledComponents";

interface OwnProps {
  user?: AuthenticatedUser;
  dialogs?: DialogsState;
}

interface PropsFromDispatch {
  updateUserPromise?: typeof updateUserPromise;
  toggleProfileDialog?: typeof toggleProfileDialogAction;
  toggleMessageDialog?: typeof toggleMessageDialogAction;
}

type AllProps = PropsFromDispatch & OwnProps;

const Wrapper = styled.div``;

const StyledPaper = styled(Paper)`
  padding: 20px;
  ${mediaDown.sm`
    padding: 20px 0;
  `}
`;

const schema = Yup.object().shape({
  employmentType: Yup.string().required("Employment type is required"),
  idNumber: Yup.string()
    .required("Identification is required")
    .when("identityType", {
      is: "ID",
      then: Yup.string().matches(
        /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
        "Invalid ID Number"
      ),
    }),
  monthlyIncome: Yup.string().required("Monthly income is required"),
  moveDate: Yup.string().required("Moving date is required"),
  work: Yup.string().required("Work information is required"),
});

class ProfileFormContainer extends React.Component<AllProps> {
  public render() {
    return (
      <Wrapper>
        <StyledPaper>{this.renderForm()}</StyledPaper>
      </Wrapper>
    );
  }

  private renderForm() {
    const emptyProfile = {
      employmentType: "",
      idNumber: "",
      identityType: "ID",
      monthlyIncome: "",
      moveDate: "",
      nationality: "South Africa",
      work: "",
    };
    const userProfile = _.pick(this.props.user?.profile, [
      "work",
      "monthlyIncome",
      "employmentType",
      "identityType",
      "idNumber",
      "moveDate",
      "nationality",
    ]);

    const profile = {
      ...emptyProfile,
      ..._.pickBy(userProfile, _.identity),
    };

    const formikEnhancer = withFormik<{}, TenantDemographicsFormValues>({
      displayName: "UpdateProfileForm",
      handleSubmit: async (values, { setSubmitting }) => {
        await this.handleUpdate(values);
        if (this.props.toggleProfileDialog) {
          this.props.toggleProfileDialog(false);
        }
      },
      mapPropsToValues: () => profile,
      validationSchema: schema,
    });
    const EnhancedProfileForm = formikEnhancer(TenantDemographicsForm);

    return <EnhancedProfileForm />;
  }

  private handleUpdate = (values: TenantDemographicsFormValues) => {
    const updatedValues = _.omit(values, "confirmPassword");
    if (this.props.updateUserPromise) {
      const { dialogs } = this.props;
      this.props.updateUserPromise(updatedValues).then(() => {
        if (dialogs && dialogs.openMessageDialog) {
          const openMessageDialog = dialogs.openMessageDialog;
          if (!openMessageDialog.messageSent && openMessageDialog.unitId) {
            if (this.props.toggleMessageDialog) {
              this.props.toggleMessageDialog({
                openDialog: true,
                toggleLogin: false,
                unitId: openMessageDialog.unitId,
              });
            }
          }
        }
      });
    }
  };
}

const mapStateToProps = ({ auth, dialogs }: ApplicationState) => ({
  dialogs,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      toggleMessageDialog: toggleMessageDialogAction,
      toggleProfileDialog: toggleProfileDialogAction,
    },
    dispatch
  ),
  ...bindPromiseCreators({ updateUserPromise }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileFormContainer);
