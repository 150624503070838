import { OrganisationColors } from "../store/organisation";
import { get, isEmpty } from "lodash";
import _logo from "../assets/images/brand/logo.png";

/**
 * get organisation whiteLabel colours
 * @param organisation organisation object
 */
export const getWhiteLabelOrganisationColors = (
  organisation?: object
): OrganisationColors | undefined => {
  const colors = get(organisation, "settings.colors");
  const shouldWhiteLabel = get(colors, "whiteLabel.active", false);
  return shouldWhiteLabel ? colors : undefined;
};

/**
 * Get organisation colour
 * @param colors org colors
 * @param type organisation colour type
 * @param defaultColor default provided colour
 */
export const getOrganisationColor = (
  colors: OrganisationColors | undefined,
  type: "primaryColor" | "secondaryColor" | "buttonTextColor",
  defaultColor: string
): string => {
  const shouldWhiteLabel = get(colors, "whiteLabel.active", false);
  const color = get(colors, `${type}`, defaultColor);

  return shouldWhiteLabel && !isEmpty(color) ? color : defaultColor;
};

/**
 * Get organisation logo or use flow default
 * @param organisation object
 */
export const getOrganisationLogo = (organisation?: object) => {
  const colors = get(organisation, "settings.colors");
  const shouldWhiteLabel = get(colors, "whiteLabel.active", undefined);
  const logoUrl = get(organisation, "logoUrl");

  return {
    logo:
      shouldWhiteLabel && !isEmpty(logoUrl)
        ? logoUrl
        : shouldWhiteLabel === false
        ? _logo
        : undefined,
    shouldWhiteLabel,
  };
};

/**
 * Check if organisation should be white labeled
 * @param organisation object
 */
export const shouldWhiteLabel = (organisation?: object) => {
  return get(organisation, "settings.colors.whiteLabel.active", false);
};
