import jwtDecode from 'jwt-decode';
import _ from 'lodash';

export function isVerificationRequired() {
  const token = localStorage.getItem("auth_token");

  if (token) {
    const decodedToken = jwtDecode(token);
    const requireVerification = _.get(
      decodedToken,
      "verification.contactNumber.required",
      false
    );

    return requireVerification;
  }

  return false;
}
