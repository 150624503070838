import { AnyAction } from 'redux';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { routeChange } from './routines';

function* handleRouteChange(action: AnyAction) {
  yield put(routeChange.success(action.payload));
}

function* routeChangeWatcher() {
  yield takeEvery(routeChange.TRIGGER, handleRouteChange);
}

export function* navigationSaga() {
  yield all([fork(routeChangeWatcher)]);
}
