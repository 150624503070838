import { TextField as MuiTextField, TextFieldProps } from 'formik-material-ui';
import React from 'react';

import { theme } from '../../styles/theme';
import styled from '../../utils/styledComponents';

const TextField = styled(MuiTextField as React.FunctionComponent<
  TextFieldProps
>).attrs({
  fullWidth: true
})`
  && {

    .MuiInputLabel-outlined {
      transform: translate(14px, 14px) scale(1); 
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
  }
   & fieldset > legend {
      display: block;
    }

    margin-bottom: 8px;
    > div::after {
      border-bottom-color: ${() => theme.colors.grey[250]};
    }

    > div::before {
      border-bottom-color: ${() => theme.colors.grey[250]};
    }

    > label {
      color: ${() => theme.colors.grey2};
    }

    > p {
      color: ${() => theme.colors.red};
      font-family: ${() => theme.fonts.MS300};
      font-weight: ${() => theme.fontWeights.MS300};
    }
    .MuiInputBase-input {
      color: #4C4C4E !important;
      font-family: ${() => theme.fonts.MS300};
      font-weight: ${() => theme.fontWeights.MS300};
      font-size: 16px;
    }
  }
`;

export default TextField;
