export const PROPERTY_ITEMS = Object.freeze({
  residential: [
    {
      name: "lease_period",
      datatype: "string",
      index: 0,
    },
    {
      name: "unit_number",
      datatype: "string",
      index: 1,
    },
    {
      name: "build_completion_status",
      datatype: "string",
      index: 2,
    },
    {
      name: "monthly_levy",
      datatype: "money",
      index: 3,
    },
    {
      name: "public_tender",
      datatype: "bool",
      index: 4,
    },
    {
      name: "land_size",
      datatype: "int",
      measurementType: "Metres Squared",
      index: 5,
    },
  ],
  commercial: [
    {
      name: "gross_monthly_rental",
      datatype: "money",
      index: 0,
    },
    {
      name: "offers_from",
      datatype: "money",
      index: 1,
    },
    {
      name: "zoning",
      datatype: "string",
      index: 2,
    },
    {
      name: "available_from",
      datatype: "string",
      index: 3,
    },
    {
      name: "lease_period",
      datatype: "string",
      index: 4,
    },
    {
      name: "lease_excludes",
      datatype: "string",
      index: 5,
    },
    {
      name: "lease_notes",
      datatype: "string",
      index: 6,
    },
    {
      name: "escalation",
      datatype: "string",
      index: 7,
    },
    {
      name: "annual_escalation",
      datatype: "int",
      measurementType: "Percentage",
      index: 8,
    },
    {
      name: "rates",
      datatype: "string",
      index: 9,
    },
    {
      name: "monthly_rates",
      datatype: "money",
      index: 10,
    },
    {
      name: "purchase_rates",
      datatype: "money",
      index: 11,
    },
    {
      name: "tenant_rental",
      datatype: "money",
      index: 12,
    },
    {
      name: "erf_number",
      datatype: "string",
      index: 13,
    },
    {
      name: "security",
      datatype: "bool",
      index: 14,
    },
    {
      name: "extras",
      datatype: "string",
      index: 15,
    },
    {
      name: "power_3_phase",
      datatype: "bool",
      index: 16,
    },
    {
      name: "building_size",
      datatype: "int",
      measurementType: "Metres Squared",
      index: 20,
    },
    {
      name: "land_size",
      datatype: "int",
      measurementType: "Metres Squared",
      index: 19,
    },
    {
      name: "air_conditioning",
      datatype: "bool",
      index: 17,
    },
    {
      name: "general_property_remarks",
      datatype: "string",
      index: 18,
    },
    {
      name: "title",
      datatype: "string",
      index: 21,
    },
    {
      name: "building_name",
      datatype: "string",
      index: 22,
    },
    {
      name: "building_height",
      datatype: "int",
      index: 23,
    },
    {
      name: "unit_number",
      datatype: "string",
      index: 24,
    },
    {
      name: "floor_number",
      datatype: "string",
      index: 25,
    },
    {
      name: "build_completion_status",
      datatype: "string",
      index: 26,
    },
    {
      name: "complex_name",
      datatype: "string",
      index: 27,
    },
    {
      name: "property_size_total_cost",
      datatype: "money",
      index: 28,
    },
    {
      name: "purchase_levy",
      datatype: "money",
      index: 29,
    },
    {
      name: "deposit",
      datatype: "money",
      index: 30,
    },
    {
      name: "office_space",
      datatype: "int",
      index: 31,
    },
    {
      name: "office_space_total_cost",
      datatype: "money",
      index: 32,
    },
    {
      name: "office_space_price_per_sqm",
      datatype: "money",
      index: 33,
    },
    {
      name: "retail",
      datatype: "int",
      index: 34,
    },
    {
      name: "retail_total_cost",
      datatype: "money",
      index: 35,
    },
    {
      name: "retail_price_per_sqm",
      datatype: "money",
      index: 36,
    },
    {
      name: "parking_ratio_bays",
      datatype: "int",
      index: 37,
    },
    {
      name: "parking_ratio_per_meter",
      datatype: "int",
      index: 38,
    },
    {
      name: "parking_notes",
      datatype: "string",
      index: 39,
    },
    {
      name: "open_parking_bays",
      datatype: "int",
      index: 40,
    },
    {
      name: "open_parkings_cost_per_bay",
      datatype: "money",
      index: 41,
    },
    {
      name: "basement_parking",
      datatype: "int",
      index: 42,
    },
    {
      name: "basement_parking_per_bay",
      datatype: "money",
      index: 43,
    },
    {
      name: "basement_parking_total_cost",
      datatype: "money",
      index: 44,
    },
    {
      name: "covered_parking_bays",
      datatype: "int",
      index: 45,
    },
    {
      name: "covered_parkings_total_cost",
      datatype: "money",
      index: 46,
    },
    {
      name: "covered_parkings_cost_per_bay",
      datatype: "money",
      index: 47,
    },
    {
      name: "factory_warehouse",
      datatype: "money",
      index: 48,
    },
    {
      name: "factory_warehouse_total_cost",
      datatype: "money",
      index: 49,
    },
    {
      name: "factory_warehouse_price_per_sqm",
      datatype: "money",
      index: 50,
    },
    {
      name: "floor_loading_capacity",
      datatype: "int",
      index: 51,
    },
    {
      name: "yard_total_cost",
      datatype: "money",
      index: 52,
    },
    {
      name: "yard_access",
      datatype: "string",
      index: 53,
    },
    {
      name: "yard_size",
      datatype: "int",
      index: 54,
    },
    {
      name: "yard_price_per_sqm",
      datatype: "money",
      index: 55,
    },
  ],
});
export const MEASUREMENT_TYPES = Object.freeze({
  "Metres Squared": "m²",
  "Square Feet": "sqft",
  "Hectares (Ha)": "ha",
  Percentage: "%", // keep on adding as they requested
});
export interface PropertyItem {
  name: string;
  datatype: string;
  measurementType?: string;
  index: number;
}
