import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import styled from '../../utils/styledComponents';
import { Field } from 'formik';
import { FieldWrapper } from '../ui/forms';
import TextInput from '../inputs/TextInput';
import DropDown from '../inputs/DynamicDropDown';

const countries = [
  'Afghanistan', 
  'Åland Islands', 
  'Albania', 
  'Algeria', 
  'American Samoa', 
  'AndorrA', 
  'Angola', 
  'Anguilla', 
  'Antarctica', 
  'Antigua and Barbuda', 
  'Argentina', 
  'Armenia', 
  'Aruba', 
  'Australia', 
  'Austria', 
  'Azerbaijan', 
  'Bahamas', 
  'Bahrain', 
  'Bangladesh', 
  'Barbados', 
  'Belarus', 
  'Belgium', 
  'Belize', 
  'Benin', 
  'Bermuda', 
  'Bhutan', 
  'Bolivia', 
  'Bosnia and Herzegovina', 
  'Botswana', 
  'Bouvet Island', 
  'Brazil', 
  'British Indian Ocean Territory', 
  'Brunei Darussalam', 
  'Bulgaria', 
  'Burkina Faso', 
  'Burundi', 
  'Cambodia', 
  'Cameroon', 
  'Canada', 
  'Cape Verde', 
  'Cayman Islands', 
  'Central African Republic', 
  'Chad', 
  'Chile', 
  'China', 
  'Christmas Island', 
  'Cocos (Keeling) Islands', 
  'Colombia', 
  'Comoros', 
  'Congo', 
  'Congo, The Democratic Republic of the', 
  'Cook Islands', 
  'Costa Rica', 
  'Cote D\'Ivoire', 
  'Croatia', 
  'Cuba', 
  'Cyprus', 
  'Czech Republic', 
  'Denmark', 
  'Djibouti', 
  'Dominica', 
  'Dominican Republic', 
  'Ecuador', 
  'Egypt', 
  'El Salvador', 
  'Equatorial Guinea', 
  'Eritrea', 
  'Estonia', 
  'Ethiopia', 
  'Falkland Islands (Malvinas)', 
  'Faroe Islands', 
  'Fiji', 
  'Finland', 
  'France', 
  'French Guiana', 
  'French Polynesia', 
  'French Southern Territories', 
  'Gabon', 
  'Gambia', 
  'Georgia', 
  'Germany', 
  'Ghana', 
  'Gibraltar', 
  'Greece', 
  'Greenland', 
  'Grenada', 
  'Guadeloupe', 
  'Guam', 
  'Guatemala', 
  'Guernsey', 
  'Guinea', 
  'Guinea-Bissau', 
  'Guyana', 
  'Haiti', 
  'Heard Island and Mcdonald Islands', 
  'Holy See (Vatican City State)', 
  'Honduras', 
  'Hong Kong', 
  'Hungary', 
  'Iceland', 
  'India', 
  'Indonesia', 
  'Iran, Islamic Republic Of', 
  'Iraq', 
  'Ireland', 
  'Isle of Man', 
  'Israel', 
  'Italy', 
  'Jamaica', 
  'Japan', 
  'Jersey', 
  'Jordan', 
  'Kazakhstan', 
  'Kenya', 
  'Kiribati', 
  'Korea, Democratic People\'S Republic of', 
  'Korea, Republic of', 
  'Kuwait', 
  'Kyrgyzstan', 
  'Lao People\'S Democratic Republic', 
  'Latvia', 
  'Lebanon', 
  'Lesotho', 
  'Liberia', 
  'Libyan Arab Jamahiriya', 
  'Liechtenstein', 
  'Lithuania', 
  'Luxembourg', 
  'Macao', 
  'Macedonia, The Former Yugoslav Republic of', 
  'Madagascar', 
  'Malawi', 
  'Malaysia', 
  'Maldives', 
  'Mali', 
  'Malta', 
  'Marshall Islands', 
  'Martinique', 
  'Mauritania', 
  'Mauritius', 
  'Mayotte', 
  'Mexico', 
  'Micronesia, Federated States of', 
  'Moldova, Republic of', 
  'Monaco', 
  'Mongolia', 
  'Montserrat', 
  'Morocco', 
  'Mozambique', 
  'Myanmar', 
  'Namibia', 
  'Nauru', 
  'Nepal', 
  'Netherlands', 
  'Netherlands Antilles', 
  'New Caledonia', 
  'New Zealand', 
  'Nicaragua', 
  'Niger', 
  'Nigeria', 
  'Niue', 
  'Norfolk Island', 
  'Northern Mariana Islands', 
  'Norway', 
  'Oman', 
  'Pakistan', 
  'Palau', 
  'Palestinian Territory, Occupied', 
  'Panama', 
  'Papua New Guinea', 
  'Paraguay', 
  'Peru', 
  'Philippines', 
  'Pitcairn', 
  'Poland', 
  'Portugal', 
  'Puerto Rico', 
  'Qatar', 
  'Reunion', 
  'Romania', 
  'Russian Federation', 
  'RWANDA', 
  'Saint Helena', 
  'Saint Kitts and Nevis', 
  'Saint Lucia', 
  'Saint Pierre and Miquelon', 
  'Saint Vincent and the Grenadines', 
  'Samoa', 
  'San Marino', 
  'Sao Tome and Principe', 
  'Saudi Arabia', 
  'Senegal', 
  'Serbia and Montenegro', 
  'Seychelles', 
  'Sierra Leone', 
  'Singapore', 
  'Slovakia', 
  'Slovenia', 
  'Solomon Islands', 
  'Somalia', 
  'South Africa', 
  'South Georgia and the South Sandwich Islands', 
  'Spain', 
  'Sri Lanka', 
  'Sudan', 
  'Suriname', 
  'Svalbard and Jan Mayen', 
  'Swaziland', 
  'Sweden', 
  'Switzerland', 
  'Syrian Arab Republic', 
  'Taiwan, Province of China', 
  'Tajikistan', 
  'Tanzania, United Republic of', 
  'Thailand', 
  'Timor-Leste', 
  'Togo', 
  'Tokelau', 
  'Tonga', 
  'Trinidad and Tobago', 
  'Tunisia', 
  'Turkey', 
  'Turkmenistan', 
  'Turks and Caicos Islands', 
  'Tuvalu', 
  'Uganda', 
  'Ukraine', 
  'United Arab Emirates', 
  'United Kingdom', 
  'United States', 
  'United States Minor Outlying Islands', 
  'Uruguay', 
  'Uzbekistan', 
  'Vanuatu', 
  'Venezuela', 
  'Viet Nam', 
  'Virgin Islands, British', 
  'Virgin Islands, U.S.', 
  'Wallis and Futuna', 
  'Western Sahara', 
  'Yemen', 
  'Zambia', 
  'Zimbabwe' 
];

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-width: auto;
    border: 1px solid ${({ theme }) => theme.colors.grey2};
    text-transform: none;
    &:first-child {
      border-radius: 15px 0 0 15px;
    }

    &:last-child {
      border-radius: 0 15px 15px 0;
    }

    &.Mui-selected {
      color: ${({ theme }) => theme.colors.orange};
      border-color: ${({ theme }) => theme.colors.orange};
    }
  }
`;

const StyledBox =  styled(Box)`
  && {
    padding-top: 10px;
  }
`;

interface TabPanel {
  value: any;
  index: number;
}

interface OwnProps {
  values: any;
  setFieldValue: any;
}

const TabPanel: FunctionComponent<TabPanel> = ({
  value, 
  index, 
  children 
}) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && <StyledBox>{children}</StyledBox>}
    </Typography>
  );
}

function a11yProps(index: number) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

const LinkTab: FunctionComponent<any> = (props: any) => {
  return (
    <StyledTab
      component="a"
      onClick={(event: any) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const NavTabs: FunctionComponent<OwnProps> = ({
  values,
  setFieldValue
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(values.nationality === 'South Africa' ? 0 : 1);
  const [foreignNatinality, setForeignNatinality] = useState('');

  const handleChange = (event: any, newValue: any) => {
    const identityType = newValue === 0 ? 'ID' : 'Passport';

    setFieldValue('identityType', identityType);
    setValue(newValue);
  };

  const handlesetForeignNatinality = (nationality: string) => setForeignNatinality(nationality);

  useEffect(() => {
    if (value === 0 && values.nationality !== 'South Africa') {
      setFieldValue('nationality', 'South Africa');
    }

    if (value === 1 && values.nationality !== 'South Africa' && values.nationality !== foreignNatinality) {
      setFieldValue('nationality', values.nationality);
      handlesetForeignNatinality(values.nationality);
    } else if (value === 1 && !_.isEmpty(foreignNatinality) && values.nationality !== foreignNatinality) {
      setFieldValue('nationality', foreignNatinality);
    }
  })

  return (
    <div className={classes.root}>
      <StyledTabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
      >
        <LinkTab label="SA ID" href="/id" {...a11yProps(0)} />
        <LinkTab label="Passport" href="/passport" {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <FieldWrapper md={12}>
          <Field
            type="number"
            label="ID Number"
            name="idNumber"
            component={TextInput}
          />
        </FieldWrapper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FieldWrapper>
          <DropDown
            dataset={countries.reduce((x: any, y: string) => { return typeof x === "object" ? {...x, [y]: y} : {[x]: x, [y]: y} })}
            values={values}
            setFieldValue={setFieldValue}
            label={"What's your nationality?"}
            name={"nationalitySelect"}
            bind={"nationality"}
          />
        </FieldWrapper>
        <FieldWrapper md={12}>
          <Field
            type="text"
            label="Passport Number"
            name="idNumber"
            component={TextInput}
          />
        </FieldWrapper>
      </TabPanel>
    </div>
  );
}

export default NavTabs;