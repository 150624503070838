import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import Grid, { GridProps } from '@material-ui/core/Grid';

import Title from '../../components/typography/Title';
import OrDivider from '../../components/ui/OrDivider';
import UpdateContactNumberFormContainer from '../../containers/UpdateContactNumberFormContainer';
import VerifyOTPContainer from '../../containers/VerifyOTPContainer';
import { ApplicationState } from '../../store';
import { AuthenticatedUser, requestOTPPromise as requestOTPPromiseAction } from '../../store/auth';
import styled from '../../utils/styledComponents';

interface PropsFromState {
  errors?: string;
  user: AuthenticatedUser;
}

interface PropsFromDispatch {
  requestOTPPromise: typeof requestOTPPromiseAction;
}

type AllProps = PropsFromState & PropsFromDispatch;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const OrDividerContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const LinksContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  margin-top: 10px;
`;

const StyledTitle = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS500} !important;
  font-size: ${({ theme }) => theme.fontSizes.title} !important;
  line-height: 1.2em !important;
`;

const SubSectionTitle = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.subtitle} !important;
`;

const SubSectionDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: 16px;
  margin: 5px 0 30px;
`;

const OrangeLink = styled.a`
  color: ${({ theme }) => theme.colors.orange};
  &:hover {
    cursor: pointer;
  }
`;

const OTPContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const SubOption = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
  text-align: center;
`;

const StyledError = styled.div`
  color: #e65757;
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  margin-bottom: 15px;
`;

const VerifyOTPPage: React.FunctionComponent<AllProps> = ({
  user,
  errors,
  requestOTPPromise,
}: AllProps) => {
  const [updateNumber, setUpdateNumber] = useState(false);

  const onRequestOTP = useCallback(async () => {
    await requestOTPPromise({});
  }, [requestOTPPromise]);

  const toggleUpdateNumberForm = () => {
    setUpdateNumber(!updateNumber);
  };

  const hideUpdateNumberForm = () => {
    setUpdateNumber(false);
  };

  return (
    <>
      {user && user.verifications.contactNumber.requestedAt && !updateNumber ? (
        <>
          <TitleWrapper>
            <SubSectionTitle>Verify your account</SubSectionTitle>
            <SubSectionDescription>{`Please enter the code sent to ${
              user && user.contactNumber
            }`}</SubSectionDescription>
          </TitleWrapper>

          <Grid item={true} xs={12}>
            <Grid container={true} justify="center">
              <OTPContainer item={true}>
                {errors && (
                  <Grid item={true}>
                    <StyledError>{errors}</StyledError>
                  </Grid>
                )}
                <VerifyOTPContainer />
              </OTPContainer>
            </Grid>
          </Grid>
          <LinksContainer container={true} justify="center">
            <Grid item={true} xs={12}>
              <SubOption>
                didn't get the code?
                <OrangeLink onClick={onRequestOTP}> Resend!</OrangeLink>
              </SubOption>
            </Grid>
            <OrDividerContainer>
              <OrDivider />
            </OrDividerContainer>
            <Grid item={true} xs={12}>
              <SubOption>
                Update your
                <OrangeLink onClick={toggleUpdateNumberForm}>
                  {" "}
                  contact number
                </OrangeLink>
              </SubOption>
            </Grid>
          </LinksContainer>
        </>
      ) : (
        <>
          <TitleWrapper>
            <SubSectionTitle>Contact Number Verification</SubSectionTitle>
            <SubSectionDescription>{`Please enter your contact number. We will send you an SMS with your verification code.`}</SubSectionDescription>
          </TitleWrapper>
          {errors && <StyledError>{errors}</StyledError>}
          <Grid item={true} xs={12}>
            <UpdateContactNumberFormContainer
              onNumberUpdated={hideUpdateNumberForm}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  errors: auth.errors,
  loading: auth.loading,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    { requestOTPPromise: requestOTPPromiseAction },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOTPPage) as any;
