import { omit } from "lodash";
import * as React from "react";

import Grid, { GridProps } from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import withWidth, {
  isWidthDown,
  isWidthUp,
  WithWidth,
} from "@material-ui/core/withWidth";

import { FlowThemeInterface } from "../../styles/theme";
import styled, { mediaDown, withTheme } from "../../utils/styledComponents";
import { HeaderContextProvider } from "../context/HeaderContext";
import LoadingPage from "../ui/LoadingPage";
import { HeaderInterface } from "./Header";
import FlowLogo from "../../assets/images/brand/logo-greyscale.png";
import Link from "@material-ui/core/Link/Link";

interface OwnProps {
  headerComponent?: React.ReactElement<HeaderInterface>;
  theme: FlowThemeInterface;
  loading?: boolean;
  headerHidden?:
    | "mdUp"
    | "lgDown"
    | "lgUp"
    | "mdDown"
    | "mdUp"
    | "smDown"
    | "smUp"
    | "xlDown"
    | "xlUp"
    | "xsDown"
    | "xsUp";
}

type AllProps = GridProps &
  WithWidth &
  OwnProps & {
    shouldWhiteLabel?: boolean;
    hasStickyFooter?: boolean;
  };

interface State {
  readonly isHeaderHidden: boolean;
}

const ContainerGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  position: relative;
`;

const ScrollContent = styled(
  (props: GridProps & { hasHeader: boolean | string }) => (
    <Grid {...omit(props, ["hasHeader"])} />
  )
)`
  min-height: 100%;
  padding: 32px 56px;
  background: ${({ theme }) => theme.colors.pageBg};
  padding-top: ${({ hasHeader }) => (hasHeader ? "82px" : "56px")};

  ${mediaDown.lg`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 30px;
`}

  ${mediaDown.md`
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 30px;
`}
`;

interface OwnFooterProps {
  shouldWhiteLabel?: boolean;
}

const Footer = styled.div<OwnFooterProps>`
  && {
    display: ${({ shouldWhiteLabel }) => (shouldWhiteLabel ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    margin-top: 20px ${mediaDown.md`margin-top: 20px`};
  }
`;

const StickyFooter = styled.div<{ shouldWhiteLabel?: boolean }>`
  && {
    display: ${({ shouldWhiteLabel }) => (shouldWhiteLabel ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 30px;
    ${mediaDown.md`margin-top: 20px; padding-bottom: 100px;`}
    ${mediaDown.sm`margin-top: -140px; padding-bottom: 50px;`}
  }
`;

const FooterContentContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const Logo = styled.img`
  && {
    height: 1rem;
  }
`;

const StyledSubTitle = styled.p`
  && {
    color: ${({ theme }) => theme.colors.grey2};
    font-size: ${({ theme }) => theme.fontSizes.buttonText};
    font-family: ${({ theme }) => theme.fonts.MS500};
    margin: 0px;
  }
`;

const ScrollSection = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
`;

class Body extends React.Component<AllProps, State> {
  public readonly state: State = {
    isHeaderHidden: false,
  };
  protected prev: any;

  public render() {
    const {
      children,
      headerComponent,
      headerHidden,
      loading,
      theme,
      width,
      ...props
    } = this.props;

    const hiddenProp = {};
    let headerPadding = true;
    if (headerHidden) {
      hiddenProp[headerHidden] = true;
      if (headerHidden.includes("Up")) {
        const breakpoint = headerHidden.replace("Up", "");
        headerPadding = !isWidthUp(breakpoint as Breakpoint, width);
      } else if (headerHidden.includes("Down")) {
        const breakpoint = headerHidden.replace("Down", "");
        headerPadding = !isWidthDown(breakpoint as Breakpoint, width);
      }
    }

    const footerContent = () => (
      <FooterContentContainer>
        <StyledSubTitle>Powered By</StyledSubTitle>
        <Link href={process.env.REACT_APP_STATIC_PAGES_URL} target="blank">
          <Logo src={FlowLogo} />
        </Link>
      </FooterContentContainer>
    );

    return (
      <HeaderContextProvider value={this.state}>
        <ContainerGrid item={true} xs={true}>
          <Hidden {...hiddenProp}>
            {headerComponent
              ? React.cloneElement(headerComponent, {
                  backgroundColor: theme.colors.pageBg,
                })
              : null}
          </Hidden>
          <ScrollSection ref={this.bodyDidMount}>
            <ScrollContent
              container={true}
              direction="column"
              {...props}
              hasHeader={headerComponent && headerPadding ? true : false}
            >
              {loading ? <LoadingPage /> : children}
              {props.hasStickyFooter ? (
                <StickyFooter shouldWhiteLabel={props.shouldWhiteLabel}>
                  {footerContent()}
                </StickyFooter>
              ) : (
                <Footer shouldWhiteLabel={props.shouldWhiteLabel}>
                  {footerContent()}
                </Footer>
              )}
            </ScrollContent>
          </ScrollSection>
        </ContainerGrid>
      </HeaderContextProvider>
    );
  }

  private bodyDidMount = (node: HTMLDivElement) => {
    if (node) {
      node.addEventListener("scroll", this.scrolled);
    }
  };

  private scrolled = (evt: any) => {
    const { isHeaderHidden } = this.state;

    if (evt.target.scrollTop > this.prev) {
      if (!isHeaderHidden) {
        this.setState({ isHeaderHidden: true });
      }
    } else {
      if (isHeaderHidden) {
        this.setState({ isHeaderHidden: false });
      }
    }
    this.prev = evt.target.scrollTop;
  };
}

export default withWidth()(withTheme(Body));
