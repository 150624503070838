import { AnyAction } from "redux";
import { all, fork, takeEvery } from "redux-saga/effects";

import { logEvent } from "./routines";

function* handleLogEvent(action: AnyAction) {
  const { FB, fbq } = window;
  const {
    payload: { event, data, exclude = [] },
  } = action;

  const eventName = event;

  if (fbq && FB && FB.AppEvents && !exclude.includes("facebook")) {
    if (eventName === "Completed registration") {
      fbq("track", "CompleteRegistration", { UserType: data.UserType });
    } else {
      yield FB.AppEvents.logEvent(eventName, data);
    }
  }
}

function* logEventWatcher() {
  yield takeEvery(logEvent.TRIGGER, handleLogEvent);
}

export function* analyticsSaga() {
  yield all([fork(logEventWatcher)]);
}
