import { Location } from 'history';

export const getQueryStringFromLocation = (location: Location): string => {
  let queryString = location.search;
  if (!queryString) {
    try {
      // try check on the path
      const path = location.pathname;
      const urlObj = new URL(path, window.location.origin);
      queryString = urlObj.search;
    } catch (err) {
      console.warn('Failed to parse path:', location.pathname, err);
      return '';
    }
  }
  return queryString;
}

export const getQueryParamsFromLocation = (location: Location): URLSearchParams => {
  const queryString = getQueryStringFromLocation(location);
  return new URLSearchParams(queryString);
}


export const addUrlPrefix = (url?: string): string => {
  if (!url || url.trim() === "") {
    return process.env.REACT_APP_PRIVACYPOLICY_LANDING_PAGE || "";
  }
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    return `https://${url}`;
  }
  return url;
};