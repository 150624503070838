import React, { useState } from 'react';

import styled, { mediaDown } from '../../utils/styledComponents';
import InfoDialog from '../dialogs/InfoDialog';
import FlowFontAwesomeIcon from '../icons/FlowFontAwesomeIcon';
import Heading from '../typography/Heading';
import Title from '../typography/Title';

const RatingContainer = styled.div`
  margin-top: -10px;
  margin-right: 20px;
  ${mediaDown.sm`margin-right: 10px;`}
  cursor: pointer;
`;

const RatingText = styled(Heading)`
  && {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.grey2};
    font-family: ${({ theme }) => theme.fonts.MS700};
  }
`;

const StyledTitle = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
    margin-top: -7.5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const RatingValue = styled(StyledTitle)`
  && {
    margin-top: -10px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
`;

const RatingIconContainer = styled.div`
  margin-right: 3px;
  margin-top: -7px;
`;

interface OwnProps {
  ratingText: string;
  ratings: number;
}

const StarRating: React.FunctionComponent<OwnProps> = ({
  ratingText,
  ratings
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <InfoDialog
        isOpened={openDialog}
        oClickEvent={onOpenDialog}
        header={`What is a features rating?`}
        content={`Flow calculates a unique building rating using the total of the facilities that the building has to offer.
     Fiber, gym,
     braai area, security and more. See this building's full list when you click View All Apartments.`}
      />
      <RatingContainer onClick={onOpenDialog}>
        <RatingText>{ratingText}</RatingText>
        <RatingWrapper>
          <RatingIconContainer>
            <FlowFontAwesomeIcon name="star" color="#ff7418" />
          </RatingIconContainer>
          <RatingValue>{ratings}</RatingValue>
        </RatingWrapper>
      </RatingContainer>
    </>
  );
};

export default StarRating;
