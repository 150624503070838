import { Field, Form, InjectedFormikProps } from "formik";
import React from "react";
import styled from "../../utils/styledComponents";
import Button from "../buttons/Button";
import TextInput from "../inputs/TextInput";

export interface MessageFormValues {
  isLoading: boolean;
  message: string;
}

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 38px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  position: "relative";
`;
const MessageOptions = styled.div`
  margin: 40px 0 25px;
  text-align: right;
`;
const StyledButton = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: ${({ theme }) => theme.fontSizes.buttonText};
    border-color: #3e6274 !important;
    color: #3e6274 !important;
    padding: 5px 10px;
    margin: 5px 0;
    height: auto;
    &:hover {
      color: #3e6274 !important;
    }
  }
`;

export const MessageForm: React.FunctionComponent<InjectedFormikProps<
  {},
  MessageFormValues
>> = props => {
  const { setFieldValue } = props;
  const setMessageValue = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setFieldValue("message", event.currentTarget?.innerText);
  };

  return (
    <Form>
      <MessageOptions>
        <StyledButton
          type="button"
          variant="outlined"
          onClick={setMessageValue}
        >
          I'd like to view this apartment
        </StyledButton>
        <StyledButton
          type="button"
          variant="outlined"
          onClick={setMessageValue}
        >
          I have a question about this apartment
        </StyledButton>
      </MessageOptions>
      <Field
        type="text"
        label="Message"
        name="message"
        placeholder="Type or select options above"
        component={TextInput}
      />

      <ButtonContainer>
        <ButtonWrapper>
          <Button
            type="submit"
            disabled={props.values.isLoading}
            primary={true}
            fullWidth={true}
            loading={props.values.isLoading}
          >
            Send a message
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </Form>
  );
};

export default MessageForm;
