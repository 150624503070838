import { has } from 'lodash';
import React, { FunctionComponent } from 'react';

import styled from '../../utils/styledComponents';

const Image = styled.div<OwnProps>`
  ${({ size }) => (has(size, "height") ? `height: ${size?.height}` : "")};
  ${({ size }) => (has(size, "width") ? `width: ${size?.width}` : "")};
  
  background-image: url(${({ image }) => image});
  border-radius: ${({ radius }) => radius ? radius : 5}px ;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export interface ImageSize {
  width?: string;
  height?: string;
}

interface OwnProps {
  image: string;
  size?: ImageSize;
  hasBorder?: boolean;
  borderColor?: string;
  radius?: number;
}

const FlowImage: FunctionComponent<OwnProps> = (props) => {
  return (
    <Image
      {...props}
    />
  );
};

export default FlowImage;
