import { isEmpty, omit } from 'lodash';
import React, { FunctionComponent } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Link, LinkProps } from '@material-ui/core';

import { FlowExtra } from '../../store/listings';
import styled from '../../utils/styledComponents';
import ExtraItem from '../listItems/ExtraItem';
import SubTitle from '../typography/SubTitle';
import Title from '../typography/Title';

const StyledDialog = styled(Dialog)`
  && {
    margin: 10px;
    .MuiPaper-root {
      border-radius: 20px;
      margin: 0px;
      width: 100%;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 20px;
    justify-content: center;
    z-index: 0;
  }
`;

const Actions = styled(DialogActions)`
  && {
    padding: 20px;
  }
`;

const DescriptionTitle = styled(Title)`
  && {
    margin-top: 20px;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.formSectionHeading};
    color: ${({ theme }) => theme.colors.grey4};
    line-height: 30px;
    width: 100%;
  }
`;

const Description = styled(SubTitle)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey3};
    text-align: center;
    line-height: 20px;
    margin-top: 5px;
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  && {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }
`;

const ReadMoreLink = styled(
  (props: LinkProps & { hasExternalLink: boolean }) => (
    <Link {...omit(props, ["hasExternalLink"])} />
  )
)`
  ${({ hasExternalLink }) => (hasExternalLink ? "" : "display: none")}
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  && {
    background: #f7f7f7;
    border-radius: 25px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey4};
    text-align: center;
    text-transform: none;
    padding: 23px 13px;
  }
`;

interface OwnActionProps {
  setDialogOpen: (event: React.SyntheticEvent) => void;
}

interface OwnProps {
  open: boolean;
  extraData: FlowExtra;
}

type AllProps = OwnActionProps & OwnProps;

const ExtraInfoDialog: FunctionComponent<AllProps> = ({
  setDialogOpen,
  open,
  extraData
}) => {
  const hasExternalLink = !isEmpty(extraData.externalLink);

  const readMore = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setDialogOpen(event);
    window.open(extraData.externalLink, extraData.descriptionTitle);
  };

  return (
    <StyledDialog open={open} maxWidth="xs" onClose={setDialogOpen}>
      <StyledDialogContent>
        <ExtraItem data={extraData} />
        <DescriptionTitle>{extraData.descriptionTitle} </DescriptionTitle>
        <Description>{extraData.description}</Description>
        <LinkWrapper>
          <ReadMoreLink hasExternalLink={hasExternalLink} onClick={readMore}>
            Read More
          </ReadMoreLink>
        </LinkWrapper>
      </StyledDialogContent>
      <Actions>
        <StyledButton onClick={setDialogOpen}>Done</StyledButton>
      </Actions>
    </StyledDialog>
  );
};

export default ExtraInfoDialog;
