import { History, Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import Grid, { GridProps } from "@material-ui/core/Grid";

import Title from "../components/typography/Title";
import OrDivider from "../components/ui/OrDivider";
import FacebookLoginContainer from "../containers/FacebookLoginContainer";
import GoogleLoginContainer from "../containers/GoogleLoginContainer";
import LoginFormContainer from "../containers/LoginFormContainer";
import SocialRegisterFormContainer from "../containers/SocialRegisterFormContainer";
import { ApplicationState } from "../store";
import {
  AuthenticatedUser,
  loginWithFacebookPromise,
  loginWithGooglePromise,
} from "../store/auth";
import {
  toggleForgotPasswordDialog as toggleForgotPasswordDialogAction,
  toggleLoginDialog,
  toggleRegisterDialog as toggleRegisterDialogAction,
} from "../store/dialogs";
import { enqueueSnackbar } from "../store/notifications";
import styled from "../utils/styledComponents";

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const LinksContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  margin-top: 8px;
  > div {
    margin-top: 0px;
  }
`;

const LinkText = styled.span`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

const OrDividerContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
`;

const OrangeLink = styled.a<{ fontSize?: number }>`
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? `${fontSize}px` : theme.fontSizes.heading};
  color: ${({ theme }) => theme.colors.orange};
  text-decoration: none;
`;

const StyledTitle = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700} !important;
  font-size: ${({ theme }) => theme.fontSizes.title} !important;
  line-height: 1.5em !important;
`;

const SubSectionTitle = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.heading} !important;
  margin-bottom: 15px !important;
`;

const LoginSuggestionDiv = styled.button`
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
  color: ${({ theme }) => theme.colors.orange};
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
`;

const StyledSocialContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div:nth-child(1) {
    margin-right: 20px;
  }
`;

const StyledHr = styled.div`
  && {
    margin-top: 34px;
    margin-bottom: 34px;
    border-bottom: solid thin ${({ theme }) => theme.colors.grey[250]};
  }
`;

interface State {
  accessToken?: string;
  data?: {
    email?: string;
    firstName: string;
    lastName: string;
  };
  provider?: "local" | "google" | "facebook";
  activeStep: number;
}

interface PropsFromDispatch {
  loginWithGooglePromise: typeof loginWithGooglePromise;
  loginWithFacebookPromise: typeof loginWithFacebookPromise;
  toggleForgotPasswordDialog: typeof toggleForgotPasswordDialogAction;
  toggleRegisterDialog: typeof toggleRegisterDialogAction;
  enqueueSnackbar: typeof enqueueSnackbar;
  toggleLoginDialog: typeof toggleLoginDialog;
}

interface ConnectedProps {
  history: History;
}

interface PropsFromState {
  authenticated: boolean;
  user?: AuthenticatedUser;
  location: Location;
}

type AllProps = ConnectedProps & PropsFromDispatch & PropsFromState;

class LoginPageContainer extends React.Component<AllProps, State> {
  public state: State = {
    accessToken: undefined,
    activeStep: 0,
    data: undefined,
    provider: "local",
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (prevProps.authenticated !== this.props.authenticated) {
      this.setState({
        provider: "local",
      });
    }
  };

  public render() {
    const loginCmsLink = `${process.env.REACT_APP_CMS_URL}/login`;
    return (
      <>
        {!this.props.authenticated && this.state.provider === "local" && (
          <>
            <TitleWrapper>
              <StyledTitle>Buyer/Tenant Login</StyledTitle>
              <SubSectionTitle>
                Are you an Agent?{" "}
                <OrangeLink href={loginCmsLink} title="login here">
                  Login here
                </OrangeLink>
              </SubSectionTitle>
            </TitleWrapper>
            <StyledSocialContainer>
              <FacebookLoginContainer
                onError={this.onError}
                onLoginError={this.onFacebookNotRegistered}
              />
              <GoogleLoginContainer
                onError={this.onError}
                onLoginError={this.onGoogleNotRegistered}
              />
            </StyledSocialContainer>
            <OrDividerContainer>
              <OrDivider />
            </OrDividerContainer>
            <LoginFormContainer />
            <LinksContainer container={true} justify="center">
              <Grid item={true}>
                <LinkText>Forgot Password?</LinkText>
                <LoginSuggestionDiv
                  onClick={() => {
                    this.props.toggleForgotPasswordDialog(true);
                  }}
                >
                  Reset here
                </LoginSuggestionDiv>
              </Grid>
            </LinksContainer>
            <StyledHr />
            <LinksContainer container={true} justify="center">
              <Grid item={true}>
                <LinkText>Dont have an account? </LinkText>
                <OrangeLink
                  href="/register"
                  title="register here"
                  fontSize={14}
                >
                  Register
                </OrangeLink>
              </Grid>
            </LinksContainer>
          </>
        )}
        {!this.props.authenticated &&
          (this.state.provider === "google" ||
            this.state.provider === "facebook") && (
            <SocialRegisterFormContainer
              userType="tenant"
              data={this.state.data}
              provider={this.state.provider}
              accessToken={this.state.accessToken}
            />
          )}
      </>
    );
  }

  private onGoogleNotRegistered = async (
    data: {
      email: string;
      firstName: string;
      lastName: string;
    },
    accessToken: string,
    error: string
  ) => {
    if (error === "This user does not exist, please register.") {
      // close modal if opened
      this.props.toggleLoginDialog(false);
      this.props.toggleRegisterDialog(false);
      const { history } = this.props;
      history.push(`/register`, { data, accessToken, provider: "google" });
    }
  };

  private onFacebookNotRegistered = async (
    data: {
      email: string;
      firstName: string;
      lastName: string;
    },
    accessToken: string,
    error: string
  ) => {
    if (error === "This user does not exist, please register.") {
      this.props.toggleLoginDialog(false);
      this.props.toggleRegisterDialog(false);
      const { history } = this.props;
      history.push(`/register`, { data, accessToken, provider: "facebook" });
    }
  };

  private onError = (error: string) => {
    this.props.enqueueSnackbar({
      message: error,
      options: { variant: "error" },
    });
  };
}

const mapStateToProps = ({ auth, router }: ApplicationState) => ({
  errors: auth.errors,
  authenticated: auth.authenticated,
  user: auth.user,
  location: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      enqueueSnackbar,
      toggleLoginDialog,
      toggleForgotPasswordDialog: toggleForgotPasswordDialogAction,
      toggleRegisterDialog: toggleRegisterDialogAction,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer) as any
) as any;
