import React from 'react';
import Helmet from 'react-helmet';

interface MetatagsProps {
  title: string;
  image: string;
  description: string;
}

const MetaTags: React.FunctionComponent<MetatagsProps> = ({ title, image, description }) => {

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={image} />
      <meta name="description" content={description} />
      <meta name= "xrobots" content="noindex, nofollow" />
    </Helmet>
  )
}

export default MetaTags;