import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { addGTMDataLayer } from '../../utils/GoogleTagManager';
import { logEvent } from '../analytics';
import { unsetDeeplink } from '../deeplink';
import { toggleLoginDialog, toggleOTPVerificationDialog, toggleProfileDialog } from '../dialogs';
import { enqueueSnackbar } from '../notifications';
import {
    authenticate, forgotPassword, login, loginWithFacebook, loginWithGoogle, logout, register,
    requestOTP, resetPassword, updateUser, uploadProfileImage, validateToken, verifyOTP
} from './routines';

const INTERCOM_SOURCE = process.env.REACT_APP_INTERCOM_SOURCE;

export const inviteTokenUrl = (): boolean => {
  const url = window.location.href;
  const expression = /(?:\/listings\/enquiry)+\/[a-z0-9]*/gm;
  const isTrue = new RegExp(expression).test(url);
  return isTrue;
};

const mapPayload = (payload: any) => {
  const isTrue = inviteTokenUrl();
  if (isTrue) {
    const inviteToken = window.location.pathname
      .split("/")
      .slice(-1)
      .toString();
    return Object.assign({}, payload, { inviteToken });
  }
  return payload;
};

function* handleAuthenticate(action: AnyAction) {
  try {
    yield put(authenticate.request());

    const token = localStorage.getItem("auth_token");
    if (token) {
      const res = yield call(
        callApi,
        "get",
        `/users/me${action.payload.updateLastLogin ? "?updateLastLogin=true" : ""
        }`
      );

      const decodedToken = jwtDecode(token);
      const requireVerification = _.get(
        decodedToken,
        "verification.contactNumber.required",
        false
      );

      if (res.data.status !== "Verified" && requireVerification) {
        yield put(logout.trigger());
      } else {
        yield put(authenticate.success(res.data));
      }
    }
  } catch (err) {
    if (err.response) {
      yield put(authenticate.failure(errorHandler(err.response)));
    } else {
      yield put(authenticate.failure("An unknown error occured."));
    }
  } finally {
    yield put(authenticate.fulfill());
  }
}

function* handleForgotPassword(action: AnyAction) {
  try {
    yield put(forgotPassword.request());

    yield call(callApi, "post", "/auth/forgot", {
      data: action.payload,
    });

    yield put(forgotPassword.success());
    yield put(
      enqueueSnackbar({
        message: "Password reset email sent",
        options: { variant: "success" },
      })
    );
  } catch (err) {
    console.log(err.response);
    if (err.response) {
      yield put(forgotPassword.failure(errorHandler(err.response)));
    } else {
      yield put(forgotPassword.failure("An unknown error occured."));
    }
  } finally {
    yield put(forgotPassword.fulfill());
  }
}

function* handleLogin(action: AnyAction) {
  try {
    yield put(login.request());
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, "post", "/auth/signin", {
      data: action.payload,
    });

    const isLandlord = res.data.tags.find((tag: any) => {
      return tag.name === "landlord";
    });

    if (isLandlord) {
      yield put(logout.request());
      yield call(callApi, "get", "/auth/signout");

      localStorage.removeItem("auth_token");

      const { FB } = window;

      if (FB) {
        FB.AppEvents.clearUserID();
      }

      yield put(unsetDeeplink());

      yield put(push("/noaccess", {}));
      yield put(logout.success());
    } else {
      if (!res.data.profile) {
        yield put(toggleProfileDialog(true));
      } else {
        yield put(toggleLoginDialog(false));
        yield put(push("/profile", {}));
      }

      const { FB } = window;

      if (FB) {
        FB.AppEvents.setUserID(res.data._id);
      }
      yield put(login.success(res.data));
    }

  } catch (err) {
    if (err.response) {
      yield put(login.failure(errorHandler(err.response)));
    } else {
      yield put(login.failure("An unknown error occured."));
    }
  } finally {
    yield put(login.fulfill());
  }
}

function* handleLoginWithGoogle(action: AnyAction) {
  try {
    yield put(loginWithGoogle.request());

    if (action.payload.register) {
      addGTMDataLayer({
        data: { source: INTERCOM_SOURCE },
        event: "tenant_started_registration",
        page: "register",
      });
    }

    const res = yield call(
      callApi,
      "post",
      `/auth/google?access_token=${action.payload.accessToken}&register=${action.payload.register}`,
      {
        data: mapPayload(action.payload.data),
      }
    );
    yield put(register.success(res.data));
    const { userType } = action.payload;

    if (action.payload.register) {
      yield put(toggleProfileDialog(true));
      yield put(
        logEvent({
          data: {
            UserType: userType,
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName}`,
          },
          event: "Completed registration",
          exclude: ["intercom"],
        })
      );
      addGTMDataLayer({
        data: { source: INTERCOM_SOURCE },
        event: "tenant_completed_registration",
        page: "register",
        user: res.data,
      });
    } else if (!res.data.profile) {
      yield put(toggleProfileDialog(true));
    } else {
      yield put(toggleLoginDialog(false));
    }
  } catch (err) {
    if (err.response) {
      if (
        err.response.status === 409 &&
        err.response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(loginWithGoogle.failure(errorHandler(err.response)));
      }
    } else {
      yield put(loginWithGoogle.failure("An unknown error occured."));
    }
  } finally {
    yield put(loginWithGoogle.fulfill());
  }
}

function* handleLoginWithFacebook(action: AnyAction) {
  try {
    yield put(loginWithFacebook.request());

    if (action.payload.register) {
      addGTMDataLayer({
        data: { source: INTERCOM_SOURCE },
        event: "tenant_started_registration",
        page: "register",
      });
    }

    const res = yield call(
      callApi,
      "post",
      `/auth/facebook?access_token=${action.payload.accessToken}&register=${action.payload.register}`,
      {
        data: mapPayload(action.payload.data),
      }
    );

    yield put(register.success(res.data));
    const { userType } = action.payload;

    if (action.payload.register) {
      yield put(toggleProfileDialog(true));
      yield put(
        logEvent({
          data: {
            UserType: userType,
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName}`,
          },
          event: "Completed registration",
          exclude: ["intercom"],
        })
      );
      addGTMDataLayer({
        data: { source: INTERCOM_SOURCE },
        event: "tenant_completed_registration",
        page: "register",
        user: res.data,
      });
    } else if (!res.data.profile) {
      yield put(toggleProfileDialog(true));
    } else {
      yield put(toggleLoginDialog(false));
    }
  } catch (err) {
    if (err.response) {
      console.log(err);
      if (
        err.response.status === 409 &&
        err.response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(loginWithFacebook.failure(errorHandler(err.response)));
      }
    } else {
      yield put(loginWithFacebook.failure("An unknown error occured."));
    }
  } finally {
    yield put(loginWithFacebook.fulfill());
  }
}

function* handleLogout() {
  try {
    yield put(logout.request());

    // To call async functions, use redux-saga's `call()`.
    yield call(callApi, "get", "/auth/signout");

    localStorage.removeItem("auth_token");

    const { FB } = window;

    if (FB) {
      FB.AppEvents.clearUserID();
    }

    yield put(unsetDeeplink());

    // yield put(push("/listings?listingType=sale", {}));

    yield put(logout.success());
    yield put(push("/login", {}));
  } catch (err) {
    if (err.response) {
      yield put(logout.failure(errorHandler(err.response)));
    } else {
      yield put(logout.failure("An unknown error occured."));
    }
  } finally {
    yield put(logout.fulfill());
  }
}

function* handleRegister(action: AnyAction) {
  try {
    yield put(register.request());
    // To call async functions, use redux-saga's `call()`.
    const { userType } = action.payload;
    const res = yield call(callApi, "post", "/auth/signup", {
      data: mapPayload(action.payload),
    });

    yield put(toggleProfileDialog(true));
    yield put(
      logEvent({
        data: {
          UserType: userType,
          email: res.data.email,
          name: `${res.data.firstName} ${res.data.lastName}`,
        },
        event: "Completed registration",
        exclude: ["intercom"],
      })
    );
    addGTMDataLayer({
      data: { source: INTERCOM_SOURCE },
      event: "tenant_completed_registration",
      page: "register",
      user: res.data,
    });
    yield put(register.success(res.data));

    return res.data;
  } catch (err) {
    if (err.response) {
      console.log(err);
      if (
        err.response.status === 409 &&
        err.response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(register.failure(errorHandler(err.response)));
      }
    } else {
      yield put(register.failure("An unknown error occured."));
    }
  } finally {
    yield put(register.fulfill());
  }
}

function* handleResetPassword(action: AnyAction) {
  try {
    yield put(resetPassword.request());

    yield call(callApi, "post", `/auth/reset/${action.payload.token}`, {
      data: action.payload.data,
    });

    yield put(resetPassword.success());
    yield put(push("/listings?listingType=sale"));
    yield put(
      enqueueSnackbar({
        message: "Password succesfully updated",
        options: { variant: "success" },
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(resetPassword.failure(err.response.data.message));
    } else if (err.response) {
      yield put(resetPassword.failure(errorHandler(err.response)));
    } else {
      yield put(resetPassword.failure("An unknown error occured."));
    }
  } finally {
    yield put(resetPassword.fulfill());
  }
}

function* handleValidateToken(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", `/auth/reset/${action.payload}`);

    if (res.error) {
      yield put(validateToken.failure(res.error));
    } else {
      yield put(validateToken.success());
    }
  } catch (err) {
    console.log(err.response.status);
    if (err.response) {
      yield put(validateToken.failure(errorHandler(err.response)));
    } else {
      yield put(validateToken.failure("An unknown error occured."));
    }
  } finally {
    yield put(validateToken.fulfill());
  }
}

function* handleUpdateUser(action: AnyAction) {
  try {
    yield put(updateUser.request());

    const res = yield call(callApi, "put", `/users`, { data: action.payload });

    addGTMDataLayer({
      data: res.data.profile,
      event: "tenant_completed_profile",
      page: "tenant-demographics-profile",
    });

    yield put(updateUser.success(res.data));
    if (inviteTokenUrl()) {
      yield put(push("/enquiries", {}));
    }
    yield put(
      enqueueSnackbar({
        message: "Profile updated successful",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    console.log(err.response.status);
    if (err.response.status === 400) {
      yield put(updateUser.failure(err.response.data.message));
    } else if (err.response) {
      yield put(updateUser.failure(errorHandler(err.response)));
    } else {
      yield put(updateUser.failure("An unknown error occured."));
    }
    yield put(
      enqueueSnackbar({
        message: "Profile failed to update",
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    yield put(updateUser.fulfill());
  }
}

function* handleUploadProfileImage(action: AnyAction) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload);

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = yield call(callApi, "post", `/users/picture`, config);

    yield put(uploadProfileImage.success(res.data));
  } catch (err) {
    console.log(err.response.status);
    if (err.response.status === 400) {
      yield put(uploadProfileImage.failure(err.response.data.message));
    } else if (err.response) {
      yield put(uploadProfileImage.failure(errorHandler(err.response)));
    } else {
      yield put(uploadProfileImage.failure("An unknown error occured."));
    }
  } finally {
    yield put(uploadProfileImage.fulfill());
  }
}

function* handleVerifyOTP(action: AnyAction) {
  try {
    yield put(verifyOTP.request());

    const res = yield call(callApi, "post", `/auth/verify`, {
      data: action.payload,
    });

    yield put(verifyOTP.success(res.data));
    yield put(toggleOTPVerificationDialog(false));
    yield put(
      logEvent({
        event: "User_Verified_Mobile_Success",
      })
    );
  } catch (err) {
    yield put(
      logEvent({
        event: "User_Verified_Mobile_Failed",
      })
    );
    if (err.response.status === 400) {
      yield put(verifyOTP.failure(err.response.data.message));
    } else if (err.response) {
      yield put(verifyOTP.failure(errorHandler(err.response)));
    } else {
      yield put(verifyOTP.failure("An unknown error occured."));
    }
  } finally {
    yield put(verifyOTP.fulfill());
  }
}

function* handleRequestOTP() {
  try {
    yield put(requestOTP.request());

    const res = yield call(callApi, "get", `/auth/verify`);

    yield put(
      enqueueSnackbar({
        message: res.data.message,
        options: { variant: "success" },
      })
    );

    yield put(requestOTP.success(res.data));
    yield put(
      logEvent({
        event: "User_Requested_Mobile_Verification",
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(requestOTP.failure(err.response.data.message));
    } else if (err.response) {
      yield put(requestOTP.failure(errorHandler(err.response)));
    } else {
      yield put(requestOTP.failure("An unknown error occured."));
    }
  } finally {
    yield put(requestOTP.fulfill());
  }
}

function* handleShowError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: errorHandler(action.payload),
      options: { variant: "error" },
    })
  );
}

function* postAuth(action: AnyAction) {
  const isLandlord = action.payload.tags.find((tag: any) => {
    return tag.name === "landlord";
  });

  if (isLandlord) {
    yield put(logout.request());
    localStorage.removeItem("auth_token");

    const { FB } = window;

    if (FB) {
      FB.AppEvents.clearUserID();
    }

    yield put(unsetDeeplink());
    yield put(push("/noaccess", {}));
    yield put(logout.success());
  } else {
    const { FB } = window;

    if (FB) {
      FB.AppEvents.setUserID(action.payload._id);
    }

    if (!!action.payload.profile && action.payload.status !== "Verified") {
      // Force Verification check to run
      // todo.revisit logical flow.
      yield put(toggleOTPVerificationDialog(false)); // disable OTP
    }
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* authenticateWatcher() {
  yield takeEvery(authenticate.TRIGGER, handleAuthenticate);
}

function* forgotPasswordWatcher() {
  yield takeEvery(forgotPassword.TRIGGER, handleForgotPassword);
}

function* loginWatcher() {
  yield takeEvery(login.TRIGGER, handleLogin);
}

function* loginWithGoogleWatcher() {
  yield takeEvery(loginWithGoogle.TRIGGER, handleLoginWithGoogle);
}

function* loginWithFacebookWatcher() {
  yield takeEvery(loginWithFacebook.TRIGGER, handleLoginWithFacebook);
}

function* logoutWatcher() {
  yield takeEvery(logout.TRIGGER, handleLogout);
}

function* registerWatcher() {
  yield takeEvery(register.TRIGGER, handleRegister);
}

function* resetPasswordWatcher() {
  yield takeEvery(resetPassword.TRIGGER, handleResetPassword);
}

function* validateTokenWatcher() {
  yield takeEvery(validateToken.TRIGGER, handleValidateToken);
}

function* updateUserWatcher() {
  yield takeEvery(updateUser.TRIGGER, handleUpdateUser);
}

function* uploadProfileImageWatcher() {
  yield takeEvery(uploadProfileImage.TRIGGER, handleUploadProfileImage);
}

function* requestOTPWatcher() {
  yield takeEvery(requestOTP.TRIGGER, handleRequestOTP);
}

function* verifyOTPWatcher() {
  yield takeEvery(verifyOTP.TRIGGER, handleVerifyOTP);
}

function* showErrorWatcher() {
  yield takeEvery(
    [
      loginWithFacebook.FAILURE,
      loginWithGoogle.FAILURE,
      login.FAILURE,
      forgotPassword.FAILURE,
      resetPassword.FAILURE,
      register.FAILURE,
    ],
    handleShowError
  );
}

function* postAuthWatcher() {
  yield takeEvery([login.SUCCESS, register.SUCCESS], postAuth);
}

// We can also use `fork()` here to split our saga into multiple watchers.
export function* authSaga() {
  yield all([
    fork(postAuthWatcher),
    fork(authenticateWatcher),
    fork(forgotPasswordWatcher),
    fork(loginWatcher),
    fork(loginWithGoogleWatcher),
    fork(loginWithFacebookWatcher),
    fork(logoutWatcher),
    fork(registerWatcher),
    fork(resetPasswordWatcher),
    fork(validateTokenWatcher),
    fork(updateUserWatcher),
    fork(uploadProfileImageWatcher),
    fork(requestOTPWatcher),
    fork(verifyOTPWatcher),
    fork(showErrorWatcher),
  ]);
}
