import React from "react";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import FlowFontAwesomeIcon from "../icons/FlowFontAwesomeIcon";
import Title from "../typography/Title";

interface ImageBackgroundProps {
  image?: string;
  backgroundImage?: string;
  description?: string;
}
interface OwnProps {
  closeDialog?: (event?: any) => void;
  TransitionComponent?: any;
  enquiry?: boolean;
  component?: any;
}

type AllProps = OwnProps & ImageBackgroundProps & DialogProps;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      width: 100%;
      border-radius: 20px;
      min-height: 555px;
      max-width: 750px;
      margin: 20px;
      ${mediaDown.sm`
        background: white;
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        margin: 0;
      `};
    }
  }
`;
const DialogContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
`;

const DialogColumn = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth ? mediaUp.sm`width: 100%;` : mediaUp.sm`width: 50%;`};
  ${mediaDown.sm`
    width: 100%;
  `}
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500} !important;
  font-size: ${({ theme }) => theme.fontSizes.formSectionHeading} !important;
  line-height: 1.5em !important;
  text-align: center;
`;

const ImageBackground = styled(DialogColumn)<ImageBackgroundProps>`
  ${mediaDown.sm`display: none;`}
  background-color: #ffefe5;
  ${({ backgroundImage }) =>
    backgroundImage && `background: url("${backgroundImage}")`};
  background-position: center;
  background-size: cover;
  justify-content: center;
  width: 100%;
`;
const Container = styled.div`
  padding: 35px;
  align-self: center;
`;
const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`;
const Content = styled.div<{ fullWidth?: boolean }>`
  && {
    ${({ fullWidth }) => (fullWidth ? `padding: 0` : `padding: 35px`)}
  }
`;

const CloseButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  color: ${({ theme }) => theme.colors.grey2};
  background: none;
  border: none;
  position: absolute;
  top: 30px;
  right: 30px;
  ${mediaDown.sm`
    top: 20px;
    right: 20px;`}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWithImage: React.FunctionComponent<AllProps> = ({
  image,
  backgroundImage,
  children,
  enquiry,
  component,
  description,
  closeDialog,
  ...props
}) => {
  return (
    <StyledDialog TransitionComponent={Transition} {...props}>
      <DialogContentContainer>
        {closeDialog && (
          <CloseButton title="Close" onClick={closeDialog}>
            <FlowFontAwesomeIcon name="times" />
          </CloseButton>
        )}
        <ImageBackground image={image} backgroundImage={backgroundImage}>
          {image && (
            <Container>
              <Image src={image} />
            </Container>
          )}

          {description && (
            <Container>
              <StyledText>{description}</StyledText>
            </Container>
          )}
        </ImageBackground>
        <DialogColumn>
          <Content>{children}</Content>
        </DialogColumn>
      </DialogContentContainer>
    </StyledDialog>
  );
};

export default DialogWithImage;
