import { omit } from 'lodash';
import * as React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled, { mediaDown } from '../../utils/styledComponents';
import { HeaderContextConsumer } from '../context/HeaderContext';

interface OwnProps {
  backgroundColor?: string;
  hidden?: boolean;
}

export type HeaderInterface = GridProps & OwnProps;

const Wrapper = styled((props: HeaderInterface) => (
  <Grid {...omit(props, ["backgroundColor", "hidden"])} />
))`
  && {
    min-height: 80px;
    position: absolute;
    top: ${({ hidden }) => (hidden ? "-112px" : "0px")};
    padding: 30px 56px;
    background: ${({ backgroundColor, theme }) =>
      backgroundColor ? `${backgroundColor}` : theme.colors.white};
    z-index: 1;
    transition: top 0.5s;

    ${mediaDown.lg`
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
  `}

    ${mediaDown.md`
     padding-left: 20px;
     padding-right: 20px;
     padding-top: 20px;
     padding-bottom: 0px;
  `}
  }
`;

const Header: React.FunctionComponent<any> = ({
  backgroundColor,
  children,
  ...props
}) => (
  <HeaderContextConsumer>
    {context => (
      <Wrapper
        container={true}
        hidden={context.isHeaderHidden}
        backgroundColor={backgroundColor}
        {...props}
      >
        {children}
      </Wrapper>
    )}
  </HeaderContextConsumer>
);

export default Header;
