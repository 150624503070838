import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

import styled, { mediaDown } from "../../utils/styledComponents";
import Button from "../buttons/Button";
import CookieIconSVG from "../icons/CookieIconSVG";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";
import Link from "../links/Link";
import { get } from "lodash";
import { addUrlPrefix } from "../../utils/url.helper";

const StyledSnackbar = styled(Snackbar)`
  && {
    width: 100%;
    height: 75px;
    bottom: 0;
    position: relative;
    background-color: white;
    box-shadow: 0 5px 7px 15px rgb(0 0 0 / 3%);
    ${mediaDown.sm`
  left: 0px;
  right: 0px;
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  bottom: 75px;
  `}
    ${mediaDown.md`
   bottom: 10px; 
  `}
  }
`;

const Container = styled.div`
  && {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1180px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Section = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const MessageBox = styled.div`
  && {
    width: 100%;
    margin-right: 10px;
    padding-left: 20px;
    line-height: 21px;
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 14px;
    ${mediaDown.md` padding-left: 0px;`};
    ${mediaDown.sm`font-size: 14px;`}
  }
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 10px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    border: 0;
    width: 60px;
    height: 48px;
  }
`;

const StyledCookieIconSVG = styled(CookieIconSVG)`
  && {
    flex-shrink: 0;
    ${mediaDown.md`display: none`};
  }
`;

export interface CookieSnackBarProps {
  open: boolean;
  organisation?: object;
  onClose: (event: React.SyntheticEvent) => void;
}

const CookieSnackBar: React.FunctionComponent<CookieSnackBarProps> = ({
  open,
  onClose,
  organisation,
}) => {
  const colors = getWhiteLabelOrganisationColors(organisation);
  const orgPrivacyPolicy = get(organisation, "privacyPolicyUrl");
  const privacyPolicy = addUrlPrefix(orgPrivacyPolicy);

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      key={"bottomcenter"}
    >
      <Container>
        <Section>
          <StyledCookieIconSVG />
          <MessageBox>
            Flow uses cookies to ensure you get the best experience on our
            website.{" "}
            <Link
              target={"_blank"}
              href={privacyPolicy}
              orgColors={colors}
              primary
            >
              Learn more
            </Link>
          </MessageBox>
        </Section>
        <Section>
          <StyledButton
            type="submit"
            primary={true}
            outline={true}
            onClick={onClose}
            orgColors={colors}
          >
            OK
          </StyledButton>
        </Section>
      </Container>
    </StyledSnackbar>
  );
};

export default CookieSnackBar;
