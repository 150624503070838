import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import TenantDemographicsFormContainer from "../../containers/TenantDemographicsFormContainer";
import { ApplicationState } from "../../store";
import { enqueueSnackbar } from "../../store/notifications";
import MetaTags from "../../components/router/MetaTags";

interface PropsFromDispatch {
  enqueueSnackbar: typeof enqueueSnackbar;
}
interface PropsFromState {
  errors?: string;
  loading: boolean;
  authenticated: boolean;
}

interface State {
  accessToken?: string;
  data?: {
    email?: string;
    firstName: string;
    lastName: string;
  };
  provider?: "local" | "google" | "facebook";
  activeStep: number;
}

type AllProps = PropsFromState & PropsFromDispatch;

class ProfileIndexPage extends React.Component<AllProps, State> {
  public static getDerivedStateFromProps(
    nextProps: AllProps,
    prevState: State
  ) {
    return null;
  }
  public state: State = {
    accessToken: undefined,
    activeStep: 0,
    data: undefined,
    provider: undefined,
  };

  public render() {
    return (
      <>
      <MetaTags title="Flow - Profile" image="" description="" />
        <TenantDemographicsFormContainer />;
      </>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
  authenticated: auth.authenticated,
  errors: auth.errors,
  loading: auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ enqueueSnackbar }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileIndexPage);
