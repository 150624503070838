import { Field, Form, InjectedFormikProps } from 'formik';
import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import ContactNumberWithCountryCode from '../../components/inputs/contactNumberWithCode';
import { fetchActiveLocalesPromise } from '../../store/defaults';
import styled from '../../utils/styledComponents';
import { UserTypeButton } from '../buttons';
import Button from '../buttons/Button';
import TextInput from '../inputs/TextInput';
import ErrorMessage from '../ui/ErrorMessage';
import { FieldWrapper, FormFieldsContainer } from '../ui/forms';
import StepperIcons from './StepperIcons';

export interface SocialRegisterFormValues {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  userType: string;
  referral?: { action: string; type: string; referralCode: string };
  invite?: { token: string };
  acceptTC: boolean;
  tags?: string[];
  Source?: string;
  UserType?: string;
}

interface OwnProps {
  typeSelectDisabled?: boolean;
}

type AllProps = InjectedFormikProps<{}, SocialRegisterFormValues> & OwnProps;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.grey3};
  margin: 10px 0 10px 0;
`;

const ButtonGrid = styled(Grid)`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  padding: 35px 20px 0;
  display: flex;
`;

const FormFieldsWrapper = styled(FormFieldsContainer)`
  padding: 0 35px;
`;

const StyledFieldWrapper = styled(FieldWrapper)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ theme }) => theme.fontSizes.navbarItem};
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const IntroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
  color: ${({ theme }) => theme.colors.grey4};
  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const SocialRegisterForm: React.FunctionComponent<AllProps> = props => {
  const { isSubmitting, values, setFieldValue, typeSelectDisabled } = props;

  const handleUserTypeChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const input = event.target as HTMLElement;
    const value = input.innerText.toLowerCase();
    setFieldValue("userType", value);
  };

  const renderUserTypeButton = (userType: string, selectedType: string) => {
    return (
      <UserTypeButton
        onClick={handleUserTypeChange}
        isActive={userType.toLowerCase() === selectedType}
        fullWidth={true}
      >
        {userType}
      </UserTypeButton>
    );
  };

  return (
    <Form>
      <FormFieldsWrapper spacing={4}>
        <IntroTitle>Profile Information</IntroTitle>
        <StyledFieldWrapper item={true} xs={12}>
          Use your profile information to ensure you qualify for the best apartments . Once your profile is created , the hard work is done and you can use the same profile for multiple applications
        </StyledFieldWrapper>
        {!typeSelectDisabled && (
          <StyledFieldWrapper item={true} xs={12}>
            <div>
              <Header>Select</Header>
              <ButtonGrid spacing={1} container={true}>
                <ButtonGrid item={true}>
                  {renderUserTypeButton("Tenant", values.userType)}
                </ButtonGrid>
                <ButtonGrid item={true}>
                  {renderUserTypeButton("Landlord", values.userType)}
                </ButtonGrid>
              </ButtonGrid>
            </div>
            <div>
              <ErrorMessage name="userType" />
            </div>
          </StyledFieldWrapper>
        )}
        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="text"
            label="First Name"
            name="firstName"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="text"
            label="Last Name"
            name="lastName"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <Field
            type="email"
            label="Email"
            name="email"
            component={TextInput}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper item={true} xs={12}>
          <ContactNumberWithCountryCode
            name="contactNumber"
            disabled={false}
            required={true}
            locales={[]}
            isValid={true}
            fetchLocales={fetchActiveLocalesPromise}
            handleOnChange={e => setFieldValue('contactNumber', e)}
            handleOnBlur={e => setFieldValue('contactNumber', e)}
          />
        </StyledFieldWrapper>
      </FormFieldsWrapper>
      <ButtonWrapper>
        <StepperIcons steps={2} currentStep={1} />
        <ButtonContainer>
          <Button
            type="submit"
            disabled={isSubmitting}
            primary={true}
            loading={isSubmitting}
          >
            Next
          </Button>
        </ButtonContainer>
      </ButtonWrapper>
    </Form>
  );
};

export default SocialRegisterForm;
