import { Reducer } from 'redux';

import { validateInviteToken } from './routines';
import { InvitesState } from './types';

const initialState: InvitesState = {
  errors: undefined,
  invites: [],
  loading: false,
  userDetails: undefined,
  inviteType: undefined,
  invitedBy: undefined,
};

const reducer: Reducer<InvitesState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    case validateInviteToken.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success

    case validateInviteToken.SUCCESS: {
      return {
        ...state,
        invitedBy: action.payload.invitedBy,
        userDetails: action.payload.userDetails,
        inviteType: action.payload.type,
      };
    }
    // Failure

    case validateInviteToken.FAILURE: {
      return { ...state, user: undefined, errors: action.payload };
    }

    // Fulfill

    case validateInviteToken.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as invitesReducer };
