import axios from 'axios';
import has from 'lodash/has';

const API_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Accept-Version": "v1",
    "Content-Type": "application/json"
  },
  withCredentials: true
});


API.interceptors.response.use(
  response => {
    // Set header in global axios
    const AUTH_TOKEN = response.headers["authorization-token"];
    if (AUTH_TOKEN) {
      axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
      localStorage.setItem("auth_token", AUTH_TOKEN);
    }

    return response;
  },

  error => {
    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  config => {
    const token = localStorage.getItem("auth_token");
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export default function callApi(method: string, path: string, options?: any) {
  // todo: refactor logic && find a better implementation --Nginx
  if (has(options, "pullFromListings") && options.pullFromListings) {
    const baseURL = process.env.REACT_APP_LISTINGS_API_URL;
    // Remove token from API call since the listings admin throws an error if a token is sent with the request
    delete axios.defaults.headers.common["Authorization"];
    return axios.get(`${baseURL}${path}`);
  }

  const axiosOptions = {
    data: {},
    method,
    params: {},
    url: path,
    ...options
  };
  return API(axiosOptions);
}
