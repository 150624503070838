import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { all, fork } from 'redux-saga/effects';

import { behaviourReducer, behaviourSaga, BehaviourState } from './activities';
import { analyticsSaga } from './analytics';
import { authReducer, authSaga, AuthState } from './auth';
import { chatReducer, chatSaga, ChatState } from './chat';
import { deeplinkReducer, deeplinkSaga, DeeplinkState } from './deeplink';
import { DafaultState, defaultReducer, defaultSaga } from './defaults';
import { dialogsReducer, dialogsSaga, DialogsState } from './dialogs';
import { inviteSaga, invitesReducer, InvitesState } from './invites';
import { leaseReducer, leaseSaga, LeaseState } from './leases';
import { listingsReducer, listingsSaga, ListingsState } from './listings';
import { navigationReducer, navigationSaga, NavigationState } from './navigation';
import { notificationReducer, notificationSaga, NotificationState } from './notifications';
import { organisationReducer, organisationSaga, OrganisationState } from './organisation';
import { referralReducer, referralSaga, ReferralState } from './referral';
import { campaignsSaga, CampaignsState, campasignsReducer } from './rewards';
import { unitsReducer, unitsSaga, UnitsState } from './units';
import { guestyReducer, guestySaga, GuestyState } from './guesty';

// The top-level state object
export interface ApplicationState {
  activities: BehaviourState;
  auth: AuthState;
  chats: ChatState;
  defaults: DafaultState;
  dialogs: DialogsState;
  navigation: NavigationState;
  notifications: NotificationState;
  router: RouterState;
  referral: ReferralState;
  deeplink: DeeplinkState;
  listing: ListingsState;
  leases: LeaseState;
  reward: CampaignsState;
  invites: InvitesState;
  organisation: OrganisationState;
  units: UnitsState;
  guesty: GuestyState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = (history: History) =>
  combineReducers<ApplicationState>({
    activities: behaviourReducer,
    leases: leaseReducer,
    auth: authReducer,
    chats: chatReducer,
    defaults: defaultReducer,
    deeplink: deeplinkReducer,
    dialogs: dialogsReducer,
    listing: listingsReducer,
    referral: referralReducer,
    navigation: navigationReducer,
    notifications: notificationReducer,
    reward: campasignsReducer,
    invites: invitesReducer,
    organisation: organisationReducer,
    router: connectRouter(history),
    units: unitsReducer,
    guesty: guestyReducer
  });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(analyticsSaga),
    fork(behaviourSaga),
    fork(authSaga),
    fork(deeplinkSaga),
    fork(chatSaga),
    fork(defaultSaga),
    fork(dialogsSaga),
    fork(referralSaga),
    fork(navigationSaga),
    fork(notificationSaga),
    fork(routinePromiseWatcherSaga),
    fork(listingsSaga),
    fork(leaseSaga),
    fork(inviteSaga),
    fork(campaignsSaga),
    fork(organisationSaga),
    fork(unitsSaga),
    fork(guestySaga)
  ]);
}
