import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import isEmpty from "lodash/isEmpty";

import configureStore from "./configureStore";
import Main from "./main";
import registerServiceWorker from "./registerServiceWorker";
import GlobalStyles from "./styles";
import { initializeGTM } from "./utils/GoogleTagManager";

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});
const store = configureStore(history);
const token = localStorage.getItem("auth_token");

const rootElement = document.getElementById("root");
const App = (
  <React.Fragment>
    <GlobalStyles />
    <Main store={store} history={history} />
  </React.Fragment>
);

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(App, rootElement);
} else {
  ReactDOM.render(App, rootElement);
}

registerServiceWorker();
if (isEmpty(token)) {
  initializeGTM();
}
