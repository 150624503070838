import _ from 'lodash';

export const addressAsString = (address: {
  line1?: string;
  line2?: string;
  suburb?: string;
  city: string;
}): string => {
  if (address) {
    return `${address.line1 ? `${address.line1}, ` : ""}
    ${address.suburb ? `${address.suburb}, ` : ""}
    ${address.city}`;
  }
  return "";
};

export const getAddressDetailsFromListing = (listingAddress: any) => {
  let address = '';

  let { line1, line2, suburb, city, province } = listingAddress;
  if (_.trim(line2) === '0') line2 = '';

  const addressArr = [line1, line2, suburb, city];
  const haveNulls = addressArr.some((value) => _.isEmpty(value));

  // Add province if address is not complete
  if (haveNulls) {
    addressArr.push(province);
  }
  address = _.join(
    _.map(_.filter(addressArr, (item) => !_.isEmpty(_.trim(item))), (item) =>
      _.trim(item)
    ),
    ', '
  );

  return address;
}