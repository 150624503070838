import _, { get, has, isEmpty, kebabCase } from "lodash";
import numeral from "numeral";
import queryString from "query-string";

import { ListingsFilterFormValues } from "../components/forms/ListingsFilterForm";

/**
 *
 *
 * @export
 * @param {string} query
 * @returns
 */
export function mapQueryToValues(query: string) {
  const queryObject: any = queryString.parse(decodeURI(query));
  const {
    Cities_in,
    organisationName,
    Suburbs_in,
    MinPrice_gte,
    MaxPrice_lte,
    Beds_in,
    Baths_in,
    PropertyTypes_in,
    landlord,
    organisation,
    _sort,
  } = queryObject;

  const params = getParams(window.location.pathname);
  const listingType: string = get(
    queryObject,
    "listingType",
    get(params, "listingType", "sale")
  );
  const listingSector: string = get(
    queryObject,
    "listingSector",
    get(params, "listingSector", "residential")
  );
  const priceString: string = `${priceFormat(MinPrice_gte)} - ${priceFormat(
    MaxPrice_lte
  )}`;

  const priceRange: string = new RegExp(/[0-9]/).test(priceString)
    ? priceString
    : "";

  const cities: string[] = _.isEmpty(Cities_in)
    ? []
    : String(Cities_in).split(",");
  const suburbs: string[] = _.isEmpty(Suburbs_in)
    ? []
    : String(Suburbs_in).split(",");

  const beds: string = _.isEmpty(Beds_in) ? "" : String(Beds_in);
  const baths: string = _.isEmpty(Baths_in) ? "" : String(Baths_in);
  const sort: string = _.isEmpty(_sort) ? "" : String(_sort);
  const propertyTypes: string[] = _.isEmpty(PropertyTypes_in)
    ? []
    : [...(PropertyTypes_in.split(",") || PropertyTypes_in)].map(
        (key: string) => _.startCase(key)
      );
  const features: string[] = Object.keys(
    _.omit(queryObject, [
      "_start",
      "_limit",
      "Cities_in",
      "Suburbs_in",
      "MinPrice_gte",
      "MaxPrice_lte",
      "Beds_in",
      "Baths_in",
      "PropertyTypes_in",
      "landlord",
      "organisation",
      "organisationName",
      "listingType",
      "listingSector",
    ])
  );

  return {
    baths,
    beds,
    cities,
    features,
    listingType,
    listingSector,
    organisationName,
    landlord,
    priceRange,
    propertyTypes,
    sort,
    suburbs,
    organisation,
  };
}

export const priceFormat = (price: any) => {
  return price ? `R${numeral(price).format("0,0")}` : "";
};

/**
 *
 *
 * @export
 * @param {string} query
 * @returns
 */
export function mapQueryToPayload(query: string) {
  const formValues = mapQueryToValues(query);

  if (_.isEmpty(formValues.priceRange)) {
    return formValues;
  } else {
    const { minPrice, maxPrice } = mapPrice(formValues.priceRange);
    return {
      ...formValues,
      maxPrice,
      minPrice,
    };
  }
}

export const getParams = (pathname: string): any => {
  const urlArray = pathname.trim().split("/");
  /** DOTO: this will change in future
   * 1 : listings
   * 2 : organisationName
   * 3 : listingSector
   * 4 : listingType
   */

  return urlArray.includes("listings")
    ? {
        organisationName: get(urlArray, "[2]", undefined),
        listingSector: get(urlArray, "[3]", undefined),
        listingType: get(urlArray, "[4]", undefined),
      }
    : undefined;
};

export const mapPropertyType = (type: string, reverse?: boolean) => {
  const queryString = reverse
    ? type.toString().trim().toLowerCase().replace(/-/g, " ")
    : type.toString().trim().toLowerCase().replace(/\s/g, "-");
  return type.includes("Apartment") ? `${queryString},building` : queryString;
};

export const mapPrice = (priceRange: string) => {
  const priceMinMax = priceRange
    .split("-")
    .map((x) => x.replace(/[^0-9]/g, ""));
  const minPrice = priceMinMax[0];
  const maxPrice = priceMinMax[1];

  return {
    minPrice,
    maxPrice,
  };
};

export const isGuest = () => {
  const token = localStorage.getItem("auth_token");
  return isEmpty(token);
};

/**
 *
 *
 * @export
 * @param {ListingsFilterFormValues} values
 * @returns
 */
export function mapValuesToQuery(values: ListingsFilterFormValues) {
  const {
    cities,
    suburbs,
    priceRange,
    beds,
    baths,
    features,
    propertyTypes,
    landlord,
    listingSector,
    listingType,
    organisation,
    organisationName,
  } = values;

  const pathParams = getParams(window.location.pathname);
  const urlParams = new URLSearchParams(window.location.search);
  const maxPriceLte = urlParams.get("MaxPrice_lte");
  const minPriceGte = urlParams.get("MinPrice_gte");
  const query: any = {
    limit: 10,
    start: 0,
  };

  if (!_.isEmpty(cities)) {
    query.cities = cities.join(",");
  }

  if (!_.isEmpty(suburbs)) {
    query.suburbs = suburbs.join(",");
  }

  if (!_.isEmpty(priceRange)) {
    const { minPrice, maxPrice } = mapPrice(priceRange);
    query.minPrice = minPrice;
    query.maxPrice = maxPrice;
  } else {
    !isEmpty(minPriceGte)
      ? delete query.MinPrice_gte
      : (query.MinPrice_gte = minPriceGte);

    !isEmpty(maxPriceLte)
      ? delete query.MaxPrice_lte
      : (query.MaxPrice_lte = maxPriceLte);
  }

  if (!_.isEmpty(beds)) {
    query.beds = beds;
  }

  if (!_.isEmpty(propertyTypes)) {
    query.propertyTypes = propertyTypes;
  }

  if (!_.isEmpty(baths)) {
    query.baths = baths;
  }

  if (!_.isEmpty(features)) {
    query.features = features;
  }

  if (!_.isEmpty(landlord)) {
    query.landlord = landlord;
  }

  if (!_.isEmpty(organisation)) {
    query.organisation = organisation;
  }

  if (!_.isEmpty(organisationName) && !pathParams.organisationName) {
    query.organisationName = organisationName;
  }

  if (!_.isEmpty(listingType) && !pathParams.listingType) {
    query.listingType = listingType.toLowerCase();
  }

  if (!_.isEmpty(listingSector) && !pathParams.listingSector) {
    query.listingSector = listingSector;
  }

  return query;
}

const getLogo = (property: any) => {
  if (
    has(property, "syndicator.agencyLogo") &&
    !_.isEmpty(property.syndicator.agencyLogo)
  ) {
    return property.syndicator.agencyLogo;
  } else if (
    has(property, "images") &&
    !_.isEmpty(property.images) &&
    has(property.images[0], "media.sizes.thumbnail")
  ) {
    return property.images[0].media.sizes.thumbnail;
  } else {
    return `https://via.placeholder.com/150/000000/FFFFFF/?text=${property.title}`;
  }
};

export const mapInviteCard = (response: any) => {
  const { agent, property, tenant } = response.data;
  const initials = agent.firstName.charAt(0) + agent.lastName.charAt(0);

  return {
    address: `${
      has(property, "address.suburb") ? property.address.suburb + ", " : ""
    } ${has(property, "address.city") ? property.address.city : ""}`,
    landlordAvator:
      (agent.profileImage && agent.profileImage.publicUrl) ||
      `https://via.placeholder.com/150/d8d8d8/FFFFFF/?text=${initials}`,
    landlordLogo: getLogo(property),
    landlordName: `${agent.firstName} ${agent.lastName}`,
    propertyName: property.title,
    propertyThumbnail:
      has(property, "images") &&
      !_.isEmpty(property.images) &&
      has(property.images[0], "media.sizes.thumbnail")
        ? property.images[0].media.sizes.thumbnail
        : `https://via.placeholder.com/150/000000/FFFFFF/?text=Unit`,

    shortDescription: property.shortDescription,
    tenantInfo: tenant,
  };
};

/**
 *
 *
 * @export
 * @param {*} payload
 * @returns
 */
export function buildQuery(payload: any) {
  const {
    landlord,
    cities,
    suburbs,
    minPrice,
    maxPrice,
    priceRange,
    beds,
    baths,
    features,
    propertyTypes,
    listingType,
    listingSector,
    MaxPrice_lte,
    MinPrice_gte,
    organisation,
    organisationName,
    agentUser,
    status,
  } = payload;
  let query: string = "";

  function getDelimiter(q: string) {
    return _.isEmpty(q) ? "?" : "&";
  }

  if (!_.isEmpty(agentUser)) {
    query += `${getDelimiter(query)}agentUser=${agentUser}`;
  }

  if (!_.isEmpty(organisationName)) {
    query += `${getDelimiter(query)}organisationName=${organisationName}`;
  }

  if (!_.isEmpty(status)) {
    query += `${getDelimiter(query)}status=${status}`;
  }

  if (priceRange) {
    const { minPrice, maxPrice } = mapPrice(priceRange);
    if (!_.isEmpty(minPrice) || !_.isEmpty(MinPrice_gte)) {
      query += `${getDelimiter(query)}MinPrice_gte=${
        !_.isEmpty(minPrice) ? minPrice : MinPrice_gte
      }`;
    }

    if (!_.isEmpty(maxPrice) || !_.isEmpty(MaxPrice_lte)) {
      query += `${getDelimiter(query)}MaxPrice_lte=${
        !_.isEmpty(maxPrice) ? maxPrice : MaxPrice_lte
      }`;
    }
  }

  if (!_.isEmpty(listingType)) {
    const type = formatListingType(listingType).toLowerCase();
    query += `${getDelimiter(query)}listingType=${type}`;
  }

  if (!_.isEmpty(listingSector)) {
    query += `${getDelimiter(
      query
    )}listingSector=${listingSector.toLowerCase()}`;
  }

  if (!_.isEmpty(landlord)) {
    query += `${getDelimiter(query)}landlord=${landlord}`;
  }

  if (!_.isEmpty(organisation)) {
    query += `${getDelimiter(query)}organisation=${organisation}`;
  }

  if (!_.isEmpty(cities)) {
    query += `${getDelimiter(query)}Cities_in=${cities}`;
  }

  if (!_.isEmpty(suburbs)) {
    query += `${getDelimiter(query)}Suburbs_in=${suburbs}`;
  }

  if (!_.isEmpty(minPrice) || !_.isEmpty(MinPrice_gte)) {
    query += `${getDelimiter(query)}MinPrice_gte=${
      !_.isEmpty(minPrice) ? minPrice : MinPrice_gte
    }`;
  }

  if (!_.isEmpty(maxPrice) || !_.isEmpty(MaxPrice_lte)) {
    query += `${getDelimiter(query)}MaxPrice_lte=${
      !_.isEmpty(maxPrice) ? maxPrice : MaxPrice_lte
    }`;
  }

  if (!_.isEmpty(beds)) {
    query += `${getDelimiter(query)}Beds_in=${beds}`;
  }

  if (!_.isEmpty(propertyTypes)) {
    query += `${getDelimiter(query)}PropertyTypes_in=${mapPropertyType(
      propertyTypes,
      true
    )}`;
  }

  if (!_.isEmpty(baths)) {
    query += `${getDelimiter(query)}Baths_in=${baths}`;
  }

  if (!_.isEmpty(features)) {
    features.forEach((feature: string) => {
      if (!_.isEmpty(feature)) {
        query += `${getDelimiter(query)}${feature}=true`;
      }
    });
  }
  return encodeURI(query);
}

export const removeExtraChar = (url: string, char: string) => {
  return url
    .split(char)
    .filter(function (x, n, s) {
      return s.indexOf(x) === n;
    })
    .join(char);
};

export function buildPath(params: any, pathname?: string) {
  let path = "";
  const { organisationName, listingSector, listingType } = params;
  if (!_.isEmpty(organisationName)) {
    path += `/${organisationName}`;
  }

  if (!_.isEmpty(listingSector)) {
    path += `/${listingSector}`;
  }

  if (!_.isEmpty(listingType)) {
    path += `/for-${listingType}`;
  }
  const cleanPath = pathname ? pathname + path : path;

  return encodeURI(cleanPath);
}

export const formatName = (orgName: string, reverse?: boolean): string => {
  if (!orgName) {
    return orgName;
  }
  return kebabCase(String(orgName).toLowerCase());
};

export const removeUrlSpace = (url: string) => {
  return url.trim().replace(/%20/g, "-");
};

export const formatListingType = (type: string) => {
  return type.trim().replace(/for-/gi, "");
};
