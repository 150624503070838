import _ from "lodash";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MuiSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { OrganisationColors } from "../../store/organisation";
import { getOrganisationColor } from "../../utils/whiteLabel";

export interface Option {
  title: string;
  active: boolean;
}
export interface CategorisedOption {
  category?: string;
  options: Option[];
}
interface OwnProps {
  id: string;
  name: string;
  label: string;
  value: any;
  options: string[] | Option[] | CategorisedOption[];
  multiple?: boolean;
  disabled?: boolean;
  minWidth?: number;
  optionsReset?: boolean;
  optionsResetLabel?: string;
  disabledOptionSuffix?: string;
  orgColors?: OrganisationColors;
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const StyledSelect = styled(MuiSelect)<{ primary?: boolean }>`
  .MuiSelect-root {
    padding-bottom: 2px;
    max-width: 150px;
    overflow: hidden;
    color: ${({ theme, primary }) =>
      primary ? theme.colors.orange : theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 14px;
    &:focus {
      background: none;
    }
    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
  .MuiSelect-icon {
    color: #c1c1c1;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 0 25px;
    .MuiTypography-root {
      font-family: ${({ theme }) => theme.fonts.MS300};
    }
  }
`;

const StyledMenuItemHeader = styled(StyledMenuItem)`
  && {
    padding: 5px 25px 0;
  }
`;

const StyledCheckbox = styled(Checkbox)<{ orgColors?: OrganisationColors }>`
  && {
    padding: 0 13px 0 0;
    &.Mui-checked {
      color: ${({ theme, orgColors }) =>
        getOrganisationColor(orgColors, "primaryColor", theme.colors.orange)};
    }
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      width: 300,
      padding: "20px 0",
    },
  },
};

const generateCategorisedOptions = (
  stringOptions: any
): CategorisedOption[] => {
  const options: Option[] = stringOptions.map((option: string) => ({
    title: option,
    active: true,
  }));

  return _.isEmpty(options) ? [] : [{ options }];
};

const Select: React.FunctionComponent<OwnProps> = ({
  id,
  name,
  label,
  value,
  disabledOptionSuffix,
  options,
  onChange,
  optionsResetLabel,
  optionsReset = false,
  multiple = false,
  disabled = false,
  minWidth = 60,
  orgColors,
}) => {
  const classes = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      maxWidth: 300,
      minWidth,
      width: "auto",
    },
  }))();

  const categorisedOptions: any = options.some(
    (option: any) => typeof option === "object"
  )
    ? options.some((option: any) => typeof option.options === "object")
      ? options
      : [{ options }]
    : generateCategorisedOptions(options);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <StyledSelect
        labelId={`${id}-checkbox-label`}
        id={`${id}-label`}
        name={name}
        multiple={multiple}
        value={value}
        onChange={onChange}
        input={<Input disableUnderline={true} />}
        renderValue={(selected: any) =>
          multiple ? `${selected.length} selected` : selected
        }
        MenuProps={MenuProps}
        disabled={disabled || _.isEmpty(categorisedOptions)}
      >
        {optionsReset && (
          <StyledMenuItem key="none" value="">
            <ListItemText
              primary={!!optionsResetLabel ? optionsResetLabel : "any"}
            />
          </StyledMenuItem>
        )}
        {categorisedOptions.map((categorisedOption: CategorisedOption) => {
          const { category } = categorisedOption;
          return [
            categorisedOption.category && (
              <StyledMenuItemHeader
                key={category}
                value={category}
                disabled={true}
              >
                {category}
              </StyledMenuItemHeader>
            ),
            categorisedOption.options.map((option: Option) => {
              const disabled = !option.active;
              const checked = String(value).includes(option.title);
              const titleSuffix =
                disabled && !!disabledOptionSuffix
                  ? ` ${disabledOptionSuffix}`
                  : "";
              const title = option.title + titleSuffix;

              return (
                <StyledMenuItem key={title} value={title} disabled={disabled}>
                  {multiple && (
                    <StyledCheckbox
                      color="primary"
                      disabled={disabled}
                      checked={checked}
                      orgColors={orgColors}
                    />
                  )}
                  <ListItemText primary={title} />
                </StyledMenuItem>
              );
            }),
          ];
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default Select;
