import * as React from "react";

import styled from "../../utils/styledComponents";

interface StepperIconProps {
  isFirst: boolean;
  isSelected: boolean;
}

const StepperContainer = styled.div`
  display: flex;
`;

const StyledStepperIcon = styled.span<StepperIconProps>`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${({ isSelected }) => isSelected ? `#000000` : `#979797`};
  margin-left: ${({ isFirst }) => isFirst ? `0` : `10px`};
  align-self: center;
`;

export interface OwnProps {
  steps: number;
  currentStep: number;
}

export const StepperIcons: React.FunctionComponent<OwnProps> = ({ steps, currentStep }) => {
  return (
    <StepperContainer>
      {[...new Array(steps)].map((value, index) => {
        const step = index + 1;
          return <StyledStepperIcon key={step} isFirst={step === 1} isSelected={step === currentStep} />
      })}
    </StepperContainer>
  );
};

export default StepperIcons;
