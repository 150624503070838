import { get, isEmpty, uniq } from "lodash";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import {
  AddressComponent,
  AddressType,
  GeocodingAddressComponentType,
  GeocodingResult,
  PlaceAutocompleteResult,
} from "@google/maps";
import { Chip, MenuItem, MenuList } from "@material-ui/core";

import localtionIcon from "../../assets/images/icons/location@3x.png";
import searchIcon from "../../assets/images/icons/search@3x.png";
import {
  fetchActiveListingsPromise,
  listingsSearchPromise,
} from "../../store/listings";
import { Chip as ChipProps, Listing, Search } from "../../store/listings/types";
import {
  buildQuery,
  mapQueryToPayload,
  mapValuesToQuery,
} from "../../utils/listingUrlHelper";
import styled, { mediaDown } from "../../utils/styledComponents";
import { ListingsFilterFormValues } from "../forms/ListingsFilterForm";
import Button from "../buttons/Button";
import { ApplicationState } from "../../store";
import { OrganisationColors } from "../../store/organisation";

const StyledMenuList = styled(MenuList)``;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 0;
  }
`;

const GooglePlacesAutocompleteContainer = styled.div`
  position: relative;
  display: flex;
  .google-places-autocomplete {
    position: relative;
    flex: 1;
  }
  .auto-complete-input-container {
    position: relative;
    display: flex;
    background: url("${searchIcon}") 13px center no-repeat;
    background-size: 14px;
    padding: 5px 5px 5px 37px;
    min-height: 14px;
    outline: none;
    border: 1px solid #f0f0f0;
    border-radius: 10px 0 0 10px;
    flex-wrap: wrap;
    align-items: center;
  }
  .suggestions-container {
    position: absolute;
    top: 100%;
    border: 1px solid #efefef;
    border-radius: 5px;
    background: white;
    margin-top: 5px;
    padding: 6px 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .auto-complete-input {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    padding: 6px 3px;
    border: none;
    outline: none;
    flex: 1;
    width: 5px;
    ${mediaDown.sm`
      font-size: 16px;
    `};
  }
  .auto-complete-input::placeholder {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    color: ${({ theme }) => theme.colors.grey2};
  }
  .suggestion {
    background: url("${localtionIcon}") 15px center no-repeat;
    background-size: 10px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex: 1;
    color: #a8a8a8;
    padding: 4px 15px;
    padding-left: 37px;
    cursor: pointer;
    border: none;
  }
  .suggestion-description {
    position: relative;
    white-space: nowrap;
  }
  .suggestion-search-term {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    color: #4c4c4e;
  }
  .suggestion-type {
    margin-left: auto;
  }
`;

const SearchButton = styled(Button)`
  && {
    text-transform: none;
    font-family: ${({ theme }) => theme.fonts.MS500};
    border-radius: 0 10px 10px 0;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    ${mediaDown.sm`
      height: 42px;
    `};
    &.Mui-disabled {
      background: ${({ theme }) => theme.colors.grey1} !important;
    }
  }
`;

const StyledChip = styled(Chip)`
  && {
    margin: 0 2px;
    border-radius: 5px;
  }
`;

interface OwnProps {
  setFieldValue: (field: string, value: any) => void;
  submitForm: () => void;
  setUpdateSearch: (value: boolean) => void;
  fetchActiveListings?: typeof fetchActiveListingsPromise;
  listingsSearch?: typeof listingsSearchPromise;
  isSubmitting: boolean;
  updateSearch: boolean;
  searchBtnText: string;
  values: ListingsFilterFormValues;
  searchValues: Search;
  listings?: Listing[];
  colors?: OrganisationColors;
}

const SimpleAddressSearch: React.FunctionComponent<OwnProps> = ({
  setFieldValue,
  submitForm,
  isSubmitting,
  setUpdateSearch,
  searchBtnText,
  values,
  listingsSearch,
  searchValues,
  updateSearch,
  fetchActiveListings,
  listings,
  colors,
}) => {
  const referer = localStorage.getItem("referer");
  const isFromTenantsPage = referer === "/tenants";
  const windowQuery = window.location.search;
  const [chips, setChips] = useState<ChipProps[]>(
    get(searchValues, "chips", [])
  );
  const [inputValue, setInputValue] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const prevValue = usePrevious(inputValue);
  const [deleteChips, setDeleteChips] = useState(false);
  const [
    shouldHandleQueryFromTenantsPage,
    setShouldHandleQueryFromTenantsPage,
  ] = useState(isFromTenantsPage && !isEmpty(windowQuery));
  const [googlePlacesAutocompleteRef, setGooglePlacesAutocompleteRef] =
    useState<any>(null);

  function usePrevious<T>(value: T): T {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current as T;
  }
  const handleSubmit = () => {
    const payload = mapValuesToQuery(values);
    const query = buildQuery(payload);
    const url = window.location.pathname + query;
    const $search = {
      chips,
      query,
    };

    if (listingsSearch) {
      listingsSearch($search);
    }

    window.history.pushState("", "", url);
    submitForm();
  };

  const getAddress = (place: GeocodingResult) => {
    const address = {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postCode: "",
      province: "",
      suburb: "",
    };

    const addressComponents: AddressComponent[] = place.address_components;

    addressComponents.forEach((component: AddressComponent) => {
      component.types.forEach(
        (type: AddressType | GeocodingAddressComponentType) => {
          const longName: string = component.long_name;

          switch (type) {
            case "street_number":
              address.line1 = longName;
              break;
            case "street_address":
            case "route":
              address.line1 = address.line1
                ? `${address.line1} ${longName}`
                : longName;
              break;
            case "sublocality":
            case "neighborhood":
              if (!!!address.suburb) {
                address.suburb = longName;
              }
              break;
            case "locality":
              address.city = longName;
              break;
            case "administrative_area_level_1":
              address.province = longName;
              break;
            case "country":
              address.country = longName;
              break;
            case "postal_code":
              address.postCode = longName;
              break;
            default:
              break;
          }
        }
      );
    });

    return address;
  };

  const handleOnSelect = (result: PlaceAutocompleteResult) => {
    const googlePlacesAutocompleteInput: any = document.getElementById(
      "react-google-places-autocomplete-input"
    );
    const { cities, suburbs } = values;
    const $chips = [...chips];
    const placeId = result.place_id;
    const chipExists = !isEmpty(
      $chips.find(({ id }: ChipProps) => id === placeId)
    );

    if (!chipExists) {
      // Set chips using main text for UX purposes
      setDisableSubmitBtn(true);
      setChips([
        ...chips,
        {
          city: "",
          id: "temp",
          suburb: result.structured_formatting.main_text,
        },
      ]);
    }

    geocodeByPlaceId(placeId).then((places: [any]) => {
      const address = getAddress(places[0]);
      const { city, suburb } = address;
      const $cities = uniq([...cities, city]);
      const $suburbs = suburb ? uniq([...suburbs, suburb]) : suburbs;

      if (!chipExists) {
        // Set chips using real address
        setChips([
          ...$chips,
          {
            city,
            id: placeId,
            suburb,
          },
        ]);
      }

      setFieldValue("cities", $cities);
      setFieldValue("suburbs", $suburbs);
      setUpdateSearch(true);
      setDisableSubmitBtn(false);
    });

    if (googlePlacesAutocompleteInput) {
      googlePlacesAutocompleteInput.focus();
    }

    googlePlacesAutocompleteRef.changeValue("");
  };

  const getPlaceType = (types: AddressType[]) => {
    switch (true) {
      case types.includes("neighborhood"):
        return "Neighborhood";

      case types.includes("sublocality"):
        return "Suburb";

      case types.includes("locality"):
        return "City";

      default:
        return "Place";
    }
  };

  const isNeighborhoodSuburbOrCity = (types: AddressType[]) => {
    return (
      types.includes("neighborhood") ||
      types.includes("sublocality") ||
      types.includes("locality")
    );
  };

  const renderSuggestions = (
    active: number,
    suggestions: PlaceAutocompleteResult[],
    onSelectSuggestion: (
      selection: PlaceAutocompleteResult,
      event: React.MouseEvent<any, MouseEvent>
    ) => void
  ) => {
    const $search: string | null | undefined = document
      .getElementById("react-google-places-autocomplete-input")
      ?.getAttribute("value");
    const searchTerm: string = !!$search ? capitalizeFirstLetter($search) : "";
    const filteredSuggestions: PlaceAutocompleteResult[] = suggestions.filter(
      ({ types }) => isNeighborhoodSuburbOrCity(types)
    );
    const lastSuggestionIndex: number | null = isEmpty(filteredSuggestions)
      ? null
      : filteredSuggestions.length - 1;
    const focusedSuggestionIndex: number | null =
      active === null ? null : active === 0 ? 0 : lastSuggestionIndex;
    const focusedSuggestionElement: HTMLElement | null =
      focusedSuggestionIndex === null
        ? null
        : document.getElementById(`suggestion-${focusedSuggestionIndex}`);

    function capitalizeFirstLetter(letters: string) {
      return letters.charAt(0).toUpperCase() + letters.slice(1);
    }

    if (!isEmpty(focusedSuggestionElement)) {
      focusedSuggestionElement?.focus();
    }

    return !isEmpty(filteredSuggestions) ? (
      <div className="suggestions-container" tabIndex={-1}>
        <StyledMenuList>
          {filteredSuggestions.map(
            (suggestion: PlaceAutocompleteResult, index: number) => {
              const { description, types } = suggestion;
              const searchTermHighlightText = description.substring(
                0,
                searchTerm.length
              );
              const searchTermHighlight = (
                <span className="suggestion-search-term">
                  {searchTermHighlightText}
                </span>
              );
              const newDescription = description.replace(
                searchTermHighlightText,
                ""
              );

              function handleOnClick(event: React.MouseEvent<any, MouseEvent>) {
                onSelectSuggestion(suggestion, event);
              }

              return (
                <StyledMenuItem
                  id={`suggestion-${index}`}
                  key={index}
                  onClick={handleOnClick}
                >
                  <div className="suggestion">
                    <span className="suggestion-description">
                      {searchTermHighlight}
                      {newDescription}
                    </span>
                    <span className="suggestion-type">
                      {getPlaceType(types)}
                    </span>
                  </div>
                </StyledMenuItem>
              );
            }
          )}
        </StyledMenuList>
      </div>
    ) : (
      <></>
    );
  };

  const handleChipDelete = (index: number) => {
    const { cities, suburbs } = values;
    const newChips = [...chips];
    const deletedChip: ChipProps = newChips.splice(index, 1)[0];
    const citiesArray = cities ? String(cities).split(",") : [];
    const suburbsArray = suburbs ? String(suburbs).split(",") : [];

    if (
      newChips.filter(({ city }: ChipProps) => city === deletedChip.city)
        .length === 0
    ) {
      const newCities = citiesArray.filter(
        (city: string) => city !== deletedChip.city
      );

      setFieldValue("cities", newCities);
    }

    if (deletedChip.suburb) {
      const newSuburbs = suburbsArray.filter(
        (suburb: string) => suburb !== deletedChip.suburb
      );

      setFieldValue("suburbs", newSuburbs);
    }

    setUpdateSearch(true);
    setChips(newChips);
  };

  const handleRenderInput = (props: any) => {
    const { value } = props;
    setInputValue(value);

    if (value && value.length > prevValue.length) {
      setDeleteChips(false);
    }

    function handleOnKeyUpCapture(event: KeyboardEvent) {
      const { key, keyCode } = event;

      if (key === "Enter" || keyCode === 13) {
        if (!disableSubmitBtn) {
          handleSubmit();
        }

        setInputValue("");

        return;
      }

      if (key === "Backspace" || keyCode === 8) {
        if (
          chips.length > 0 &&
          (deleteChips || (isEmpty(value) && isEmpty(prevValue)))
        ) {
          handleChipDelete(chips.length - 1);

          return;
        }

        if (isEmpty(value) && prevValue.length === 1) {
          setDeleteChips(true);
        }
      }

      return;
    }

    return (
      <div className="auto-complete-input-container">
        {chips.map(({ city, suburb }: ChipProps, index: number) => {
          function onDelete() {
            handleChipDelete(index);
          }

          return (
            <StyledChip
              key={index}
              variant="outlined"
              color="secondary"
              size="small"
              label={suburb ? suburb : city}
              onDelete={onDelete}
            />
          );
        })}
        <input
          className="auto-complete-input"
          {...props}
          value={inputValue}
          onKeyUpCapture={handleOnKeyUpCapture}
        />
      </div>
    );
  };

  const handleQueryFromTenantsPage = useCallback(
    (query: string) => {
      const queryObject = queryString.parse(decodeURI(query));
      const { Cities_in, Suburbs_in } = queryObject;
      const cities = !isEmpty(Cities_in) ? String(Cities_in).split(",") : [];
      const suburbs = !isEmpty(Suburbs_in) ? String(Suburbs_in).split(",") : [];

      setShouldHandleQueryFromTenantsPage(false);

      if (cities.length === 1 && suburbs.length === 1) {
        const $city = cities[0];
        const $suburb = suburbs[0];

        // Set chips using main text for UX purposes
        setChips([
          {
            city: $city,
            id: "temp",
            suburb: $city,
          },
        ]);

        geocodeByAddress(`${$suburb}, ${$city}`).then((places: [any]) => {
          const place = places[0];
          const { place_id } = place;
          const address = getAddress(place);
          const { city, suburb } = address;

          // Set chips using real address
          setChips([
            {
              city,
              id: place_id,
              suburb,
            },
          ]);
        });

        return true;
      }

      return false;
    },
    [setChips]
  );

  useEffect(() => {
    const localStorageSearch = localStorage.getItem("search");
    const cachedSearch = localStorageSearch
      ? JSON.parse(localStorageSearch)
      : null;

    if (
      cachedSearch &&
      cachedSearch.query &&
      !cachedSearch.query.includes("listingType")
    ) {
      cachedSearch.query = `${cachedSearch.query}&listingType=sale`;
    }
    const cachedQuery = cachedSearch ? cachedSearch.query : "?listingType=sale";
    const windowQueryObject = mapQueryToPayload(windowQuery);
    const cachedSearchObject = mapQueryToPayload(cachedQuery);

    // TODO: fix the price query

    // if (fetchActiveListings) {
    //   const payload = mapQueryToPayload(windowQuery);

    //   fetchActiveListings(payload);
    // }

    // If the user changes the cities/suburbs manually on the url we lose the chips
    if (
      cachedSearch &&
      windowQueryObject.suburbs.join() === cachedSearchObject.suburbs.join() &&
      windowQueryObject.cities.join() === cachedSearchObject.cities.join()
    ) {
      setChips(cachedSearch.chips);
    } else {
      setChips([]);
    }
  }, [windowQuery, fetchActiveListings, setChips]);

  useEffect(() => {
    const $search: Search | null = searchValues ? searchValues : null;
    const query = isFromTenantsPage
      ? windowQuery
      : $search
      ? $search.query
      : null;
    const hasHandledQueryFromTenantsPage =
      isFromTenantsPage &&
      shouldHandleQueryFromTenantsPage &&
      query &&
      handleQueryFromTenantsPage(query);

    if (query && !hasHandledQueryFromTenantsPage) {
      // const payload = mapQueryToPayload(query);

      if ($search) {
        if (!isEmpty($search.chips)) {
          setChips($search.chips);
        }

        // todo remove as it creates multiple requests
        // fetchActiveListings(payload);
      }
    }
  }, [
    searchValues,
    setChips,
    fetchActiveListings,
    handleQueryFromTenantsPage,
    isFromTenantsPage,
    shouldHandleQueryFromTenantsPage,
    windowQuery,
  ]);

  return (
    <GooglePlacesAutocompleteContainer>
      <GooglePlacesAutocomplete
        ref={(ref) => setGooglePlacesAutocompleteRef(ref)}
        inputClassName="auto-complete-input"
        placeholder={
          updateSearch || !isEmpty(chips) ? "" : "Search Suburb or City"
        }
        autocompletionRequest={{
          componentRestrictions: {
            country: ["za"],
          },
        }}
        loader={<></>}
        onSelect={handleOnSelect}
        renderSuggestions={renderSuggestions}
        renderInput={handleRenderInput}
      />
      <SearchButton
        disabled={isSubmitting || disableSubmitBtn}
        type="button"
        onClick={submitForm}
        primary={true}
        orgColors={colors}
      >
        {searchBtnText}
      </SearchButton>
    </GooglePlacesAutocompleteContainer>
  );
};

const mapStateToProps = ({ listing }: ApplicationState) => ({
  listings: listing.listings,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchActiveListings: fetchActiveListingsPromise,
      listingsSearch: listingsSearchPromise,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleAddressSearch);
