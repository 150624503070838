import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const authenticate = createRoutine("@@auth/AUTHENTICATE");
export const authenticatePromise = promisifyRoutine(authenticate);
export const forgotPassword = createRoutine("@@auth/FORGOT_PASSWORD");
export const forgotPasswordPromise = promisifyRoutine(forgotPassword);
export const login = createRoutine("@@auth/LOGIN");
export const loginPromise = promisifyRoutine(login);
export const loginWithGoogle = createRoutine("@@auth/LOGIN_WITH_GOOGLE");
export const loginWithGooglePromise = promisifyRoutine(loginWithGoogle);
export const loginWithFacebook = createRoutine("@@auth/LOGIN_WITH_FACEBOOK");
export const loginWithFacebookPromise = promisifyRoutine(loginWithFacebook);
export const register = createRoutine("@@auth/REGISTER");
export const registerPromise = promisifyRoutine(register);
export const logout = createRoutine("@@auth/LOGOUT");
export const resetPassword = createRoutine("@@auth/RESET_PASSWORD");
export const resetPasswordPromise = promisifyRoutine(resetPassword);
export const validateToken = createRoutine("@@auth/VALIDATE_TOKEN");
export const updateUser = createRoutine("@@auth/UPDATE_USER");
export const updateUserPromise = promisifyRoutine(updateUser);
export const uploadProfileImage = createRoutine("@@auth/UPLOAD_PROFILE_IMAGE");
export const requestOTP = createRoutine("@@auth/REQUEST_OTP");
export const requestOTPPromise = promisifyRoutine(requestOTP);
export const verifyOTP = createRoutine("@@auth/VERIFY_OTP");
export const verifyOTPPromise = promisifyRoutine(verifyOTP);
