import { omit } from 'lodash';
import * as React from 'react';

import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

import styled from '../../utils/styledComponents';

interface OwnProps {
  light?: boolean;
}

type AllProps = TypographyProps & OwnProps;

const Heading = styled((props: AllProps) => (
  <MuiTypography {...omit(props, ["light"])} />
)).attrs({
  variant: "h6"
})`
  && {
    font-family: ${({ theme, light }) =>
      light ? theme.fonts.MS500 : theme.fonts.MS700};
    font-weight: ${({ theme, light }) =>
      light ? theme.fontWeights.MS500 : theme.fontWeights.MS700};
    font-size: ${({ theme }) => theme.fontSizes.heading};
    color: ${({ theme }) => theme.colors.grey4};
    line-height: 34px;
  }
`;

export default Heading;
