import omit from 'lodash/omit';
import React, { FunctionComponent } from 'react';

// import { IconName } from "@fortawesome/fontawesome-svg-core";
import { PaperProps } from '@material-ui/core/Paper';

import { FlowExtra } from '../../store/listings';
import styled from '../../utils/styledComponents';
import FlowFontAwesomeIcon from '../icons/FlowFontAwesomeIcon';
import Paper from '../ui/Paper';

const SytledContainer = styled(
  (props: PaperProps & { isDialogView: boolean; color: string }) => (
    <Paper {...omit(props, ["isDialogView", "color"])} />
  )
)`
  && {
    border-radius: 10px !important;
    display: flex;
    padding: 7.5px;
    margin: 10px;
    margin-left: 0px;
    justify-content: center;

    ${({ isDialogView, color }) =>
      isDialogView
        ? `background: ${color}; flex-grow: 1; margin: 0px; padding: 39px 0px 31px 0px;`
        : `background: #f7f7f7; cursor: pointer;`}
  }
`;

const OfferTextWrapper = styled.div<{ isDialogView: boolean }>`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-size: ${({ theme }) => theme.fontSizes.heading};
    color: ${({ isDialogView, theme }) =>
      isDialogView ? theme.colors.white : theme.colors.grey4};
  }
  line-height: 12px;
  letter-spacing: -0.5px;
  text-align: left;
  white-space: nowrap;
`;

const PromoTextWrapper = styled.div<{ isDialogView: boolean }>`
  && {
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 11.2px;
    color: ${({ isDialogView, theme }) =>
      isDialogView ? theme.colors.white : theme.colors.grey2};
    line-height: 12px;
    letter-spacing: -0.5px;
    margin-top: 1.5px;
    text-align: left;
    white-space: nowrap;
  }
`;

const IconWrapper = styled.div`
  margin-top: -2px;
`;

interface OwnProps {
  data: FlowExtra;
  onItemClick?: (selectedId: string) => void;
}

type AllProps = OwnProps;

const TextWrapper = styled.div`
  margin: 0 10px;
  align-content: center;
`;

const ExtraItem: FunctionComponent<AllProps> = ({ data, onItemClick }) => {
  const onClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (onItemClick) {
      onItemClick(data._id);
    }
  };
  const isDialogView = onItemClick ? false : true;

  return (
    <SytledContainer
      onClick={onClick}
      id={data._id}
      isDialogView={isDialogView}
      color={data.color}
    >
      <IconWrapper>
        <FlowFontAwesomeIcon
          name={data.icon}
          color={isDialogView ? "#ffffff" : data.color}
        />
      </IconWrapper>
      <TextWrapper>
        <OfferTextWrapper isDialogView={isDialogView}>
          {data.title}
        </OfferTextWrapper>
        <PromoTextWrapper isDialogView={isDialogView}>
          {data.subtitle}
        </PromoTextWrapper>
      </TextWrapper>
    </SytledContainer>
  );
};

export default ExtraItem;
