import { has, isEmpty, get } from "lodash";
import TagManager from "react-gtm-module";

import { Listing } from "../store/listings";
import { mapQueryToValues } from "./listingUrlHelper";

const gtmId: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
const preview: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV as string;
const auth: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH as string;
export interface DataLayer {
  event: string;
  page: string;
  data?: any;
  user?: any;
}

export const initializeGTM = (rawData: any = undefined): void => {
  TagManager.initialize({
    auth,
    gtmId,
    preview,
    dataLayer: deriveRawData(rawData),
  });
};

const deriveRawData = (rawData: any) => {
  const user = {
    contactNumber: get(rawData, "user.contactNumber"),
    firstName: get(rawData, "user.firstName"),
    lastName: get(rawData, "user.lastName"),
    email: get(rawData, "user.email"),
  };

  return { user: !isEmpty(user) ? user : undefined };
};

export const addGTMDataLayer = (tagManagerArgs: DataLayer): void => {
  TagManager.dataLayer({
    dataLayer: tagManagerArgs,
  });
};

export const deriveListingsDataLayer = (
  listings: Listing[],
  event: string,
  page: string
) => {
  const urlQuery = encodeURI(window.location.search);
  const valuesAsObject = mapQueryToValues(urlQuery);
  const { cities, suburbs } = valuesAsObject;

  Object.keys(cities).forEach((key) => {
    const city = cities[key];
    const listingsByCity = !isEmpty(listings)
      ? listings.filter(
          (item) => item.address.city.toLowerCase() === city.toLowerCase()
        )
      : [];

    let province = "";
    if (!isEmpty(listingsByCity)) {
      province = has(listingsByCity[0], "address.province")
        ? listingsByCity[0].address.province
        : "";

      const ids = Object.keys(listingsByCity).map(
        (index) => listingsByCity[index]._id
      );
      const suburbsInListings = Object.keys(listingsByCity).map(
        (index) => listingsByCity[index].address.suburb
      );

      let suburb = "";
      Object.keys(suburbs).forEach((index) => {
        if (
          suburbsInListings.some(
            (s) => s.toLowerCase() === suburbs[index].toLowerCase()
          )
        ) {
          suburb = suburbs[key];
        }
      });

      addGTMDataLayer({
        data: {
          city,
          listingIds: ids,
          province,
          searchTerms: urlQuery,
          suburb,
        },
        event,
        page,
      });
    }
  });
};
