import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

import styled from '../../../utils/styledComponents';

type AllProps = TypographyProps;

const FormSectionHeading = styled(MuiTypography as React.FunctionComponent<
  AllProps
>).attrs({
  variant: "h4"
})`
  && {
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ theme }) => theme.fontSizes.formSectionHeading};
    color: ${({ theme }) => theme.colors.grey4};
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

export default FormSectionHeading;
