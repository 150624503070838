import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { logEvent } from '../analytics';
import { unsetDeeplink } from '../deeplink';
import { enqueueSnackbar } from '../notifications';
import { acceptInvite, validateInviteToken } from './routines';

function* handleValidateInviteToken(action: AnyAction) {
  try {
    yield put(validateInviteToken.request());
    const res = yield call(
      callApi,
      "get",
      `/invites/accept/${action.payload.token}`
    );
    yield put(validateInviteToken.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(validateInviteToken.failure(errorHandler(err.response)));
    } else {
      yield put(validateInviteToken.failure("An uknown error occured"));
    }
  } finally {
    yield put(validateInviteToken.fulfill());
  }
}

function* handleAcceptInvite(action: AnyAction) {
  try {
    yield put(acceptInvite.request());
    const res = yield call(
      callApi,
      "post",
      `/invites/accept/${action.payload.token}`,
      { data: action.payload.data }
    );
    yield put(acceptInvite.success(res.data));

    if (action.payload.data.userDetails) {
      yield put(
        logEvent({
          data: {
            UserType: action.payload.data.userDetails.userType,
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName}`,
          },
          event: "Completed registration",
          exclude: ["intercom"],
        })
      );
    }

    yield put(push("/tenants"));

    yield put(unsetDeeplink());
    yield put(
      enqueueSnackbar({
        message: "Invite accepted",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        yield put(
          acceptInvite.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
        yield put(
          enqueueSnackbar({
            message:
              "Could not register user. An account with the supplied email already exists.",
            options: {
              variant: "error",
            },
          })
        );
      } else {
        yield put(acceptInvite.failure(errorHandler(err.response)));
        yield put(
          enqueueSnackbar({
            message: errorHandler(err.response),
            options: {
              variant: "error",
            },
          })
        );
      }
    } else {
      yield put(acceptInvite.failure("An uknown error occured"));
    }
  } finally {
    yield put(acceptInvite.fulfill());
  }
}

function* validateInviteTokenWatcher() {
  yield takeEvery(validateInviteToken.TRIGGER, handleValidateInviteToken);
}

function* acceptInviteWatcher() {
  yield takeEvery(acceptInvite.TRIGGER, handleAcceptInvite);
}

export function* inviteSaga() {
  yield all([fork(validateInviteTokenWatcher), fork(acceptInviteWatcher)]);
}
