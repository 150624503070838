import React from 'react';

import List, { ListProps } from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "100%",
      width: "100%",
    },
  })
);

const FlowExtraList: React.FunctionComponent<ListProps> = (
  props: ListProps
) => {
  const classes = useStyles();
  return <List className={classes.root} {...props} />;
};

export default FlowExtraList;
