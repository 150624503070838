import { transparentize } from 'polished';
import React from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import MuiCircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

import styled from '../../utils/styledComponents';
import { GoogleIcon } from '../icons';
import Button from './Button';

interface OwnProps {
  loading?: boolean;
}

type AllProps = ButtonProps & OwnProps;

const Icon = styled(GoogleIcon)`
   font-size: 24px;
  margin-right: 10px;
  background: #fff;
  flex-shrink: 0;
`;

const StyledText = styled.div`&&{
  color: #EA4336;
  font-size: 16px;
  font-family:  ${({ theme }) => theme.fonts.MS500};
  font-weight:  ${({ theme }) => theme.fontWeights.MS500};
}`;


const StyledButton = styled(Button)`
  && {
    color: #000 !important;
    border-color: ${({ theme }) => theme.colors.grey3} !important;
    white-space: nowrap;
    &:hover {
      background: ${transparentize("0.25", "#fff")}!important;
      border-color: ${({ theme }) => theme.colors.grey3} !important;
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;

const StyledProgress = styled(MuiCircularProgress as React.FunctionComponent<
  CircularProgressProps
>)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline;
  width: 100%;
`;

const GoogleButton: React.FunctionComponent<AllProps> = ({
  loading,
  ...props
}) => {
  return (
    <Wrapper>
      <StyledButton primary={true} outline={true} {...props}>
        <Icon nativeColor="#fff" />
        <StyledText>
          {props.children}
        </StyledText>
      </StyledButton>
      {loading && <StyledProgress size={24} color="secondary" />}
    </Wrapper>
  );
};

export default GoogleButton;