import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../../utils/styledComponents';

const FormButtonWrapper = styled(
  (props: React.FunctionComponent<GridProps>) => (
    <Grid container={true} {...props} />
  )
)`
  padding: 10px;
  > div {
    padding: 8px;
  }
` as any;

export default FormButtonWrapper;
