import { Unit } from "../store/units";
import { Unit as ListingUnit } from "../store/listings";

export const getUnitPrice = (
  unit?: Unit | ListingUnit,
  isGtm = false
): number | string | undefined =>
  isGtm && unit
    ? unit.rentalAmount
    : unit && (unit.displayPrice || unit.rentalAmount);
