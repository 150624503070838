import { Field, Form, InjectedFormikProps } from 'formik';
import React from 'react';

import styled from '../../utils/styledComponents';
import Button from '../buttons/Button';
import DatePicker from '../inputs/DatePicker';
import DropDown from '../inputs/DynamicDropDown';
import IDNumberToggle from '../inputs/IDNumberToggle';
import TextInput from '../inputs/TextInput';
import { FieldWrapper, FormFieldsContainer } from '../ui/forms';
import StepperIcons from './StepperIcons';

export interface TenantDemographicsFormValues {
  work?: string;
  monthlyIncome?: string;
  employmentType?: string;
  idNumber?: string;
  moveDate?: string;
}

const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
`;

const ButtonWrapper = styled.div`
  padding: 35px 0 0;
  display: flex;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

const IntroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
  color: ${({ theme }) => theme.colors.grey4};
  margin: 0 20px 20px;
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.MS300};
  color: #868788;
  margin: 0;
`;

export const ProfileForm: React.FunctionComponent<InjectedFormikProps<
  {},
  TenantDemographicsFormValues
>> = (props) => {
  const { isSubmitting, values, setFieldValue, errors } = props;

  return (
    <Form>
      <FormFieldsContainer style={{ flexDirection: "column" }} spacing={4}>
        <IntroTitle>Profile Information</IntroTitle>
        <FieldWrapper item={true} xs={12}>
          <Description>
            Use your profile information to ensure you qualify for the best
            apartments. Once your profile is created , the hard work is done and
            you can use the same profile for multiple applications
          </Description>
        </FieldWrapper>
        <FieldWrapper md={12} item>
          <Field
            type="text"
            label="Where do you work?"
            name="work"
            component={TextInput}
          />
        </FieldWrapper>
        <FieldWrapper md={12} item>
          <DropDown
            dataset={[
              "R5 000 - R7 000",
              "R8 000 - R10 000",
              "R11 000 - R13 000",
              "R14 000 - R16 000",
              "R17 000 - R19 000",
              "R20 000 - R22 000",
              "R23 000 - R25 000",
              "R26 000 - R28 000",
              "R29 000 - R31 000",
              "R32 000 - R34 000",
              "R35 000 - R37 000",
              "R38 000 - R40 000",
              "R41 000+",
            ].reduce((x: any, y: string) => {
              return typeof x === "object"
                ? { ...x, [y]: y }
                : { [x]: x, [y]: y };
            })}
            values={values}
            setFieldValue={setFieldValue}
            label={"What's your monthly income?"}
            bind={"monthlyIncome"}
          />
        </FieldWrapper>
        <FieldWrapper md={12} item>
          <DropDown
            dataset={{
              "Part-time": "Part-time",
              Permanent: "Permanent",
              "Self-employed": "Self-employed",
              Student: "Student",
            }}
            values={values}
            setFieldValue={setFieldValue}
            label={"What's your employment type?"}
            bind={"employmentType"}
          />
        </FieldWrapper>
        <FieldWrapper md={12} item>
          <DatePicker
            label="When do you need to move?"
            selectedDate={values.moveDate}
            handleDateChange={(date: Date) =>
              setFieldValue("moveDate", new Date(date).toString())
            }
            name="moveDate"
            errors={errors}
            disabled={isSubmitting}
          />
        </FieldWrapper>
        <FieldWrapper md={12} item>
          <IDNumberToggle values={values} setFieldValue={setFieldValue} />
        </FieldWrapper>
      </FormFieldsContainer>
      <ButtonWrapper>
        <StepperIcons steps={2} currentStep={2} />
        <ButtonContainer>
          <StyledButton
            type="submit"
            disabled={isSubmitting}
            primary={true}
            loading={isSubmitting}
          >
            Done
          </StyledButton>
        </ButtonContainer>
      </ButtonWrapper>
    </Form>
  );
};

export default ProfileForm;
