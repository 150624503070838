import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchOrganisation = createRoutine(
  "@@organisation/FETCH_ORGANISATION"
);

export const fetchOrganisationPromise = promisifyRoutine(fetchOrganisation);

export const sendBroadEnquiry = createRoutine("@@organisation/SEND_BROAD_ENQUIRY");
export const sendBroadEnquiryPromise = promisifyRoutine(sendBroadEnquiry);
