import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Grid from '@material-ui/core/Grid';

import Button from '../../components/buttons/Button';
import { Content, Page } from '../../components/layout';
import Title from '../../components/typography/Title';
import ForgotPasswordFormContainer from '../../containers/ForgotPasswordFormContainer';
import { toggleLoginDialog as toggleLoginDialogAction } from '../../store/dialogs';
import styled from '../../utils/styledComponents';

const Spacer = styled.div`
  height: 20px;
`;

const TitleWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
`;

interface PropsFromDispatch {
  toggleLoginDialog: typeof toggleLoginDialogAction;
}

type AllProps = PropsFromDispatch;

class ForgotPasswordIndexPage extends React.Component<AllProps> {
  public render() {
    return (
      <Page>
        <Content justify="center" alignItems="center">
          <Grid item={true} md={12}>
            <TitleWrapper>
              <Title>Request password reset</Title>
            </TitleWrapper>
            <ForgotPasswordFormContainer />
            <Spacer />
            <Button onClick={this.openLoginDialog} fullWidth={true} text={true}>
              Back to Log in
            </Button>
          </Grid>
        </Content>
      </Page>
    );
  }

  private openLoginDialog = () => {
    this.props.toggleLoginDialog(true);
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    { toggleLoginDialog: toggleLoginDialogAction },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordIndexPage);
